import React from "react";

function SearchInput({ handleInputChange, searchTerm }) {
    return (
        <div className="d-flex align-items-end justify-content-end mt-3 me-4 ">
            <div className="search searchClass">
                <div className="searchInputs searchInputDiv">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={handleInputChange}
                        value={searchTerm}
                    />
                    <button className="searchIcon searchBtn">
                        <span className="pi pi-search"></span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SearchInput;
