export default function secondsToHms(d) {
    const dec = Number(d);
    var h = Math.floor(dec / 3600);
    var m = Math.floor((dec % 3600) / 60);
    var s = Math.floor((dec % 3600) % 60);

    // Create a string representation
    const hStr = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
    const mStr = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
    const sStr = s > 0 ? s + (s === 1 ? " sec " : " secs ") : "";

    return hStr + mStr + sStr;
}
