import React, { useRef } from "react";
import { t } from "i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./sliderStyle.css";
import Card from "../../common/card/Card";
// import { useState } from "react";

function CarouselBlock(props) {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1400 },
            items: props.componentName === "Recommendation" ? 5 : 4,
            slidesToSlide: props.componentName === "Recommendation" ? 5 : 4
        },
        desktop: {
            breakpoint: { max: 1400, min: 1200 },
            items: props.componentName === "Recommendation" ? 5 : 4,
            slidesToSlide: props.componentName === "Recommendation" ? 5 : 4
        },
        tablet: {
            breakpoint: { max: 1200, min: 991 },
            items: 3,
            slidesToSlide: 3
        },
        mobile: {
            breakpoint: { max: 991, min: 576 },
            items: 2,
            slidesToSlide: 2
        },
        xsmobile: {
            breakpoint: { max: 576, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };
    const carouselRef = useRef(null); 
    const ButtonGroup = ({ next, previous, ...rest }) => {
        const {
            carouselState: { currentSlide, totalItems, slidesToShow }
        } = rest;
        return (
            <div className="carousel-button-group">
                <button aria-label="Go to previous slide"
                    className={currentSlide === 0 ? "prev-button disable-btn" : "prev-button"}
                    onClick={() => previous()}></button>
                <button aria-label="Go to next slide" 
                    className={totalItems >= slidesToShow?currentSlide === totalItems - slidesToShow ? "next-button disable-btn" : "next-button":"next-button disable-btn"}
                    onClick={() => next()}></button>
            </div>
        );
    };
    
    return (
        <React.Fragment>
            <div className="carousel-container p-0">
                <Carousel
                    responsive={responsive}
                    showDots={true}
                    keyBoardControl={true}
                    containerClass="carousel-container"
                    renderArrowsWhenDisabled={true}
                    arrows={false}
                    infinite={false}
                    ref={carouselRef}
                    customButtonGroup={<ButtonGroup
                        next={props.next}
                        previous={props.previous}
                        rest={props.rest}
                    />}>
                    {props.myLearningObj ? (
                        // to not show DNS Card
                        props.myLearningObj
                            .filter((e) => !e.name.includes("DNS"))
                            .map((e) => (
                                <Card
                                    key={e.id}
                                    componentName={props.componentName}
                                    data={e}
                                    playCourse={props.playCourse}
                                    showCartPopUp={false}
                                    homePageMyLearning={props?.homePageMyLearning}
                                    fromHomePage={props?.fromHomePage}
                                    fromUpcommingOverdue={props?.fromUpcommingOverdue}></Card>
                            ))
                    ) : (
                        <h4 className="no-data">{t("no-courses-are-available")}</h4>
                    )}
                </Carousel>
            </div>
        </React.Fragment>
    );
}

export default CarouselBlock;