import React, { useEffect, useState, useContext, useRef } from "react";
import { apis } from "../../services/Api";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { InputGroup, FormControl } from "react-bootstrap";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import "./SearchCourseDetails.css";
import { useTranslation } from "react-i18next";
import Card1 from "../../common/card/Card1";
import Card from "../../common/card/Card";
import { appContext } from "../../context/appContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { filterJson } from "../../constant/filterJSON";
import Loader from "../../common/Loader/Loader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import scrollUp from "../../assets/images/scroll-up.png";
import { useSearchParams, useNavigate } from "react-router-dom";
import filterby_icon from "../../../src/assets/images/filter-by.svg";
import filterby_collpase_icon from "../../../src/assets/images/filter-collapse-icon.svg";
import { colourSelectOption } from "../../common/colourSelectOption";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setFilterBySearch } from "../../slices/mainSlice";

let loadButton=false;
const SearchCourseDetails = ({ searchQuery, myLearning, filterSnippetTypes }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { filterBySearch } = useSelector((state) => state.main);

    const { searchCourseParam } = useContext(appContext);
    let [courseList, setCourseList] = useState([]);
    let [hasMore, setHasMore] = useState(true);
    let [cursor, setCursor] = useState(null);
    let [noData, setNoData] = useState(false);
    const [showCourses, setShowCourses] = useState(true);
    const [, setSelectedOption] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const { skillsListData } = useContext(appContext);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [showButton, setShowButton] = useState(false);
    let [selectedStyle] = useState({
        id: 1,
        className: "catalog-block"
    });

    const sidebarRef = useRef(null);
    const courseRef = useRef(null);

    const { catalogsListItem } = useContext(appContext);

    let flag = true;

    // //  !for selected filter
    const initialFilters = {
        "filter.loTypes": searchParams.get("filter.loTypes")
            ? searchParams.get("filter.loTypes").split(",")
            : [],
        "filter.catalogIds": searchParams.get("filter.catalogIds")
            ? searchParams.get("filter.catalogIds").split(",")
            : [],
        "filter.skill.level": searchParams.get("filter.skill.level")
            ? searchParams.get("filter.skill.level").split(",")
            : [],
        "filter.skillName": searchParams.get("filter.skillName")
            ? searchParams.get("filter.skillName").split(",")
            : [],
        "filter.tagName": searchParams.get("filter.tagName")
            ? searchParams.get("filter.tagName").split(",")
            : [],
        "filter.duration.range": searchParams.get("filter.duration.range")
            ? searchParams.get("filter.duration.range").split(",")
            : [],
        "filter.priceRange": searchParams.get("filter.priceRange")
            ? searchParams.get("filter.priceRange").split(",")
            : [],
        "filter.loFormat": searchParams.get("filter.loFormat")
            ? searchParams.get("filter.loFormat").split(",")
            : [],
        "filter.learnerState": searchParams.get("filter.learnerState")
            ? searchParams.get("filter.learnerState").split(",")
            : [],
        sort: searchParams.get("sort")?.trim() || "relevance"
    };

    let [selectedFilters, setSelectedFilters] = useState(initialFilters);

    const handleChange = (selected) => {
        setSelectedOption(selected);
        setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            sort: selected.value
        }));
    };

    //FilterBy setIsAtBottom
    useEffect(() => {
        setIsAtBottom(false);
    }, [filterBySearch]);

    useEffect(() => {
        setIsAtBottom(false);
    }, [noData]);

    useEffect(() => {
        // Attach a scroll event listener to the scrollable parent
        if (sidebarRef !== null) {
            const handleScroll = () => {
                const scrollPosition = window.innerHeight;
                const scrollHeight = sidebarRef.current.scrollHeight;
                const courseScrollHeight = courseRef.current.scrollHeight;
                const clientHeight = window.scrollY;
                if (courseScrollHeight > scrollHeight) {
                    const isAtBottom = scrollPosition + clientHeight >= scrollHeight + 200;
                    setIsAtBottom(isAtBottom);
                }
            };

            window.addEventListener("scroll", handleScroll);

            // Remove the event listener when the component unmounts
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, []);

    useEffect(() => {
        let urlKeys = Object.keys(selectedFilters);
        let url = [];
        urlKeys.forEach((element) => {
            if (selectedFilters[element].length > 0) {
                url.push(
                    encodeURIComponent(element) + "=" + encodeURIComponent(selectedFilters[element])
                );
            }
        });
        url.push("q=" + encodeURIComponent(searchParams.get("q")));

        // Join the query parameters
        const newSearchParams = url.join("&");

        // Update the search parameters in the URL
        setSearchParams(newSearchParams);

        // You can also navigate to the new URL
        navigate({ search: newSearchParams });
    }, [selectedFilters, setSearchParams, navigate]);

    const onChangeFilterSolutionCatalogIds = (value, e) => {
        addOrRemoveFromFilterState(e.target.checked, "filter.catalogIds", value);
    };

    const onChangeFilterOtherCatalogIds = (value, e) => {
        addOrRemoveFromFilterState(e.target.checked, "filter.catalogIds", value);
    };

    const onChangeFilterLoTypes = (value, e) => {
        addOrRemoveFromFilterState(e.target.checked, "filter.loTypes", value);
    };

    const onChangeFilterSkillName = (value, e) => {
        addOrRemoveFromFilterState(e.target.checked, "filter.skillName", value);
    };

    const onChangeFilterOtherSkillName = (value, e) => {
        addOrRemoveFromFilterState(e.target.checked, "filter.skillName", value);
    };

    const onChangeFilterSkillLevel = (value, e) => {
        addOrRemoveFromFilterState(e.target.checked, "filter.skill.level", value);
    };
    
    const onChangeFilterTagName = (value, e) => {
        addOrRemoveFromFilterState(e.target.checked, "filter.tagName", value);
    };

    const onChangeFilterLearnerState = (value, e) => {
        addOrRemoveFromFilterState(e.target.checked, "filter.learnerState", value);
    };

    const onChangeFilterLoFormat = (value, e) => {
        addOrRemoveFromFilterState(e.target.checked, "filter.loFormat", value);
    };

    const onChangeFilterDuration = (value, e) => {
        addOrRemoveFromFilterState(e.target.checked, "filter.duration.range", value);
    };

    const onChangeFilterSorting = (value, e) => {
        addOrRemoveFromFilterState(e.target.checked, "sort", value);
    };

    /**
     *This function update selectedFilters state
     *
     * @param {*} checked - boolean value
     * @param {*} keyName - JSON object key name for update data
     * @param {*} value - value to update
     */
    const addOrRemoveFromFilterState = (checked, keyName, value) => {
        let tempSelectedFilters = { ...selectedFilters };
        if (checked) {
            setSelectedFilters({
                ...selectedFilters,
                [keyName]: [...selectedFilters[keyName], value]
            });
        } else {
            let findElementIndex = tempSelectedFilters[keyName].findIndex((e) => e === value);
            if (findElementIndex >= 0) {
                tempSelectedFilters[keyName].splice(findElementIndex, 1);
                setSelectedFilters(tempSelectedFilters);
            }
        }
    };

    const filterRecords = async (params, data, included, links) => {
        if (data?.length === 0) return;
        const filteredDataArr = data.filter((e) => {
            const learningObject = included.find(
                (item) => e.id === item.id && item.type === "learningObject"
            );
            return (
                (learningObject?.attributes?.localizedMetadata &&
                    !learningObject?.attributes?.localizedMetadata[0]?.name.includes("DNS") &&
                    !learningObject?.attributes?.tags?.some((tag) => tag.includes("DNS")))
            );
        });

        filteredLearningObjectsData = [...filteredLearningObjectsData, ...filteredDataArr];
        filteredLearningObjectsIncluded = [...filteredLearningObjectsIncluded, ...included];
        filteredLearningObjectsLinks = links;

        if (links?.next && filteredLearningObjectsData.length < 10) {
            const urlParams = new URLSearchParams(links.next);
            const nextCursor = urlParams.get("page[cursor]");
            const response = await apis.searchCourses(
                searchParams.get("q"),
                nextCursor,
                10,
                params.join("&"),
                selectedFilters["sort"]
            );
            if (response?.data?.length > 0) {
                await filterRecords(params, response.data, response.included, response.links);
            }
        }
    };

    let filteredLearningObjectsData = [];
    let filteredLearningObjectsIncluded = [];
    let filteredLearningObjectsLinks = {};
    const apiCall = async (c) => {
        let urlKeys = Object.keys(selectedFilters);
        let params = [];
        urlKeys.forEach((element) => {
            if (selectedFilters[element].length > 0) {
                params.push(
                    encodeURIComponent(element) + "=" + encodeURIComponent(selectedFilters[element])
                );
            }
        });
        if (filterSnippetTypes.length > 0) {
            const filterSnippetTypesString = `filter.snippetTypes=${filterSnippetTypes.join(",")}`;
            params.push(filterSnippetTypesString);
        }

        // function searchCourses(searchStr,cursor, limit = 10,params,sort="relevance")
        let { data, included, links } = await apis.searchCourses(
            searchParams.get("q"),
            c,
            10,
            params.join("&"),
            selectedFilters["sort"]
        );

        await filterRecords(params, data, included, links);

        data = filteredLearningObjectsData;
        included = filteredLearningObjectsIncluded;
        links = filteredLearningObjectsLinks;

        if (!links.next) {
            setHasMore(false);
            setShowButton(false);
            loadButton=false;
        } else {
            const urlParams = new URLSearchParams(links.next);
            const nextCursor = urlParams.get("page[cursor]");
            setCursor(nextCursor);
            setHasMore(true);
            loadButton=true;
        }
        if (!c) {
            setCourseList([]);
            if (data.length > 0) {
                setNoData(false);
                setShowCourses(true);
            } else {
                setNoData(true);
                setShowCourses(false);
            }
        }

        for (let i = 0; i < data.length; i++) {
            let completionDeadline = "";
            let loType = data[i]?.attributes?.modelSubType;
            let enrollmentData = included?.find(
                (e) => e.id === data[i]?.id && e.type === "learningObject"
            );

            const enrollmentId = enrollmentData?.relationships?.enrollment?.data?.id;
            const modifiedEnrollmentId = enrollmentId?.split("_")?.slice(0, 2)?.join("_");

            let enrollCourseInstanceData = "";

            if (enrollmentId) {
                enrollCourseInstanceData = included?.find(
                    (e) =>
                        e.id === enrollmentId?.substring(0, enrollmentId.lastIndexOf("_")) &&
                        e.type === "learningObjectInstance"
                );
            } else {
                enrollCourseInstanceData = included?.find(
                    (e) =>
                        e.id === data[i]?.relationships?.instances?.data[0]?.id &&
                        e.type === "learningObjectInstance"
                );
            }

            if (
                enrollmentData?.attributes?.completionDeadline &&
                new Date(enrollmentData?.attributes?.completionDeadline)
                    .toString()
                    .toLowerCase() !== "invalid date"
            ) {
                completionDeadline = enrollmentData?.attributes?.completionDeadline;
            } else if (
                enrollCourseInstanceData?.attributes?.completionDeadline &&
                new Date(enrollCourseInstanceData?.attributes?.completionDeadline)
                    .toString()
                    .toLowerCase() !== "invalid date"
            ) {
                completionDeadline = enrollCourseInstanceData?.attributes?.completionDeadline;
            }

            if (
                loType === "learningProgram" ||
                loType === "certification" ||
                loType === "course" ||
                loType === "jobAid"
            ) {
                completionDeadline = data[i]?.attributes?.completionDeadline;
            }

            setCourseList((oldArray) => [
                ...oldArray,
                {
                    id: enrollmentData?.id,
                    imageUrl: enrollmentData?.attributes?.imageUrl,
                    loFormat: enrollmentData?.attributes?.loFormat,
                    // overview: data[i]?.attributes?.localizedMetadata[0]?.overview,
                    loType: enrollmentData?.attributes?.loType,
                    // name: enrollmentData?.attributes?.localizedMetadata[0]?.name,
                    name: data[i]?.attributes?.name,
                    duration: enrollmentData?.attributes?.duration,
                    // description: enrollmentData?.attributes?.localizedMetadata[0]?.description,
                    description: data[i]?.attributes?.description,
                    averageRating: enrollmentData?.attributes?.rating?.averageRating || 0,
                    ratingsCount: enrollmentData?.attributes?.rating?.ratingsCount || 0,
                    /*price: Object.prototype.hasOwnProperty.call(enrollmentData?.attributes, "price")
                        ? //  data[i]?.attributes.hasOwnProperty("price")
                        enrollmentData?.attributes.price
                        : 0,*/
                    enrollment: enrollmentId ? true : false,
                    instancesId: modifiedEnrollmentId,
                    skills: enrollmentData?.relationships?.skills?.data[0]?.id.split("_")[1],
                    isBookmarked: enrollmentData?.attributes?.isBookmarked || false,
                    progressPercent: data[i]?.attributes?.progressPercent,
                    dueDate: completionDeadline,
                    tags: enrollmentData?.attributes?.tags || []
                    // progressPercent: enrollmentData?.attributes?.progressPercent,
                    // enrollmentCount: enrollmentCount || 0

                    // isPurchased: isPurchasedRes.data[data[i].id]
                }
            ]);
            //console.log("courseList: ", courseList);
        }
        filteredLearningObjectsData = [];
        filteredLearningObjectsIncluded = [];
        filteredLearningObjectsLinks = {};
    };
    useEffect(() => {
        if (searchQuery && searchQuery?.trim() !== "") {
            apiCall();
        }
    }, [selectedFilters, searchCourseParam]);

    const nextCursor = () => {
        apiCall(cursor);
    };

    //solution catalog
    const [allCatalog, setAllCatalog] = useState([]);
    const [inputSolutionCatalog, setInputSolutionCatalog] = useState("");

    // Other  Catalog
    const [inputOtherCatalog, setInputOtherCatalog] = useState("");

    // // Format
    const [inputFormat, setInputFormat] = useState("");

    // SkillLevels
    // Role Based Skill
    const [roleBasedSkill, setRoleBasedSkill] = useState([]);
    const [inputRoleBasedSkill, setInputRoleBasedSkill] = useState("");

    // Other Skill

    const [inputOtherSkill, setInputOtherSkill] = useState("");

    // Tags
    const [tag, setTag] = useState([]);
    const [inputTag, setInputTag] = useState("");
    // Status

    const selectOptions = filterJson.searchInSorting.map((format) => ({
        id: format.id,
        value: format.value,
        label: format.label,
        arrow: format.arrow,
        url: format.url,
        icon: format.icon
    }));
    // Reset Filter
    const [resetFilter, setResetFilter] = useState(false);

    let inputSolutionCatalogHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value;
        setInputSolutionCatalog(lowerCase);
    };

    let myCatalogData = [];
    async function fetchCatalogs(offset = 0) {
        let response = null;
        if (offset > 0) {
            response = await apis.getCatalogs(offset);
        } else {
            response = await apis.getCatalogs();
        }
        if (response) {
            if (response.data?.length > 0) {
                for (const data of response.data) {
                    myCatalogData.push(data);
                }
            }
            if (response.links?.next) {
                const nextOffset = response.links?.next.split("page[offset]=")[1].split("&")[0];
                await fetchCatalogs(parseInt(nextOffset));
            }
        }
    }

    const getAllCatalogName = async () => {
        if (
            sessionStorage.getItem("CatalogList") === null ||
            JSON.parse(sessionStorage.getItem("CatalogList")).length === 0
        ) {
            await fetchCatalogs();
            sessionStorage.setItem("CatalogList", JSON.stringify(myCatalogData));
        } else {
            myCatalogData = JSON.parse(sessionStorage.getItem("CatalogList"));
        }
        setAllCatalog(myCatalogData);
    };
    const filteredSolutionCatalog = allCatalog.filter((item) =>
        catalogsListItem?.some((solutionItem) => {
            if (solutionItem.CatalogName === item.attributes.name) {
                // Add CatalogName to the item being filtered
                item.DisplayName = solutionItem.DisplayName;
                return true; // Include the item in filteredSolutionCatalog
            }
            return false; // Exclude the item from filteredSolutionCatalog
        })
    );

    const solutionCatalogValue = filteredSolutionCatalog.filter((item) => {
        // Convert item name to lowercase for case-insensitive comparison
        // const itemNameLowerCase = item.attributes.name.toLowerCase();
        const itemNameLowerCase = item?.DisplayName?.toLowerCase();

        // Convert the input search term to lowercase
        const searchTermLowerCase = inputSolutionCatalog.toLowerCase();

        return searchTermLowerCase === "" ? true : itemNameLowerCase.includes(searchTermLowerCase);
    });
    const solutionCatalogNames = new Set(
        filteredSolutionCatalog.map((item) => item.attributes.name)
    );
    const otherCatalog = allCatalog.filter(
        (item) => !solutionCatalogNames.has(item.attributes.name)
    );
    let otherCatalogdata = otherCatalog.filter((item) => {
        // Convert item name to lowercase for case-insensitive comparison
        const itemNameLowerCase = item.attributes.name.toLowerCase();

        // Convert the input search term to lowercase
        const searchTermLowerCase = inputOtherCatalog.toLowerCase();

        return searchTermLowerCase === "" ? true : itemNameLowerCase.includes(searchTermLowerCase);
    });
    // Other Catalog
    const inputOtherCatalogHandler = (e) => {
        var lowerCase = e.target.value;
        setInputOtherCatalog(lowerCase);
    };

    let filterFormatData = filterJson.Format.filter((form) => {
        return inputFormat === "" ? form : form.toLowerCase().includes(inputFormat);
    });

    // Roll Based Skill
    let inputRoleBasedSkillHandler = (e) => {
        var lowerCase = e.target.value;
        setInputRoleBasedSkill(lowerCase);
    };
    let mySkillData = [];
    async function fetchSkills(offset = 0) {
        let response = null;
        if (offset > 0) {
            response = await apis.getSkills(offset);
        } else {
            response = await apis.getSkills();
        }

        if (response) {
            if (response.data?.length > 0) {
                for (const data of response.data) {
                    mySkillData.push(data);
                }
            }
            if (response.links?.next) {
                const nextOffset = response.links?.next.split("page[offset]=")[1].split("&")[0];
                await fetchSkills(parseInt(nextOffset));
            }
        }
    }

    let getAllBasedSkills = async () => {
        if (
            sessionStorage.getItem("SkillList") === null ||
            JSON.parse(sessionStorage.getItem("SkillList")).length === 0
        ) {
            await fetchSkills();
            sessionStorage.setItem("SkillList", JSON.stringify(mySkillData));
        } else {
            mySkillData = JSON.parse(sessionStorage.getItem("SkillList"));
        }
        setRoleBasedSkill(mySkillData);
    };

    let filteredRoleBasedSkills = roleBasedSkill.filter((skill) => {
        return skillsListData.some((roleSkill) => roleSkill?.SkillName === skill?.attributes?.name);
    });
    let roleBasedValues = filteredRoleBasedSkills.filter((item) => {
        const itemNameLowerCase = item.attributes.name.toLowerCase();

        // Convert the input search term to lowercase
        const searchTermLowerCase = inputRoleBasedSkill.toLowerCase();

        return searchTermLowerCase === "" ? true : itemNameLowerCase.includes(searchTermLowerCase);
    });
    const roleBasedSkillNames = new Set(
        filteredRoleBasedSkills.map((item) => item.attributes.name)
    );
    const otherBasedSkill = roleBasedSkill.filter(
        (item) => !roleBasedSkillNames.has(item.attributes.name)
    );
    // Other Skills
    let inputOtherSkillsHandler = (e) => {
        var lowerCase = e.target.value;
        setInputOtherSkill(lowerCase);
    };

    let otherBasedSkillsdata = otherBasedSkill.filter((item) => {
        {
            const itemNameLowerCase = item.attributes.name.toLowerCase();

            // Convert the input search term to lowercase
            const searchTermLowerCase = inputOtherSkill.toLowerCase();

            return searchTermLowerCase === ""
                ? true
                : itemNameLowerCase.includes(searchTermLowerCase);
        }
    });

    // Tag Based Skill
    const getTagNames = async () => {
        let data = await apis.getTagData();
        setTag(data?.data);
    };

    let inputTagHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value;
        setInputTag(lowerCase);
    };

    let filterTagData = tag?.attributes?.names?.filter((name) => {
        const tagNameLowerCase = name.toLowerCase();
        // Convert the input search term to lowercase
        const searchTermLowerCase = inputTag.toLowerCase();

        return searchTermLowerCase === "" ? true : tagNameLowerCase.includes(searchTermLowerCase);
    });

    const SetFiltersToEmpty = () => {
        const updatedFilters = {
            "filter.catalogIds": [],
            "filter.duration.range": [],
            "filter.learnerState": [],
            "filter.loFormat": [],
            "filter.skill.level":[],
            "filter.loTypes": [],
            "filter.priceRange": [],
            "filter.skillName": [],
            "filter.tagName": [],
            sort: "relevance", // Keep the sort property
        };
        setInputSolutionCatalog("");
        setInputFormat("");
        setInputOtherCatalog("");
        setInputRoleBasedSkill("");
        setInputOtherSkill("");
        setInputTag("");
        setSelectedFilters(updatedFilters);
        var inputs = document.querySelectorAll("input[type='checkbox'][id^='check']");
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].checked = false;
        }
        navigate(`/searchcourse?sort=relevance&q=${searchQuery}`)
    }

    // Reset Filter
    let ResetClick = () => {
        setResetFilter(true);
        if (resetFilter === true) {
            setInputFormat("");
            setInputOtherCatalog("");
            setInputRoleBasedSkill("");
            setInputRoleBasedSkill("");
            setInputOtherSkill("");
            setInputTag("");
            var inputs = document.querySelectorAll("#check");
            for (var i = 0; i < inputs.length; i++) {
                inputs[i].checked = false;
            }
            setSelectedFilters(initialFilters);
        }
    };

    useEffect(() => {
        ResetClick();
    }, [resetFilter]);

    useEffect(() => {
        getTagNames();
        // getRole
    }, []);

    useEffect(() => {
        init();
    }, []);

    const handleBackButtonClick = () => {
        navigate("/home");
    };

    useEffect(() => {
        window.addEventListener("popstate", handleBackButtonClick);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("popstate", handleBackButtonClick);
        };
    }, []);

    const init = async () => {
        await getAllCatalogName();
        await getAllBasedSkills();
    };

    // Right side section
    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(!showModal);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    // const [showScrollToBottom, setShowScrollToBottom] = useState(true);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    //  d-none class remove
    const filterByIcon = () => {
        dispatch(setFilterBySearch(true));
    };
    const filterByCollapse = () => {
        dispatch(setFilterBySearch(false));
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const element = document.documentElement; // You may need to select the correct element
        if(courseList.length>0){
            setNoData(false);
            if (element.scrollHeight <= element.clientHeight+400) {
                if(loadButton)
                    setShowButton(true);
            } else {
                setShowButton(false);
                //nextCursor();
            }
        }
    }, [courseList]);


    return (
        <Container fluid className="pl-30 allCourses">
            <Row>
                <div>
                    <Container fluid className="p-0">
                        <div className="all-courses-mobile">
                            <p>All Courses</p>
                            <Button
                                variant="default"
                                onClick={handleShow}
                                className="filterbtn primary-btn">
                                <span className="filterbg"></span>
                                Filter
                            </Button>
                        </div>

                        <Row>
                            <div className="all-courses-info">
                                <div className={filterBySearch ? "left-info" : "left-info d-none"}>
                                    <div
                                        ref={sidebarRef}
                                        className={`${
                                            isAtBottom
                                                ? "sidebarBotton leftSectionAllCourses scroller px-3 py-1"
                                                : "sidebarOther leftSectionAllCourses scroller px-4 py-2"
                                        }`}>
                                        <div className="titleHead d-flex align-items-center search-filterby-titlehead justify-content-between">
                                            <div className="px-3 d-flex align-items-center justify-content-between w-100">
                                                <span className="filterBy">{t("filter-by")}</span>
                                                <span>
                                                    <button
                                                        className="btn btn-link btn-view reset-filter filterEffect mb-0 mt-1"
                                                        onClick={SetFiltersToEmpty}>
                                                        {t("reset-filter")}
                                                    </button>
                                                    <button
                                                        className="btn btn-link filterby-collpase position-relative"
                                                        onClick={filterByCollapse}>
                                                        <img
                                                            src={filterby_collpase_icon}
                                                            alt="filterby collpase"
                                                            className="filterby-collpase-icon"
                                                        />
                                                        <div className="tooltip-box2 d-none">
                                                            Click to Collapse Filter
                                                        </div>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            {/* <button className="btn btn-link btn-view reset-filter" onClick={SetFiltersToEmpty}>
                                                    {t("reset-filter")}
                                                </button>   */}
                                        </div>
                                        <div className="subHead my-2">
                                            {t("my-learning-filter.dx-enablement-catalogs")}
                                        </div>
                                        <div className="mb-2">
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Search for Solution Catalogs"
                                                    className="searh-icon searchBox1 py-2"
                                                    value={inputSolutionCatalog}
                                                    onChange={inputSolutionCatalogHandler}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="scroller mt-3">
                                            {solutionCatalogValue
                                                ?.sort((a, b) =>
                                                    a?.DisplayName?.localeCompare(b?.DisplayName)
                                                )
                                                ?.map((element) => {
                                                    return (
                                                        <div key={element?.id}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                name="Solution Catalog"
                                                                value={element?.attributes?.name}
                                                                className="filterCheckbox"
                                                                id={`check-${element?.id}`}
                                                                label={
                                                                    <span>
                                                                        {element?.DisplayName}
                                                                    </span>
                                                                }
                                                                onClick={(e) =>
                                                                    onChangeFilterSolutionCatalogIds(
                                                                        element.id,
                                                                        e
                                                                    )
                                                                }
                                                                checked={
                                                                    Array.isArray(
                                                                        selectedFilters[
                                                                            "filter.catalogIds"
                                                                        ]
                                                                    ) && // Check if it's an array
                                                                    selectedFilters[
                                                                        "filter.catalogIds"
                                                                    ].find((e) => e === element.id)
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>

                                        <div className="titleHead my-4"></div>
                                        <div className="subHead my-2">
                                            {t("my-learning-filter.other-catalogs")}
                                        </div>
                                        <div className="mb-2">
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Search for Other Catalogs"
                                                    className="searh-icon searchBox1 py-2"
                                                    value={inputOtherCatalog}
                                                    onChange={inputOtherCatalogHandler}
                                                />
                                            </InputGroup>
                                        </div>

                                        <div className="scroller mt-3">
                                            {otherCatalogdata
                                                ?.sort((a, b) =>
                                                    a?.attributes?.name?.localeCompare(
                                                        b?.attributes?.name
                                                    )
                                                )
                                                ?.map((element) => {
                                                    return (
                                                        <div key={element?.id}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                name="OtherCatalog"
                                                                value={element?.attributes?.name}
                                                                id={`check-${element?.id}`}
                                                                className="filterCheckbox"
                                                                label={
                                                                    <span>
                                                                        {element?.attributes?.name}
                                                                    </span>
                                                                }
                                                                onClick={(e) =>
                                                                    onChangeFilterOtherCatalogIds(
                                                                        element.id,
                                                                        e
                                                                    )
                                                                }
                                                                checked={
                                                                    Array.isArray(
                                                                        selectedFilters[
                                                                            "filter.catalogIds"
                                                                        ]
                                                                    ) && // Check if it's an array
                                                                    selectedFilters[
                                                                        "filter.catalogIds"
                                                                    ].find((e) => e === element.id)
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>

                                        <div className="titleHead my-4"></div>
                                        <p className="subHead">{t("my-learning-filter.type")}</p>
                                        <div className="scroller">
                                            {filterJson.loTypes?.map((type) => {
                                                return (
                                                    <div key={type.id}>
                                                        <Form.Check
                                                            className="filterCheckbox"
                                                            type="checkbox"
                                                            name="Type"
                                                            value={type.value}
                                                            id={`check-${type?.value}`}
                                                            label={<span>{type.label}</span>}
                                                            onClick={(e) =>
                                                                onChangeFilterLoTypes(type.value, e)
                                                            }
                                                            checked={
                                                                Array.isArray(
                                                                    selectedFilters[
                                                                        "filter.loTypes"
                                                                    ]
                                                                ) && // Check if it's an array
                                                                selectedFilters[
                                                                    "filter.loTypes"
                                                                ].find((e) => e === type.value)
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div className="titleHead my-4"></div>
                                        <p className="subHead">
                                            {t("my-learning-filter.duration")}
                                        </p>
                                        <div className="scroller">
                                            {filterJson.durationRange.map((type) => {
                                                return (
                                                    <div key={type.id}>
                                                        <Form.Check
                                                            className="filterCheckbox"
                                                            type="checkbox"
                                                            name="Type"
                                                            value={type.value}
                                                            id={`check-${type?.value}`}
                                                            label={<span>{type.label}</span>}
                                                            onClick={(e) =>
                                                                onChangeFilterDuration(
                                                                    type.value,
                                                                    e
                                                                )
                                                            }
                                                            checked={
                                                                Array.isArray(
                                                                    selectedFilters[
                                                                        "filter.duration.range"
                                                                    ]
                                                                ) && // Check if it's an array
                                                                selectedFilters[
                                                                    "filter.duration.range"
                                                                ].find((e) => e === type.value)
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div className="titleHead my-4"></div>
                                        <p className="subHead">{t("my-learning-filter.format")}</p>
                                        <div className="scroller">
                                            {filterFormatData?.map((format) => {
                                                return (
                                                    <div key={format.id}>
                                                        <Form.Check
                                                            className="filterCheckbox"
                                                            type="checkbox"
                                                            name="Format"
                                                            value={format.value}
                                                            id={`check-${format?.value}`}
                                                            label={<span>{format.label}</span>}
                                                            onClick={(e) =>
                                                                onChangeFilterLoFormat(
                                                                    format.value,
                                                                    e
                                                                )
                                                            }
                                                            // checked={selectedFilters["filter.loFormat"].find(
                                                            //     (e) => e === format.value
                                                            // )}
                                                            checked={
                                                                Array.isArray(
                                                                    selectedFilters[
                                                                        "filter.loFormat"
                                                                    ]
                                                                ) && // Check if it's an array
                                                                selectedFilters[
                                                                    "filter.loFormat"
                                                                ].find((e) => e === format.value)
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {roleBasedValues?.length > 0 && (
                                            <>
                                                <div className="titleHead my-4"></div>
                                                <div className="subHead my-2">
                                                    {t("my-learning-filter.role-based-skills")}
                                                </div>
                                                <div className="mb-2">
                                                    <InputGroup>
                                                        <FormControl
                                                            placeholder="Search for Role Based Skills"
                                                            className="searh-icon searchBox1 py-2"
                                                            value={inputRoleBasedSkill}
                                                            onChange={inputRoleBasedSkillHandler}
                                                        />
                                                    </InputGroup>
                                                </div>
                                                <div className="scroller mt-3">
                                                    {roleBasedValues
                                                        ?.sort((a, b) =>
                                                            a?.attributes?.name?.localeCompare(
                                                                b?.attributes?.name
                                                            )
                                                        )
                                                        ?.map((skillname) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        skillname?.attributes?.name
                                                                    }>
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        name="Roll Based Skills"
                                                                        value={
                                                                            skillname?.attributes
                                                                                ?.name
                                                                        }
                                                                        id={`check-${skillname?.id}`}
                                                                        className="filterCheckbox"
                                                                        label={
                                                                            <span>
                                                                                {
                                                                                    skillname
                                                                                        ?.attributes
                                                                                        ?.name
                                                                                }
                                                                            </span>
                                                                        }
                                                                        // onChange={(e) => handleCheckBoxChange(e)}
                                                                        onClick={(e) =>
                                                                            onChangeFilterSkillName(
                                                                                skillname
                                                                                    ?.attributes
                                                                                    ?.name,
                                                                                e
                                                                            )
                                                                        }
                                                                        // checked={selectedFilters['filter.skillName'].find(
                                                                        //   (e) => e === skillname?.attributes?.name
                                                                        // )}
                                                                        checked={
                                                                            Array.isArray(
                                                                                selectedFilters[
                                                                                    "filter.skillName"
                                                                                ]
                                                                            ) && // Check if it's an array
                                                                            selectedFilters[
                                                                                "filter.skillName"
                                                                            ].find(
                                                                                (e) =>
                                                                                    e ===
                                                                                    skillname
                                                                                        ?.attributes
                                                                                        ?.name
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </>
                                        )}

                                        <div className="titleHead my-4"></div>
                                        <div className="subHead my-2">
                                            {t("my-learning-filter.other-skills")}
                                        </div>
                                        <div className="mb-2">
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Search for Other Skills"
                                                    className="searh-icon searchBox1 py-2"
                                                    value={inputOtherSkill}
                                                    onChange={inputOtherSkillsHandler}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="scroller mt-3">
                                            {otherBasedSkillsdata
                                                ?.sort((a, b) =>
                                                    a?.attributes?.name?.localeCompare(
                                                        b?.attributes?.name
                                                    )
                                                )
                                                ?.map((otherskill) => {
                                                    return (
                                                        <div key={otherskill?.attributes?.name}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                name="Other Skills"
                                                                value={otherskill?.attributes?.name}
                                                                id={`check-${otherskill?.id}`}
                                                                className="filterCheckbox"
                                                                label={
                                                                    <span>
                                                                        {
                                                                            otherskill?.attributes
                                                                                ?.name
                                                                        }
                                                                    </span>
                                                                }
                                                                onClick={(e) =>
                                                                    onChangeFilterOtherSkillName(
                                                                        otherskill?.attributes
                                                                            ?.name,
                                                                        e
                                                                    )
                                                                }
                                                                // checked={selectedFilters['filter.skillName'].find(
                                                                //   (e) => e === otherskill?.attributes?.name
                                                                // )}
                                                                checked={
                                                                    Array.isArray(
                                                                        selectedFilters[
                                                                            "filter.skillName"
                                                                        ]
                                                                    ) && // Check if it's an array
                                                                    selectedFilters[
                                                                        "filter.skillName"
                                                                    ].find(
                                                                        (e) =>
                                                                            e ===
                                                                            otherskill?.attributes
                                                                                ?.name
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <div className="titleHead my-4"></div>
                                        <p className="subHead my-2">
                                            {t("my-learning-filter.skill-proficiency-level")}
                                        </p>
                                        <div>
                                            {filterJson.SkillLevels?.map((skillLevel) => {
                                                return (
                                                    <div key={skillLevel.id}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="SkillLevel"
                                                            value={skillLevel.value}
                                                            id={`check-${skillLevel?.value}`}
                                                            label={
                                                                <span>{skillLevel.label}</span>
                                                            }
                                                            className="filterCheckbox"
                                                            // onChange={(e) => handleCheckBoxChange(e)}
                                                            onClick={(e) =>
                                                                onChangeFilterSkillLevel(
                                                                    skillLevel.value,
                                                                    e
                                                                )
                                                            }
                                                            // checked={selectedFilters['filter.skill.level'].find(
                                                            //   (e) => e === skillLevel.value
                                                            // )}
                                                            checked={
                                                                Array.isArray(
                                                                    selectedFilters[
                                                                        "filter.skill.level"
                                                                    ]
                                                                ) && // Check if it's an array
                                                                selectedFilters[
                                                                    "filter.skill.level"
                                                                ].find(
                                                                    (e) =>
                                                                        e === skillLevel.value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div className="titleHead my-4"></div>
                                        <div className="subHead my-2">
                                            {t("my-learning-filter.keyword")}
                                        </div>
                                        <div className="mb-2">
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Search for Keyword"
                                                    className="searh-icon searchBox1 py-2"
                                                    value={inputTag}
                                                    onChange={inputTagHandler}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="scroller mt-3">
                                            {filterTagData
                                                ?.sort((a, b) => a?.localeCompare(b))
                                                ?.filter((e) => !e.includes("DNS"))
                                                .map((tagname) => {
                                                    return (
                                                        <div key={tagname}>
                                                            <Form.Check
                                                                className="filterCheckbox"
                                                                type="checkbox"
                                                                name="tags"
                                                                value={tagname}
                                                                id={`check-${tagname}`}
                                                                label={<span>{tagname}</span>}
                                                                onClick={(e) =>
                                                                    onChangeFilterTagName(
                                                                        tagname,
                                                                        e
                                                                    )
                                                                }
                                                                checked={
                                                                    Array.isArray(
                                                                        selectedFilters[
                                                                            "filter.tagName"
                                                                        ]
                                                                    ) && // Check if it's an array
                                                                    selectedFilters[
                                                                        "filter.tagName"
                                                                    ].find((e) => e === tagname)
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                    <div className="modal-container">
                                        <Modal show={showModal}>
                                            <Modal.Header
                                                closeButton
                                                className="border-0"
                                                onClick={handleShow}></Modal.Header>
                                            <Modal.Body className="py-0">
                                                <div className="success-modal-container-mobile p-0">
                                                    <div className="titleHead d-flex align-items-center justify-content-between">
                                                        <span className="filterBy">
                                                            {t("filter-by")}text
                                                        </span>
                                                        <span>
                                                            <button
                                                                className="btn btn-link btn-view"
                                                                onClick={SetFiltersToEmpty}>
                                                                {t("reset-filter")}
                                                            </button>
                                                        </span>
                                                    </div>

                                                    <div className="subHead my-2">
                                                        {t(
                                                            "my-learning-filter.dx-enablement-catalogs"
                                                        )}
                                                    </div>
                                                    <div className="mb-2">
                                                        <InputGroup>
                                                            <FormControl
                                                                placeholder="Search for Solution Catalogs"
                                                                className="searh-icon searchBox1 py-2"
                                                                value={inputSolutionCatalog}
                                                                onChange={
                                                                    inputSolutionCatalogHandler
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                    <div className="scroller mt-3">
                                                        {solutionCatalogValue
                                                            ?.sort((a, b) =>
                                                                a?.DisplayName?.localeCompare(
                                                                    b?.DisplayName
                                                                )
                                                            )
                                                            ?.map((element) => {
                                                                return (
                                                                    <div key={element?.id}>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            name="Solution Catalog"
                                                                            value={
                                                                                element?.attributes
                                                                                    ?.name
                                                                            }
                                                                            className="filterCheckbox"
                                                                            id={`check-${element?.id}`}
                                                                            label={
                                                                                <span>
                                                                                    {
                                                                                        element?.DisplayName
                                                                                    }
                                                                                </span>
                                                                            }
                                                                            onClick={(e) =>
                                                                                onChangeFilterSolutionCatalogIds(
                                                                                    element.id,
                                                                                    e
                                                                                )
                                                                            }
                                                                            checked={
                                                                                Array.isArray(
                                                                                    selectedFilters[
                                                                                        "filter.catalogIds"
                                                                                    ]
                                                                                ) && // Check if it's an array
                                                                                selectedFilters[
                                                                                    "filter.catalogIds"
                                                                                ].find(
                                                                                    (e) =>
                                                                                        e ===
                                                                                        element.id
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>

                                                    <div className="titleHead my-4"></div>
                                                    <div className="subHead my-2">
                                                        {t("my-learning-filter.other-catalogs")}
                                                    </div>
                                                    <div className="mb-2">
                                                        <InputGroup>
                                                            <FormControl
                                                                placeholder="Search for Other Catalogs"
                                                                className="searh-icon searchBox1 py-2"
                                                                value={inputOtherCatalog}
                                                                onChange={inputOtherCatalogHandler}
                                                            />
                                                        </InputGroup>
                                                    </div>

                                                    <div className="scroller mt-3">
                                                        {otherCatalogdata
                                                            ?.sort((a, b) =>
                                                                a?.attributes?.name?.localeCompare(
                                                                    b?.attributes?.name
                                                                )
                                                            )
                                                            ?.map((element) => {
                                                                return (
                                                                    <div key={element?.id}>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            name="OtherCatalog"
                                                                            value={
                                                                                element?.attributes
                                                                                    ?.name
                                                                            }
                                                                            id={`check-${element?.id}`}
                                                                            className="filterCheckbox"
                                                                            label={
                                                                                <span>
                                                                                    {
                                                                                        element
                                                                                            ?.attributes
                                                                                            ?.name
                                                                                    }
                                                                                </span>
                                                                            }
                                                                            onClick={(e) =>
                                                                                onChangeFilterOtherCatalogIds(
                                                                                    element.id,
                                                                                    e
                                                                                )
                                                                            }
                                                                            checked={
                                                                                Array.isArray(
                                                                                    selectedFilters[
                                                                                        "filter.catalogIds"
                                                                                    ]
                                                                                ) && // Check if it's an array
                                                                                selectedFilters[
                                                                                    "filter.catalogIds"
                                                                                ].find(
                                                                                    (e) =>
                                                                                        e ===
                                                                                        element.id
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                    <div className="titleHead my-4"></div>
                                                    <p>{t("my-learning-filter.type")}</p>
                                                    <div className="scroller">
                                                        {filterJson.loTypes?.map((type) => {
                                                            return (
                                                                <div key={type.id}>
                                                                    <Form.Check
                                                                        className="filterCheckbox"
                                                                        type="checkbox"
                                                                        name="Type"
                                                                        value={type.value}
                                                                        id={`check-${type?.value}`}
                                                                        label={
                                                                            <span>
                                                                                {type.label}
                                                                            </span>
                                                                        }
                                                                        onClick={(e) =>
                                                                            onChangeFilterLoTypes(
                                                                                type.value,
                                                                                e
                                                                            )
                                                                        }
                                                                        checked={
                                                                            Array.isArray(
                                                                                selectedFilters[
                                                                                    "filter.loTypes"
                                                                                ]
                                                                            ) && // Check if it's an array
                                                                            selectedFilters[
                                                                                "filter.loTypes"
                                                                            ].find(
                                                                                (e) =>
                                                                                    e === type.value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                    <div className="titleHead my-4"></div>
                                                    <p>{t("my-learning-filter.duration")}</p>
                                                    <div className="scroller">
                                                        {filterJson.durationRange.map((type) => {
                                                            return (
                                                                <div key={type.id}>
                                                                    <Form.Check
                                                                        className="filterCheckbox"
                                                                        type="checkbox"
                                                                        name="Type"
                                                                        value={type.value}
                                                                        id={`check-${type?.value}`}
                                                                        label={
                                                                            <span>
                                                                                {type.label}
                                                                            </span>
                                                                        }
                                                                        onClick={(e) =>
                                                                            onChangeFilterDuration(
                                                                                type.value,
                                                                                e
                                                                            )
                                                                        }
                                                                        checked={
                                                                            Array.isArray(
                                                                                selectedFilters[
                                                                                    "filter.duration.range"
                                                                                ]
                                                                            ) && // Check if it's an array
                                                                            selectedFilters[
                                                                                "filter.duration.range"
                                                                            ].find(
                                                                                (e) =>
                                                                                    e === type.value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                    <div className="titleHead my-4"></div>
                                                    <p>{t("my-learning-filter.format")}</p>
                                                    <div className="scroller">
                                                        {filterFormatData?.map((format) => {
                                                            return (
                                                                <div key={format.id}>
                                                                    <Form.Check
                                                                        className="filterCheckbox"
                                                                        type="checkbox"
                                                                        name="Format"
                                                                        value={format.value}
                                                                        id={`check-${format?.value}`}
                                                                        label={
                                                                            <span>
                                                                                {format.label}
                                                                            </span>
                                                                        }
                                                                        onClick={(e) =>
                                                                            onChangeFilterLoFormat(
                                                                                format.value,
                                                                                e
                                                                            )
                                                                        }
                                                                        checked={
                                                                            Array.isArray(
                                                                                selectedFilters[
                                                                                    "filter.loFormat"
                                                                                ]
                                                                            ) && // Check if it's an array
                                                                            selectedFilters[
                                                                                "filter.loFormat"
                                                                            ].find(
                                                                                (e) =>
                                                                                    e ===
                                                                                    format.value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                    {roleBasedValues?.length > 0 && (
                                                        <>
                                                            <div className="titleHead my-4"></div>
                                                            <div className="subHead my-2">
                                                                {t(
                                                                    "my-learning-filter.role-based-skills"
                                                                )}
                                                            </div>
                                                            <div className="mb-2">
                                                                <InputGroup>
                                                                    <FormControl
                                                                        placeholder="Search for Role Based Skills"
                                                                        className="searh-icon searchBox1 py-2"
                                                                        value={inputRoleBasedSkill}
                                                                        onChange={
                                                                            inputRoleBasedSkillHandler
                                                                        }
                                                                    />
                                                                </InputGroup>
                                                            </div>
                                                            <div className="scroller mt-3">
                                                                {roleBasedValues
                                                                    ?.sort((a, b) =>
                                                                        a?.attributes?.name?.localeCompare(
                                                                            b?.attributes?.name
                                                                        )
                                                                    )
                                                                    ?.map((skillname) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    skillname
                                                                                        ?.attributes
                                                                                        ?.name
                                                                                }>
                                                                                <Form.Check
                                                                                    type="checkbox"
                                                                                    name="Roll Based Skills"
                                                                                    value={
                                                                                        skillname
                                                                                            ?.attributes
                                                                                            ?.name
                                                                                    }
                                                                                    id={`check-${skillname?.id}`}
                                                                                    className="filterCheckbox"
                                                                                    label={
                                                                                        <span>
                                                                                            {
                                                                                                skillname
                                                                                                    ?.attributes
                                                                                                    ?.name
                                                                                            }
                                                                                        </span>
                                                                                    }
                                                                                    // onChange={(e) => handleCheckBoxChange(e)}
                                                                                    onClick={(e) =>
                                                                                        onChangeFilterSkillName(
                                                                                            skillname
                                                                                                ?.attributes
                                                                                                ?.name,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                    // checked={selectedFilters['filter.skillName'].find(
                                                                                    //   (e) => e === skillname?.attributes?.name
                                                                                    // )}
                                                                                    checked={
                                                                                        Array.isArray(
                                                                                            selectedFilters[
                                                                                                "filter.skillName"
                                                                                            ]
                                                                                        ) && // Check if it's an array
                                                                                        selectedFilters[
                                                                                            "filter.skillName"
                                                                                        ].find(
                                                                                            (e) =>
                                                                                                e ===
                                                                                                skillname
                                                                                                    ?.attributes
                                                                                                    ?.name
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="titleHead my-4"></div>
                                                    <div className="subHead my-2">
                                                        {t("my-learning-filter.other-skills")}
                                                    </div>
                                                    <div className="mb-2">
                                                        <InputGroup>
                                                            <FormControl
                                                                placeholder="Search for Other Skills"
                                                                className="searh-icon searchBox1 py-2"
                                                                value={inputOtherSkill}
                                                                onChange={inputOtherSkillsHandler}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                    <div className="scroller mt-3">
                                                        {otherBasedSkillsdata
                                                            ?.sort((a, b) =>
                                                                a?.attributes?.name?.localeCompare(
                                                                    b?.attributes?.name
                                                                )
                                                            )
                                                            ?.map((otherskill) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            otherskill?.attributes
                                                                                ?.name
                                                                        }>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            name="Other Skills"
                                                                            value={
                                                                                otherskill
                                                                                    ?.attributes
                                                                                    ?.name
                                                                            }
                                                                            id={`check-${otherskill?.id}`}
                                                                            className="filterCheckbox"
                                                                            label={
                                                                                <span>
                                                                                    {
                                                                                        otherskill
                                                                                            ?.attributes
                                                                                            ?.name
                                                                                    }
                                                                                </span>
                                                                            }
                                                                            onClick={(e) =>
                                                                                onChangeFilterOtherSkillName(
                                                                                    otherskill
                                                                                        ?.attributes
                                                                                        ?.name,
                                                                                    e
                                                                                )
                                                                            }
                                                                            // checked={selectedFilters['filter.skillName'].find(
                                                                            //   (e) => e === otherskill?.attributes?.name
                                                                            // )}
                                                                            checked={
                                                                                Array.isArray(
                                                                                    selectedFilters[
                                                                                        "filter.skillName"
                                                                                    ]
                                                                                ) && // Check if it's an array
                                                                                selectedFilters[
                                                                                    "filter.skillName"
                                                                                ].find(
                                                                                    (e) =>
                                                                                        e ===
                                                                                        otherskill
                                                                                            ?.attributes
                                                                                            ?.name
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                    <div className="titleHead my-4"></div>
                                                    <p className="subHead my-2">
                                                        {t("my-learning-filter.skill-proficiency-level")}
                                                    </p>
                                                    <div>
                                                        {filterJson.SkillLevels?.map((skillLevel) => {
                                                            return (
                                                                <div key={skillLevel.id}>
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        name="SkillLevel"
                                                                        value={skillLevel.value}
                                                                        id={`check-${skillLevel?.value}`}
                                                                        label={
                                                                            <span>{skillLevel.label}</span>
                                                                        }
                                                                        className="filterCheckbox"
                                                                        // onChange={(e) => handleCheckBoxChange(e)}
                                                                        onClick={(e) =>
                                                                            onChangeFilterSkillLevel(
                                                                                skillLevel.value,
                                                                                e
                                                                            )
                                                                        }
                                                                        // checked={selectedFilters['filter.skill.level'].find(
                                                                        //   (e) => e === skillLevel.value
                                                                        // )}
                                                                        checked={
                                                                            Array.isArray(
                                                                                selectedFilters[
                                                                                    "filter.skill.level"
                                                                                ]
                                                                            ) && // Check if it's an array
                                                                            selectedFilters[
                                                                                "filter.skill.level"
                                                                            ].find(
                                                                                (e) =>
                                                                                    e === skillLevel.value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                    <div className="titleHead my-4"></div>
                                                    <div className="subHead my-2">
                                                        {t("my-learning-filter.keyword")}
                                                    </div>
                                                    <div className="mb-2">
                                                        <InputGroup>
                                                            <FormControl
                                                                placeholder="Search for Keyword"
                                                                className="searh-icon searchBox1 py-2"
                                                                value={inputTag}
                                                                onChange={inputTagHandler}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                    <div className="scroller mt-3">
                                                        {filterTagData
                                                            ?.sort((a, b) => a?.localeCompare(b))
                                                            ?.filter((e) => !e.includes("DNS"))
                                                            .map((tagname) => {
                                                                return (
                                                                    <div key={tagname}>
                                                                        <Form.Check
                                                                            className="filterCheckbox"
                                                                            type="checkbox"
                                                                            name="tags"
                                                                            value={tagname}
                                                                            id={`check-${tagname}`}
                                                                            label={
                                                                                <span>
                                                                                    {tagname}
                                                                                </span>
                                                                            }
                                                                            onClick={(e) =>
                                                                                onChangeFilterTagName(
                                                                                    tagname,
                                                                                    e
                                                                                )
                                                                            }
                                                                            checked={
                                                                                Array.isArray(
                                                                                    selectedFilters[
                                                                                        "filter.tagName"
                                                                                    ]
                                                                                ) && // Check if it's an array
                                                                                selectedFilters[
                                                                                    "filter.tagName"
                                                                                ].find(
                                                                                    (e) =>
                                                                                        e ===
                                                                                        tagname
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>

                                <div className="right-info">
                                    <div className="rightSectionAllCourses p-2 " ref={courseRef}>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap sort-minHeight">
                                            <div className="d-flex flex-wrap ms-4 mt-2">
                                                <div
                                                    className={
                                                        filterBySearch
                                                            ? "filterby-top-btn d-none"
                                                            : "filterby-top-btn"
                                                    }>
                                                    <button
                                                        className="btn primary-btn filterby-btn position-relative"
                                                        onClick={filterByIcon}>
                                                        <span className="label">
                                                            {t("Filter By")}
                                                        </span>
                                                        <img
                                                            src={filterby_icon}
                                                            alt="filterby icon"
                                                            className="filterby-icon"
                                                        />
                                                        <div className="tooltip-box d-none">
                                                            Click to Expand Filter
                                                        </div>
                                                    </button>
                                                </div>

                                                <div className="filterby-status">
                                                    <p className="fw-bold pe-4 ps-1">Status:</p>
                                                    {filterJson.learnerState?.map((status) => {
                                                        if (
                                                            status?.value === "notenrolled" &&
                                                            myLearning
                                                        ) {
                                                            return null;
                                                        }
                                                        return (
                                                            <div key={status.id}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    name="Status"
                                                                    value={status.value}
                                                                    id={`check-${status?.value}`}
                                                                    className="filterCheckbox px-3"
                                                                    label={
                                                                        <span className="ms-2 me-4">
                                                                            {status.value ===
                                                                            "enrolled"
                                                                                ? "Yet to Start"
                                                                                : status.label}
                                                                        </span>
                                                                    }
                                                                    onClick={(e) =>
                                                                        onChangeFilterLearnerState(
                                                                            status.value,
                                                                            e
                                                                        )
                                                                    }
                                                                    checked={
                                                                        Array.isArray(
                                                                            selectedFilters[
                                                                                "filter.learnerState"
                                                                            ]
                                                                        ) && // Check if it's an array
                                                                        selectedFilters[
                                                                            "filter.learnerState"
                                                                        ].find(
                                                                            (e) =>
                                                                                e === status.value
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            {showCourses && (
                                                <div className="m-4 d-flex align-items-center ps-0 ms-1 mt-3">
                                                    <div className="mx-4 font-bold">Sort By</div>
                                                    <Select
                                                        className="pgno select-option me-0"
                                                        onChange={handleChange}
                                                        value={selectOptions.find((option) => option.value === selectedFilters.sort)}
                                                        options={selectOptions}
                                                        defaultValue={selectOptions.find(
                                                            (e) =>
                                                                e.value === selectedFilters["sort"]
                                                        )}
                                                        onClick={onChangeFilterSorting}
                                                        styles={colourSelectOption}
                                                        getOptionLabel={(option) => (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center"
                                                                }}>
                                                                <span> {option.label} </span>
                                                                <span className="icon-class">
                                                                    {" "}
                                                                    {option.icon}
                                                                </span>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        {noData && (
                                            <div className="noData">
                                                No results found. Please try another search term.
                                            </div>
                                        )}

                                        <div className="all-courses-div centeredCards row coursesgrid-container coursesgrid-container-fill">
                                            {courseList ? (
                                                [
                                                    ...new Map(
                                                        courseList.map((item) => [item.id, item])
                                                    ).values()
                                                ].map((e) =>
                                                    myLearning ? (
                                                        <Card
                                                            className="m-4"
                                                            key={e.id}
                                                            componentName="MyLearning"
                                                            data={e}
                                                            showCartPopUp={false}
                                                            courseList={courseList}></Card>
                                                    ) : (
                                                        <Card1
                                                            className="m-4"
                                                            key={e.id}
                                                            componentName="MyLearning"
                                                            data={e}
                                                            showCartPopUp={false}
                                                            courseList={courseList}
                                                            flag={flag}></Card1>
                                                    )
                                                )
                                            ) : (
                                                <div></div>
                                            )}
                                            <InfiniteScroll
                                                dataLength={courseList.length}
                                                next={() => nextCursor()}
                                                hasMore={hasMore}
                                                loader={Loader}
                                                className={selectedStyle.className}
                                                endMessage={
                                                    <p style={{ textAlign: "center" }}>
                                                        <b></b>
                                                    </p>
                                                }
                                                scrollThreshold={0.2}></InfiniteScroll>
                                            
                                            {/* {showButton && (
                                                <button onClick={()=>nextCursor()}>Load More</button>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showButton && (
                                <div className="loadmore-courses">
                                    <div className="loadmore-courses-lable">
                                        <button onClick={()=>nextCursor()}>Load More</button>
                                        <span className="down-arrow"></span>
                                    </div>
                                </div>
                            )}
                        </Row>
                    </Container>
                    {showScrollToTop && (
                        <span
                            tabIndex={0}
                            className="scroll-button scrollTop"
                            role="button"
                            onClick={scrollToTop}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Space") {
                                    scrollUp();
                                }
                            }}
                            aria-label="Scroll to Top">
                            <img src={scrollUp} alt="Scroll to Top" />
                        </span>
                    )}
                </div>
            </Row>
        </Container>
    );
};

export default SearchCourseDetails;
