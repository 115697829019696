function getToken() {
    let userData = localStorage.getItem("CPToken") || null;
    return JSON.parse(userData);
}

function setToken(data) {
    localStorage.setItem("CPToken", JSON.stringify(data));
}

function isLogin() {
    let data = getToken();
    if (data) {
        return true;
    } else {
        return false;
    }
}

function clearLocalStorage() {
    localStorage.removeItem("CPToken");
    sessionStorage.clear();
    localStorage.removeItem("NavMenuData");
}

export {
    getToken,
    setToken,
    isLogin,
    clearLocalStorage,
}