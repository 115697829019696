/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { uniqWith, isEqual, remove, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { _dataApprovalPreview, _optionStatus, _tableHeader } from "../../data/dataMySkillJourney";
import CustomAccordion from "../../component/CustomAccordion/CustomAccordion";
import CustomAccordionApprovalPreview from "../../component/CustomAccordion/CustomAccordionApprovalPreview/CustomAccordionApprovalPreview";
import { apis } from "../../services/Api";
import Loader from "../../common/Loader/Loader";
import convertDateToString from "../../services/Datehelper";
import { appContext } from "../../context/appContext";
import Breadcrumbs from "../../common/Breadcrumbs";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";
import ProficiencyLevelModal from "../../common/Modal/ProficienyLevelModal";
import failed_icon from "../../assets/images/failed_icon.svg";
import Scroller from "../../common/Scroller/Scroller";
import { setCollapseData, setIsAllSelected, setIsAllSkillSelected } from "../../slices/mainSlice";
import "./MySkillJourney.css";
import { searchInArray } from "../../utils/dataTable";
import info from "../../assets/images/info.svg";

function getSkillHistoryObject(data) {
    return {
        skillName: data.SkillName,
        skillId: data.SkillId,
        groupId: data.SkillGroupId,
        roleId: data.RoleId,
        skillGroup: data.SkillGroupName,
        CurrentLevel: data.CurrentLevel,
        SubmittedLevel: data.SubmittedLevel,
        TargetLevel: data.TargetLevel,
        submittedDate: convertDateToString(data.SubmittedDateTime),
        aprroveDate: convertDateToString(data.ApprovalDateTime),
        status: data.ApprovalStatus,
        ApprovedFromRole: data.ApprovedFromRole,
        keynote: data.RejectionReason,
        roleSkillGroupId: `${data.RoleId}_${data.SkillGroupId}_${data.SkillId}`,
        skillIdTargetLevel: `${data.RoleId}_${data.SkillGroupId}_${data.SkillId}_${data.TargetLevel}_${data.SubmittedLevel}`,
        userRoleSkillsId: data.UserRoleSkillId,
        SkillDescription: data?.SkillDescription,
        RoleName: data?.RoleName
    };
}

function MySkillJourney(props) {
    let { state } = useLocation();
    const [displayMessageBox, setDisplayMessageBox] = useState(false);
    const [
        showDisplayMsgBoxForAwaitingApprovalPopUp,
        setShowDisplayMsgBoxForAwaitingApprovalPopUp
    ] = useState(false);
    const [submitForApproval, setSubmitForApproval] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedRoles] = useState();
    const [myskillJourneyData, setMyskillJourneyData] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);

    const [localStorageData, setLocalStorageData] = useState([]);
    const [localStoragePreviewData, setLocalStoragePreviewData] = useState([]);
    const [selectedSkillWithLevel, setSelectedSkillWithLevel] = useState([]);
    const [disableSubmit] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [selectStatusOptions, setSelectStatusOptions] = useState();
    const [selectRolesOptions, setSelectRolesOptions] = useState();
    const [roleDataListUI, setRoleDataListUI] = useState();
    const { CheckUserRoleSkills, dispatch } = useContext(appContext);
    const [originalStatusOptionList] = useState();
    const [, setFinalDataToSubmit] = useState();
    const [isDataLoaded] = useState(false);
    const [selectedStatusN, setSelectedStatusN] = useState([]);
    const [selectedRolesN, setSelectedRolesN] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [proficiencyShowModal, setProficiencyShowModal] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [messageForFailure, setMessageForFailure] = useState("");
    const [warningPopup, setWarningPopup] = useState("");
    const [noDataonClickApply, setNoDataonClickApply] = useState(false);
    const [showFilteredData, setShowFilteredData] = useState(false);
    const [navigatePreviousPage, setNavigatePreviousPage] = useState(false);
    const [currentlyNoData, setCurrentlyNoData] = useState("");
    const [dataForSkillJourney, setDataForSkillJourney] = useState();
    const [showPopUpForAwaitingStatus, setShowPopUpForAwaitingStatus] = useState(false);

    const [searchInput, setSearchInput] = useState("");
    const [onClickSearch, setClickSearch] = useState(false);

    const [skillDuplicateChecked, setSkillDuplicateChecked] = useState(false);

    const crumbs = [
        { label: "Home", url: "/" },
        { label: "My Skill Journey", url: "/myskilljourney" }
    ];

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [currentAccordionContent, setCurrentAccordionContent] = useState([]);
    const [currentAccordionIndex, setCurrentAccordionIndex] = useState(-1);
    const { isAllSkillSelected } = useSelector((state) => state.main);
    const reduxDispatch = useDispatch();

    const handleFilter = (e) => {
        const searchTerm = e.target.value;
        setSearchInput(searchTerm);
    };

    const searchResults = searchInArray(roleDataListUI, searchInput);

    const prepareSelectRolesOptions = () => {
        const roleOptions = [];
        const uniqueRoles = new Set();
        let uniqueId = 0;

        myskillJourneyData?.forEach((item) => {
            const roleName = item?.RoleName || "Additional Skills";
            if (!uniqueRoles.has(roleName)) {
                uniqueRoles.add(roleName);
                roleOptions.push({
                    id: uniqueId,
                    value: roleName,
                    label: roleName
                });
                uniqueId++;
            }
        });

        setSelectRolesOptions(roleOptions);
        setSelectedRolesN(roleOptions);
    };

    const prepareSelectStatusOptions = () => {
        const optionStatus = _optionStatus.sort((a, b) => a.label.localeCompare(b.label));
        setSelectStatusOptions(optionStatus);
        setSelectedStatus(optionStatus);
        setSelectedStatusN(optionStatus);
    };

    const getMySkillJourneyData = async () => {
        setShowLoader(true);
        try {
            const response = await apis.getMySkillJourney();
            if (response?.status === 200) {
                setMyskillJourneyData(response?.data);
                setFinalDataToSubmit(response?.data);
                if (response?.data?.length === 0) {
                    setCurrentlyNoData("No Skill(s)/Role Packet(s) are assigned to you.");
                }
                if (!response?.data?.length) setShowLoader(false);
            } else {
                setShowLoader(false);
                setCurrentlyNoData("No Skill(s)/Role Packet(s) are assigned to you.");
            }
        } catch (error) {
            console.warn(error);
            setShowLoader(false);
        }
    };

    const handleSkillSelection = (content, checked, contentSkillId, index) => {
        if (checked) {
            if (selectedSkills.indexOf(content.skillIdTargetLevel) < 0) {
                let newSkills = [...selectedSkills, content.skillIdTargetLevel];
                setSelectedSkills(newSkills);
            }
        } else {
            const index = selectedSkills.indexOf(content.skillIdTargetLevel);
            if (index > -1) {
                let newSkills = [
                    ...selectedSkills.filter((sk) => sk !== content.skillIdTargetLevel)
                ];
                setSelectedSkills(newSkills);
            }
        }

        setCurrentAccordionIndex(index);
        setCurrentAccordionContent({
            ...currentAccordionContent,
            [`${index}-content`]: contentSkillId
        });
    };

    useEffect(() => {
        const contentData = currentAccordionContent[`${currentAccordionIndex}-content`];
        const updatedState = [...isAllSkillSelected];

        const newSelected = contentData?.every((skillId) => selectedSkills?.includes(skillId));

        updatedState[currentAccordionIndex] = newSelected;

        reduxDispatch(setIsAllSkillSelected(updatedState));
    }, [currentAccordionIndex, currentAccordionContent, selectedSkills]);

    const transformSkills = (inputArray) => {
        const extractMain = (item) => item.split("_")[0];
        const extractSelected = (item) => item.split("_")[2];

        const mainSkills = Array.from(new Set(inputArray.map(extractMain)));
        const selectedSkills = inputArray.map(extractSelected);

        return { main: mainSkills, selected: selectedSkills };
    };

    const hasDuplicates = (arr) => new Set(arr).size !== arr.length;

    useEffect(() => {
        if (!isEmpty(selectedSkills)) {
            const { main, selected } = transformSkills(selectedSkills);
            const hasDuplicateSkills = main.length > 1 && hasDuplicates(selected);
            setSkillDuplicateChecked(hasDuplicateSkills);
        }
    }, [selectedSkills]);

    const handleOkDisplayPopUp = () => {
        setShowDisplayMsgBoxForAwaitingApprovalPopUp(false);
        if (localStoragePreviewData?.length === 0) {
            setNavigatePreviousPage(true);
            setMessageForFailure("No Skill(s) are available for preview.");
            setMessageforPopup("");
            setWarningPopup("");
            setShowModal(true);
        }
    };

    const handleCloseDisplayPopUp = () => {
        if (state?.viewContent) {
            dispatch({ type: "CheckUserRoleSkillsItem", payload: [] });
        }
        setShowDisplayMsgBoxForAwaitingApprovalPopUp(false);
        navigate(-1);
    };

    const getCDataBySTatus = (CheckUserRoleSkills) => {
        const res = [];
        CheckUserRoleSkills?.res?.map((item) => {
            CheckUserRoleSkills?.roleWiseSkillsData?.map((item2) => {
                if (item.SkillId === item2.SkillId) {
                    res.push({ ...item, ...item2 });
                }
            });
        });
        return res;
    };

    const getOtherSkillData = (CheckUserRoleSkills) => {
        const otherSkills = CheckUserRoleSkills?.res?.filter((obj) => {
            if (obj.GroupName) {
                return { ...obj, SkillGroupName: obj.GroupName };
            }
            return obj;
        });
        return otherSkills;
    };

    const handleSetDataForSkillJournerPage = () => {
        if (state?.submitForApproval || state?.viewContent) {
            const selectedSkillsForApproval = getSelectedRollPreview(
                getCDataBySTatus(CheckUserRoleSkills)?.filter(
                    (i) => !i.Status || i.Status !== "Awaiting Approval"
                ),
                true
            );
            if (selectedSkillsForApproval?.length > 0) {
                setSubmitForApproval(true);
            }
            setLocalStoragePreviewData(selectedSkillsForApproval);
        } else if (state?.courseDetails) {
            if (state?.courseDetails) {
                const selectedSkillsForApprovalFromCourseDetails = getSelectedRollPreview(
                    getOtherSkillData(CheckUserRoleSkills)?.filter(
                        (i) => !i.Status || i.Status !== "Awaiting Approval"
                    ),
                    true
                );
                if (selectedSkillsForApprovalFromCourseDetails?.length > 0) {
                    setSubmitForApproval(true);
                }
                setLocalStoragePreviewData(selectedSkillsForApprovalFromCourseDetails);
            }
        } else {
            setSubmitForApproval(false);
        }

        let approvalStatus = "none";
        const awaitingApproval = CheckUserRoleSkills?.res?.filter(
            (e) => e.Status == "Awaiting Approval"
        );
        if (CheckUserRoleSkills?.res?.length == awaitingApproval?.length) {
            approvalStatus = "all";
        } else if (
            awaitingApproval?.length !== 0 &&
            CheckUserRoleSkills?.res?.length > awaitingApproval?.length
        ) {
            approvalStatus = "partial";
        }

        const skillsAwaitingApproval = getCDataBySTatus(CheckUserRoleSkills).filter(
            (i) => i.Status === "Awaiting Approval"
        );

        if (
            (state?.submitForApproval || state?.courseDetails) &&
            skillsAwaitingApproval.length > 0
        ) {
            if (approvalStatus === "all") {
                setShowPopUpForAwaitingStatus(true);
                setShowDisplayMsgBoxForAwaitingApprovalPopUp(false);
            } else {
                setShowDisplayMsgBoxForAwaitingApprovalPopUp(true);
                setShowPopUpForAwaitingStatus(false);
            }
        } else {
            setShowDisplayMsgBoxForAwaitingApprovalPopUp(false);
            setShowPopUpForAwaitingStatus(false);
        }

        const skillsAwaitingApproval1 = getOtherSkillData(CheckUserRoleSkills)?.filter(
            (i) => i.Status === "Awaiting Approval"
        );
        if (
            (state?.submitForApproval || state?.courseDetails) &&
            skillsAwaitingApproval1?.length > 0
        ) {
            if (approvalStatus === "all") {
                setShowPopUpForAwaitingStatus(true);
                setShowDisplayMsgBoxForAwaitingApprovalPopUp(false);
            } else {
                setShowDisplayMsgBoxForAwaitingApprovalPopUp(true);
                setShowPopUpForAwaitingStatus(false);
            }
        } else {
            setShowDisplayMsgBoxForAwaitingApprovalPopUp(false);
            setShowPopUpForAwaitingStatus(false);
        }
    };

    useEffect(() => {
        handleSetDataForSkillJournerPage();
        getMySkillJourneyData();
        prepareSelectRolesOptions();
        prepareSelectStatusOptions();

        return () => {
            reduxDispatch(setIsAllSkillSelected([]));
            reduxDispatch(setIsAllSelected([]));
            reduxDispatch(setCollapseData([]));
        };
    }, []);

    useEffect(() => {
        if (!isDataLoaded) {
            getMySkillJourneyData();
            prepareSelectRolesOptions();
            prepareSelectStatusOptions();
            handleSetDataForSkillJournerPage();
        }
    }, [isDataLoaded]);

    const prepareRoleListDataForUI = (dataList, roleOptionList, statusOptionList) => {
        setFinalDataToSubmit(dataList);
        const updatedListArryUI = [dataList].map((arry) => transforRoleDatatoView(arry));

        setNoDataonClickApply(updatedListArryUI[0].length === 0);
        setRoleDataListUI(updatedListArryUI);
    };

    useEffect(() => {
        if (myskillJourneyData?.length > 0) {
            if (showFilteredData) {
                handleApply();
                setSelectedRolesN(selectedRolesN);
            } else {
                prepareSelectRolesOptions();
                prepareRoleListDataForUI(
                    myskillJourneyData,
                    selectRolesOptions,
                    selectStatusOptions
                );
            }
            const dataAfterTransform = transforRoleDatatoView(myskillJourneyData);
            setLocalStorageData(dataAfterTransform);
            setShowLoader(false);
            setSearchInput("");
        }
    }, [myskillJourneyData]);

    const handleCloseDisplayMessage = () => {
        setDisplayMessageBox(false);
    };

    const handleSubmit = async (e) => {
        setShowLoader(true);
        let localPrivData = [];
        if (localStoragePreviewData.length && localStoragePreviewData.length > 1) {
            localStoragePreviewData.forEach((item) => {
                if (item.skills.length) {
                    item.skills.forEach((data) => {
                        localPrivData.push(data);
                    });
                }
            });
        } else {
            localPrivData = localStoragePreviewData[0].skills;
        }
        let FinallocalPrivData = [];
        localPrivData.filter((data) => {
            let ProfLen = 0;
            data.proficiencyLevels.forEach((level) => {
                if (level.checked) ProfLen += 1;
            });
            if (ProfLen !== 3) FinallocalPrivData.push(data);
        });
        if (
            selectedSkillWithLevel.length > 0 &&
            selectedSkillWithLevel.length === FinallocalPrivData.length
        ) {
            // setDisableSubmit(true);
            const inputData = selectedSkillWithLevel.map((sk) => {
                let _obj;
                if (!sk.userRoleSkillsId) {
                    _obj = {
                        userRoleSkillId: 0,
                        roleId: sk.roleId,
                        groupId: sk.groupId,
                        skillId: sk.skillId,
                        submittedLevel: sk.proficiencyLevels.value,
                        TargetLevel: sk.TargetLevel
                    };
                } else {
                    _obj = {
                        userRoleSkillId: sk.userRoleSkillsId,
                        submittedLevel: sk.proficiencyLevels.value,
                        roleId: sk.roleId,
                        groupId: sk.groupId,
                        skillId: sk.skillId,
                        TargetLevel: sk.TargetLevel
                    };
                }
                // setDisableSubmit(false);
                return _obj;
            });
            const filteredSkills = dataForSkillJourney?.skills?.filter((skill) =>
                skill?.proficiencyLevels?.some(
                    (level) => level?.value === 300 && level?.checked === true
                )
            );
            const formattedData = [];

            filteredSkills?.forEach((item) => {
                formattedData.push({
                    userRoleSkillId: item.userRoleSkillsId,
                    submittedLevel: item.TargetLevel,
                    roleId: item.roleId,
                    groupId: item.groupId,
                    skillId: item.skillId,
                    TargetLevel: item.TargetLevel,
                    CurrentLevel: item.CurrentLevel
                });
                return formattedData;
            });
            const payloadForSubmit = [...inputData, ...formattedData];

            const input = { userroleskilliddata: payloadForSubmit };
            const response = await apis.submitSkillForApproval(input);
            const link = (
                <>
                    Unable to complete the request, please retry after some time. If you need
                    further help, contact{" "}
                    <a
                        href="mailto:dx-sio-enablement@adobe.com"
                        className="btn btn-link btn-view fontSize14Text filterEffect">
                        dx-sio-enablement@adobe.com
                    </a>
                    .
                </>
            );

            if (response?.message === "all_enrolled") {
                setMessageforPopup(
                    "Selected Skill(s) have been successfully submitted for approval."
                );
                setShowModal(true);
            } else if (response?.message === "all_awaitingApproval") {
                setMessageforPopup(
                    "Skill(s) submitted are already in awaiting approval. Please submit only the Skill(s) which are not in awaiting approval."
                );
                setShowModal(true);
            } else if (response?.message === "partial_awaitingApproval") {
                setMessageforPopup(
                    "Few Skill(s) are already in awaiting approval. Please submit only the Skill(s) which are not in awaiting approval."
                );
                setShowModal(true);
            } else if (response?.message === "partially_enrolled") {
                const skillName = [];
                let retiredSkills = "";
                let unenrolledSkills = "";
                response?.extraInfo?.forEach((a) => {
                    skillName.push(a?.SkillName);
                });
                response?.RetiredFailure?.forEach((a, key) => {
                    retiredSkills += `${a?.SkillName}`;
                    if (key < response?.RetiredFailure.length - 1) {
                        retiredSkills += ", ";
                    }
                });

                if (response?.ALMEnrollmentFailed?.length > 0 && retiredSkills != "") {
                    unenrolledSkills = " ,";
                }
                response?.ALMEnrollmentFailed?.forEach((a, key) => {
                    unenrolledSkills += `${a?.SkillName}`;
                    if (key < response?.ALMEnrollmentFailed.length - 1) {
                        unenrolledSkills += ", ";
                    }
                });
                setMessageforPopup(
                    `Few Skill(s) have been submitted successfully for approval. Unable to submit following Skill(s) for approval:  ${retiredSkills} ${unenrolledSkills}`
                );
                setShowModal(true);
            } else if (response.message === "none_enrolled_retired") {
                const link = (
                    <>
                        Unable to submit the selected Skill(s) for approval, please contact{" "}
                        <a href="mailto:dx-sio-enablement@adobe.com">dx-sio-enablement@adobe.com</a>
                        .
                    </>
                );
                setMessageForFailure(link);
                setMessageforPopup("");
                setShowModal(true);
            } else if (response.message === "none_enrolled") {
                setMessageForFailure(link);
                setMessageforPopup("");
                setShowModal(true);
            } else {
                setMessageForFailure(link);
                setMessageforPopup("");
            }
            setWarningPopup("");
            setShowModal(true);
            setSubmitForApproval(false);
            setSelectedSkills([]);
            setSelectedSkillWithLevel([]);
            getMySkillJourneyData();
            setLocalStorageData([]);
            setShowLoader(false);
        } else {
            if (FinallocalPrivData.length === 0) {
                setMessageForFailure(" No Skill(s) are available for submission.");
            } else {
                setMessageForFailure(
                    "Please select proficiency level to submit the Skill(s) for approval."
                );
            }
            setShowLoader(false);
            setMessageforPopup("");
            setWarningPopup("");
            setShowModal(true);
        }
    };

    const handleApply = async () => {
        setShowLoader(true);
        setShowFilteredData(true);
        let updatedDataPerStatus = [];
        if (selectedStatusN.length > 0) {
            selectedStatusN.forEach((item) => {
                const tempInner = myskillJourneyData.filter((msj) => {
                    if (msj.ApprovalStatus === item.label) {
                        return msj;
                    }
                });
                updatedDataPerStatus.push(tempInner);
            });
        }

        let flatStatusArray = [].concat.apply([], updatedDataPerStatus);
        let updatedDataPerRoles = [];

        if (selectedRolesN?.length > 0) {
            selectedRolesN.forEach((itm) => {
                const tempInnerR = flatStatusArray.filter((msj) => {
                    if (
                        itm?.label?.trim()?.toUpperCase() === "ADDITIONAL SKILLS" &&
                        (msj?.RoleName == null || msj?.RoleName === "")
                    ) {
                        return msj;
                    }
                    if (
                        msj?.RoleName?.trim()?.toUpperCase() === itm?.label?.trim()?.toUpperCase()
                    ) {
                        return msj;
                    }
                });
                updatedDataPerRoles.push(tempInnerR);
            });
        }

        let flatRolesArray = [].concat.apply([], updatedDataPerRoles);
        await prepareRoleListDataForUI(flatRolesArray, selectedRoles, selectedStatus);
        setShowLoader(false);
    };

    const handleSubmitForApproval = async () => {
        if (skillDuplicateChecked) {
            setMessageForFailure("You cannot submit same Skill(s) from multiple role packets.");
            setMessageforPopup("");
            setWarningPopup("");
            setShowModal(true);
            return false;
        }

        if (selectedSkills?.length > 0) {
            setShowLoader(true);
            const selectedSkillsForApproval = await getSelectedRollPreview(myskillJourneyData);

            const finalData = selectedSkillsForApproval.map((item) => {
                const uniqueData = uniqWith(item?.skills, isEqual);
                return { ...item, skills: uniqueData };
            });
            setLocalStoragePreviewData(finalData);
            setShowLoader(false);
            setSubmitForApproval(true);
            setSelectedSkillWithLevel([]);
        } else {
            setMessageForFailure("Please select at least one Skill to continue.");
            setMessageforPopup("");
            setWarningPopup("");
            setShowModal(true);
        }

        reduxDispatch(setIsAllSkillSelected([]));
        reduxDispatch(setIsAllSelected([]));
    };

    const handleClearAll = (e) => {
        setMyskillJourneyData(myskillJourneyData);
        setSelectStatusOptions(originalStatusOptionList);
        getMySkillJourneyData();

        prepareSelectStatusOptions();
        prepareSelectRolesOptions();

        prepareRoleListDataForUI(myskillJourneyData, selectRolesOptions, selectStatusOptions);
        setLocalStoragePreviewData(_dataApprovalPreview);
        setClickSearch(false);
        setNoDataonClickApply(false);
        setSearchInput("");
    };

    const handleProficiencyLevel = (data, e) => {
        const indexOfExisting = selectedSkillWithLevel.findIndex(
            (e) => e.skillId == data.skillId && e.roleId == data.roleId && e.groupId == data.groupId
        );
        let newLevelSelections = [...selectedSkillWithLevel];
        if (indexOfExisting > -1) {
            newLevelSelections.splice(indexOfExisting, 1);
        }
        newLevelSelections.push(data);
        setSelectedSkillWithLevel(newLevelSelections);
    };

    const backToMySkillJounry = () => {
        setLocalStoragePreviewData([]);
        setSelectedSkillWithLevel([]);
        setSelectedSkills([]);
        setSubmitForApproval(false);
        dispatch({ type: "CheckUserRoleSkillsItem", payload: [] });
        if (state?.pathname) {
            navigate(state?.pathname, { state: state?.pathname?.state });
        } else {
            navigate("/myskilljourney");
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setMessageForFailure("");
        if (messageForFailure !== "" && navigatePreviousPage) {
            navigate(-1);
        }
    };

    const handleProficiencyCloseModal = () => {
        setProficiencyShowModal(false);
    };
    const handleProficiencyOpenModal = () => {
        setProficiencyShowModal(true);
    };
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const proficiencyLevelsGroup = (contentData, skillName, skillGroup) => {
        setDataForSkillJourney(contentData);
        const _contentData = contentData?.title?.replace(/[^a-zA-Z0-9]/g, "");
        const _skillName = skillName
            ?.split(" ")
            ?.join("")
            ?.replace(/[^a-zA-Z0-9]/g, "");
        const _skillGroup = skillGroup
            ?.split(" ")
            ?.join("")
            ?.replace(/[^a-zA-Z0-9]/g, "");
        return _contentData + _skillName + _skillGroup;
    };

    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4">
                <div className="headerImage">
                    <h1>{t("my-skill-journey")}</h1>
                </div>
            </Container>

            {showLoader ? (
                <Loader />
            ) : showPopUpForAwaitingStatus ? (
                <div></div>
            ) : !showLoader &&
              localStorageData?.length == 0 &&
              (CheckUserRoleSkills?.res?.length == 0 || CheckUserRoleSkills?.res == undefined) ? (
                <div className="center-container">
                    <div className="content-box">
                        <h2 className="styled-heading no-data">{currentlyNoData}</h2>
                    </div>
                </div>
            ) : (localStorageData && localStorageData?.length > 0) ||
              (CheckUserRoleSkills && CheckUserRoleSkills?.res?.length > 0) ? (
                <div className="pl-30 myskilljourney scrollable-page">
                    <div className="main-section pageBorder">
                        {!submitForApproval ? (
                            <div className="my-skill-journey-container">
                                <div
                                    className={`top-container-section ${
                                        isSticky ? "stickyBoxShadow" : ""
                                    }`}>
                                    <Row>
                                        <Col lg={9} md={9} sm={12} className="container-section">
                                            <div className="filter-section mt-2 align-items-end">
                                                <div className="select-section select-status-section">
                                                    <div className="title-label">Select Status</div>
                                                    <MultiSelect
                                                        options={selectStatusOptions}
                                                        value={selectedStatusN}
                                                        onChange={setSelectedStatusN}
                                                        labelledBy={"Select"}
                                                        isCreatable={true}
                                                    />
                                                </div>
                                                <div className="select-section select-roles-section">
                                                    <div className="title-label">Select Roles</div>
                                                    <MultiSelect
                                                        options={selectRolesOptions}
                                                        value={selectedRolesN}
                                                        onChange={setSelectedRolesN}
                                                        labelledBy={"Select"}
                                                        isCreatable={true}
                                                    />
                                                </div>
                                                <div className="btn-apply">
                                                    <button
                                                        className="btn primary-btn mx-2 mb-1"
                                                        onClick={(e) => handleApply(e)}>
                                                        Apply
                                                    </button>
                                                </div>
                                                <div className="btn-clear-all align-self-center mt-4">
                                                    <button
                                                        className="btn btn-link btn-view"
                                                        onClick={(e) => handleClearAll(e)}>
                                                        Reset Filters
                                                    </button>
                                                </div>
                                                {/* <div className="level-options-device level-options-device-myskill mt-2">
                                                    <div className="labels-group">
                                                        <div className="fontSize14">
                                                            Proficiency Levels:
                                                        </div>

                                                        <div className="labels-item">
                                                            <div className=""></div>
                                                            <div className="text">
                                                                100
                                                                <span className="sub">
                                                                    (Beginner)
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="labels-item">
                                                            <div className=""></div>
                                                            <div className="text">
                                                                200
                                                                <span className="sub">
                                                                    (Intermediate)
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="labels-item">
                                                            <div className=""></div>
                                                            <div className="text">
                                                                300
                                                                <span className="sub">
                                                                    (Expert)
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </Col>

                                        <Col lg={3} sm={12} className="button-section pt-5">
                                            <div className="d-flex align-items-end justify-content-end">
                                                <div className="search searchClass">
                                                    <div className="searchInputs searchInputDiv">
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            onChange={handleFilter}
                                                            value={searchInput}
                                                        />
                                                        <button className="searchIcon searchBtn">
                                                            <span className="pi pi-search"></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={8} md={12} sm={12} className="container-section">
                                            <div className="level-options-device level-options-device-myskill pt-4">
                                                <div className="labels-group">
                                                    <div className="fontSize14">
                                                        <img
                                                            src={info}
                                                            alt="info"
                                                            className="info-icon"
                                                            onClick={handleProficiencyOpenModal}
                                                            style={{ cursor: "pointer" }}
                                                        />{" "}
                                                        Proficiency Levels:
                                                    </div>

                                                    <div className="labels-item">
                                                        <div className=""></div>
                                                        <div className="text">
                                                            100
                                                            <span className="sub">(Beginner)</span>
                                                        </div>
                                                    </div>
                                                    <div className="labels-item">
                                                        <div className=""></div>
                                                        <div className="text">
                                                            200
                                                            <span className="sub">
                                                                (Intermediate)
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="labels-item">
                                                        <div className=""></div>
                                                        <div className="text">
                                                            300
                                                            <span className="sub">(Expert)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={12} sm={12} className="button-section">
                                            <div className="button-section pt-4">
                                                <button
                                                    className="btn primary-btn primary-blue"
                                                    onClick={handleSubmitForApproval}>
                                                    Submit Skill(s) for Approval
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="main-container-section my-skill-journey">
                                    {!isEmpty(searchResults) ? (
                                        <CustomAccordion
                                            mainData={myskillJourneyData}
                                            data={searchResults}
                                            tableHeader={_tableHeader}
                                            handleSubmitForApprovalClick={handleSkillSelection}
                                            setSelectedSkills={setSelectedSkills}
                                            selectedSkills={selectedSkills}
                                        />
                                    ) : noDataonClickApply ? (
                                        <p className="no-data">
                                            No data found for the current selection.
                                        </p>
                                    ) : (
                                        <p className="no-data">
                                            No results found. Please try another search term.
                                        </p>
                                    )}
                                </div>

                                {/* <div className="button-section pt-2">
                                    <button
                                        className="btn primary-btn primary-blue"
                                        onClick={handleSubmitForApproval}>
                                        Submit Skill(s) for Approval
                                    </button>
                                </div> */}
                            </div>
                        ) : (
                            <div>
                                <div className="skill-approval-preview-container">
                                    <div className="top-container-section">
                                        <Row>
                                            <Col sm={8} className="container-section">
                                                <div className="title">Skill Approval Preview</div>
                                            </Col>
                                            <Col sm={4} className="button-section">
                                                <button
                                                    className="btn cancel-btn m-2"
                                                    onClick={backToMySkillJounry}>
                                                    Back
                                                </button>
                                                <button
                                                    className="btn primary-btn primary-blue m-2"
                                                    disabled={disableSubmit ? "disable" : ""}
                                                    onClick={handleSubmit}>
                                                    Submit
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="main-approval-preview-container-section">
                                        <CustomAccordionApprovalPreview
                                            data={localStoragePreviewData}
                                            handleProficiencyLevel={handleProficiencyLevel}
                                            proficiencyLevelsGroup={proficiencyLevelsGroup}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="modal-container">
                        <Modal show={displayMessageBox} className="reject-modal" centered>
                            <Modal.Body className="py-4 px-5">
                                <div className="success-modal-container mt-4">
                                    <span className="reject-icon"></span>
                                    <p className="success-text-message">
                                        Technical skill has been rejected.
                                    </p>
                                </div>
                                <p className="reason">Rejection Reason:</p>
                                <textarea
                                    name="reason-textarea"
                                    className="reason-textarea"
                                    rows="4"
                                    placeholder="&nbsp;Type rejected reason:"></textarea>
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center py-4">
                                <button
                                    className="btn btn-link m-2 "
                                    onClick={() => handleCloseDisplayMessage()}>
                                    Cancel
                                </button>
                                <button
                                    className="btn primary-btn primary-blue m-2"
                                    onClick={() => handleCloseDisplayMessage()}>
                                    Submit
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            ) : (
                <> </>
            )}

            <div className="modal-container-skills">
                <Modal show={showDisplayMsgBoxForAwaitingApprovalPopUp} centered>
                    <Modal.Body>
                        <div className="failure-modal-container-body my-5">
                            <img className="failed-icon" src={failed_icon} alt="failed"></img>
                            <div className="success-text-message2 p-0 pt-3">
                                Selected one or more Skill(s) are in <b>Awaiting Approval</b>{" "}
                                status. You will not see those skills on the next (Skill Approval
                                Preview) page. Do you want to proceed?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="failure-modal-container-footer">
                            {/* btn-view */}
                            <button
                                className="btn btn-link"
                                onClick={() => handleCloseDisplayPopUp()}>
                                Cancel
                            </button>
                            <button
                                className="btn primary-btn primary-blue m-2"
                                onClick={() => handleOkDisplayPopUp()}>
                                Yes
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="modal-container-skills">
                <Modal show={showPopUpForAwaitingStatus} centered>
                    <Modal.Body>
                        <div className="failure-modal-container-body my-5">
                            <img className="failed-icon" src={failed_icon} alt="failed"></img>
                            <div className="success-text-message2 p-0 pt-3">
                                Selected Skill(s) are in <b>Awaiting Approval</b> status.
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="failure-modal-container-footer">
                            <button
                                className="btn primary-btn primary-blue m-2"
                                onClick={() => handleCloseDisplayPopUp()}>
                                Ok
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
            <ModalforSuccessAndFailure
                messageforPopup={messageforPopup}
                messageForFailure={messageForFailure}
                warningPopup={warningPopup}
                show={showModal}
                onClose={handleCloseModal}
            />
             <ProficiencyLevelModal
                show={proficiencyShowModal}
                onClose={handleProficiencyCloseModal}
            />
            <Scroller />
        </>
    );

    function transforRoleDatatoView(dataRela) {
        let transformData = [];

        dataRela.forEach((data) => {
            let existingRole = transformData.find((role) => role.id === data.RoleId);

            if (!existingRole) {
                // New role
                let role = {
                    id: data.RoleId,
                    title: data.RoleName || "Additional Skills",
                    skills: [
                        {
                            skillName: data.SkillName,
                            history: [getSkillHistoryObject(data)]
                        }
                    ]
                };
                transformData.push(role);
            } else {
                // Existing role
                let existingSkill = existingRole.skills.find(
                    (skill) => skill.skillName === data.SkillName
                );

                if (!existingSkill) {
                    // New skill for the existing role
                    existingRole.skills.push({
                        skillName: data.SkillName,
                        history: [getSkillHistoryObject(data)]
                    });
                } else {
                    // Existing skill, add to its history
                    existingSkill.history.push(getSkillHistoryObject(data));
                }
            }
        });

        const additionalSkills = remove(
            transformData,
            (item) => item.title === "Additional Skills"
        );
        transformData.push(...additionalSkills);

        return transformData;
    }

    function getProficiencies(CurrentLevel) {
        return [
            {
                label: "Level 100",
                value: 100,
                status: CurrentLevel && CurrentLevel >= 100 ? "completed" : "started",
                checked: CurrentLevel && CurrentLevel >= 100 ? true : false,
                disabled: CurrentLevel && CurrentLevel >= 100 ? true : false
            },
            {
                label: "Level 200",
                value: 200,
                status: CurrentLevel && CurrentLevel >= 200 ? "completed" : "started",
                checked: CurrentLevel && CurrentLevel >= 200 ? true : false,
                disabled: CurrentLevel && CurrentLevel >= 200 ? true : false
            },
            {
                label: "Level 300",
                value: 300,
                status: CurrentLevel && CurrentLevel >= 300 ? "completed" : "started",
                checked: CurrentLevel && CurrentLevel >= 300 ? true : false,
                disabled: CurrentLevel && CurrentLevel >= 300 ? true : false
            }
        ];
    }

    function getSelectedRollPreview(dataRela, skillJourny) {
        let roleId;
        let selectedSkillsForApproval = [];
        dataRela?.forEach((data) => {
            if (data.RoleId === "") {
                data.RoleId = 0;
            }
            if (roleId !== data.RoleId) {
                const roleGroupSkillTargetSubmittedId = `${data.RoleId}_${data.SkillGroupId}_${data.SkillId}_${data.TargetLevel}_${data.SubmittedLevel}`;

                if (selectedSkills.indexOf(roleGroupSkillTargetSubmittedId) > -1) {
                    let role = {
                        id: data.RoleId,
                        title: data.RoleName || "Additional Skills",
                        skills: [createPreviewSkillsObject(data)]
                    };
                    roleId = data.RoleId;
                    selectedSkillsForApproval.push(role);
                } else if (skillJourny) {
                    let role = {
                        id: data.RoleId,
                        title: data.RoleName || "Additional Skills",
                        skills: [createPreviewSkillsObject(data)]
                    };
                    roleId = data.RoleId;
                    selectedSkillsForApproval.push(role);
                }
            } else {
                const roleGroupSkillTargetSubmittedId = `${data.RoleId}_${data.SkillGroupId}_${data.SkillId}_${data.TargetLevel}_${data.SubmittedLevel}`;

                if (selectedSkills.indexOf(roleGroupSkillTargetSubmittedId) > -1) {
                    let lastRole = selectedSkillsForApproval[selectedSkillsForApproval.length - 1];
                    const isSkillPresent = lastRole?.skills?.filter(
                        (s) => s.skillName === data.SkillName
                    );
                    if (isSkillPresent?.length === 0) {
                        lastRole.skills.push(createPreviewSkillsObject(data));
                    } else {
                        let role = {
                            id: data.RoleId,
                            title: data.RoleName || "Additional Skills",
                            skills: [createPreviewSkillsObject(data)]
                        };
                        roleId = data.RoleId;
                        selectedSkillsForApproval.push(role);
                    }
                } else if (skillJourny) {
                    let lastRole = selectedSkillsForApproval[selectedSkillsForApproval.length - 1];
                    const isSkillPresent = lastRole?.skills?.filter(
                        (s) => s.skillName === data.SkillName
                    );

                    if (isSkillPresent?.length === 0) {
                        lastRole.skills.push(createPreviewSkillsObject(data));
                    } else {
                        let role = {
                            id: data.RoleId,
                            title: data.RoleName || "Additional Skills",
                            skills: [createPreviewSkillsObject(data)]
                        };
                        roleId = data.RoleId;
                        selectedSkillsForApproval.push(role);
                    }
                }
            }
        });
        let updatedArray = [];
        if (selectedSkillsForApproval) {
            selectedSkillsForApproval?.forEach((item) => {
                let obj = updatedArray.filter((ele) => ele.id === item.id);
                if (obj && obj.length > 0) {
                    obj[0].skills = obj[0].skills.concat([...item.skills]);
                } else {
                    updatedArray.push(item);
                }
            });
        }
        return updatedArray;
    }

    function createPreviewSkillsObject(data) {
        return {
            skillName: data.SkillName,
            skillGroup: data.SkillGroupName || data?.GroupName,
            skillId: data.SkillId,
            groupId: data.SkillGroupId || data.GroupId,
            roleId: data.RoleId,
            userRoleSkillsId: data.UserRoleSkillId,
            TargetLevel: data.TargetLevel,
            CurrentLevel: data.CurrentLevel,
            proficiencyLevels: getProficiencies(data.CurrentLevel)
        };
    }
}

export default MySkillJourney;
