/* eslint-disable linebreak-style */
import { configureStore } from "@reduxjs/toolkit";

import mainSliceReducer from "../slices/mainSlice";
import userProfileReducer from "../component/SignIn/userProfileSlice";
import navigationSlice from "../component/Navigation/navigationSlice";
import learnerListSlice from "../features/MyTeam/learnerListSlice";
import courseListSlice from "../features/MyTeam/courseListSlice";
import myTeamSubmissionSlice from "../features/MyTeamSkillsSubmission/myTeamSubmissionSlice";
import myTeamSlice from "../features/MyTeam/myTeamSlice";
import existingTemplateSlice  from "../features/ManageTemplates/ExistingTemplate/existingTemplateSlice";

export const store = configureStore({
    reducer: {
        main: mainSliceReducer,
        userProfile: userProfileReducer,
        navigation: navigationSlice,
        learnerList: learnerListSlice,
        courseList: courseListSlice,
        myTeam: myTeamSlice,
        myTeamSubmissionList: myTeamSubmissionSlice,
        existingTemplateList: existingTemplateSlice,
    }
});
