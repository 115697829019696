/* eslint-disable */
import React, { useMemo, useState } from "react";
import calendar from "../../../src/assets/images/vc-callendar.svg";
import clock from "../../../src/assets/images/vc-clock.svg";
import seat from "../../../src/assets/images/vc-seat.svg";
import user from "../../../src/assets/images/vc-user.svg";
import link from "../../../src/assets/images/vc-link.svg";
import locator from "../../../src/assets/images/vc-map.svg";
import sessionRecording from "../../../src/assets/images/sessionRecording.svg";



import "primeicons/primeicons.css";
import secondsToHms from "../../common/SecondsToHms/secondsToHms";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import ErrorModal from "../../services/errorModal";

export default function ClassRoomVirtualClassroomTypeDetails({ item }) {
    const [showModal, setShowModal] = useState(false)
    function getDataTime(date) {
        return moment(date).format("LLL");
    }
    const VirtualClassError = "This Virtual Classroom session cannot be joined as it has already passed the due date."

    function validURL(str) {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return !!pattern.test(str);
    }
    const linkToDisplay = useMemo(() => {
        if (validURL(item.location)) {
            if (moment(item?.completionDeadline).isBefore(moment()) )
                return (
                    <Link onClick={() => setShowModal(true)} className="vc-link">
                        Virtual Classroom URL
                    </Link>
                )
            else {
                return (<Link to={item.location} target="_blank" className="vc-link">
                    Virtual Classroom URL
                </Link>)
            }
        } else {
            return "Virtual Classroom URL";
        }

    }, [item.location]);

    return (
        <>
            {showModal && (
                <ErrorModal
                    error={VirtualClassError}
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                />
            )}
            <p className="px-4 inner-module-name"></p>
            {
                item?.dateStart ? (
                    <>
                        <div className="row vc-div mx-0">
                            <div className="col-lg-7 col-sm-12">
                                <p className="details p-2">
                                    <span className="p-3">
                                        <img className="list-view-block-img" src={calendar} alt="images"></img>
                                    </span>
                                    {`${getDataTime(item?.dateStart ?? new Date())} - ${getDataTime(
                                        item?.completionDeadline ?? new Date()
                                    )}`}
                                </p>
                                <p className="details p-2">
                                    <span className="p-3">
                                        <img className="list-view-block-img" src={seat} alt="images"></img>
                                    </span>
                                    {item?.seatLimit ?? "No Seat Available"}
                                </p>
                                {item.contentType == "Virtual Classroom" ? (
                                    <>

                                        <p className="details p-2">
                                            <span className="p-3">
                                                <img className="list-view-block-img" src={link} alt="images"></img>
                                            </span>
                                            {linkToDisplay}
                                        </p>
                                        {
                                            item?.virtualrecording && item?.virtualrecording?.length !== 0   && (<><p className="details p-2">
                                                <span className="p-3">
                                                    <img className="list-view-block-img " src={sessionRecording} alt="images"></img>
                                                </span>
                                                Session Recording
                                            </p>
                                                {
                                                    item?.virtualrecording?.map((res) => {
                                                        return <>
                                                            <Link to={res.url} style={{paddingLeft:"48px"}}  className="details pointer">{res?.name}</Link>
                                                        </>
                                                    })
                                                }</>)}
                                    </>
                                ) : (
                                    <p className="details detailed">
                                        <span className="locator">
                                            <img
                                                className="list-view-block-img"
                                                src={locator}
                                                alt="images"></img>
                                        </span>
                                        <span className="urlContent">
                                            {item.location ? item.location : "Not Available"}
                                        </span>
                                    </p>
                                )}
                            </div>
                            <div className="col-lg-5 col-sm-12">
                                <p className="details p-2">
                                    <span className="p-3">
                                        <img
                                            className="list-view-block-img"
                                            src={clock}
                                            alt="images"
                                            title="Duration"></img>
                                    </span>
                                    {secondsToHms(item?.duration) || "No Duration"}
                                </p>
                                <p className="details p-2 instructorName">
                                    <span className="p-3">
                                        <img className="list-view-block-img" src={user} alt="images"></img>
                                    </span>
                                    {item?.instructorName ?? "-"}
                                </p>

                                {item.contentType == "Virtual Classroom" ? (
                                    <p className="details detailed">
                                        <span className="locator">
                                            <img
                                                className="list-view-block-img"
                                                src={locator}
                                                alt="images"></img>
                                        </span>
                                        <span className="urlContent">
                                            {item.roomLocation ? item.roomLocation : "Not Available"}
                                        </span>
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div >
                    </>
                ) : <div className="row vc-div mx-0" style={{ paddingLeft: "20px" }}>No session detail have been updated</div>
            }
        </>
    );
}
