import React from "react";
import moment from "moment";

const DetailsLearnerReportsCellContent = ({ data }) => {
    return (
        <tbody>
            {data.map((user, index) => (
                <tr className="tableRows" key={index}>
                    <td className="columnData">{user?.CourseName}</td>
                    <td className="columnData">{user?.Type}</td>
                    <td className="columnData">
                        {user?.EnrollmentDate !== "" &&
                            moment(user?.EnrollmentDate).format("MMM Do YYYY")}
                    </td>
                    <td className="columnData">
                        {user?.DueDate !== "" && moment(user?.DueDate).format("MMM Do YYYY")}
                    </td>
                    <td className="columnData">
                        {user?.CompletionDate !== "" &&
                            moment(user?.CompletionDate).format("MMM Do YYYY")}
                    </td>
                    <td className="columnData">
                        {Number.isInteger(Number(user?.Progress))
                            ? Math.floor(user?.Progress)
                            : user?.Progress}
                        %
                    </td>
                    <td className="columnData">{user?.Status}</td>
                </tr>
            ))}
        </tbody>
    );
};

export default DetailsLearnerReportsCellContent;
