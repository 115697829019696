import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardSIOEnablementTeam from "../../common/CardSIOEnablementTeam";
import email from "../../assets/images/email.svg";
import submiticon from "../../assets/images/submit.svg";
import "./SIOEnablemnetTeam.css";
import { useNavigate } from "react-router-dom";

const SIOEnablementTeam = ({ data, title }) => {
    const enablementData = data;
    let navigate = useNavigate();
    // const url = "https://adoberm.my.workfront.com/requests/new?activeTab=tab-new-helpRequest&projectID=62698b660017409a13740823c2d85b14&path=";
    const url = "https://adoberm.my.workfront.com/requests/new?activeTab=tab-new-helpRequest&projectID=65eb7fee001d4663d7758070d055220e&path=65eb892b002054fe19cde91e5b919bc0";
    let submitEnablement = () => {
        window.open(url, "_blank");
    };

    const NavigateToEnablement = () => {
        navigate("/en/additionalresources/sioenablementteam");
    };
    const carouselRef = useRef(null); // Create a ref for the carousel component

    const handlePrevious = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1400 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 1400, min: 1200 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1200, min: 991 },
            items: 4
        },
        mobile: {
            breakpoint: { max: 991, min: 576 },
            items: 2
        },
        xsmobile: {
            breakpoint: { max: 576, min: 0 },
            items: 2
        }
    };
    return (
        <>
            <div className="title main-head mb-3 mt-4 mlm"> {title}</div>
            <div className="Carousel-block row my-1 sioenablement align-items-stretch justify-content-center">
                <div className="col-12 col-sm-8 col-lg-10 py-4 px-4">
                    <div className="title-block pt-0 px-0">                        
                        <span className="view-all">
                            <button className="btn btn-link btn-view" onClick={NavigateToEnablement}>
                View All
                            </button>
                        </span>
                    </div>
                    <div className="carousel-container">
                        <Carousel
                            responsive={responsive}
                            showDots={true}
                            renderArrowsWhenDisabled={true}
                            slidesToSlide={4}
                            className="mt-4 pt-4"
                            keyBoardControl={true}
                            autoPlay={true}
                            autoPlaySpeed={3000}
                            infinite={true}
                            arrows={false}
                            ref={carouselRef}
                        >
                            {enablementData?.map((enablement, index) => (
                                <React.Fragment key={index}>
                                    <CardSIOEnablementTeam data={enablement} key={index} />
                                </React.Fragment>
                            ))}
                        </Carousel>
                        <button className="prev-button" onClick={handlePrevious}>
                        </button>
                        <button className="next-button" onClick={handleNext}>
                        </button>
                    </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-2  py-4 reachout">
                    <div className="mail text-center h-50">
                        <img src={email} className="img-fluid d-block mx-auto" alt=""></img>
                        <p className="m-0 desc-text">Reach Out To Us</p>
                        <a href="mailto:dx-sio-enablement@adobe.com" className="btn btn-link btn-view fontSize14Text filterEffect" target="_blank" rel="noreferrer">
                        dx-sio-enablement@adobe.com
                        </a>
                    </div>
                    <div className="dotted-border"></div>
                    <div className="submit-file text-center h-50">
                        <img src={submiticon} className="img-fluid d-block mx-auto" alt=""></img>
                        <p className="m-0 desc-text">Submit Enablement Intake Form</p>
                        <button className="btn primary-btn my-2" onClick={submitEnablement}>
              Click Here
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SIOEnablementTeam;
