import React from "react";

function ScaleTenFeedBack(props) {
    return (
        <React.Fragment>
            <div className='question-text fontSize14 mt-4 mb-2'>
                <span style={{ marginRight: "10px" }}>{props?.seq}.</span>
                {props?.data?.localizedMetadata[0].name}
                {props?.data?.mandatory && (
                    <span style={{ color: "red" }}>*</span>
                )}
            </div>
            {Array.apply(null, Array(10)).map(function (x, i) {
                
                const answerValue = (i + 1).toString();
                return (
                    <div className="form-check form-check-inline me-4" key={i}>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="questionType"
                            value={answerValue}
                            onClick={() => props.setAns(props.data.questionId, answerValue)}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">
                            {answerValue}
                        </label>
                    </div>
                );
            })}
            {/* {props?.data?.mandatory && props?.errorScale && <div style= {{color:"red"}}>{props?.errorScale}</div>}   */}
        </React.Fragment>
    );
}

export default ScaleTenFeedBack;