import React from "react";
import { Modal } from "react-bootstrap";
import Failure_Icon from "../../assets/images/Failure_Icon.png";
import Success_Icon from "../../assets/images/Success_Icon.png";
import Failure1_Icon from "../../assets/images/Failure1_Icon.png";
import Form from "react-bootstrap/Form";
import "../../../src/features/ManageTemplates/Templates.css";
import FileUploadFileList from "./../../component/ImportCsv/ImportcsvCard/FileUploadFileList";

function ModalforSuccessAndFailure({
    messageforPopup,
    show,
    onClose,
    warningPopup,
    messageForFailure,
    confirmButton,
    handleDelete,
    updateTemplate,
    handleChange,
    handleUpdate,
    fileValidation,
    csvFile
}) {
    return (
        <Modal centered show={show}>
            {updateTemplate ?
                <>
                    <Modal.Header>
                        <h4>Update Template</h4>
                    </Modal.Header>
                    <Modal.Body className="manageTemplates m-3">
                        <div className="alm-file-upload-conatainer-content m-0">
                            <Form.Group controlId="formFileMultiple2" className="alm-file-upload-content d-flex align-items-center justify-content-center">
                                <Form.Control
                                    onChange={(e) => handleChange(e)}
                                    type="file"
                                    className="alm-file-upload-input updateTemplateCard alm-file-upload-content d-flex align-items-center justify-content-center"
                                    accept=".csv" />
                            </Form.Group>
                            {!csvFile?.name ? null : (
                                <FileUploadFileList

                                    fileName={csvFile?.name}
                                    className="alm-file-upload-file-list imgTag"

                                />
                            )}
                            <div className="fileValidation">{fileValidation}</div>
                        </div>

                    </Modal.Body>
                </>
                :
                <Modal.Body>
                    <div className="success-modal-container">
                        {messageforPopup && (
                            <div className="failure-modal-container-body my-5">
                                <img
                                    className="failed-icon m-auto"
                                    src={Success_Icon}
                                    alt="failed"></img>
                                <div className="success-text-message2 p-0 pt-3">{messageforPopup}</div>
                            </div>
                        )}
                        {messageForFailure && (
                            <div className="failure-modal-container-body my-5">
                                <img className="failed-icon" src={Failure1_Icon} alt="failed"></img>
                                <div className="success-text-message2 p-0 pt-3">
                                    {messageForFailure}
                                </div>
                            </div>
                        )}
                        {warningPopup && (
                            <div className="failure-modal-container-body my-5">
                                <img className="failed-icon" src={Failure_Icon} alt="failed"></img>
                                <div className="success-text-message2 p-0 pt-3">{warningPopup}</div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            }
            {confirmButton ?
                <Modal.Footer>
                    <button className="btn primary-btn m-2" onClick={onClose}>
                        No
                    </button>
                    <button className="btn primary-btn m-2" onClick={handleDelete}>
                        Yes
                    </button>
                </Modal.Footer>
                :
                updateTemplate ?
                    <Modal.Footer>
                        <button className="btn primary-btn m-2" onClick={onClose}>
                            Cancel
                        </button>
                        <button className="btn primary-btn m-2" onClick={handleUpdate} disabled={!csvFile} >
                            Update
                        </button>
                        
                    </Modal.Footer>
                    :
                    <Modal.Footer>
                        <button className="btn primary-btn m-2" onClick={onClose}>
                            Close
                        </button>
                    </Modal.Footer>
            }
        </Modal>
    );
}

export default ModalforSuccessAndFailure;
