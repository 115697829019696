import logo from "../../assets/images/logo2.png";
import "./signInstyle.css";

function MaintenanceView() {
    return (
        <div className="main-block">
            <div className="container">
                <div className="login-block-txt">
                    <div className="login-box">
                        <img src={logo} className="my-5" alt="" />
                        <h3 className="headding-title-login my-4">
 Please be advised that the DX Enablement Hub is under maintenance from November 8, 10:00 PM EST to November 11, 1:00 AM EST. During this time you may continue your learning from <a href="mailto:dx-sio-enablement@adobe.com">https://learningmanager.adobe.com/adobeinternal.</a> We apologize for any inconvenience caused.</h3><h3 className="headding-title-login my-4">For more information please reach out to <a href="mailto:dx-sio-enablement@adobe.com">dx-sio-enablement@adobe.com</a></h3>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default MaintenanceView;
