import React from "react";
import { Card } from "react-bootstrap";
import DummyProfile from "../../assets/images/dummyProfile.svg";

const EnablementCard = ({ name, Designation,AvatarUrl }) => {
    return (
        <>
            <Card style={{ width: "auto" }}>
                <Card.Img variant="top" src={ AvatarUrl || DummyProfile } />
                <Card.Body>
                    <Card.Title>{name}</Card.Title>
                    <Card.Text>{Designation}</Card.Text>
                </Card.Body>
            </Card>
        </>
    );
};

export default EnablementCard;