import React from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import useDataReports from "../../hooks/useDataReports";
import Breadcrumbs from "../../common/Breadcrumbs";
import { _tableHeaderCourseDetails } from "../../data/dataCourseReports";
import { courseReportsDetailsBreadCrumbs } from "../../constant/breadCrumbs";
import DetailsCourseReportsCellContent from "./DetailsCourseReportsCellContent";
import DataTable from "../../component/DataTable";
import TableHeading from "../../component/TableHeading";
import Scroller from "../../common/Scroller/Scroller";
import "./CourseReports.css";

function DetailsTableForCourse() {
    const { detailsCourseData, detailsCourseName } = useSelector((state) => state.courseList);

    const {
        searchTerm,
        filteredData,
        currentItems,
        currentPage,
        itemsPerPage,
        sortKey,
        sortOrder,
        handleSort,
        handleInputChange,
        setCurrentPage
    } = useDataReports({ data: detailsCourseData, pageName: "courseDetails" });

    return (
        <>
            <Breadcrumbs crumbs={courseReportsDetailsBreadCrumbs} />
            <Container fluid className="pl-30 py-4">
                <div className="headerImage">
                    <h1>Learners for {detailsCourseName}</h1>
                </div>

                <div className="courseReports mt-5">
                    <div className="courseReportsTable">
                        <DataTable
                            handleInputChange={handleInputChange}
                            searchTerm={searchTerm}
                            currentItems={currentItems}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            filteredData={filteredData}
                            itemsPerPage={itemsPerPage}>
                            <TableHeading
                                sortKey={sortKey}
                                sortOrder={sortOrder}
                                columns={_tableHeaderCourseDetails}
                                handleSort={handleSort}
                            />
                            <DetailsCourseReportsCellContent data={currentItems} />
                        </DataTable>
                    </div>
                </div>
            </Container>
            <Scroller />
        </>
    );
}

export default DetailsTableForCourse;
