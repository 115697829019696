import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ScaleTenFeedBack from "../ScaleTenFeedBack/ScaleTenFeedBack";
import LikeabilityFeedback from "../LikeabilityFeedback/LikeabilityFeedbak";
import QualitativeFeedBack from "../QualitativeFeedBack/QualitativeFeedBack";
import { apis } from "../../services/Api";
// import { NotificationManager } from "react-notifications"
import Success_Icon from "../../assets/images/Success_Icon.png";
import Failure1_Icon from "../../assets/images/Failure1_Icon.png";

function FeedbackModal({ t, fromNavigation, courseDetails, learningObjectInstanceEnrollmentDetails }) {
    const [show, setShow] = useState(false);
    const [questionsData, setQuestionsData] = useState();
    const [errorQualitative, setErrorQualitative] = useState("");
    const [errorScale, setErrorScale] = useState("");
    const [errorLikeability, setErrorLikeability] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [image, setImage] = useState(true);
    const [showErrorMsg , setShowErrorMsg] = useState(false)


   
    useEffect(() => {
        if(fromNavigation) 
            handleShow(); 
    }, []);

    const handleClose = () => {
        setShow(false)
        setShowErrorMsg(false);
    };
    const handleShow = async () => {
        try {
            let enrolledInstanceId = learningObjectInstanceEnrollmentDetails?.relationships?.loInstance?.data?.id
            let { data } = await apis.retrieveL1FeedbackFormInformation(
                courseDetails.id,
                enrolledInstanceId
            );
            setQuestionsData(data);
        } catch (error) {
            console.log(error);
        }
        setShow(true);
        setErrorQualitative("");
        setErrorScale("");
        setErrorLikeability("");
    };

    const setAns = (id, ans) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
        let tempQuestions = [...questionsData?.attributes.questions];
        let index = tempQuestions.findIndex((e) => {
            return e.questionId === id;
        });
        tempQuestions[index].answer = ans;
        setQuestionsData({
            ...questionsData,
            attributes: {
                ...questionsData.attributes,
                questions: tempQuestions
            }
        });
    };

    const handleOkDisplayMessage = () => {
        setShowModal(false);
    };

    const submitAns = async () => {
    // eslint-disable-next-line no-prototype-builtins
        setShowErrorMsg(false)
        let check = false;
        const answeredQuestions = questionsData?.attributes?.questions?.filter((item) => {
            return Object.prototype.hasOwnProperty.call(item, "answer");
        });


        let hasEmptyMandatoryField = questionsData?.attributes?.questions?.forEach((e) => {
           
            if (e?.mandatory && !e?.answer?.trim()) {
                // setShowErrorMsg(true)
                check = true
            } 
        });


        if (!check) {
            // Prepare the payload
            if (answeredQuestions.length !== 0 && !hasEmptyMandatoryField) {
                const payload = {
                    data: {
                        id: questionsData?.id,
                        type: questionsData?.type,
                        attributes: {
                            showAutomatically: questionsData?.attributes.showAutomatically,
                            score: parseInt(answeredQuestions[0].answer),
                            questions: answeredQuestions.map((e) => ({
                                mandatory: e.mandatory,
                                questionId: e.questionId,
                                questionType: e.questionType,
                                answer: e.answer
                            }))
                        }
                    }
                };
                try {
                    let enrolledInstanceId = learningObjectInstanceEnrollmentDetails?.id;
                    if (payload) {
                        let res = await apis.sendL1FeedbackAnswersFromALearner(enrolledInstanceId, payload);
                        if (res?.status === 204) {
                            // alert(" successfully submitted feedback")
                            setShow(false);
                            setShowModal(true);
                            setModalMessage("Feedback submitted successfully.");
                        } else if (
                            res?.status === 400 &&
                            res?.data?.source?.info?.toLowerCase() == "feedback for the lo is already given."
                        ) {
                            // alert("feedback-already-given")
                            setShow(false);
                            setShowModal(true);
                            setModalMessage("You have already provided the feedback.");
                            setImage(false);
                        } else {
                            // alert("something went wrong please try again later")
                            setShow(false);
                            setShowModal(true);
                            setModalMessage("An error occurred while submitting your feedback. Please try again later.");
                            setImage(false);
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }else{
            setShowErrorMsg(true)
        }
    };

    //This function generate dynamic ui for question
    const questionTypeUI = (e, i) => {
        let questionIndexId = i + 1
        switch (e.questionType) {
        case "scaleTen":
            return (
                <ScaleTenFeedBack
                    key={e.id}
                    seq = {questionIndexId}
                    data={e}
                    setAns={setAns}
                    errorScale={errorScale}></ScaleTenFeedBack>
            );
        case "likeability":
            return (
                <LikeabilityFeedback
                    key={e.id}
                    seq = {questionIndexId}
                    data={e}
                    setAns={setAns}
                    errorLikeability={errorLikeability}></LikeabilityFeedback>
            );
        case "l1Qualitative":
            return (
                <QualitativeFeedBack
                    key={e.id}
                    seq = {questionIndexId}
                    data={e}
                    setAns={setAns}
                    errorQualitative={errorQualitative}></QualitativeFeedBack>
            );
        default:
            break;
        }
    };

    return (
        <>
            <button className="btn primary-btn my-3 w-100" onClick={handleShow}>
        Course/Learning Path Feedback
            </button>

            <Modal
                className="modalFeedbackContent"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title className="feedbackHeader">Course Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="fw-bold">{courseDetails?.attributes.localizedMetadata[0].name}</h5>
                    {questionsData?.attributes.questions.map((e, i) => (
                        <React.Fragment key={i}>{questionTypeUI(e, i)}</React.Fragment>
                    ))}
                    { showErrorMsg ? <>
                        <div style={{ color: "red" }}>Please fill out all required questions</div>
                    </> : <></> }
                    
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-link m-2" onClick={handleClose}>
                        {t("cancel")}
                    </button>
                    <button className="btn primary-btn primary-blue m-2" onClick={submitAns}>
                        {t("Submit")}
                    </button>
                </Modal.Footer>
            </Modal>
            <div className="modal-container success-modal">
                <Modal centered show={showModal}>
                    <Modal.Body className="my-5">
                        <div className="success-modal-container text-center">
                            {image ? (
                                <img className="failed-icon m-auto" src={Success_Icon} alt="Success"></img>
                            ) : (
                                <img className="failed-icon" src={Failure1_Icon} alt="failed"></img>
                            )}
                            <div className="success-text-message2">{modalMessage}</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn primary-btn primary-blue m-2" onClick={() => handleOkDisplayMessage()}>
              Ok
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default FeedbackModal;
