/* eslint-disable react/no-unknown-property */
import { useEffect, useState } from "react";
import CustomAccordionItemTwo from "./CustomAccordionItemTwo";
import "../CustomAccordion.css";

function CustomAccordionTwo({
    getMyTeamSkills,
    data,
    handleSubmitForApprovalClick,
    setChekedLernerIds,
    chekedLernerIds,
    setAddress,
    address,
    searchData,
    handleData
}) {
    const [_data, setData] = useState();

    useEffect(() => {
        setData(data);
    }, [data]);

    return (
        <div defaultActiveKey="0">
            {_data &&
                _data.map((elm, i) => (
                    <div key={i} className="content-box">
                        {
                            <CustomAccordionItemTwo
                                getMyTeamSkills={getMyTeamSkills}
                                key={i}
                                data={elm.skills}
                                handleSFAClick={handleSubmitForApprovalClick}
                                setChekedLernerIds={setChekedLernerIds}
                                chekedLernerIds={chekedLernerIds}
                                setAddress={setAddress}
                                address={address}
                                searchData={searchData}
                                handleData={handleData}
                            />
                        }
                    </div>
                ))}
        </div>
    );
}

export default CustomAccordionTwo;
