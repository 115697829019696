import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./certificationWallPage.css";
import CertificationWallOfFame from "../../component/CertificationWallOfFame";
import { useTranslation } from "react-i18next";
import { apis } from "../../services/Api";
import Breadcrumbs from "../../common/Breadcrumbs";
import Scroller from "../../common/Scroller/Scroller";

const CertificationWall = () => {
    const { t } = useTranslation();
    const [CertificationWallOfFameData, setCertificationWallOfFameData] = useState([]);
    const [seq,setSeq] =useState([])

    const crumbs = [
        { label: "Home", url: "/" },
        { label: "Certification Wall Of Fame", url: "/certificationwall" },
    ];

    let getcertificationData = async () => {
        let quaterlydata = await apis.certificationQuaterlyData();
        let quaterlyData2 = quaterlydata?.certificates;
        setCertificationWallOfFameData(quaterlyData2);  
        const quarters = getCurrentAndPreviousQuarters();
        const data = quaterlyData2.filter((item)=>{
            return item.Year===quarters[0].Year && item.Qtr===quarters[0].Qtr
        });
        if(data?.length === 0) {
            const lastQuarter = Number(quarters[quarters?.length-1].Qtr);
            const lastQuarterYear = Number(quarters[quarters?.length-1].Year);
            let previousQuarter = lastQuarter;
            let previousQuarterYear = lastQuarterYear;
            if(previousQuarter === 1) {
                previousQuarterYear = previousQuarterYear - 1;
                quarters.push({Qtr:"4",Year:`${previousQuarterYear}`});
            } else {
                previousQuarter = previousQuarter - 1
                quarters.push({Qtr:`${previousQuarter}`,Year:`${previousQuarterYear}`});
            }
        }
        setSeq(quarters)
    }
    useEffect(() => {
        if (CertificationWallOfFameData?.length === 0) {
            getcertificationData();
        }
    }, [CertificationWallOfFameData])

    const getQuarter = (date) => {
        if (!(date instanceof Date)) {
            throw new Error("Invalid date");
        }
        let month = date.getMonth()+1;
       
        if ((month===12) || (month >= 0 && month < 3)) {
            return 1;
        } else if (month >= 3 && month < 6) {
            return 2; 
        } else if (month >= 6 && month < 9) {
            return 3; 
        } else {
            return 4;
        }
    };
    const getCurrentAndPreviousQuarters = () => {
        const currentDate = new Date();
        const month = Number(currentDate.getMonth()) + 1;
        const currentQuarter = getQuarter(currentDate);
        const quarters = [];
        
        for (let i = 0; i < 4; i++) {
            let quarter = currentQuarter - i;
            if(quarter===0)
            {
                let year = (currentQuarter>=quarter)?(currentDate.getFullYear() - Math.floor((quarter - 1) / 4))-2:(currentDate.getFullYear() - Math.floor((quarter - 1) / 4));
                quarter=4;
                if(month === 12){
                    year = year + 1;
                }
                quarters.push({Qtr:`${quarter}`,Year:`${year}`});
            }else if(quarter===-1) {
                let year = (currentQuarter>=quarter)?(currentDate.getFullYear() - Math.floor((quarter - 1) / 4))-2:(currentDate.getFullYear() - Math.floor((quarter - 1) / 4));
                quarter=3;
                if(month === 12){
                    year = year + 1;
                }
                quarters.push({Qtr:`${quarter}`,Year:`${year}`});
            }else if(quarter===-2){
                let year = (currentQuarter>=quarter)?(currentDate.getFullYear() - Math.floor((quarter - 1) / 4))-2:(currentDate.getFullYear() - Math.floor((quarter - 1) / 4));
                quarter=2;
                if(month === 12){
                    year = year + 1;
                }
                quarters.push({Qtr:`${quarter}`,Year:`${year}`});
            }
            else{
                let year = currentDate.getFullYear() - Math.floor((quarter - 1) / 4);
                if(month === 12){
                    year = year + 1;
                }
                quarters.push({Qtr:`${quarter}`,Year:`${year}`});
            }
        }
        return quarters;
    };
    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4">
                <Row> <div className="headerImage"> <h1>Certification Wall Of Fame</h1> </div> </Row>

            </Container>
            <Container fluid className="pl-30 py-4">
                <div className="row certificaion-all">
                    <p className="head-text">Congratulations to all our Adobe Certified Employees!</p>
                    <p className="fontSize13">Each of the individuals listed below has demonstrated a high level of expertise by passing the ACE exam. </p>
                    <p className="fontSize13 m-0">Looking to get certified, refer to the below resources:-</p>
                    <ul className="pl-30">
                        <li>How to request for ACE exam voucher - <a href="https://wiki.corp.adobe.com/pages/viewpage.action?pageId=3091498162" target="_blank" rel="noreferrer" >Click Here</a></li>
                        <li>Schedule available ACE exams - <a href="https://certification.adobe.com/" target="_blank" rel="noreferrer" >Click Here</a></li>
                        <li>Navigating the Adobe Certification Portal - <a href="https://wiki.corp.adobe.com/pages/viewpage.action?pageId=3324329987" target="_blank" rel="noreferrer" >Click Here</a></li>
                        <li>Certification Rewards Program - <a href="https://helpx-internal.corp.adobe.com/content/help/en/dmacc-internal/QualityofSupport/dx-support-certification.html" target="_blank" rel="noreferrer" >Click Here</a> (VPN required)</li>
                        <li>Renew your certification - <a href="https://certification.adobe.com/certifications/learn-more?tab=learnmore8#_blank" target="_blank" rel="noreferrer" >Click Here</a></li>
                    </ul>
                    <p className="fontSize13">Visit the Adobe Digital Experience Certification Program page to search for courses as you prepare for your Adobe Certification Exam.</p>
                    <p>In addition to above, please consult your mentor/manager for tips, best practices, and how to prepare for your first ACE certification exam.</p>
                    <p className="fontSize13 m-0">For any reward/voucher queries email: <a target="_blank" rel="noreferrer" href="mailto:gchugh@adobe.com">gchugh@adobe.com</a>/<a href="mailto:dx-sio-enablement@adobe.com">dx-sio-enablement@adobe.com</a> </p>
                    <p className="fontSize13">For any certification exam queries email: <a target="_blank" rel="noreferrer" href="mailto:certif@adobe.com">certif@adobe.com</a></p>
                </div>
                {/* <div className="row certificaion-all">
                    <p className="head-text">Congratulations to all our Adobe Certified Employees!</p>
                    <p className="fontSize13">Each of the individuals listed below has demonstrated a high level of expertise by passing the ACE exam. </p>
                    <p className="fontSize13 m-0">Looking to get certified, refer to the below resources:-</p>
                    <p className="fontSize13">(Some links may require a VPN)</p>
                    <p className="m-0 fontSize13"><a target="_blank" rel="noreferrer" href="https://helpx-internal.corp.adobe.com/content/help/en/dmacc-internal/QualityofSupport/dx-support-certification.html">Certification Rewards Program</a></p>
                    <p className="m-0 fontSize13"><a target="_blank" rel="noreferrer" href="https://solutionpartners.adobe.com/solution-partners/training_and_certification/certification.html">ACE exams - available exams</a></p>
                    <p className="fontSize13"><a target="_blank" rel="noreferrer" href="https://experienceleague.adobe.com/docs/certification/program/renew.html?lang=en">Renew your certification</a></p>
                    <p className="fontSize13">Visit the new <a target="_blank" rel="noreferrer" href="https://app.rockinfo.com/courses/?/courses">Adobe Digital Experience Certification Program</a> page to search for courses as you prepare for your Adobe Certification Exam.</p>
                    <p className="fontSize13">In addition to the above, please consult your mentor/manager for tips, best practices, and how to prepare for your first ACE certification exam!</p>
                    <p className="fontSize13 m-0">For any reward/voucher queries email: <a target="_blank" rel="noreferrer"  href="mailto:gchugh@adobe.com">gchugh@adobe.com</a></p>
                    <p className="fontSize13">For any certification exam queries email: <a target="_blank" rel="noreferrer"  href="mailto:certif@adobe.com">certif@adobe.com</a></p>
                </div> */}
                <div className="row certificaion-all">
                    {seq.map((seqdata,index)=>{
                        let data =CertificationWallOfFameData.filter((item)=>{
                            return item.Year===seqdata.Year && item.Qtr===seqdata.Qtr
                        })
                        return(
                            (data?.length>0)&& (<CertificationWallOfFame title={t("certification.quarter-label", { year: seqdata?.Year?.slice(-2), quarter: `Q${seqdata.Qtr}` })} data={data} showViewAll={false} key={index} />)
                        )}
                    
                    )
                    }
                </div>
            </Container>
            <Scroller />
        </>
    )
}

export default CertificationWall;
