import { createSlice } from "@reduxjs/toolkit";
import { apis } from "../../services/Api";

const initialState = {
    isPending: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    learnerList: [],
    learnerReportList: [],
    detailsLearnerData: [],
    detailsLearnerUsername: null
};

export const learnerListSlice = createSlice({
    name: "learnerList",
    initialState,
    reducers: {
        setLearnerList: (state, action) => {
            state.learnerList = action.payload;
        },
        setDetailsLearnerData: (state, action) => {
            return {
                ...state,
                detailsLearnerData: action.payload
            };
        },
        setDetailsUsername: (state, action) => {
            return {
                ...state,
                detailsLearnerUsername: action.payload
            };
        }
    },
    extraReducers(builder) {
        builder
            .addCase(apis.getLearnersData.pending, (state) => {
                return {
                    ...state,
                    isPending: true
                };
            })
            .addCase(apis.getLearnersData.fulfilled, (state, action) => {
                const list = Object.entries(action.payload?.data).map(
                    ([courseName, courseInfo]) => {
                        return {
                            courseName,
                            Enrolled: courseInfo?.Enrolled,
                            Completed: courseInfo?.Completed,
                            NotStarted: courseInfo?.NotStarted,
                            InProgress: courseInfo?.InProgress,
                            Name: courseInfo?.Name,
                            EnrolledLPCertificationCourse:
                                courseInfo?.EnrolledLPCertificationCourse,
                            CompletedLPCertificationCourse:
                                courseInfo?.CompletedLPCertificationCourse,
                            InProgressLPCertificationCourse:
                                courseInfo?.InProgressLPCertificationCourse,
                            NotStartedLPCertificationCourse:
                                courseInfo?.NotStartedLPCertificationCourse
                        };
                    }
                );

                return {
                    ...state,
                    isPending: false,
                    isSuccess: true,
                    learnerReportList: list
                };
            })
            .addCase(apis.getLearnersData.rejected, (state, action) => {
                return {
                    ...state,
                    isPending: false,
                    isError: true,
                    errorMessage: action.error.message
                };
            });
    }
});

export const { setLearnerList, setDetailsLearnerData, setDetailsUsername } =
    learnerListSlice.actions;
export default learnerListSlice.reducer;
