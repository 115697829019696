/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import _, { isEmpty } from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import {
    _data,
    _dataApprovalPreview,
    _optionStatus,
    _optionRoles,
    _tableHeaderForViewSkill,
    _mskDummyData
} from "../../data/dataMySkillJourney";
import CustomAccordion from "../../component/CustomAccordion/CustomAccordion";
import CustomAccordionApprovalPreview from "../../component/CustomAccordion/CustomAccordionApprovalPreview/CustomAccordionApprovalPreview";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";
import { apis } from "../../services/Api";
import { toast } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import convertDateToString from "../../services/Datehelper";
import { useContext } from "react";
import { appContext } from "../../context/appContext";
import Breadcrumbs from "../../common/Breadcrumbs";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";
import Failure_Icon from "../../assets/images/Failure_Icon.png";
import moment from "moment";
import Scroller from "../../common/Scroller/Scroller";
import "./MySkillJourney.css";
import { useDispatch } from "react-redux";
import { setApproveRejectData, setCollapseData, setIsAllSelected, setIsAllSkillSelected } from "../../slices/mainSlice";
import { searchInArray } from "../../utils/dataTable";
import info from "../../assets/images/info.svg";
import ProficiencyLevelModal from "../../common/Modal/ProficienyLevelModal";

function getSkillHistoryObject(data) {
    return {
        skillName: data.SkillName,
        skillId: data.SkillId,
        groupId: data.SkillGroupId,
        roleId: data.RoleId,
        skillGroup: data.SkillGroupName,
        CurrentLevel: data.CurrentLevel,
        SubmittedLevel: data.SubmittedLevel,
        TargetLevel: data.TargetLevel,
        submittedDate: convertDateToString(data.SubmittedDateTime),
        aprroveDate: convertDateToString(data.ApprovalDateTime),
        status: data.ApprovalStatus,
        ApprovedFromRole: data.ApprovedFromRole,
        keynote: data.RejectionReason,
        roleSkillGroupId: `${data.RoleId}_${data.SkillGroupId}_${data.SkillId}`,
        skillIdTargetLevel: `${data.RoleId}_${data.SkillGroupId}_${data.SkillId}_${data.TargetLevel}_${data.SubmittedLevel}`,
        userRoleSkillsId: data.UserRoleSkillsId,
        TransactionId: data.TransactionId,
        SkillDescription: data?.SkillDescription
    };
}

// eslint-disable-next-line no-unused-vars
function ViewSkillJourney(props) {
    let { state } = useLocation();
    let { id } = useParams();
    const [displayMessageBox, setDisplayMessageBox] = useState(false);
    const [submitForApproval, setSubmitForApproval] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedRoles, setSelectedRoles] = useState();
    const [myskillJourneyData, setMyskillJourneyData] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const navigationData = useSelector((state) => state.navigation);

    const [localStorageData, setLocalStorageData] = useState([]);
    const [localStoragePreviewData, setLocalStoragePreviewData] = useState([]);
    const [selectedSkillWithLevel, setSelectedSkillWithLevel] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [selectStatusOptions, setSelectStatusOptions] = useState();
    const [selectRolesOptions, setSelectRolesOptions] = useState();
    const [roleDataListUI, setRoleDataListUI] = useState();
    const { catalogListRSJ, CheckUserRoleSkills, dispatch } = useContext(appContext);

    const [submitForApprovalData, setSubmitForApprovalData] = useState();
    const [originalStatusOptionList, setOriginalStatusOptionList] = useState();
    const [originalRolesOptionList, setOriginalRolesOptionList] = useState();
    const [finalDataToSubmit, setFinalDataToSubmit] = useState();
    const [proficiencyShowModal, setProficiencyShowModal] = useState(false);

    const [selectedStatusN, setSelectedStatusN] = useState([]);
    const [selectedRolesN, setSelectedRolesN] = useState([]);
    const [showActionColumn, setShowActionColumn] = useState(false);
    const [chekedLernerIds, setChekedLernerIds] = useState([]);
    const [address, setAddress] = useState("");
    const [displayMessageBoxForReject, setDisplayMessageBoxForReject] = useState(false);
    const [displayMessageBoxForApproval, setDisplayMessageBoxForApproval] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [onClickSearch, setClickSearch] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [noDataAvailable, setNoDataAvailable] = useState("");
    const [noDataonClickApply, setNoDataonClickApply] = useState(false);
    const [rejectPopError, setRejectPopError] = useState("");
    const [messageForFailure, setMessageForFailure] = useState("");
    const [showDisable, setDisabled] = useState(false);

    const [currentAccordionContent, setCurrentAccordionContent] = useState([]);
    const [currentAccordionIndex, setCurrentAccordionIndex] = useState(-1);
    const { isAllSelected, approveRejectData } = useSelector((state) => state.main);
    const[roleFilterArr ,setRoleFilterArr]=useState();
    const[filterUi, setFilterUi]=useState(false);
    const reduxDispatch = useDispatch();

    const crumbs = state?.skillSubmission
        ? [
              { label: "Home", url: "/" },
              { label: " Skills Submission", url: "/skillssubmission" },
              { label: "View Skill Journey", url: `/viewskilljourney/${id}` }
          ]
        : [
              { label: "Home", url: "/" },
              { label: "Skill Dashboard", url: "/myteam" },
              { label: "View Skill Journey", url: `/viewskilljourney/${id}` }
          ];

    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigateTo = (e) => {
        const anchor = e.target.closest("a");
        navigate("/" + anchor.getAttribute("href").replace("#", ""));
    };

    const prepareSelectRolesOptions = () => {
        const _selectRolesOptions = catalogListRSJ.filter((item) => !item.isComingSoon);
        const roleOptions = _selectRolesOptions
            .map((item, index) => {
                return { id: index, value: item.subMenuTitle, label: item.subMenuTitle };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
        setSelectedRoles(roleOptions);
        // setSelectRolesOptions(roleOptions)
        setSelectedRolesN(roleOptions)
    };

    const prepareSelectStatusOptions = () => {
        const optionStatus = _optionStatus.sort((a, b) => a.label.localeCompare(b.label));
        setSelectStatusOptions(optionStatus);
        setSelectedStatus(optionStatus);
        setSelectedStatusN(optionStatus);
    };

    const getMySkillJourneyData = async (fromCloseModal = false) => {
        setShowLoader(true);
        try {
            const response = await apis.getViewSkillJourney(id);
            if (response?.status === 200) {
                setMyskillJourneyData(response.data);
                setFinalDataToSubmit(response.data);
                const roleOptions = [];

                const uniqueRoles = new Set();
                let uniqueId = 0;

                response?.data?.forEach((item) => {
                    const roleName = item?.RoleName || "Additional Skills";
                    if (!uniqueRoles.has(roleName)) {
                        uniqueRoles.add(roleName);
                        roleOptions.push({
                            id: uniqueId,
                            value: roleName,
                            label: roleName
                        });
                        uniqueId++;
                    }
                });
                if(!fromCloseModal){
                    setSelectRolesOptions(roleOptions);
                    setSelectedRolesN(roleOptions);
                }
                if (!response?.data?.length) {
                    // setShowLoader(false);
                    setNoDataAvailable("No content found.");
                }
            } else {
                // setShowLoader(false);
            }
        } catch (error) {
            console.warn(error);
            setShowLoader(false);
        }
    };

    // const handleSkillSelection = (content, checked) => {
    //     if (checked) {
    //         if (selectedSkills.indexOf(content.skillIdTargetLevel) < 0) {
    //             let newSkills = [...selectedSkills, content.skillIdTargetLevel];
    //             setSelectedSkills(newSkills);
    //         }
    //     } else {
    //         const index = selectedSkills.indexOf(content.skillIdTargetLevel);
    //         if (index > -1) {
    //             let newSkills = [
    //                 ...selectedSkills.filter((sk) => sk !== content.skillIdTargetLevel)
    //             ];
    //             setSelectedSkills(newSkills);
    //         }
    //     }
    // };

    const handleSkillSelection = (content, checked, contentTransectionId, index) => {
        if (checked) {
            if (chekedLernerIds.indexOf(content.TransactionId) < 0) {
                let newSkills = [...chekedLernerIds, content.TransactionId];
                setChekedLernerIds(newSkills);
            }
        } else {
            const index = chekedLernerIds.indexOf(content.TransactionId);
            if (index > -1) {
                let newSkills = [...chekedLernerIds.filter((sk) => sk !== content.TransactionId)];
                setChekedLernerIds(newSkills);
            }
        }

        setCurrentAccordionIndex(index);
        setCurrentAccordionContent({
            ...currentAccordionContent,
            [`${index}-content`]: contentTransectionId
        });
    };

    useEffect(() => {
        const contentData = currentAccordionContent[`${currentAccordionIndex}-content`];
        const updatedState = [...isAllSelected];

        const newSelected = contentData?.every((skillId) => chekedLernerIds?.includes(skillId));

        updatedState[currentAccordionIndex] = newSelected;

        reduxDispatch(setIsAllSelected(updatedState));
    }, [currentAccordionIndex, currentAccordionContent, chekedLernerIds]);

    useEffect(() => {
        if (id) {
            setShowActionColumn(true);
        }
        setSearchInput("");
    }, []);

    const handleProficiencyCloseModal = () => {
        setProficiencyShowModal(false);
    };
    const handleProficiencyOpenModal = () => {
        setProficiencyShowModal(true);
    };
    const prepareRoleListDataForUI = (dataList, roleOptionList, statusOptionList) => {
        setFinalDataToSubmit(dataList);
        // const updatedListArry = [];
        // roleOptionList?.forEach((item) => {
        //   const updatedObj = prepareDataBasedOnRoles(dataList, item.value);
        //   updatedListArry.push(updatedObj)
        // });
        const updatedListArryUI = [dataList]?.map((arry) => transforRoleDatatoView(arry));
        if (updatedListArryUI[0].length === 0) {
            setNoDataonClickApply(true);
        } else {
            setNoDataonClickApply(false);
        }
        setRoleDataListUI(updatedListArryUI);
    };

    useEffect(() => {
        if (state?.submitForApproval) {
            setSubmitForApproval(true);
            const selectedSkillsForApproval = getSelectedRollPreview(
                CheckUserRoleSkills?.roleWiseSkillsData?.filter((c) =>
                    CheckUserRoleSkills?.res?.some((s) => s.SkillId === c.SkillId)
                ),
                true
            );
            setLocalStoragePreviewData(selectedSkillsForApproval);
        } else {
            setSubmitForApproval(false);
        }
        getMySkillJourneyData();
        prepareSelectRolesOptions();
        prepareSelectStatusOptions();
        // if(selectRolesOptions?.length > 0){
        // prepareRoleListDataForUI(myskillJourneyData, selectRolesOptions, selectStatusOptions);
        // }
    }, []);

    const prepareDataBasedOnRoles = (myskillJourneyData, role) => {
        return myskillJourneyData?.filter((obj) => {
            return obj?.RoleName?.toUpperCase() === role?.toUpperCase();
        });
    };

    useEffect(() => {
        if (myskillJourneyData.length > 0) {
                        prepareRoleListDataForUI(myskillJourneyData, selectRolesOptions, selectStatusOptions);
            const dataAfterTransform = transforRoleDatatoView(myskillJourneyData);
            setLocalStorageData(dataAfterTransform);
            // setShowLoader(false);
        } else {
            // setShowLoader(false);
        }
        setShowLoader(false);
        setSearchInput("");
        handleApply();
    }, [myskillJourneyData]);

    useEffect(() => {
        if(filterUi){
            setShowLoader(true);
            const filteredDataForUi = roleFilterArr?.filter((item) => !approveRejectData?.includes(item.TransactionId) )
            setRoleFilterArr(filteredDataForUi)
            prepareRoleListDataForUI(filteredDataForUi, selectedRoles, selectedStatus);
            setShowLoader(false);
            setFilterUi(false);
            reduxDispatch(setApproveRejectData([]));
        }
    }, [myskillJourneyData]);

    const updateFilterUi =()=>{
        setFilterUi(true);
    }

    const handleCloseDisplayMessage = () => {
        setDisplayMessageBox(false);
    };
    const handleSubmit = async (e) => {
        if (selectedSkillWithLevel.length > 0) {
            setDisableSubmit(true);
            const inputData = selectedSkillWithLevel.map((sk) => {
                let _obj;
                if (sk.userRoleSkillsId === undefined) {
                    _obj = {
                        userRoleSkillId: 0,
                        roleId: sk.roleId,
                        groupId: sk.groupId,
                        skillId: sk.skillId,
                        submittedLevel: sk.proficiencyLevels.value
                    };
                } else {
                    _obj = {
                        userRoleSkillId: sk.userRoleSkillsId,
                        submittedLevel: sk.proficiencyLevels.value,
                        roleId: 0,
                        groupId: 0
                    };
                }
                return _obj;
            });
            const input = { userroleskilliddata: inputData };
            const resp = await apis.submitSkillForApproval(input);
            // toast.success("Skills submitted successfully for approval.");
            setMessageforPopup("Skill(s) submitted successfully for approval.");
            setShowModal(true);
            setDisableSubmit(true);
            setSubmitForApproval(false);

            setSelectedSkillWithLevel([]);
            getMySkillJourneyData();
            setLocalStorageData([]);
        } else {
            // toast.error("Please select skills level for approval.");
            setMessageforPopup("Please select skill level for approval.");
            setShowModal(true);
        }
    };

    const handleApply = (e) => {
        let updatedDataPerStatus = [];
        if (selectedStatusN.length > 0) {
            selectedStatusN.forEach((item) => {
                const tempInner = myskillJourneyData.filter((msj) => {
                    if (msj.ApprovalStatus === item.label) {
                        return msj;
                    }
                });
                updatedDataPerStatus.push(tempInner);
            });
        }

        let flatStatusArray = [].concat.apply([], updatedDataPerStatus);
        let updatedDataPerRoles = [];
        
        if (selectedRolesN?.length > 0) {
                        selectedRolesN.forEach((itm) => {
                const tempInnerR = flatStatusArray.filter((msj) => {
                    if (
                        itm?.label?.trim()?.toUpperCase() === "ADDITIONAL SKILLS" &&
                        (msj?.RoleName == null || msj?.RoleName === "")
                    ) {
                        return msj;
                    }
                    if (
                        msj?.RoleName?.trim()?.toUpperCase() === itm?.label?.trim()?.toUpperCase()
                    ) {
                        return msj;
                    }
                });
                updatedDataPerRoles.push(tempInnerR);
            });
        }

        let flatRolesArray = [].concat.apply([], updatedDataPerRoles);
        setRoleFilterArr(flatRolesArray)
        prepareRoleListDataForUI(flatRolesArray, selectedRoles, selectedStatus);
    };

    const handleSubmitForApproval = (e) => {
        const selectedSkillsForApproval = getSelectedRollPreview(myskillJourneyData);
        setLocalStoragePreviewData(selectedSkillsForApproval);
        setSubmitForApproval(true);
        setSelectedSkillWithLevel([]);
    };

    const handleClearAll = (e) => {
        setMyskillJourneyData(myskillJourneyData);
        setSelectStatusOptions(originalStatusOptionList);
        // setSelectRolesOptions(originalRolesOptionList);
        getMySkillJourneyData();
        prepareSelectStatusOptions();
        prepareSelectRolesOptions();

        prepareRoleListDataForUI(myskillJourneyData, selectRolesOptions, selectStatusOptions);
        setLocalStoragePreviewData(_dataApprovalPreview);
        setClickSearch(false);
        setNoDataonClickApply(false);
    };

    const handleProficiencyLevel = (data, e) => {
        const indexOfExisting = selectedSkillWithLevel.findIndex(
            (e) =>
                e.skillId === data.skillId && e.roleId === data.roleId && e.groupId === data.groupId
        );
        let newLevelSelections = [...selectedSkillWithLevel];
        if (indexOfExisting > -1) {
            newLevelSelections.splice(indexOfExisting, 1);
        }
        newLevelSelections.push(data);
        setSelectedSkillWithLevel(newLevelSelections);
    };

    const backToMySkillJounry = () => {
        setSubmitForApproval(false);
        setLocalStoragePreviewData([]);
        setSelectedSkillWithLevel([]);
    };

    // if(showLoader){
    //   return <Loader/>
    // }

    // if (localStorageData.length <= 0) {
    //     console.warn(localStorageData);
    // }

    function showIfLernerIdIsSelected() {
        if (chekedLernerIds?.length) {
            return { cursor: "pointer" };
        }
        return { cursor: "pointer", pointerEvents: "none", opacity: "0.2" };
    }

    function handleApprove() {
        setDisplayMessageBoxForApproval(true);
    }

    function handleReject() {
        setDisplayMessageBoxForReject(true);
    }

    const handleInputChange = (event) => {
        setAddress(event.target.value);
    };

    const handleCloseDisplayMessage1 = () => {
        setAddress("");
        setDisplayMessageBox(false);
        setDisplayMessageBoxForReject(false);
        setDisplayMessageBoxForApproval(false);
        setRejectPopError("");
    };

    function getDataForReject() {
        let selectedFile = [];
        if (address.trim() !== "") {
            chekedLernerIds?.map((id) => {
                let obj = {
                    skillTransactionId: id,
                    ApprovalStatus: "Rejected",
                    rejectReason: address
                };
                selectedFile.push(obj);
            });
            return selectedFile;
        } else return selectedFile;
    }

    const handleSubmitDisplayMessage = async (e) => {
        const data = getDataForReject();
        const checkedData = data.map((item) => item.skillTransactionId)
        reduxDispatch(setApproveRejectData([...approveRejectData, ...checkedData]))
        if (data?.length > 0) {
            setRejectPopError("");
            let payload = {
                skilltransactiondata: data ?? []
            };
            const response = await apis.getChangeStatusOfSkills(payload);
            console.log("response in viewSkillJourney",response)
            setDisplayMessageBoxForReject(false);
            setChekedLernerIds([]);
            // toast.success("Selected Skills are Rejected successfully.");
            if (response.message === "all_rejected") {
                setMessageforPopup("Selected Skill(s) have been rejected successfully.");
                setShowModal(true);
            } else if (response.message === "partially_rejected") {
                const skillName = [];
                response?.extraInfo?.notUpdatedSkills?.forEach((a) => {
                    skillName.push(a.SkillName);
                });
                setMessageforPopup(
                    `Few Skill(s) have been rejected successfully. Unable to reject following Skill(s): ${skillName.join(
                        ", "
                    )}`
                );
                setShowModal(true);
            } else if (response.message === "none_rejected") {
                const link = (
                    <>
                        Unable to complete the request, please retry after some time. If you need
                        further help, contact{" "}
                        <a
                            href="mailto:dx-sio-enablement@adobe.com"
                            className="btn btn-link btn-view fontSize14Text filterEffect">
                            dx-sio-enablement@adobe.com
                        </a>
                        .
                    </>
                );

                setMessageForFailure(link);
                setShowModal(true);
            } else if (response.message === "check_notifications") {
                setMessageforPopup("Your request is under process. Please look at notifications for further response.");
                setShowModal(true);
            }
            // setMessageforPopup("Selected Skill(s) have been rejected successfully.")
            // setShowModal(true);
            setFilterUi(true)
            getMySkillJourneyData(true);
            
        } else {
            setRejectPopError("Please enter a reason for rejection.");
        }
        reduxDispatch(setIsAllSkillSelected([]));
        reduxDispatch(setIsAllSelected([]));
    };

    function getDataForApprove() {
        let selectedFile = [];
        chekedLernerIds?.map((id) => {
            let obj = { skillTransactionId: id, ApprovalStatus: "Approved" };
            selectedFile.push(obj);
        });
        return selectedFile;
    }

    const handleOkDisplayMessage = async (e) => {
        setDisabled(true);
        const data = getDataForApprove();
        const checkedData = data.map((item) => item.skillTransactionId)
        reduxDispatch(setApproveRejectData([...approveRejectData, ...checkedData]))
        let payload = {
            skilltransactiondata: data ?? []
        };
        const response = await apis.getChangeStatusOfSkills(payload);
        console.log("response in viewSkillJourney 579",response)
        setDisplayMessageBoxForApproval(false);
        setDisabled(false);
        setChekedLernerIds([]);
        // toast.success("Selected Skills are Approved successfully.");
        if (response.message === "all_approved") {
            setMessageforPopup("Selected Skill(s) have been approved successfully.");
            setShowModal(true);
        } else if (response?.message === "partially_approved") {
            const skillName = [];
            response?.extraInfo?.notUpdatedSkills?.forEach((a) => {
                skillName?.push(a?.SkillName);
            });
            setMessageforPopup(
                `Few Skill(s) have been approved successfully. Unable to approve following Skill(s): ${skillName.join(
                    ", "
                )}`
            );
            setShowModal(true);
        } else if (response.message === "none_approved") {
            const link = (
                <>
                    Unable to complete the request, please retry after some time. If you need
                    further help, contact{" "}
                    <a
                        href="mailto:dx-sio-enablement@adobe.com"
                        className="btn btn-link btn-view fontSize14Text filterEffect">
                        dx-sio-enablement@adobe.com
                    </a>
                    .
                </>
            );

            setMessageForFailure(link);
            setShowModal(true);
        } else if (response.message === "check_notifications") {
            setMessageforPopup("Your request is under process. Please look at notifications for further response.");
            setShowModal(true);
        }
        // setMessageforPopup("Selected Skill(s) have been approved successfully.");
        // setShowModal(true);
        setFilterUi(true)
        getMySkillJourneyData(true);
        reduxDispatch(setIsAllSkillSelected([]));
        reduxDispatch(setIsAllSelected([]));
    };

    const handleFilter = (e) => {
        const searchTerm = e.target.value;
        setSearchInput(searchTerm);
    };

    const searchResults = searchInArray(roleDataListUI, searchInput);

    // const handleFilter = (event) => {
    //     const inputValue = event?.target?.value;
    //     if (inputValue) {
    //         setSearchInput(inputValue);
    //     } else if (inputValue?.length === 0) {
    //         setSearchInput("");
    //         setClickSearch(false);
    //         prepareRoleListDataForUI(myskillJourneyData, selectRolesOptions, selectStatusOptions);
    //     }
    // };

    // const handlerFilteredData = () => {
    //     setClickSearch(true);
    //     const filteredResults = [];

    //     roleDataListUI?.forEach((innerArray) => {
    //         if (Array.isArray(innerArray)) {
    //             innerArray?.forEach((item) => {
    //                 const filteredItem = {
    //                     id: item?.id,
    //                     title: item?.title,
    //                     skills: []
    //                 };

    //                 item?.skills?.forEach((skill) => {
    //                     const filteredSkill = {
    //                         skillName: skill?.skillName,
    //                         history: skill?.history?.filter((historyItem) => {
    //                             return Object?.entries(historyItem)?.some(([key, value]) => {
    //                                 if (
    //                                     [
    //                                         "TransactionId",
    //                                         "groupId",
    //                                         "keynote",
    //                                         "roleId",
    //                                         "roleSkillGroupId",
    //                                         "skillId",
    //                                         "skillIdTargetLevel",
    //                                         "userRoleSkillsId",
    //                                         "SkillDescription"
    //                                     ].includes(key)
    //                                 ) {
    //                                     return false;
    //                                 }

    //                                 const stringValue = value?.toString()?.toLowerCase();
    //                                 const searchValue = searchInput?.toLowerCase();

    //                                 if (!isNaN(value) && stringValue?.includes(searchValue)) {
    //                                     return true;
    //                                 }

    //                                 if (key === "submittedDate" || key === "aprroveDate") {
    //                                     const formattedDate = moment(value).format("MMM Do YYYY");
    //                                     return formattedDate.toLowerCase().includes(searchValue);
    //                                 }

    //                                 if (
    //                                     typeof value === "string" &&
    //                                     stringValue?.includes(searchValue)
    //                                 ) {
    //                                     return true;
    //                                 }

    //                                 return false;
    //                             });
    //                         })
    //                     };

    //                     if (filteredSkill?.history?.length > 0) {
    //                         filteredItem?.skills?.push(filteredSkill);
    //                     }
    //                 });

    //                 if (filteredItem?.skills?.length > 0) {
    //                     filteredResults?.push(filteredItem);
    //                 }
    //             });
    //         }
    //     });
    //     if (filteredResults?.length === 0) {
    //         setNoDataonClickApply(true);
    //     } else {
    //         setNoDataonClickApply(false);
    //     }

    //     setRoleDataListUI(filteredResults);
    // };

    // const handleKeyDown = (event) => {
    //     if (event.key === "Enter") {
    //         handlerFilteredData();
    //     }
    // };

    const handleCloseModal = () => {
        setShowModal(false);
        setFilterUi(true)
        getMySkillJourneyData(true)
    };

    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);

            reduxDispatch(setIsAllSkillSelected([]));
            reduxDispatch(setIsAllSelected([]));
            reduxDispatch(setCollapseData([]));
        };
    }, []);

    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4">
                <div className="headerImage">
                    {/* <h1>My Skill Journey</h1> */}
                    <h1>
                        {t("Skill Journey")} of {state?.userName}
                    </h1>
                    {/* <h3>{state?.userName}</h3> */}
                </div>
            </Container>
            {showLoader ? (
                <Loader />
            ) : localStorageData?.length == 0 ? (
                <>
                    <div class="center-container">
                        <div class="content-box">
                            <h2 class="styled-heading">{noDataAvailable}</h2>
                        </div>
                    </div>
                </>
            ) : localStorageData && localStorageData?.length > 0 ? (
                <>
                    <div className="pl-30 myskilljourney">
                        <div className="main-section pageBorder">
                            {!submitForApproval ? (
                                <>
                                    <div className="my-skill-journey-container">
                                        <div
                                            className={`top-container-section ${
                                                isSticky ? "stickyBoxShadow" : ""
                                            }`}>
                                            <Row>
                                                <Col lg={9} sm={12} className="container-section">
                                                    <div className="filter-section mt-2 align-items-end">
                                                        <div className="select-section select-status-section">
                                                            <div className="title-label">
                                                                Select Status
                                                            </div>
                                                            <MultiSelect
                                                                options={selectStatusOptions}
                                                                value={selectedStatusN}
                                                                onChange={setSelectedStatusN}
                                                                labelledBy={"Select"}
                                                                isCreatable={true}
                                                            />
                                                        </div>
                                                        <div className="select-section select-roles-section">
                                                            <div className="title-label">
                                                                Select Roles
                                                            </div>
                                                            <MultiSelect
                                                                options={selectRolesOptions}
                                                                value={selectedRolesN}
                                                                onChange={setSelectedRolesN}
                                                                labelledBy={"Select"}
                                                                isCreatable={true}
                                                            />
                                                        </div>
                                                        <div className="btn-apply">
                                                            {/* <div className="title-label"></div> */}
                                                            <button
                                                                className="btn primary-btn mx-2 mb-1"
                                                                onClick={(e) => handleApply(e)}>
                                                                Apply
                                                            </button>
                                                        </div>
                                                        <div className="btn-clear-all align-self-center mt-4">
                                                            {/* <div className="title-label"></div> */}
                                                            <button
                                                                className="btn btn-link btn-view"
                                                                onClick={(e) => handleClearAll(e)}>
                                                                Reset Filters
                                                            </button>
                                                        </div>
                                                        {/* <div className="level-options-device mt-2">
                                                            <div className="labels-group">
                                                                <div className="fontSize14">
                                                                    Proficiency Levels:
                                                                </div>

                                                                <div className="labels-item">
                                                                    <div className=""></div>
                                                                    <div className="text">
                                                                        100
                                                                        <span className="sub">
                                                                            (Beginner)
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="labels-item">
                                                                    <div className=""></div>
                                                                    <div className="text">
                                                                        200
                                                                        <span className="sub">
                                                                            (Intermediate)
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="labels-item">
                                                                    <div className=""></div>
                                                                    <div className="text">
                                                                        300
                                                                        <span className="sub">
                                                                            (Expert)
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </Col>
                                                <Col lg={3} sm={12} className="button-section pt-5">
                                                    <div className="d-flex align-items-end justify-content-end">
                                                        <div className="search searchClass">
                                                            <div className="searchInputs searchInputDiv">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Search"
                                                                    onChange={handleFilter}
                                                                    value={searchInput}
                                                                />
                                                                <button className="searchIcon searchBtn">
                                                                    <span className="pi pi-search"></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    lg={8}
                                                    md={12}
                                                    sm={12}
                                                    className="container-section">
                                                    <div className="level-options-device level-options-device-myskill pt-4">
                                                        <div className="labels-group">
                                                            <div className="fontSize14">
                                                                <img
                                                                    src={info}
                                                                    alt="info"
                                                                    className="info-icon"
                                                                    onClick={handleProficiencyOpenModal}
                                                                    style={{ cursor: "pointer" }}
                                                                />{" "}
                                                                Proficiency Levels:
                                                            </div>

                                                            <div className="labels-item">
                                                                <div className=""></div>
                                                                <div className="text">
                                                                    100
                                                                    <span className="sub">
                                                                        (Beginner)
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="labels-item">
                                                                <div className=""></div>
                                                                <div className="text">
                                                                    200
                                                                    <span className="sub">
                                                                        (Intermediate)
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="labels-item">
                                                                <div className=""></div>
                                                                <div className="text">
                                                                    300
                                                                    <span className="sub">
                                                                        (Expert)
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {/* <Col lg={4} md={12} sm={12} className="button-section">
                                                    <div className="button-section pt-4">
                                                        <button
                                                            className="btn primary-btn primary-blue"
                                                            onClick={handleSubmitForApproval}>
                                                            Submit Skill(s) for Approval
                                                        </button>
                                                    </div>
                                                </Col> */}
                                            </Row>
                                        </div>
                                        <div className="main-container-section my-skill-journey view-skill">
                                            {!isEmpty(searchResults) ? (
                                                <CustomAccordion
                                                    mainData={myskillJourneyData}
                                                    data={searchResults}
                                                    tableHeader={_tableHeaderForViewSkill}
                                                    handleViewSelectSkill={handleSkillSelection}
                                                    showActionColumn={showActionColumn}
                                                    setAddress={setAddress}
                                                    address={address}
                                                    setChekedLernerIds={setChekedLernerIds}
                                                    chekedLernerIds={chekedLernerIds}
                                                    getMySkillJourneyData={getMySkillJourneyData}
                                                    handleClearAll={handleClearAll}
                                                    updateFilterUi={updateFilterUi}
                                                />
                                            ) : noDataonClickApply ? (
                                                <p className="no-data">
                                                    No data found for the current selection.
                                                </p>
                                            ) : (
                                                <p className="no-data">
                                                    No results found. Please try another search
                                                    term.
                                                </p>
                                            )}
                                        </div>

                                        {!noDataonClickApply && !isEmpty(searchResults) && (
                                            <div className="skill-submission-btn btn-new">
                                                <div
                                                    className="btn-label btn-label-reject"
                                                    style={showIfLernerIdIsSelected()}
                                                    onClick={handleReject}>
                                                    <i className="pi pi-times"></i>{" "}
                                                    <span>Reject</span>
                                                </div>
                                                <div
                                                    className="btn-label btn-label-approve"
                                                    style={showIfLernerIdIsSelected()}
                                                    onClick={handleApprove}>
                                                    <i className="pi pi-check"></i>{" "}
                                                    <span>Approve</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <div className="skill-approval-preview-container">
                                        <div className="top-container-section">
                                            <Row>
                                                <Col sm={8} className="container-section">
                                                    <div className="title">
                                                        Skill Approval Preview
                                                    </div>
                                                </Col>
                                                <Col sm={4} className="button-section">
                                                    <button
                                                        className="btn cancel-btn m-2"
                                                        onClick={backToMySkillJounry}>
                                                        Back
                                                    </button>
                                                    <button
                                                        className="btn primary-btn primary-blue m-2"
                                                        disabled={disableSubmit ? "disable" : ""}
                                                        onClick={handleSubmit}>
                                                        Submit
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="main-approval-preview-container-section">
                                            <CustomAccordionApprovalPreview
                                                data={localStoragePreviewData}
                                                handleProficiencyLevel={handleProficiencyLevel}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="modal-container">
                            <Modal show={displayMessageBox} className="reject-modal" centered>
                                <Modal.Body className="py-4 px-5">
                                    <div className="success-modal-container mt-4">
                                        <span className="reject-icon"></span>
                                        <p className="success-text-message">
                                            Technical skill has been rejected.
                                        </p>
                                    </div>
                                    <p className="reason">Rejection Reason:</p>
                                    <textarea
                                        name="reason-textarea"
                                        className="reason-textarea"
                                        rows="4"
                                        placeholder="&nbsp;Type rejected reason:"></textarea>
                                </Modal.Body>
                                <Modal.Footer className="justify-content-center py-4">
                                    <button
                                        className="btn btn-link m-2 cancel-btn"
                                        onClick={() => handleCloseDisplayMessage()}>
                                        Cancel
                                    </button>
                                    <button
                                        className="btn primary-btn primary-blue m-2"
                                        onClick={() => handleCloseDisplayMessage()}>
                                        Submit
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}

            <Modal show={displayMessageBoxForReject} className="skill-submission-modal" centered>
                <Modal.Body>
                    <div className="success-modal-container px-3">
                        <div className="content-section">
                            <div>
                                <Form>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>
                                            <div className="content-label">
                                                Rejection Reason <span className="astrix">*</span>
                                            </div>
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            className="content-textarea"
                                            rows="5"
                                            name="address"
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        {rejectPopError && (
                                            <span style={{ color: "red" }}>{rejectPopError}</span>
                                        )}
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-link m-2"
                        onClick={() => handleCloseDisplayMessage1()}>
                        Cancel
                    </button>
                    <button
                        className="btn primary-btn primary-blue m-2 brn-submit"
                        onClick={(e) => handleSubmitDisplayMessage(e)}>
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
            <div className="modal-container success-modal">
                <Modal centered show={displayMessageBoxForApproval}>
                    <Modal.Body>
                        <div className="success-modal-container my-5 text-center">
                            <img
                                className="failed-icon mx-auto"
                                src={Failure_Icon}
                                alt="failed"></img>
                            <div className="success-text-message2 p-0 pt-3">
                                Are you sure you want to approve the selected Skill(s)?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-link m-2"
                            onClick={() => handleCloseDisplayMessage1()}>
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn primary-blue m-2"
                            onClick={(e) => handleOkDisplayMessage(e)}
                            i
                            disabled={showDisable}>
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
            <ModalforSuccessAndFailure
                messageforPopup={messageforPopup}
                show={showModal}
                onClose={handleCloseModal}
                messageForFailure={messageForFailure}
            />
            <ProficiencyLevelModal
                show={proficiencyShowModal}
                onClose={handleProficiencyCloseModal}
            />
            <Scroller />
        </>
    );

    function transforRoleDatatoView(dataRela) {
        let transformData = [];

        dataRela?.forEach((data) => {
            let existingRole = transformData.find((role) => role.id === data.RoleId);

            if (!existingRole) {
                // New role
                let role = {
                    id: data.RoleId,
                    title: data.RoleName || "Additional Skills",
                    skills: [
                        {
                            skillName: data.SkillName,
                            history: [getSkillHistoryObject(data)]
                        }
                    ]
                };
                transformData.push(role);
            } else {
                // Existing role
                let existingSkill = existingRole.skills.find(
                    (skill) => skill.skillName === data.SkillName
                );

                if (!existingSkill) {
                    // New skill for the existing role
                    existingRole.skills.push({
                        skillName: data.SkillName,
                        history: [getSkillHistoryObject(data)]
                    });
                } else {
                    // Existing skill, add to its history
                    existingSkill.history.push(getSkillHistoryObject(data));
                }
            }
        });

        return transformData;
    }

    function getProficiencies(submittedLevel, targetLevel) {
        return [
            {
                label: "Level 100",
                value: 100,
                status: submittedLevel && submittedLevel === 100 ? "completed" : "started",
                checked: submittedLevel && submittedLevel === 100 ? true : false,
                disabled: submittedLevel && submittedLevel > 100 ? true : false
            },
            {
                label: "Level 200",
                value: 200,
                status: submittedLevel && submittedLevel === 200 ? "completed" : "started",
                checked: submittedLevel && submittedLevel === 200 ? true : false,
                disabled: submittedLevel && submittedLevel > 200 ? true : false
            },
            {
                label: "Level 300",
                value: 300,
                status: submittedLevel && submittedLevel === 300 ? "completed" : "started",
                checked: submittedLevel && submittedLevel === 300 ? true : false,
                disabled: submittedLevel && submittedLevel === 300 ? true : false
            }
        ];
    }
    function getSelectedRollPreview(dataRela, skillJourny) {
        let roleId;
        let selectedSkillsForApproval = [];

        dataRela?.forEach((data) => {
            if (roleId !== data.RoleId) {
                //new role received
                roleId = data.RoleId;
                const roleGroupSkillTargetSubmittedId = `${data.RoleId}_${data.SkillGroupId}_${data.SkillId}_${data.TargetLevel}_${data.SubmittedLevel}`;

                if (selectedSkills.indexOf(roleGroupSkillTargetSubmittedId) > -1) {
                    let role = {
                        id: data.RoleId,
                        title: data.RoleName,
                        skills: [createPreviewSkillsObject(data)]
                    };
                    selectedSkillsForApproval.push(role);
                } else if (skillJourny) {
                    let role = {
                        id: data.RoleId,
                        title: data.RoleName,
                        skills: [createPreviewSkillsObject(data)]
                    };
                    selectedSkillsForApproval.push(role);
                }
            } else {
                const roleGroupSkillTargetSubmittedId = `${data.RoleId}_${data.SkillGroupId}_${data.SkillId}_${data.TargetLevel}_${data.SubmittedLevel}`;

                if (selectedSkills.indexOf(roleGroupSkillTargetSubmittedId) > -1) {
                    let lastRole = selectedSkillsForApproval[selectedSkillsForApproval.length - 1];
                    const isSkillPresent = lastRole?.skills?.filter(
                        (s) => s.skillName === data.SkillName
                    );

                    if (isSkillPresent?.length === 0) {
                        lastRole.skills.push(createPreviewSkillsObject(data));
                    } else {
                        let role = {
                            id: data.RoleId,
                            title: data.RoleName,
                            skills: [createPreviewSkillsObject(data)]
                        };
                        selectedSkillsForApproval.push(role);
                    }
                } else if (skillJourny) {
                    let lastRole = selectedSkillsForApproval[selectedSkillsForApproval.length - 1];
                    const isSkillPresent = lastRole?.skills?.filter(
                        (s) => s.skillName === data.SkillName
                    );

                    if (isSkillPresent?.length === 0) {
                        lastRole.skills.push(createPreviewSkillsObject(data));
                    } else {
                        let role = {
                            id: data.RoleId,
                            title: data.RoleName,
                            skills: [createPreviewSkillsObject(data)]
                        };
                        selectedSkillsForApproval.push(role);
                    }
                }
            }
        });
        return selectedSkillsForApproval;
    }

    function createPreviewSkillsObject(data) {
        return {
            skillName: data.SkillName,
            skillGroup: data.SkillGroupName,
            skillId: data.SkillId,
            groupId: data.SkillGroupId,
            roleId: data.RoleId,
            userRoleSkillsId: data.UserRoleSkillsId,
            proficiencyLevels: getProficiencies(
                data.ApprovalStatus === "Rejected"
                    ? data.SubmittedLevel - 100
                    : data.SubmittedLevel,
                data.TargetLevel
            )
        };
    }
}

export default ViewSkillJourney;