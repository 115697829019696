/* eslint-disable no-debugger */
import React, { useEffect, useState, useContext, useMemo } from "react";
import { apis } from "../../services/Api";
import DummyProfile from "../../assets/images/dummyProfile.svg";
import { useNavigate  } from "react-router-dom";
import { appContext } from "../../context/appContext";

const MyProfile = () => {
    let imagecontent1 = "https://cpcontents.adobe.com/public/images/default_user_avatar.svg?cp_oauth_jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwIjoidXJsIiwicmFuZCI6IjJjYjM4NTY2YzZmMGNkNWU4YWM1MGU1OGVmNzMyZGZlIiwidiI6IjEiLCJvcmlnaW5hbFVybEhhc2giOiIxZTc5ZTk3Y2RiYzIzNTIzYjVmMzE0ZjVjODg0MTc0YjhkNDBhYzczIiwiZXhwIjoxNjg0MzkzNDY0LCJyaWQiOiIxMTg5NTU0NSIsImlhdCI6MTY4Mzc4ODY2NCwiY2lkIjoiMjA4MCJ9.l3Zd5iVADh_lQOsSuewoKISqCdfskiEEg4OO2l5QJXE";
    let imagecontent = "https://cpcontents.adobe.com/public/images/default_user_avatar.svg?cp_oauth_jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwIjoidXJsIiwicmFuZCI6ImFlMDAwMGZkZDAyM2E5Y2EzMzJlNjJhYTUxNDYwY2NkIiwidiI6IjEiLCJvcmlnaW5hbFVybEhhc2giOiIxZTc5ZTk3Y2RiYzIzNTIzYjVmMzE0ZjVjODg0MTc0YjhkNDBhYzczIiwiZXhwIjoxNjg0MzkxNjU4LCJyaWQiOiIxMTg5NTU0NSIsImlhdCI6MTY4Mzc4Njg1OCwiY2lkIjoiMjA4MCJ9.4TBuAREmIuDumWdVDpAKKZVadWhzVGoON2cXC1JnwuI";
    const { AvatarUrl} = useContext(appContext);
    const [profile,setProfile] = useState({});
    const [approvedData, setApprovedData] = useState({});

    let navigate = useNavigate();
    let navtigateTo = ()=>{
        navigate("/myskilljourney");
    }

    let getUser = async () => {
        let data = await (apis.getUser());
        setProfile(data);
    }

    let getApprovedRoleAndSkills = async () => {
        let data1 = await apis.getApprovedRolesAndSkills();
        setApprovedData(data1);
    };

    const profileImage = useMemo(() => {
         
        const imgContent = profile?.data?.attributes?.avatarUrl === imagecontent ||
                                    profile?.data?.attributes?.avatarUrl === imagecontent1
         
        return imgContent  ? DummyProfile
            : AvatarUrl?.avatarUrl
                ? AvatarUrl?.avatarUrl
                : profile?.data?.attributes?.avatarUrl
    }, [AvatarUrl,profile,DummyProfile,imagecontent,imagecontent1]
    
    )
    useEffect(()=>{
        getUser();
        getApprovedRoleAndSkills();
    },[]);


    return (
        <>
            <div className="row user-bg mx-0">
                <div className="col-lg-12 col-md-12 col-12 text-center text-md-start home-profile-info">
                    <div className="home-profile-img-box">
                        <div className="home-profile-img">
                            <img
                                className="mx-auto d-block img-fluid"
                                src={profileImage}
                                alt=""></img>
                        </div>                        
                    </div>
                    
                    <div className="home-profile-short-info">
                        <p className="username ">
                            {profile?.data?.attributes?.name}
                            <span className="seprator px-1"> | </span>{" "}
                            <span className="designation">{profile?.data?.attributes?.profile}</span>
                        </p>
                        <div className="row mobileProfileDashboard">
                            <div className="col-lg-6 col-md-5 col-12 text-center text-md-start">
                                <p className="approved-skill m-0">Approved Skills</p>
                                <p className="address m-0 headerLimiter">
                                    {approvedData && approvedData?.uniqueSkillsArr?.length > 0 ? approvedData?.uniqueSkillsArr?.map((approvedskill) => {
                                        return (
                                            <>
                                                {approvedskill} {<span className="seprator px-1"> | </span>}
                                            </>
                                        );
                                    }) : <>None</>
                                    } 
                                </p>
                                <button className="morebtndashboard" onClick={navtigateTo} aria-hidden="true">
                                    {approvedData?.approvedSkills?.length > 0 && (
                                        <>
        More <span>&gt;</span>
                                        </>
                                    )}
                                </button>
                            </div>
                            <div className="col-lg-6 col-md-5 mb-lg-2 col-12  mt-md-0 text-center text-md-start">
                                <p className="approved-skill m-0">Current Assigned Role Packets</p>
                                <p className="address m-0 role-packets">
                                    {approvedData && approvedData?.roles?.length > 0 ?  approvedData?.roles?.map((role) => {
                                        return (
                                            <>
                                                {role.RoleName} {<span className="seprator px-1"> | </span>}
                                            </>
                                        );
                                    }) : <>None</>
                                    }
                                </p>
                                <button className="morebtndashboard" onClick={navtigateTo} aria-hidden="true">
                                    {approvedData?.roles?.length > 0 && (
                                        <>
        More <span>&gt;</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>  
                    </div>
                    

                </div>
            </div>
        </>
    );
};

export default MyProfile;
