
const RoleCatalog = [
    "AEP",
    "AEM",
    "AEP Auxiliary",
    "AEP ADVANCED",
    "3D Animation",
    "3D Lighting",
    "3D Materials",
    "3D Modeling",
    "DevOps",
    "DevOps Foundations",
    "DevOps Tools"
];

const loTypes = [
    {
        id: 1,
        label: "Certification",
        value: "certification"
    },
    {
        id: 2,
        label: "Course",
        value: "course"
    },
    {
        id: 3,
        label: "Job Aid",
        value: "jobAid"
    },
    {
        id: 4,
        label: "Learning Path",
        value: "learningProgram"
    }
];

const Format = [
    {
        id: 1,
        label: "Activity",
        value: "Activity"
    },
    {
        id: 2,
        label: "Blended",
        value: "Blended"
    },
    {
        id: 3,
        label: "Classroom",
        value: "Classroom"
    },
    {
        id: 4,
        label: "Self Paced E-Learning",
        value: "Self Paced"
    },
    {
        id: 5,
        label: "Virtual Classroom",
        value: "Virtual Classroom"
    }
];

const SkillLevels = [
    {
        id: 1,
        label: "Level 100 (Beginner)",
        value: "1"
    },
    {
        id: 2,
        label: "Level 200 (Intermediate)",
        value: "2"
    },
    {
        id: 3,
        label: "Level 300 (Advanced)",
        value: "3"
    }
];

const OtherSkills = [
    "AEP",
    "AEM",
    "AEP Auxiliary",
    "AEP ADVANCED",
    "3D Animation",
    "3D Lighting",
    "3D Materials",
    "3D Modeling",
    "DevOps",
    "DevOps Foundations",
    "DevOps Tools",
    "React Js",
    "Kubernates",
    "AI",
    "Machine Learning"
];

const learnerState = [
    {
        id: 1,
        label: "Yet to Start",
        value: "enrolled"
    },
    {
        id: 2,
        label: "In Progress",
        value: "started"
    },
    {
        id: 3,
        label: "Completed",
        value: "completed"
    },
    {
        id: 4,
        label: "Not Enrolled",
        value: "notenrolled"
    }
];
const sorting = [
    {
        id: 1,
        label: "Due Date",
        value: "dueDate",
        arrow: "Up",
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
        </svg>
    },
    {
        id: 2,
        label: "Enrolled Date",
        value: "dateEnrolled",
        arrow: "Up",
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
        </svg>
    },
    {
        id: 3,
        label: "Enrolled Date",
        value: "-dateEnrolled",
        arrow: "Down",
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
        </svg>
    },
    {
        id: 4,
        label: "Name(A-Z)",
        value: "name",
        arrow:"",
        icon:""
    },
    {
        id: 5,
        label: "Name(Z-A)",
        value: "-name",
        arrow:"",
        icon:""
    },
    {
        id: 6,
        label: "Recently Published",
        value: "-date",
        arrow: "",
        icon: ""
    }
];

const searchInSorting = [

    {
        id: 1,
        label: "Relevance",
        value: "relevance",
        arrow: "",
        icon: ""
    },
    {
        id: 2,
        label: "Name(A-Z)",
        value: "name",
        arrow: "",
        icon: ""
    },
    {
        id: 3,
        label: "Name(Z-A)",
        value: "-name",
        arrow: "",
        icon: ""
    },
    {
        id: 4,
        label: "Recently Published",
        value: "-date",
        arrow: "",
        icon: ""
    }
];

const snippetTypes = [
    {
        id: 1,
        label: " Course Metadata",
        value: "courseName"
    },
    {
        id: 2,
        label: "Notes",
        value: "note",
    },
    {
        id: 3,
        label: "Skills",
        value: "skillName",
    },
    {
        id: 4,
        label: "Badges",
        value: "badgeName",
    },
    {
        id: 5,
        label: "Tags",
        value: "courseTag",
    }
]

const durationRange = [
    {
        id: 1,
        label: "30 min or less",
        value: "0-1800",
    },
    {
        id: 2,
        label: "31 Min - 2 Hours",
        value: "1801-7200",
    },
    {
        id: 3,
        label: "2 Hours +",
        value: "7201-99999",
    },
]

export const week3_4Data = [
    {id: "11", catLabel: "Commerce", catDisplayName: "Adobe Commerce"},
    {id: "12", catLabel: "AEM", catDisplayName: "Adobe Experience Manager (AEM)"},
    {id: "13", catLabel: "AEP", catDisplayName: "Adobe Experience Platform (AEP)"},
    {id: "14", catLabel: "Analytics", catDisplayName: "Analytics"},
    {id: "15", catLabel: "AAM", catDisplayName: "Audience Manager"},
    {id: "16", catLabel: "Campaign", catDisplayName: "Campaign"},
    {id: "17", catLabel: "Marketo", catDisplayName: "Marketo Engage"},
    {id: "18", catLabel: "Target", catDisplayName: "Target"},
    {id: "19", catLabel: "Workfront", catDisplayName: "Workfront"}
]


export const filterJson={
    RoleCatalog,
    loTypes,
    Format,
    SkillLevels,
    OtherSkills,
    learnerState,
    sorting,
    snippetTypes,
    searchInSorting,
    durationRange,
    week3_4Data
}