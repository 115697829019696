import { createSlice } from "@reduxjs/toolkit";
import { apis } from "../../services/Api";

const initialState = {
    isPending: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    myTeamData: [],
    roles: [],
    skills: []
};

export const myTeamSlice = createSlice({
    name: "myTeam",
    initialState,
    extraReducers(builder) {
        builder
            .addCase(apis.getMyTeamStats.pending, (state) => {
                return {
                    ...state,
                    isPending: true
                };
            })
            .addCase(apis.getMyTeamStats.fulfilled, (state, action) => {
                const list = Object.values(action?.payload);

                return {
                    ...state,
                    isPending: false,
                    isSuccess: true,
                    myTeamData: list
                };
            })
            .addCase(apis.getMyTeamStats.rejected, (state, action) => {
                return {
                    ...state,
                    isPending: false,
                    isError: true,
                    errorMessage: action.error.message
                };
            })
            .addCase(apis.getRolesAndSkillsDropDown.pending, (state) => {
                return {
                    ...state,
                    isPending: true
                };
            })
            .addCase(apis.getRolesAndSkillsDropDown.fulfilled, (state, action) => {
                const { roles, skills } = action.payload;

                return {
                    ...state,
                    isPending: false,
                    isSuccess: true,
                    roles,
                    skills
                };
            })
            .addCase(apis.getRolesAndSkillsDropDown.rejected, (state, action) => {
                return {
                    ...state,
                    isPending: false,
                    isError: true,
                    errorMessage: action.error.message
                };
            })
            .addCase(apis.getFilteredDataOnApply.pending, (state) => {
                return {
                    ...state,
                    isPending: true
                };
            })
            .addCase(apis.getFilteredDataOnApply.fulfilled, (state, action) => {
                return {
                    ...state,
                    isPending: false,
                    isSuccess: true,
                    myTeamData: action.payload
                };
            })
            .addCase(apis.getFilteredDataOnApply.rejected, (state, action) => {
                return {
                    ...state,
                    isPending: false,
                    isError: true,
                    errorMessage: action.error.message
                };
            });
    }
});

export const { setLearnerList, setDetailsLearnerData, setDetailsUsername } = myTeamSlice.actions;

export default myTeamSlice.reducer;
