/* eslint-disable */
import './CustomAccordionApprovalPreview.css';

function CustomAccordionApprovalPreviewHeader({ data }) {
  return (
    <div className="custom-accordion-approval-preview-header">
      <table className="custom-accordion-approval-preview-tbl">
        <tr>
          <th width="25%">Skill Name</th>
          <th width="24%">Skill Group</th>
          <th width="17%">Level 100 (Beginner)</th>
          <th width="17%">Level 200 (Intermediate)</th>
          <th width="17%">Level 300 (Expert)</th>
        </tr>
      </table>
    </div>
  );
}

export default CustomAccordionApprovalPreviewHeader;
