import React, { useState } from "react";
import { Modal, Container, Row, Col, Form, InputGroup, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { apis } from "../../services/Api";
import "./RoleSkillsJourney.css";

export default function AssignRoleToTeam({
    set_UserId,
    setCheckUserRes,
    showModal,
    handleShow,
    selectedOptionForLerner,
    assignRoleIds,
    assignRoleSkillGrpIds,
    name,
    getPayloadForValidation,
    state,
    setDisplayMessageBoxForalreadyAssignRoleSkill,
    stateForRole,
    setDisplayMessageBoxForConfirmation,
    setDisplayMessageBoxForalreadyAssignRole,
    setDisplayMessageBoxForConfirmationCourse,
}) {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedSkillIds, setSelectedSkillIds] = useState([]);
    const [errorLearner, setErrorLearner] = useState([]);

    const { learnerList } = useSelector((state) => state.learnerList);

    const handleShowThis = () => {
        handleShow();
        // setLearnerList(learnerList);
        setSelectedSkillIds([]);
        setErrorLearner("");
        setSearchTerm("")
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const toggleLearnerSelection = (skillId) => {
        const updatedSkillIds = selectedSkillIds.includes(skillId)
            ? selectedSkillIds.filter((id) => id !== skillId)
            : [...selectedSkillIds, skillId];

        setSelectedSkillIds(updatedSkillIds);
        setErrorLearner("");
    };

    const handleSubmit = async () => {
        if (selectedSkillIds.length) {
            if (selectedOptionForLerner == 3) {
                const learnerUserIds = selectedSkillIds?.map(String);
                set_UserId(learnerUserIds);
                setDisplayMessageBoxForConfirmationCourse(true);
            }
            if (selectedOptionForLerner == 2) {
                if (selectedSkillIds.length) {
                    const learnerUserIds = selectedSkillIds?.map(String);
                    set_UserId(learnerUserIds);
                    let res = await apis.validateUserRoleSkill(
                        getPayloadForValidation(state, learnerUserIds)
                    );
                    setCheckUserRes(res);
                    if (!res?.assignUserSkills?.response) {
                        setDisplayMessageBoxForConfirmation(false);
                        setDisplayMessageBoxForalreadyAssignRoleSkill(true);
                    } else {
                        setDisplayMessageBoxForConfirmation(true);
                        setDisplayMessageBoxForalreadyAssignRoleSkill(false);
                    }
                } else {
                    assignRoleSkillGrpIds("");
                }
            }
            if (selectedOptionForLerner == 1) {
                if (selectedSkillIds.length) {
                    const learnerUserIds = selectedSkillIds?.map(String);
                    set_UserId(learnerUserIds);
                    let res = await apis.validateUserRoleSkill(
                        getPayloadForValidation(stateForRole, learnerUserIds)
                    );
                    setCheckUserRes(res);
                    if (!res?.assignUserRoles?.response) {
                        setDisplayMessageBoxForConfirmation(false);
                        setDisplayMessageBoxForalreadyAssignRole(true);
                    } else {
                        setDisplayMessageBoxForConfirmation(true);
                        setDisplayMessageBoxForalreadyAssignRole(false);
                    }
                } else {
                    assignRoleIds("");
                }
            }
            handleShowThis();
            setErrorLearner("");
        } else {
            setErrorLearner("Please select Learner(s)");
        }
        setSearchTerm("");
    };

    // Updated filtering logic to filter learners based on the search term
    const filteredLearners = learnerList.filter((learner) =>
        learner.Name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="modal">
            <Modal show={showModal} centered className="assign-people-modal" onHide={handleShowThis}>
                <Modal.Header closeButton>
                    <Modal.Title className="mb-0 header-line">{name}</Modal.Title>
                </Modal.Header>
                {!learnerList || learnerList.length === 0 ? (
                    <div className="text-center mt-4 mb-4">
                        <p className="text-muted">No Learner(s) assigned to you!</p>
                    </div>
                ) : (
                    <>
                        <Modal.Body className="px-4">
                            <div className="mb-2">
                                <InputGroup className="">
                                    <FormControl
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={handleInputChange}
                                        className="search-bar border-bottom"
                                    />
                                </InputGroup>
                            </div>
                            <Container>
                                <Row className="custom-height my-4">
                                    {filteredLearners?.map(({ InternalUserId, Name }) => (
                                        <Col
                                            key={InternalUserId}
                                            xs={12}
                                            md={4}
                                            lg={4}
                                            className="my-2 learnerMember">
                                            <Form.Check
                                                type="checkbox"
                                                checked={selectedSkillIds.includes(InternalUserId)}
                                                onChange={() =>
                                                    toggleLearnerSelection(InternalUserId)
                                                }
                                                label={<span title={Name}>{Name}</span>}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                            <div>
                                {errorLearner && (
                                    <span style={{ color: "red" }}>{errorLearner}</span>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-right">
                            <div className="failure-modal-container-footer">
                                <button className="btn btn-link p-2" onClick={handleShowThis}>
                                    Cancel
                                </button>
                                <button
                                    className="btn primary-btn primary-blue m-2"
                                    onClick={handleSubmit}>
                                    {selectedOptionForLerner === 3 ? "Enroll" : "Assign"}
                                </button>
                            </div>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        </div>
    );
}
