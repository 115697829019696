/* eslint-disable*/
import React from 'react';
import { useEffect } from 'react';
import { apis } from '../../services/Api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate,Link } from 'react-router-dom';
import CarouselBlock from '../../common/slider/slider';
import { t } from "i18next";
import Loader from '../../common/Loader/Loader';

const MyLearning = ({ props }) => {
  const fromHomePage=true;
  const { t } = useTranslation();
  const navigate = useNavigate();
  let [loDetails, setLoDetails] = useState([]);
  const [getALlEnrollmentData, setGetALlEnrollmentData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState('recentlyEnrolled');
  const [manual, setManual] = useState(false);
  const [flag, setFlag] = useState(false);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setManual(true);
  };

  let getEnrollment = async () => {
    let result = await apis.getEnrollments()
    setGetALlEnrollmentData(result.data || []);
    const enrollmentData = result.data || [];
    getLoContents(enrollmentData);
  }
  let myLearningIncluded = [];
  let filteredMyLearning = [];
  async function fetchMyLearning(cursor = '') {
    let response = null;
    let myLearningData = [];
    let sort="";
    setIsLoading(true);
    if(selectedOption === "approachingDueDate"){
      sort= 'dueDate';
    }else {
      sort= '-dateEnrolled';
    }
    if(cursor != '') {
      response  = await apis.getMyLearningByDueDate(cursor,sort);
    }
    else {
      response = await apis.getMyLearningByDueDate(cursor= "",sort);
    }

    if (response) {
      if(response.data?.length > 0) {
        for (const data of response.data) {
          myLearningData.push(data);
        }
        const filterDnsData = myLearningData.filter((e, i) => {
          return (
            ((e?.attributes?.localizedMetadata && !e?.attributes?.localizedMetadata[0]?.name.includes("DNS")) && !e?.attributes?.tags?.some(tag => tag.includes("DNS")))
          );
        });
        let filteredDataArr= [];
        if(selectedOption === "approachingDueDate") {
          filteredDataArr = filterDnsData.filter((ele, i) => {
            let completionDeadline = "";
            const enrollmentId = ele?.relationships?.enrollment?.data?.id;
            const enrolledData = response?.included?.find(
              (e) => e.id === enrollmentId && e.type === "learningObjectInstanceEnrollment"
            );

            const enrollCourseInstanceData = response?.included?.find(
              (e) => e.id === enrollmentId.substring(0,enrollmentId.lastIndexOf("_")) && e.type === "learningObjectInstance"
            );

            if(enrolledData?.attributes?.completionDeadline && new Date(enrolledData?.attributes?.completionDeadline).toString().toLowerCase() !== 'invalid date') {
              completionDeadline = enrolledData?.attributes?.completionDeadline
            } else if(enrollCourseInstanceData?.attributes?.completionDeadline && new Date(enrollCourseInstanceData?.attributes?.completionDeadline).toString().toLowerCase() !== 'invalid date') {
              completionDeadline = enrollCourseInstanceData?.attributes?.completionDeadline
            }

            return (
              completionDeadline && completionDeadline !== "" && new Date(completionDeadline) >= new Date(new Date().setDate(new Date().getDate()-15)) && new Date(completionDeadline) <= new Date(new Date().setDate(new Date().getDate()+180))
            );
              
          });
      }
        filteredMyLearning = selectedOption === "approachingDueDate" ? [...filteredMyLearning, ...filteredDataArr] : [...filteredMyLearning, ...filterDnsData];
      }
      if(response?.included?.length > 0){
        for (const included of response.included) {
          myLearningIncluded.push(included);       
        }      
      }
      if(response?.links?.next) {
        const urlParams = new URLSearchParams(response.links.next);
        const nextCursor = urlParams.get("page[cursor]");
        if(filteredMyLearning.length < 20) {
          await fetchMyLearning(nextCursor);
        }
      }
    } 
  }

  async function getLoContents(enrollmentData) {
    await fetchMyLearning();
    //Keep it for now
    // if(selectedOption === "approachingDueDate" && filteredMyLearning.length === 0 && !manual) {
    //   myLearningIncluded = [];
    //   filteredMyLearning = [];
    //   setSelectedOption("recentlyEnrolled");
    //   return;
    // }

    const data = filteredMyLearning;
    const included = myLearningIncluded;
    myLearningIncluded = [];
    filteredMyLearning = [];
    let loContent = [];
    for (let i = 0; i < data.length; i++) {
      const enrollmentId = data[i]?.relationships?.enrollment?.data?.id;
      let completionDeadline = "";
      let enrolledData = included?.find(
        (e) => e.id === enrollmentId && e.type === "learningObjectInstanceEnrollment"
      );
    
      let enrollCourseInstanceData = included?.find(
        (e) => e.id === enrollmentId.substring(0,enrollmentId.lastIndexOf("_")) && e.type === "learningObjectInstance"
      );

      if(enrolledData?.attributes?.completionDeadline && new Date(enrolledData?.attributes?.completionDeadline).toString().toLowerCase() !== 'invalid date') {
        completionDeadline = enrolledData?.attributes?.completionDeadline
      } else if(enrollCourseInstanceData?.attributes?.completionDeadline && new Date(enrollCourseInstanceData?.attributes?.completionDeadline).toString().toLowerCase() !== 'invalid date') {
        completionDeadline = enrollCourseInstanceData?.attributes?.completionDeadline
      }
      /*if(data[i]?.attributes?.loType === "certification"){
        completionDeadline = enrolledData?.attributes?.completionDeadline
      }else{
        completionDeadline = enrollCourseInstanceData?.attributes?.completionDeadline
      }*/
      const isExist = enrollmentData?.find(el => {
        return data[i]?.id === el?.relationships?.learningObject?.data?.id
      })
      loContent.push({
        id: data[i].id,
        imageUrl: data[i]?.attributes?.imageUrl,
        loFormat: data[i]?.attributes?.loFormat,
        name: data[i]?.attributes?.localizedMetadata[0]?.name,
        description: data[i]?.attributes?.localizedMetadata[0]?.description,
        overview: data[i]?.attributes?.localizedMetadata[0]?.overview,
        loType: data[i]?.attributes?.loType,
        Author: '',
        duration: data[i]?.attributes?.duration,
        averageRating: data[i]?.attributes?.rating?.averageRating,
        ratingsCount: data[i]?.attributes?.rating?.ratingsCount || 0,
        price: data[i].attributes?.purchasedPrice,
        enrollmentNum: data[i]?.relationships?.enrollment,
        enrollmentType: data[i]?.attributes?.imageUrl,
        instancesId: enrollCourseInstanceData.id,
        skills: data[i]?.relationships?.skills?.data[0]?.id?.split('_')[1],
        isBookmarked: data[i]?.attributes?.isBookmarked || false,
        enrollmentId: enrollmentId,
        progressPercent: isExist ? isExist?.attributes?.progressPercent : 0,
        enrollment: Object.prototype.hasOwnProperty.call(data[i]?.relationships, 'enrollment')
            ? // data[i].relationships.hasOwnProperty("enrollment")
              true
            : false,
        // dueDate:enrollmentData?.attributes?.completionDeadline   
        dueDate:completionDeadline,
        tags:data[i]?.attributes?.tags || [] 
      });
    }
    myLearningIncluded= [];
    filteredMyLearning= [];
    setLoDetails(loContent);
    setIsLoading(false);
  }
  const sortByEnrollDate = (loDetailFilterData) => {

  const sortedData = loDetailFilterData.sort((a, b) => new Date(b.enrollmentDate) - new Date(a.enrollmentDate));
   setLoDetails(sortedData);
     };
  let loDetailFilterData =loDetails.filter((data) => {
    return data?.progressPercent !== 100
  })
  useEffect(() => {
    getEnrollment();
  }, [selectedOption]);
  
  useEffect(()=>{
    setFlag(true);
  })
  const playCourse = (cid, mid) => {
    navigate(`/course-player/${cid}`, {
      state: { cid, mid, goBackUrl: location?.pathname },
    });
  };
  return (
   
    <div className='p-0'>
      
      <div className="Carousel-block row my-4 mb-0 mx-0">
     <div className="title-block px-0">
          <span className="main-head"> {t('My Learning')} </span>
          <span className="view-all">
          <div className='mylearning-radio-box'>
          <div className='radio-item'>
            <input
              className='radioinput'
              type="radio"
              value="recentlyEnrolled"
              checked={selectedOption === 'recentlyEnrolled'}
              onChange={() => handleOptionChange('recentlyEnrolled')}
            />
            <label htmlFor="">Recently Enrolled</label>            
          </div>
          <div className='radio-item'>
          <input
            className='radioinput'
              type="radio"
              value="approachingDueDate"
              checked={selectedOption === 'approachingDueDate'}
              onChange={() => handleOptionChange('approachingDueDate')}
            />
            <label htmlFor="upcomingOverdue">Upcoming/Overdue</label>
          </div>
            <button className="btn btn-link btn-view" onClick={() => { navigate("/mylearning"); }}>View All</button>
            </div></span>
        </div>
        </div>
      <div className="Carousel-block row mb-0 mx-0 px-5">
        
        {isLoading ? (
      // Show loading spinner or some loading indicator
      <div style= {{position : 'relative'}}><Loader></Loader></div>
    ) : loDetails.length > 0 ? (
      // Display data when available
        <CarouselBlock skills={props.skills} myLearningObj={loDetailFilterData} playCourse={playCourse} componentName="MyLearning" fromHomePage={fromHomePage} fromUpcommingOverdue={selectedOption === "approachingDueDate" ? true :false}/>
    ) : 
      // Show "No Data" message if no data available
      
      flag  && (
      selectedOption === "approachingDueDate"?
      (<h1 className="No-data-message no-data">
       No Courses/Learning Paths with Upcoming due date/Overdue is assigned to you. Please select Recently Enrolled option or go to <Link to="/allcourses"> All Courses</Link> to explore available content.
       </h1>)
      :(
      <h1 className="No-data-message no-data">
       No Courses/Learning Paths are assigned to you. Please go to <Link to="/allcourses"> All Courses</Link> to explore available content.
       </h1>) 
      )   
    }
      </div>
    
  </div>
  );
};

export default MyLearning;