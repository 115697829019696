export const learnerReportsBreadCrumbs = [
    { label: "Home", url: "/" },
    { label: "Learner Report", url: "/learnerreports" }
];

export const learnerReportsDetailsBreadCrumbs = [
    { label: "Home", url: "/" },
    { label: "Learners Report", url: "/learnerreports" },
    { label: "User Details", url: "/learnerreports/userDetails" }
];

export const courseReportsBreadCrumbs = [
    { label: "Home", url: "/" },
    { label: "Course Report", url: "/coursereports" }
];

export const courseReportsDetailsBreadCrumbs = [
    { label: "Home", url: "/" },
    { label: "Course Report", url: "/coursereports" },
    { label: "User Details", url: "/coursereports/coursedetails" }
];

export const myTeamBreadcrumbs = [
    { label: "Home", url: "/" },
    { label: "Skill Dashboard", url: "/myteam" }
];
