/* eslint-disable linebreak-style */
import React from "react";
import { account_id, client_id, signin_url } from "../../AppConfig";
import { toQueryString } from "../../common/queryString/toQueryString"
import "./LoginFailed.css"
// import logo from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.svg";

// Can be a string as well. Need to ensure each key-value pair ends with ;


function LoginFailed() {
    
    const {t} = useTranslation()


    const onSignIn = () => {

        let location = `${window.location.origin}/login` ;
        let queryParams = {
            client_id: `${client_id}`,
            redirect_uri: `${location}`,
            state: "prime_auth",
            scope: "learner:read,learner:write",
            response_type: "CODE"
        };

        let authorizeEndpoint = `${signin_url}/oauth/o/authorize?logoutAfterAuthorize=true&account=${account_id}&${toQueryString(queryParams)}`;
        window.location.assign(authorizeEndpoint);
    }


    return (
        <React.Fragment>
            <div className='signin-block'>
                <div className='main-block'>
                    <div className='container'>
                        <div className="login-block-txt">
                            <div className='login-box'>
                                {/* <h1 className="headding-title">{t("loginFailed-text-1")} </h1> */}
                                <img src={logo} className='mb-4' alt='' />
                                <div className='login-text my-4'>
                                    <p>{t("loginFailed-1")}</p>
                                </div>
                                <button type="button" className=" fill" onClick={onSignIn}>{t("login-with-val", {val:"Adobe"})}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className='container'>
                    <div className='row footer-block'>
                        <div className='footer-txt col'>
                            {t("powred-by-adobe")}
                        </div>
                    </div>

                </div>
            </footer>
        </React.Fragment >
    );
}


export default LoginFailed;