import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDetailsCourseData, setDetailsCourseName } from "../MyTeam/courseListSlice";

const CourseReportsCellContent = ({ data }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const openDetailsTable = (data, courseName) => {
        dispatch(setDetailsCourseName(courseName));
        dispatch(setDetailsCourseData(data));
        navigate("/coursereports/coursedetails");
    };

    return (
        <tbody>
            {data.map((course, index) => (
                <tr key={index} className="tableRows">
                    <td className="columnData">{course?.courseName}</td>
                    <td className="columnData">
                        <button
                            className="table-cell-button"
                            style={{
                                textDecoration: "none",
                                cursor: "default"
                            }}>
                            {course?.Type}
                        </button>
                    </td>
                    <td className="columnData">
                        <button
                            className="table-cell-button"
                            onClick={() =>
                                openDetailsTable(course?.EnrolledLearnersList, course?.courseName)
                            }>
                            {course?.Enrolled}
                        </button>
                    </td>
                    <td className="columnData">
                        <button
                            className="table-cell-button"
                            onClick={() =>
                                openDetailsTable(course?.CompletedLearners, course?.courseName)
                            }>
                            {course?.Completed}
                        </button>
                    </td>
                    <td className="columnData">
                        <button
                            className="table-cell-button"
                            onClick={() =>
                                openDetailsTable(course?.InProgressLearners, course?.courseName)
                            }>
                            {course?.InProgress}
                        </button>
                    </td>
                    <td className="columnData">
                        <button
                            className="table-cell-button"
                            onClick={() =>
                                openDetailsTable(course?.NotStartedLearners, course?.courseName)
                            }>
                            {course?.NotStarted}
                        </button>
                    </td>
                </tr>
            ))}
        </tbody>
    );
};

export default CourseReportsCellContent;
