// import React, { useState, useEffect, useRef } from "react";
// import "./CustomAccordion.css";

// function RejectInfoIcon({ address }) {
//     const [showKeyNote, setShowKeyNote] = useState(false);
//     const divRef = useRef(null);

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (divRef.current && !divRef.current.contains(event.target)) {
//                 setShowKeyNote(false);
//             }
//         };

//         document.addEventListener("click", handleClickOutside);

//         return () => {
//             document.removeEventListener("click", handleClickOutside);
//         };
//     }, []);

//     const handleInfoIconClick = () => {
//         setShowKeyNote(!showKeyNote);
//     }

//     const handleInfoIconClose = () => {
//         setShowKeyNote(false);
//     }

//     return (
//         <span className={"tbl-info-btn-status-span"} ref={divRef}>
//             <button className="tbl-info-btn-status" onClick={handleInfoIconClick}></button>
//             {showKeyNote ? (
//                 <div className={"popup tbl-info-info-box"}>
//                     <div className='popup-wrapper'>
//                         <button className='popup-close-info' onClick={handleInfoIconClose}>X</button>
//                         <p className='content-text pe-4' >{address}</p>
//                     </div>
//                 </div>
//             ) : null}
//         </span>
//     );
// }
// export default RejectInfoIcon;


// import React, { useState, useEffect, useRef } from "react";
import "./CustomAccordion.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
function RejectInfoIcon({ address }) {
    return (<>
        <OverlayTrigger
            trigger="click"
            placement="left"
            rootClose
            overlay={
                <Popover id="popover-trigger-click-root-close" className="popover-body-rejectioned">
                    <Popover.Body className="popover-body-rejection" style={{ fontSize: "15px",maxHeight: "200px", overflowY: "auto" }}>
                        {address}
                    </Popover.Body>
                </Popover>
            }
        >
            <button className="tbl-info-btn-status " type="button">
            </button>
        </OverlayTrigger>
    </>    
    );
}
export default RejectInfoIcon;