/* eslint-disable */
export const tableClassName = "custom-accordion-tbl-skill-journey";
export const _data = [
    {
      id: 1,
      title: 'AEM Support Engineer',
      skills: [
        {
          skillName: 'AEM Sites: Authoring/Foundation',
          
history:[
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Not Started',
              keynote:""
            },
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            }
          ]
        },
        {
          skillName: 'AEM Sites: Communities',
          
history: [
            {
              skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'AEM Sites: Integration Campaign',
          
history: [{
          skillName: 'AEM Sites: Integration Campaign',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Rejected',
          keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery",
          
          }]
        },
        {
          skillName: 'Technical Skill 3',
          
history:[
            {
              skillName: 'Technical Skill 3',
          skillGroup: 'TechnicalSkills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'Technical Skill 2',
          
history:[{
            skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Approved',
          keynote:"",
          }]
        },
        {
          skillName: 'Technical Skill 1',
          
history:[{
            skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Awaiting Approval',
          keynote:"",
          }]
        }
      ]
    },
    {
      id: 2,
      title: 'Marketo',
      skills: [
        {
          skillName: 'AEM Sites: Authoring/Foundation',
          
history:[
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            },
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            },
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            }
          ]
        },
        {
          skillName: 'AEM Sites: Communities',
          
history: [
            {
              skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'AEM Sites: Integration Campaign',
          
history: [{
            skillName: 'AEM Sites: Integration Campaign',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Rejected',
          keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery",
          
          }]
        },
        {
          skillName: 'Technical Skill 3',
          
history:[
            {
              skillName: 'Technical Skill 3',
          skillGroup: 'TechnicalSkills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'Technical Skill 2',
          
history:[{
            skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Approved',
          keynote:"",
          }]
        },
        {
          skillName: 'Technical Skill 1',
          
history:[{
            skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Awaiting Approval',
          keynote:"",
          }]
        }
      ]
    },
    {
      id: 3,
      title: 'Magento',
      skills: [
        {
          skillName: 'AEM Sites: Authoring/Foundation',
          
history:[
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            },
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            },
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            }
          ]
        },
        {
          skillName: 'AEM Sites: Communities',
          
history: [
            {
              skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'AEM Sites: Integration Campaign',
          
history: [{
            skillName: 'AEM Sites: Integration Campaign',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Rejected',
          keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery",
          
          }]
        },
        {
          skillName: 'Technical Skill 3',
          
history:[
            {
              skillName: 'Technical Skill 3',
          skillGroup: 'TechnicalSkills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'Technical Skill 2',
          
history:[{
            skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Approved',
          keynote:"",
          }]
        },
        {
          skillName: 'Technical Skill 1',
          
history:[{
            skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Awaiting Approval',
          keynote:"",
          }]
        }
      ]
    },
    {
      id: 4,
      title: 'Campaign',
      skills: [
        {
          skillName: 'AEM Sites: Authoring/Foundation',
          
history:[
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            },
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            },
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            }
          ]
        },
        {
          skillName: 'AEM Sites: Communities',
          
history: [
            {
              skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'AEM Sites: Integration Campaign',
          
history: [{
            skillName: 'AEM Sites: Integration Campaign',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Rejected',
          keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery",
          
          }]
        },
        {
          skillName: 'Technical Skill 3',
          
history:[
            {
              skillName: 'Technical Skill 3',
          skillGroup: 'TechnicalSkills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'Technical Skill 2',
          
history:[{
            skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Approved',
          keynote:"",
          }]
        },
        {
          skillName: 'Technical Skill 1',
          
history:[{
            skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Awaiting Approval',
          keynote:"",
          }]
        }
      ]
    },
    {
      id: 5,
      title: 'AAM',
      skills: [
        {
          skillName: 'AEM Sites: Authoring/Foundation',
          
history:[
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            },
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            },
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            }
          ]
        },
        {
          skillName: 'AEM Sites: Communities',
          
history: [
            {
              skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'AEM Sites: Integration Campaign',
          
history: [{
            skillName: 'AEM Sites: Integration Campaign',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Rejected',
          keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery",
          
          }]
        },
        {
          skillName: 'Technical Skill 3',
          
history:[
            {
              skillName: 'Technical Skill 3',
          skillGroup: 'TechnicalSkills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'Technical Skill 2',
          
history:[{
            skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Approved',
          keynote:"",
          }]
        },
        {
          skillName: 'Technical Skill 1',
          
history:[{
            skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Awaiting Approval',
          keynote:"",
          }]
        }
      ]
    },
    {
      id: 6,
      title: 'Analytics',
      skills: [
        {
          skillName: 'AEM Sites: Authoring/Foundation',
          
history:[
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            },
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            },
            {
              skillName: 'AEM Sites: Authoring/Foundation',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            }
          ]
        },
        {
          skillName: 'AEM Sites: Communities',
          
history: [
            {
              skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'AEM Sites: Integration Campaign',
          
history: [{
            skillName: 'AEM Sites: Integration Campaign',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Rejected',
          keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery",
          
          }]
        },
        {
          skillName: 'Technical Skill 3',
          
history:[
            {
              skillName: 'Technical Skill 3',
          skillGroup: 'TechnicalSkills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
            }
          ]
        },
        {
          skillName: 'Technical Skill 2',
          
history:[{
            skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Approved',
          keynote:"",
          }]
        },
        {
          skillName: 'Technical Skill 1',
          
history:[{
            skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Awaiting Approval',
          keynote:"",
          }]
        }
      ]
    }
  ];
export const _dataApprovalPreview = [
    {
      id: 1,
      title: 'AEM Support Engineer',
      skills: [
        {
          skillName: 'AEM Sites: Authoring/Foundation',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
            {
              label: 'Level 100',
              status: 'started',
        checked: false
              },
              {
              label: 'Level 200',
              status: 'started',
        checked: false
              },
              {
              label: 'Level 300',
              status: 'started',
        checked: false
              }
          ]
        },
        {
          skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Integrations Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    },
    {
      id: 2,
      title: 'Marketo',
      skills: [
        {
          skillName: 'AEM Sites: Authoring / Foundation',
          skillGroup: 'Technical',
		  proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites Integrations: Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    },
    {
      id: 3,
      title: 'Magento',
      skills: [
        {
          skillName: 'AEM Sites: Authoring / Foundation',
          skillGroup: 'Technical',
		  proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites Integrations: Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    },
    {
      id: 4,
      title: 'Campaign',
      skills: [
        {
          skillName: 'AEM Sites: Authoring / Foundation',
          skillGroup: 'Technical',
		  proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites Integrations: Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    },
    {
      id: 5,
      title: 'AAM',
      skills: [
        {
          skillName: 'AEM Sites: Authoring / Foundation',
          skillGroup: 'Technical',
		  proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites Integrations: Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    },
    {
      id: 6,
      title: 'Analytics',
      skills: [
        {
          skillName: 'AEM Sites: Authoring / Foundation',
          skillGroup: 'Technical',
		  proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Communities',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites Integrations: Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    }
  ];
export const _tableHeader = [
  {id:1, title:"Skill Name",property:"skillName"},
  {id:2, title:"Skill Group",property:"skillGroup"},
  {id:3, title:"Current Level",property:"CurrentLevel"},
  {id:4, title:"Submitted Level",property:"SubmittedLevel"},
  {id:5, title:"Target Level",property:"TargetLevel"},
  {id:6, title:"Submitted Date",property:"submittedDate"},
  {id:7, title:"Approved/ Rejected Date",property:"aprroveDate"},
  {id:8, title:"Status",property:"status"},
];
export const _tableHeaderForViewSkill = [
  {id:1, title:"Skill Name",property:"skillName"},
  {id:2, title:"Skill Group",property:"skillGroup"},
  {id:3, title:"Current Level",property:"CurrentLevel"},
  {id:4, title:"Submitted Level",property:"SubmittedLevel"},
  {id:5, title:"Target Level",property:"TargetLevel"},
  {id:6, title:"Submitted Date",property:"submittedDate"},
  {id:7, title:"Approved/ Rejected Date",property:"aprroveDate"},
  {id:8, title:"Status",property:"status"},
  {id:9, title:"Action"},
];
export const _optionStatus = [{
    "id": 1,
    "value": "Not Started",
    "label": "Not Started"
  }, {
    "id": 2,
    "value": "Awaiting Approval",
    "label": "Awaiting Approval"
  }, {
    "id": 4,
    "value": "Approved",
    "label": "Approved"
  }, {
    "id": 5,
    "value": "Rejected",
    "label": "Rejected",
  }];
export const _optionRoles = [{
    "id": 1,
    "value": "9ce42304-b732-4791-9731-6f299b6df8c7",
    "label": "AEM Support Engineer"
  }, {
    "id": 2,
    "value": "90419f06-7d07-45c8-bcec-d675096fe27f",
    "label": "Marketo"
  }, {
    "id": 3,
    "value": "a23521da-0a48-4ef6-baa2-d727704f65c2",
    "label": "Magento"
  }, {
    "id": 4,
    "value": "34b2b58a-0123-49e2-b2de-1eef0922139b",
    "label": "Campaign"
  }, {
    "id": 5,
    "value": "a23521da-0a48-4ef6-baa2-d727704f68c6",
    "label": "AAM"
  }, {
    "id": 6,
    "value": "34b2b58a-0123-49e2-b2de-1eef0922169d",
    "label": "Analytics"
  }]

  export const _mskDummyData = {
    "data": [
        {
            "UserRoleSkillsId": 184,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 19,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Cloud: Assets Essentials standalone",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Not Started",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 193,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 24,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Integrations: Target",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Not Started",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 192,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 23,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Integrations: Analytics",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Not Started",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 191,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 29,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Integrations: Assets Essentials & Workfront",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Not Started",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 190,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 28,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Integrations: Assets Essentials & AJO",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Awaiting Approval",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 189,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 20,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Integrations: Adobe Learning Manager",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "In Progress",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 188,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 25,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Integrations: Campaign",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "In Progress",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 187,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 21,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Integrations: Adobe I/O",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Awaiting Approval",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 186,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 17,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Cloud: Brand Portal",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Awaiting Approval",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 185,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 22,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Integrations: Adobe Launch",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Awaiting Approval",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 194,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 27,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Integrations: Workfront Connector(Enhanced)",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "In Progress",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 183,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 18,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Cloud: Dynamic Media/Scene7",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "In Progress",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 182,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 14,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Core: Screens",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "In Progress",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 181,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 13,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Core: Core Platform",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Approved",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 180,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 16,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Cloud: Cloud Manager",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Approved",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 179,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 15,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Core: Guides",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Approved",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 178,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 11,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "solution technical skills",
            "SkillName": "Customer Journey Analytics (CJA)",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Approved",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 177,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 10,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Core: Sites",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Rejected",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 176,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 12,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Core: Forms",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Rejected",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 203,
            "RoleId": 4,
            "SkillGroupId": 2,
            "SkillId": 34,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "core technical skills",
            "SkillName": "Java",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Rejected",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 212,
            "RoleId": 4,
            "SkillGroupId": 7,
            "SkillId": 43,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "professional skills",
            "SkillName": "Professional Skills: Negotiation",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Rejected",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 207,
            "RoleId": 4,
            "SkillGroupId": 7,
            "SkillId": 42,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "professional skills",
            "SkillName": "Professional Skills: Project Management",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Not Started",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 206,
            "RoleId": 4,
            "SkillGroupId": 2,
            "SkillId": 37,
            "Name": "Arun K",
            "RoleName": "AEM SUPPORT ENGINEER",
            "SkillGroupName": "core technical skills",
            "SkillName": "Kubernetes",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Not Started",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 198,
            "RoleId": 4,
            "SkillGroupId": 2,
            "SkillId": 32,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "core technical skills",
            "SkillName": "CSS",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Approved",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 197,
            "RoleId": 4,
            "SkillGroupId": 2,
            "SkillId": 35,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "core technical skills",
            "SkillName": "Splunk",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Approved",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 196,
            "RoleId": 4,
            "SkillGroupId": 5,
            "SkillId": 26,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "solution technical skills",
            "SkillName": "AEM Integrations: Experience Platform",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Rejected",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        },
        {
            "UserRoleSkillsId": 195,
            "RoleId": 4,
            "SkillGroupId": 2,
            "SkillId": 31,
            "Name": "Arun K",
            "RoleName": "Customer Journey Analytics (CJA)",
            "SkillGroupName": "core technical skills",
            "SkillName": "Java Script",
            "TargetLevel": 300,
            "SubmittedLevel": null,
            "ApprovalStatus": "Rejected",
            "RejectionReason": null,
            "SubmittedDateTime": null,
            "ApprovalDateTime": null,
            "ApprovalManager": null
        }
    ],
    "status": 200,
    "statusText": "",
    "headers": {
        "content-length": "13838",
        "content-type": "application/json"
    },
    "config": {
        "transitional": {
            "silentJSONParsing": true,
            "forcedJSONParsing": true,
            "clarifyTimeoutError": false
        },
        "adapter": [
            "xhr",
            "http"
        ],
        "transformRequest": [
            null
        ],
        "transformResponse": [
            null
        ],
        "timeout": 0,
        "xsrfCookieName": "XSRF-TOKEN",
        "xsrfHeaderName": "X-XSRF-TOKEN",
        "maxContentLength": -1,
        "maxBodyLength": -1,
        "env": {},
        "headers": {
            "Accept": "application/json, text/plain, */*",
            "Authorization": "oauth 360f363e4550c9747e417e6bdec50c68"
        },
        "method": "get",
        "url": "https://cj2ku1iiec.execute-api.us-east-1.amazonaws.com/development/getMySkillJourney"
    },
    "request": {}
  }