import React from "react";
import { Modal ,Table} from "react-bootstrap";
function ProficiencyLevelModal({
    show,
    onClose
}) {
    return (
        <Modal centered show={show} size="lg">
            <Modal.Header style={{ justifyContent: "space-between" }}>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={onClose}></button>
            </Modal.Header>
            <Modal.Body className="px-5">
                <div className="success-modal-container">
                    <div className="failure-modal-container-body my-2">
                        <div className="success-text-message2 p-0">
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>Proficiency Levels</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={1}>
                                        <td>Level <br/>100/Beginner</td>
                                        <td style={{ textAlign: "left" }}>Individual is a novice or beginner in the skill area and is currently developing their skills. Requires supervision and guidance from mentors or manager to apply skills effectively to the job.</td>
                                    </tr>
                                    <tr key={2}>
                                        <td>Level <br/>200/Intermediate</td>
                                        <td style={{ textAlign: "left" }}>Individual has some level of experience and prior knowledge and is able to apply this knowledge on the Job. Requires additional learning to build advance level skills. Can independently isolate and troubleshoot most issues without escalating or consulting.</td>
                                    </tr>
                                    <tr key={2}>
                                        <td>Level <br/>300/Expert</td>
                                        <td style={{ textAlign: "left" }}>Individual is an expert in the area and is frequently consulted on complex problems by peers and supervisors. Able to mentor others on the skill area and develop their skills. Creates content and presents to customers/peers and others as needed.</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn primary-btn primary-blue m-2" onClick={onClose}>
                    Ok
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default ProficiencyLevelModal;
