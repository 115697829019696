/* eslint-disable */
import { useRef, useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { Context } from '../../../context/context';
import './CustomAccordionApprovalPreview.css';
import { useEffect } from 'react';

function CustomAccordionApprovalPreviewItem({ data, handleProficiencyLevel, proficiencyLevelsGroup }) {
  const [collapse, setCollapse] = useState(true);
  const [contentData, setContentData] = useState(data);
 useEffect(() => {
  setContentData(data);
 },[data]);

  return (
    <div className="custom-accordion-approval-preview-item">
      <div className="custom-accordion-approval-preview-item-content">
        <table className="custom-accordion-approval-preview-tbl">
          {contentData.skills &&
            contentData.skills.map((itm, i) => {
              return (
                <tr key={i}>
                  <td width="25%">{itm.skillName}</td>
                  <td width="24%">{itm.skillGroup}</td>
                  {itm?.proficiencyLevels.map((pf,imagecontent) => {
                    return (
                      <td width="17%">
                        {pf.status !== 'completed' ? (
                          <Form.Check // prettier-ignore
                            type="radio"
                            // id={itm?.proficiencyLevels?.value}
                            name={proficiencyLevelsGroup(contentData, itm.skillName, itm.skillGroup)}
                            label={
                              <span onClick={(e) => e.stopPropagation()}>
                                {pf.label}
                              </span>
                            }
                            value={pf.label}
                            className="radio-btn checkboxOne"
                            onChange={(e) => {
                              const updatedData = {
                                "skillId": itm.skillId,
                                "groupId": itm.groupId,
                                "roleId": itm.roleId,
                                "userRoleSkillsId":itm.userRoleSkillsId,
                                "proficiencyLevels": pf,
                                "TargetLevel":itm.TargetLevel
                              }
                              handleProficiencyLevel(updatedData, e)
                            }}
                            disabled = {pf.disabled || (pf.status === 'completed' && pf.checked === null)}
                          />
                        ) : (
                          <div className='disabledRadioBtn'>
                            <span className='disabledCircle'></span>
                            <span className='disabledText'>{pf.label}</span>
                          </div>
                        )}
                      
                    </td>
                    )
                  })}
                </tr>
              )
            })}
        </table>
      </div>
    </div>
  );
}

export default CustomAccordionApprovalPreviewItem;
