import { createSlice } from "@reduxjs/toolkit";

const initialState ={
};


export const navigationSlice = createSlice({
    name:"navigation",
    initialState,
    reducers:{
        setNavigationData:(state, action)=>{
            state.navigation=action.payload
        },
    }
})

export const {setNavigationData} =navigationSlice.actions;
export default navigationSlice.reducer;