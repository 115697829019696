/* eslint-disable */
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import "react-multi-carousel/lib/styles.css";
import import_icon from "../../../assets/images/import_importcsv.png";
import { apis } from "../../../services/Api";
import ImportCSVCardUserDetail from "./ImportcsvCardUserDetail";
import {
  convertBitToMb,
  getFiscalYearDetail,
  setFiscalYearOptions
} from "../../../helper/Helper";
import { optionsQuarter } from "../../../data/dataImportCSV";
import "../../../App.css";
import "../Importcsv.css";
import FileUploadFileList from "./FileUploadFileList";
import Loader from "../../../common/Loader/Loader";
import { async } from "q";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalforSuccessAndFailure from "../../../common/Modal/Modal";
import Modal from "react-bootstrap/Modal";
const ImportCSVCard = ({
  title,
  userName,
  lastUploadDate,
  statusOfLastImport,
  isYearOptionAvailable = false,
  isQuaterlyOptionAvailable = false,
  id
}) => {
  const fiscalYearDetails = getFiscalYearDetail();
  const getQuarter = (date) => {
    return Math.floor((date.getMonth() + 3) / 3);
};
  const defaulttFY = {
    value: fiscalYearDetails.currentFiscalYear.endYear,
    label: `FY ${fiscalYearDetails.currentFiscalYear.endYear}`
  };
  const defaulttQtr = {
    value: `${getQuarter(new Date())}`,
    label: `Quarter ${getQuarter(new Date())}`
  };
  const [importStatus, setImportStatus] = useState(false);
  const [uploadDate, setUploadDate] = useState();
  const [optionFiscalYear, setOptionFiscalYear] = useState();
  const [optionFiscalQuarter, setOptionFiscalQuarter] = useState();
  const [selectedYear, setSelectedYear] = useState(defaulttFY);
  const [selectedQuarter, setSelectedQuarter] = useState(defaulttQtr);
  const [csvFile, setCsvFile] = useState();
  const [showFileUploadStatus, setShowFileUploadStatus] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [displayMessageBox, setDisplayMessageBox] = useState(false);
  const [errorstate,setErrorstate] = useState(false);
  const [showInfoModal,setShowInfoModal] = useState(false);
  const [presgnUrl,setPresgnUrl]= useState("")
 
  // const [userName, setUserName] = useState([])
  // const [lastUploadDate,setLastUploadDate] = useState([]);
  // const[statusOfLastImport, setStatusOfLastImport] = useState([])
  
  const [showStatus,setShowStatus] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [messageforPopup, setMessageforPopup] = useState("");
  const [messageforInfo, setMessageforInfo] = useState("");

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      fontSize: state.selectProps.myFontSize
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize
    })
  };
 
  const setPresignedUrl = async () => {
    let result;
    switch (id) {
      case "navigationCsvUploadsTest":
        result = await apis.getNavigationCsvUploadsTest();
        localStorage.removeItem("NavMenuData");
          break;
      case "catalogCsvUploads":
        result = await apis.getCatalogPresignedUrl();
        localStorage.removeItem("NavMenuData");
        break;
      case "csvUploads":
        result = await apis.getCertificationPresignedUrl(selectedYear.value, selectedQuarter.value);   
        break;
      case "enablementCsvUploads": 
        result = await apis.getSioEnablementPresignedUrl();
        break;
      case "masterSkillsCsvUploads":
        result = await apis.getMasterSkillPresignedUrl();
        break;
      case "customUsersCsvUploads":
        result = await apis.getCustomUserPresignedUrl();
        break;
      case "usrEnrlmntCsvUploads":
        result = await apis.getUserRoleEnrollmentPresignedUrl();
        break;
      case "usrUnEnrlmntCsvUploads":
        result = await apis.getUserRoleUnEnrollmentPresignedUrl();
          break;    
      default:
        break;
    }

    let res = result?.data?.presigned_url;
    return res;
  };

  useEffect(() => {
    const fiscalYearDetails = getFiscalYearDetail().currentFiscalYear.fullDate;
    setUploadDate(fiscalYearDetails);
    setOptionFiscalYear(setFiscalYearOptions());
    setOptionFiscalQuarter([...optionsQuarter]);
    setShowFileUploadStatus(false); 
  }, []);
  const handleYearChange = (selectedOption) => {
    setImportStatus(false);
    setSelectedYear(selectedOption);
    setShowFileUploadStatus(false);
  };
  const handleQuarterChange = (selectedOption) => {
    setImportStatus(false);
    setSelectedQuarter(selectedOption);
    setShowFileUploadStatus(false);
  };

  const handleChange = (e) => {
    setShowFileUploadStatus(false);
   
    if (e.currentTarget.files) {
      setCsvFile(e.currentTarget.files[0]);
    }
  };

  const showInfoModalClose = (e) => {
    setShowInfoModal(false);
    setMessageforInfo("")
  };

  const showInfoModalOpen = (msg) => {
    setShowInfoModal(true);
    setMessageforInfo(msg)
  };

  const fetchData = async (presignedResult) => {
    var requestOptions = {
      method: "PUT",
      headers: { "Content-Type": `text/csv` },
      body: csvFile,
      redirect: "follow"
    };
    fetch(presignedResult, requestOptions)
      .then((response) => {
        setDisplayMessageBox(true);
        if (response?.url) {
          setShowLoader(false);
          setErrorstate(false)
        } 
        return response.text();
      })
      .catch((error) => {
        setShowLoader(false);
        setErrorstate(true)
        setDisplayMessageBox(true);
      });
    setTimeout(() => {
      setDisplayMessageBox(false);
    }, 2000);
    setImportStatus(true);
    setShowFileUploadStatus(true);
    
  };

  const handleFileUpdate = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const presignedResult = await setPresignedUrl();
    if(presignedResult){
      setErrorstate(false)
      if(showStatus === false){
      setMessageforPopup("Thank you for uploading, the import will start soon! \n Please refresh the page to see the changes, If it does not auto refresh.");
      setShowModal(true);
    }else{
      setMessageforPopup("File uploaded already.");
      setShowModal(true);
    }
    setShowStatus(true);
    }else{
      setErrorstate(true);
      setMessageforPopup("Failed to upload file!");
      setShowModal(true);    
    }
    fetchData(presignedResult);
  };

  const csvDownloadLink = async(e)=>{
    e.preventDefault()
    const res = await apis.getPresignedUrlToDownloadCsv(e.target.id);
    if(!res){
      setMessageforPopup("Failed to download file!");
      setShowModal(true);
  }
  else{
    window.open(res.PresignedUrl,"_self")
    setPresgnUrl(res.PresignedUrl)
    setMessageforPopup("File downloaded successfully.");
    setShowModal(true);
  }
  }
  const reload=()=>window.location.reload();
  const handleCloseModal = () => {
    setShowModal(false);
    reload();
  };
  
  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-12 my-4 pe-3">
        <div className="card">
          <div className="card-body">
            <h6 className="card-title d-flex align-items-center justify-content-between">
              <div className="card-title-content">{title}</div>
              {isYearOptionAvailable === true ? (
                <div className="card-right-content">
                  <div className="dropdowns d-flex align-items-center">
                    <div className="dropdown me-2">
                      <Select
                        myFontSize="14px"
                        styles={styles}
                        options={optionFiscalYear}
                        onChange={handleYearChange}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        defaultValue={defaulttFY}
                      />
                    </div>
                    <div className="dropdown">
                      <Select
                        myFontSize="14px"
                        styles={styles}
                        options={optionFiscalQuarter}
                        onChange={handleQuarterChange}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        defaultValue={optionsQuarter[getQuarter(new Date())-1]}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </h6>
            <div className="alm-file-upload-conatainer-content">
              <Form.Group controlId="formFileMultiple2" className="alm-file-upload-content d-flex align-items-center justify-content-center">
              <span className="dropDownIcon alm-file-upload-icon">
                <a href="" download={presgnUrl} target="_self">
                  <img src={import_icon} alt="" id={id} onClick={(e)=>csvDownloadLink(e)}></img></a>
                  </span>
                <Form.Control
                  onChange={(e) => handleChange(e)}
                  type="file"
                  // multiple
                  className="alm-file-upload-input"
                  accept=".csv"
                  id={id}
                />
              </Form.Group>
              {!showFileUploadStatus && !csvFile?.name ? null : (
                <FileUploadFileList
                  fileName={csvFile?.name}
                  fileSize={convertBitToMb(csvFile?.size)}
                  className="alm-file-upload-file-list"
                />
              )}
            </div>
          </div>
          <div className="card-footer text-body-secondary d-flex align-items-center justify-content-between otherText ">
            <ImportCSVCardUserDetail 
              lastUploadDate={lastUploadDate}
              userName={userName}
              statusOfLastImport={statusOfLastImport}
              importStatus={importStatus}
             showFileUploadStatus={showFileUploadStatus}             
              csvFileName={csvFile}
              error={errorstate}
              id={id}
              flag={true}
              showInfoModalOpen={showInfoModalOpen}
            />
           <div>
              <button
                className="btn primary-btn my-2"
                onClick={(e) => handleFileUpdate(e)}
                disabled={!csvFile}>
                Update
              </button>
              <ToastContainer />
            </div>
          </div>
          {showLoader ? (
            <div className="show-loader">
              <Loader />
            </div>
          ) : null}
        </div>
      </div>
      {/* <div className="modal-container-skills"> */}
                <Modal show={showInfoModal} centered  onHide={showInfoModalClose}  size="lg">
                    <Modal.Header closeButton>
                    <Modal.Title className="infoHeader">{title} - Import</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="infoPopup" >
                        <div className="infoPopup-body">
                            <div className="justify-content-start px-2 py-2">
                              <div dangerouslySetInnerHTML={{ __html: messageforInfo }}></div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div >
                            <button
                                className="btn primary-btn primary-blue m-2"
                                onClick={() => showInfoModalClose()}>
                                Ok
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
        {/* </div> */}
      <ModalforSuccessAndFailure messageforPopup={messageforPopup} show={showModal} onClose={handleCloseModal} />
    </>
  );
};

export default ImportCSVCard;
