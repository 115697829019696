import React, { useEffect, useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import FileUploadFileList from "../FileUploadedFileList";
import Loader from "../../../common/Loader/Loader";
import ModalforSuccessAndFailure from "../../../common/Modal/Modal";
import { apis } from "../../../services/Api";
import info from "../../../assets/images/info.svg";
import user_icon from "../../../assets/images/user_importcsv.png";
import "../Templates.css";
import Modal from "react-bootstrap/Modal";
import parse from "html-react-parser";
import calendar_icon from "../../../assets/images/calendar_importcsv.png";

import { appContext } from "../../../context/appContext";
import { buildNestedDataGroupedByTemplateId } from "../../../utils/dataTable";
// import ImportCSVCardUserDetail from "../../../component/ImportCsv/ImportcsvCard/ImportcsvCardUserDetail";


const NewTemplate = () => {
    const { dispatch } = useContext(appContext);
    const [csvFile, setCsvFile] = useState();
    // const [templateData,setTemplateData]=useState(null);
    const [showFileUploadStatus, setShowFileUploadStatus] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [messageforFailure, setMessageforFailure] = useState("");
    const [fileValidation, setfileValidation] = useState("");
    const [fileName, setFileName] = useState("");
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [messageforInfo, setMessageforInfo] = useState("");
    console.log(messageforInfo);
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorMsg, setErrorMsg] = useState([]);
    const [statusOfLastImport, setStatusOfLastImport] = useState(null)
    // const [errorStatus, setErrorStatus] = useState(error);
    const [rowNumber, setRowNumber] = useState([]);
    console.log({ errorMsg, rowNumber });
    useEffect(() => {
        setShowFileUploadStatus(false);
    }, []);


    const handleChange = e => {
        setShowFileUploadStatus(false);
        if (e.currentTarget.files) {
            const file = e.currentTarget.files[0];
            const fileExtension = file.name.split(".").pop().toLowerCase();

            // Specific filename pattern
            const filenamePattern = /^Template_\d+$/;

            if (fileExtension === "csv") {
                const filenameWithoutExtension = file.name.replace(/\.csv$/i, "");
                if (filenamePattern.test(filenameWithoutExtension)) {
                    setCsvFile(file);
                    setfileValidation("");

                    // Get file name
                    setFileName(filenameWithoutExtension);
                } else {
                    setCsvFile(null);
                    setfileValidation("Invalid file name.");
                    // setShowModal(true);
                }
            } else {
                setCsvFile(null);
                setfileValidation("Only .csv files are allowed.");
                // setShowModal(true);
            }
        }

    }
    const setPresignedUrl = async () => {
        let result;
        result = await apis.getTemplate(fileName);
        localStorage.removeItem("NavMenuData");

        if (!result?.data?.presigned_url) {
            return result?.data;
        }
        return result?.data?.presigned_url;
    }

    const handleFileUpdate = async e => {
        e.preventDefault();
        setShowLoader(true);
        try {
            const presignedResult = await setPresignedUrl();

            if (presignedResult && !presignedResult?.message) {
                if (showStatus === false) {
                    setMessageforPopup(
                        <>
                            Thank you for uploading, the import will start soon!<br />
                            Please refresh to see the changes, if it does not auto refresh.
                        </>
                    );
                    setShowModal(true);
                } else {
                    setMessageforFailure("File uploaded already.");
                    setShowModal(true);
                }
                setShowStatus(true);
            } else {
                const failureMessage = presignedResult?.message === "TemplateExisting_CannotBeAdded"
                    ? "Unable to add: A template with this name already exists."
                    : presignedResult?.message;
                setMessageforFailure(failureMessage);
                setShowModal(true);
            }
            fetchData(presignedResult);
            getStatusOfLastImport();
        } catch (error) {
            console.log("error while fetching presignedURL for page Template", error);
        }
    };



    const fetchData = async presignedResult => {
        var requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "text/csv" },
            body: csvFile,
            redirect: "follow"
        };
        fetch(presignedResult, requestOptions)
            .then(response => {
                if (response?.url) {
                    setShowLoader(false);
                }
                return response.text();
            })
            .catch(error => {
                console.error(error)
                setShowLoader(false);
            });
        setTimeout(() => {
        }, 2000);
        setShowFileUploadStatus(true);
    };
    const reload = () => {        
        window.location.reload(); // page reloads        
    };


    const handleCloseModal = () => {
        setShowModal(false);
        reload();
    };

    const getStatusOfLastImport = async () => {
        try {
            const response = await apis.getStatusOfFile();
            if (response?.status == 200) {
                const templateData = response?.data?.statusOfImportCsv;
                const finalData = templateData.find(ele => ele?.ImportType == "pageTemplateCsvUploads");
                if (finalData.responseMessage.statusCode == 400) {
                    const { body: { error } } = finalData.responseMessage;
                    setErrorMessage(error)
                }
                console.log("finalData in add", finalData);
                if ((finalData.status === "add_Passed" || finalData.status === "update_Passed")) {
                    let templateResponse = { JSONData: [] };
                    localStorage.setItem("TemplateJSONData", JSON.stringify(templateResponse));
                    dispatch({ type: "templateJSONData", payload: templateResponse });
                    console.log(
                        "get Template from localstorage",
                        JSON.parse(localStorage.getItem("TemplateJSONData"))
                    );
                    const flatData = await apis.getNavigationFlatData();
                    console.log("updated flatData in update", flatData);
                    if (flatData) {
                        // console.log("flatData >>>", flatData.message)
                        templateResponse = await buildNestedDataGroupedByTemplateId(flatData);
                        // Ensure the data is in the correct format
                        const convertedData = { JSONData: templateResponse };
                        localStorage.setItem("TemplateJSONData", JSON.stringify(convertedData));
                        dispatch({ type: "templateJSONData", payload: templateResponse.JSONData });
                    }
                }
                setStatusOfLastImport(finalData);
            }
            // Update the state with the status message
        } catch (error) {
            console.error("Error fetching status:", error);
            setStatusOfLastImport(null);
        }
    };
    console.log(statusOfLastImport?.status, "statusOfLastImport");
    // Fetch the status message when the component mounts
    useEffect(() => {
        getStatusOfLastImport();
    }, []);
    const showInfoModalOpen = msg => {
        setShowInfoModal(true);
        setMessageforInfo(msg)
    };
    const showInfoModalClose = () => {
        setShowInfoModal(false);
        setMessageforInfo("")
    };
    const handleInforToggle = () => {
        let rowNumbers = [];
        let apiError = false;
        let templateError = [];
        if (Array.isArray(errorMessage)) {
            errorMessage?.forEach(ele => {
                if (Array.isArray(ele)) {
                    let rowError = {
                        rowNumbers: ele.rowNumbers,
                        err: ele.err
                    };
                    apiError = false;
                    templateError.push(rowError);
                }
                setErrorMsg(templateError);
            })
        } else {
            let rowError = {
                rowNumbers: 0,
                err: [{ msg: errorMessage, colName: "" }]
            };
            templateError.push(rowError);
            setErrorMsg(templateError);
        }
        if (rowNumbers.length > 0) {
            let Text = "";
            if (apiError)
                showInfoModalOpen(rowNumbers)
            else
                showInfoModalOpen(Text)
        } else {
            showInfoModalOpen()
        }
        setRowNumber(rowNumbers);
    }
    console.log(errorMessage, "setErrorMsg123");

    const formatErrors = errors => {
        const errorMap = new Map();
        errors.forEach(ele => {
            ele?.err?.forEach(text => {
                const key = text?.msg;
                if (errorMap.has(key)) {
                    if (ele?.rowNumbers) {
                        errorMap.get(key).push(ele?.rowNumbers);
                    }
                } else {
                    errorMap.set(key, ele?.rowNumbers ? [ele?.rowNumbers] : []);
                }
            });
        });
        return Array.from(errorMap.entries()).map(([msg, rows]) => ({
            msg,
            rows,
        }));
    };

    const formatDate = dateString => {
        if (!dateString) {
            return "No Date";
        }

        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            return "Invalid Date";
        }

        const options = { year: "numeric", month: "short", day: "numeric" };
        const formattedDate = date.toLocaleDateString("en-US", options);

        // Adding "th", "st", "nd", "rd" to the day
        const day = date.getDate();
        let daySuffix = "th"; // Default to "th"

        if (day % 10 === 1 && day !== 11) {
            daySuffix = "st";
        } else if (day % 10 === 2 && day !== 12) {
            daySuffix = "nd";
        } else if (day % 10 === 3 && day !== 13) {
            daySuffix = "rd";
        }


        return formattedDate.replace(/(\d+)(?=,)/, `${day}${daySuffix}`);

        
    };

    // Return starts below

    return (
        <>
            <div className="manageTemplates">
                <div className="card1">
                    <div className="card">
                        <div className="card-body">
                            <div className="alm-file-upload-conatainer-content">
                                <Form.Group controlId="formFileMultiple2" className="alm-file-upload-content d-flex align-items-center justify-content-center">
                                    <Form.Control
                                        onChange={e => handleChange(e)}
                                        type="file"
                                        className="alm-file-upload-input"
                                        accept=".csv" />
                                </Form.Group>

                                {!showFileUploadStatus && !csvFile?.name ? null : (
                                    <FileUploadFileList

                                        fileName={csvFile?.name}
                                        className="alm-file-upload-file-list imgTag"

                                    />
                                )}
                                <div className="fileValidation">{fileValidation}</div>
                            </div>
                            <div>
                                <div className="statusText me-2">
                                    {(statusOfLastImport?.status === "add_Failed" || statusOfLastImport?.status === "add_Passed") &&
                                        <p className="m-0 font-bold">
                                            Status of Last Import for {
                                                (() => {
                                                    try {
                                                        // Attempt to parse the responseMessage as JSON
                                                        const parsedResponse = JSON.parse(statusOfLastImport?.responseMessage || "{}");
                                                        return parsedResponse.templateId || statusOfLastImport?.responseMessage?.templateId;
                                                    } catch (e) {
                                                        // If parsing fails, assume it's already the template ID
                                                        return statusOfLastImport?.responseMessage?.templateId;
                                                    }
                                                })()
                                            } :
                                            <span className={statusOfLastImport?.status === "add_Failed" ? "failedMessage" : (statusOfLastImport?.status === "add_Passed" ? "successMessage" : "")}>

                                                {" "}
                                                {statusOfLastImport?.status === "add_Failed" ? statusOfLastImport?.status?.split("_")[1] : (statusOfLastImport?.status === "add_Passed" ? statusOfLastImport?.status?.split("_")[1] : "")}
                                                { }
                                            </span>
                                            {statusOfLastImport?.status === "add_Failed" && (
                                                <button onClick={() => handleInforToggle()} className="button-tooltip mx-2">
                                                    <img src={info} alt="info" />
                                                </button>
                                            )}
                                        </p>}


                                </div>
                                <div className="infoSection mt-2 font-bold">
                                    <div className="right-barNewTemplate me-3">
                                        <span className="me-2 timeStamp">
                                            <img src={calendar_icon} alt=""></img>
                                        </span>
                                        {statusOfLastImport?.UploadedOn ? formatDate(statusOfLastImport.UploadedOn) : "No Date"}
                                    </div>
                                    <div className="ms-2">
                                        <span className="me-2 timeStamp">
                                            <img src={user_icon} alt=""></img>
                                        </span>
                                        {statusOfLastImport?.UploadedBy}
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="card-footer text-body-secondary d-flex align-items-center justify-content-between otherText">

                            <div className="m-auto footerButton">
                                <button
                                    className="btn primary-btn my-2 btnColorBg"
                                    onClick={e => handleFileUpdate(e)}
                                    disabled={!csvFile}
                                >Import
                                </button>
                            </div>


                        </div>

                        {showLoader ? (
                            <div className="show-loader">
                                <Loader />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            <Modal show={showInfoModal} centered onHide={showInfoModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="infoHeader">Status</Modal.Title>
                </Modal.Header>
                <Modal.Body className="infoPopup" >
                    <div className="infoPopup-body">
                        <div className="justify-content-start px-2 py-2">
                            {Array.isArray(errorMessage) && errorMessage.length >= 1 && (
                                <div>
                                    <p>Following row(s) were not able to insert due to error:</p>
                                    <ul>
                                        {formatErrors(errorMessage).map((error, index) => (
                                            <li key={index}>
                                                {error.rows.length > 0 && `Row no: ${error.rows.join(", ")} : `}
                                                {parse(error.msg)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {errorMsg?.length >= 1 && (
                                <div>
                                    <p>Following row(s) were not able to insert due to error:</p>
                                    {errorMsg.map((ele, index) => (
                                        <div key={index}>
                                            {parse(ele.err?.map(text => text?.msg).join(""))}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div >
                        <button
                            className="btn primary-btn m-2"
                            onClick={() => showInfoModalClose()}>
                            Ok
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <ModalforSuccessAndFailure show={showModal} onClose={handleCloseModal} messageForFailure={messageforFailure} messageforPopup={messageforPopup} />
        </>
    );
};

export default NewTemplate;
