import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EnablementCard from "../../common/EnablementCard";
import email from "../../assets/images/email.svg";
import "./enablementTeam.css";
import submiticon from "../../assets/images/submit.svg";
import { useTranslation } from "react-i18next";
import "./enablementTeam.css";
import { apis } from "../../services/Api";
import parse from "html-react-parser";
import Breadcrumbs from "../../common/Breadcrumbs";
import { appContext } from "../../context/appContext";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";
import Scroller from "../../common/Scroller/Scroller";

const index = () => {
    const { editorDataforPreview } = useContext(appContext);

    const [previewData, setPreviewData] = useState("");

    const { dispatch } = useContext(appContext);

    const [showModal, setShowModal] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [warningPopup, setWarningPopup] = useState("");

    const { t } = useTranslation();
    const navigate = useNavigate();

    const url = "https://adoberm.my.workfront.com/requests/new?activeTab=tab-new-helpRequest&projectID=65eb7fee001d4663d7758070d055220e&path=65eb892b002054fe19cde91e5b919bc0";
    const crumbs = [
        { label: "Home", url: "/" },
        { label: "SIO Enablement Team", url: "/sioenablementteam" }
    ];
    let submitEnablement = () => {
        window.open(url, "_blank");
    };

    const [enablementTeamData, setenablementTeamData] = useState([]);
    const [editorData, setEditorData] = useState("");

    let getSIOEnablementStaticdata = async () => {
        await apis
            .getSIOEnablementStaticData()
            .then((e) => {
                if (e.data.staticData[0].enablementStaticData) {
                    setEditorData(e.data.staticData[0].enablementStaticData);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleUpdateEditor = async () => {
        if (previewData) {
            const result = await apis.updatSIOEnablementTeam(previewData);
            // const msg = result.message;
            if (!result) {
                setWarningPopup("User is not an admin.");
                setShowModal(true);
            } else {
                setMessageforPopup("SIO team data updated successfully.");
                setShowModal(true);
                setTimeout(() => {
                    navigate("/updatesioenablementdata");
                }, 4000);
            }
        }
        dispatch({ type: "editorDataforPreview", payload: "" });
    };

    const handleCancel = async () => {
        dispatch({ type: "cancelData", payload: previewData });
        navigate("/updatesioenablementdata", { state: previewData });
        dispatch({ type: "editorDataforPreview", payload: "" });
    };

    useEffect(() => {
        getSIOEnablementStaticdata();
    }, [editorDataforPreview]);
    const handleCloseModal = () => {
        setShowModal(false);
    };
    let getenablemenetData = async () => {
        let result = await apis.getEnablementTeamData();
        setenablementTeamData(result.enablements);
    };

    useEffect(() => {
        getenablemenetData();
    }, []);

    useEffect(() => {
        setPreviewData(editorDataforPreview);
    }, [editorDataforPreview]);

    useEffect(() => {
        return () => {
            dispatch({ type: "editorDataforPreview", payload: "" });
        }
    }, []);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4">
                <Row>
                    {" "}
                    <div className="headerImage">
                        {" "}
                        <h1>SIO Enablement Team</h1>{" "}
                    </div>{" "}
                </Row>
            </Container>
            {editorDataforPreview !== "" ? (
                <Container fluid className="pl-30 py-4 show-for-manager">
                    <div className="d-flex justify-content-end ">
                        <Row>
                            <Col>
                                <button className="btn primary-btn" onClick={ handleCancel}>
                                    {t("cancel")}
                                </button>
                            </Col>
                            <Col>
                                <button className="btn primary-btn primary-blue" onClick={handleUpdateEditor}>
                                    {t("update")}
                                </button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            ) : (
                <></>
            )}
            <Container fluid className="pl-30 py-4">
                <Row className="soienablementteam">
                    {/* <div className="staticData">{parse(`${editorData}`)}</div> */}
                    {editorDataforPreview !== "" ? (
                        <p>{parse(`${previewData}`)}</p>
                    ) : (
                        <div className="staticData">{parse(`${editorData}`)}</div>
                    )}

                    <div className="enablement-inner card_box my-5">
                        <Container fluid responsive={responsive}>
                            <Row>
                                <p className="main-head"> {t("meet-the-enablement-team")}</p>
                                <div className="col-xl-10">
                                    <div className="row">
                                        {enablementTeamData?.map((item) => (
                                            <Col className="col-xl-2 col-lg-3 col-sm-4  col-6" key={item.id}>
                                                <EnablementCard {...item} />
                                            </Col>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-xl-2 reachout">
                                    <div className="reachout-inner">
                                        <div className="mail text-center">
                                            <img src={email} className="img-fluid d-block mx-auto" alt=""></img>
                                            <p className="m-0 desc-text">Reach Out To Us</p>
                                            <a href="mailto:dx-sio-enablement@adobe.com" className="btn btn-link btn-view fontSize14Text filterEffect" target="_blank" rel="noreferrer">
                                            dx-sio-enablement@adobe.com
                                            </a>
                                        </div>
                                        <div className="dotted-border"></div>
                                        <div className="submit-file text-center">
                                            <img src={submiticon} className="img-fluid d-block mx-auto" alt="" />
                                            <p className="mt-2 desc-text">Submit Enablement Intake Form</p>
                                            <button className="btn primary-btn my-2" onClick={submitEnablement}>
                                                {" "}
                                                {t("enablement-team.click-here")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </Row>
                <ModalforSuccessAndFailure
                    messageforPopup={messageforPopup}
                    warningPopup={warningPopup}
                    show={showModal}
                    onClose={handleCloseModal}
                />
            </Container>
            <Scroller  />
        </>
    );
};
export default index;
