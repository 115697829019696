import { isEmpty } from "lodash";

const CheckBoxTooltip = ({ index, message }) => {
    return (
        !isEmpty(message) && (
            <div id={`custom-tooltip${index}`} className="custom-tooltip">
                {message}
            </div>
        )
    );
};

export default CheckBoxTooltip;
