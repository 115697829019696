import { createSlice } from "@reduxjs/toolkit";

const initialState ={
};


export const userProfileSlice =createSlice({
    name:"userProfile",
    initialState,
    reducers:{
        setProfile:(state, action)=>{
            state.userProfile=action.payload
        },
    }
})

export const {setProfile} =userProfileSlice.actions;
export default userProfileSlice.reducer;