import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import calendar_icon from "../../assets/images/calendar_importcsv.png";
import "./EditVpTemplate.css";
import moment from "moment";
import { apis } from "../../services/Api";
import Breadcrumbs from "../../common/Breadcrumbs";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";

const EditVPEmailId = () => {
    const { t } = useTranslation();
    // let date = new Date();
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;

    const [error, setError] = useState(null);
    const [fieldError, setValueError] = useState(null);
    const [userIdError, setUserIdError] = useState(null);
    const [email, setEmail] = useState([]);
    const [field, setField] = useState("");
    const [userGroupid, setUserGroupId] = useState([]);
    const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [messageForFailure, setMessageForFailure] = useState("");
    const crumbs = [
        { label: "Home", url: "/" },
        { label: "Edit VP Email ID", url: "/editvpemailid" }
    ];

    const handleEmailChange = (e) => {
        e.preventDefault();
        let val = e.target.value;
        setEmail(val);
    };
    const handleFieldChange = (e) => {
        e.preventDefault();
        let val = e.target.value;
        setField(val);
    };
    const handleVpUserIdChange = (e) => {
        e.preventDefault();
        let val = e.target.value;
        setUserGroupId(val);
    }

    const getVPEmailId = async () => {
        let result = await apis.getVPEmailId();
        const len = result.ListOfVpEmails.length;
        setEmail(result?.ListOfVpEmails[len - 1]?.FieldValue);
        setField(result?.ListOfVpEmails[len - 1]?.FieldName);
        setLastUpdatedDate(result?.ListOfVpEmails[len - 1]?.UpdatedOn);
        setUserGroupId(result?.ListOfVpEmails[len-1]?.VPUserGroupIds);
        
    };

    useEffect(() => {
        getVPEmailId();
    }, []);

    // useEffect(() => {

    // },[lastUpdatedDate])

    const updateEmailId = async () => {
        if (email.length !== 0) {
            setIsValid(false);
            await apis.updateVPEmailId(email, field,userGroupid);
            setMessageforPopup("Email(s) updated successfully.")
            setMessageForFailure("");
            setShowModal(true);
            return [email, field,userGroupid];
        } else {
            setIsValid(true);
            setMessageForFailure("Please enter a valid Email Id.")
            setMessageforPopup("");
            setShowModal(true);
        }
    };

    const validateEmail = () => {
        if (email && email.length) {
            const emailList = email.split(",");

            for (const email of emailList) {
                const trimmedEmail = email.trim();
                if (trimmedEmail !== "") {
                    if (emailRegex.test(trimmedEmail)) {
                        setIsValid(false);
                        setError(null);
                    } else {
                        setIsValid(true);
                        setError("Please enter a valid email address");
                    }
                }
            }
        }
    };

    const validateEmailValue = () => {
        if (field !== "") {
            setIsValid(false);
            setValueError(null);
        } else {
            setIsValid(true);
            setValueError("Field value cannot be empty");
        }
    };
    const validateUserGroupId = () => {
        if (userGroupid && userGroupid.length){
            setIsValid(false);
            setUserIdError(null);
        }else{
            setIsValid(true);
            setUserIdError("User group id cannot be empty")
        }
    }

    useEffect(() => {
        validateEmail();
        validateEmailValue();
        validateUserGroupId();
    }, [email, field,userGroupid]);

    const handleCloseModal = () => {
        setShowModal(false);
    };
    

    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4 emailvptemplate">
                <Row>
                    <div className="headerImage">
                        <h1>{t("edit-vp-email-id")}</h1>
                    </div>
                </Row>

                <div className="d-flex justify-content-center flex-row centercard mt-3">
                    <div className="col-md-5 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <span className="textSubject">Update VP Email ID</span>
                                <div className="subject-line-container">
                                    <textarea
                                        
                                        id="subjectInput"
                                        className="form-control subject-line-input scroller"
                                        value={email}
                                        name="email"
                                        onChange={handleEmailChange}
                                        required
                                    />
                                    <div className="error-color">{error}</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <span className="textSubject">Update VP value</span>
                                {/* <div className="subject-line-container"> */}
                                <input
                                    type="text"
                                    id="subjectInput"
                                    className="form-control subject-line-input"
                                   
                                    name="field"
                                    defaultValue={field}
                                    
                                    onChange={handleFieldChange}
                                    required
                                />
                                <div className="error-color">{fieldError}</div>                              
                            </div>
                            <div className="card-body mb-2">
                                <span className="textSubject">Update User Group IDs</span>
                                <div className="subject-line-container">
                                    <textarea
                                        
                                        id="subjectInput"
                                        type="number"
                                        className="form-control subject-line-input scroller"
                                        value={userGroupid}
                                        name="userGroupId"
                                        onChange={handleVpUserIdChange}
                                        required
                                    />
                                    <div className="error-color">{userIdError}</div>
                                </div>
                            </div>

                            <div className="card-footer text-body-secondary d-flex align-items-center justify-content-between otherText">
                                <div>
                                    <span className="me-2">
                                        <img src={calendar_icon} alt=""></img>
                                    </span>
                                    <span>{moment(lastUpdatedDate).format("MM/DD/YYYY")}</span>
                                </div>
                                <div>
                                    <button
                                        className="btn primary-btn my-2"
                                        type="button"
                                        onClick={updateEmailId}
                                        disabled={isValid}>
                    Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalforSuccessAndFailure messageforPopup={messageforPopup} show={showModal} onClose={handleCloseModal} messageForFailure={messageForFailure}/>
            </Container>
        </>
    );
};

export default EditVPEmailId;
