import { createSlice } from "@reduxjs/toolkit";
import { apis } from "../../../services/Api";

const initialState = {
    isPending: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    existingTemplateList : []
}

export const existingTemplateSlice = createSlice({
    name: "manageTemplate",
    initialState,
    extraReducers(builder) {
        builder
            .addCase(apis.getManageTemplateList.pending, (state) => {
                return {
                    ...state,
                    isPending: true
                };
            })
            .addCase(apis.getManageTemplateList.fulfilled, (state, action) => {
                const list = Object.values(action?.payload);

                return {
                    ...state,
                    isPending: false,
                    isSuccess: true,
                    existingTemplateList: list
                };
            })
            .addCase(apis.getManageTemplateList.rejected, (state, action) => {
                return {
                    ...state,
                    isPending: false,
                    isError: true,
                    errorMessage: action.error.message
                };
            })
    }
})

export default existingTemplateSlice.reducer;