// import React, { useContext, useEffect, useState } from "react";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./dashboard.css";
import MyCalendar from "../../component/Calendar/MyCalendar";
import { apis } from "../../services/Api";
import { useTranslation } from "react-i18next";
// import { appContext } from "../../context/appContext";
import Recommendation from "../../component/Recommendations";
import CertificationWallOfFame from "../../component/CertificationWallOfFame";
import MyProfile from "../../component/MyProfile";
import SIOEnablementTeam from "../../component/SIOEnablementTeam";
import MyLearning from "../../component/MyLearning";
import Scroller from "../../common/Scroller/Scroller";

function Dashboard(props) {
    const { t } = useTranslation();
    const [CertificationWallOfFameData, setCertificationWallOfFameData] = useState([]);
    const [enablementData, setEnablementData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentQtr, setCurrentQtr] = useState();
    const [currentYr, setCurrentYr] = useState();
    let flag = true;

    const getQuarter = (date) => {
        if (!(date instanceof Date)) {
            throw new Error("Invalid date");
        }
        let month = date.getMonth() + 1;
        if (month === 12 || (month >= 0 && month < 3)) {
            return 1;
        } else if (month >= 3 && month < 6) {
            return 2;
        } else if (month >= 6 && month < 9) {
            return 3;
        } else {
            return 4;
        }
    };
    let getcertificationData = async () => {
        let quaterlydata = await apis.certificationQuaterlyData();
        let quaterlyData2 = quaterlydata?.certificates;
        let currentDate = new Date();
        const month = Number(currentDate.getMonth()) + 1;
        let currentYear = currentDate.getFullYear();
        if (month === 12) {
            currentYear += 1;
        }
        let currentQuarter = getQuarter(currentDate);
        let currentQuaterYearData = [];

        while (currentQuaterYearData?.length === 0) {
            // Check current quarter data
            quaterlyData2?.forEach((currentData) => {
                if (
                    currentData.Qtr === currentQuarter.toString() &&
                    currentData.Year === currentYear.toString()
                ) {
                    currentQuaterYearData.push(currentData);
                }

            });
            if (!quaterlyData2) {
                break;
            }
            // If no data found for the current quarter, switch to the previous quarter
            if (currentQuaterYearData.length === 0) {
                currentQuarter -= 1;
                if (currentQuarter === 0) {
                    currentQuarter = 4;
                    currentYear -= 1;
                }
            }
        }
        setCertificationWallOfFameData(currentQuaterYearData);
        setCurrentYr(currentYear);
        setCurrentQtr(currentQuarter);
        setLoading(false);
    };

    // pervious code  -

    // let getcertificationData = async () => {
    //     let quaterlydata = await apis.certificationQuaterlyData();
    //     let quaterlyData2 = quaterlydata?.certificates;
    //     let currentDate = new Date();
    //     const month = Number(currentDate.getMonth()) + 1;
    //     let currentYear = currentDate.getFullYear();
    //     if(month === 12)
    //         currentYear += 1;
    //     let currentQuarter = getQuarter(currentDate);
    //     let currentQuaterYearData = []
    //     quaterlyData2?.forEach((currentData)=>{
    //         if(currentData.Qtr === currentQuarter.toString() && currentData.Year === currentYear.toString()){
    //             currentQuaterYearData.push(currentData)
    //         }
    //     })
    //     if(currentQuaterYearData.length === 0){
    //         let priviousQuarter = 4;
    //         let priviousQuarterYear = currentYear;
    //         if(currentQuarter > 1){
    //             priviousQuarter = currentQuarter - 1;
    //         }
    //         if(month === 12)
    //             priviousQuarterYear -= 1;
    //         currentQuarter = priviousQuarter;
    //         currentYear = priviousQuarterYear;
    //         quaterlyData2?.forEach((currentData)=>{
    //             if(currentData.Qtr === currentQuarter.toString() && currentData.Year === currentYear.toString()){
    //                 currentQuaterYearData.push(currentData)
    //             }
    //         })
    //     }
    //     setCertificationWallOfFameData(currentQuaterYearData);
    //     setCurrentYr(currentYear);
    //     setCurrentQtr(currentQuarter);
    //     setLoading(false);
    // };

    // -----------
    const getEnablementData = async () => {
        try {
            const response = await apis.getEnablementTeamData();
            const enablementData = response.enablements;
            setEnablementData(enablementData);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (CertificationWallOfFameData.length === 0) {
            getcertificationData();
        }
        if (enablementData.length === 0) {
            getEnablementData();
        }
    }, []);

    // const { dispatch } = useContext(appContext);

    // let getEnrollment = async () => {
    //     let result = await apis.getEnrollments();
    //     const enrollments = result?.data?.map((item) => {
    //         return {
    //             progressPercent: item?.attributes?.progressPercent,
    //             id: item?.relationships?.learningObject?.data?.id,
    //         };
    //     });
    //     dispatch({ type: "enrollmentItems", payload: enrollments });
    // };

    // useEffect(() => {
    //     getEnrollment();
    // }, []);

    return (
        <>
            <Container fluid className="pl-30 py-4 mb-4 mt-2">
                <div className='dashboard-user-info'>
                    <div className="left-info">
                        <MyProfile />
                        <Row className='mx-0'>
                            <MyLearning props={props} />
                        </Row>
                    </div>
                    <div className="right-info">
                        <Row className='mx-0'>
                            <MyCalendar />
                        </Row>
                    </div>
                </div>

                <Row className='mx-0'>
                    <Col sm={12}>
                        <Row>
                            <CertificationWallOfFame
                                data={CertificationWallOfFameData}
                                title={t("certification.certification-wall-of-fame")}
                                loading={loading}
                                currentQtr={currentQtr}
                                currentYr={currentYr}
                                showCertificationTitle={flag}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row className='mx-0'>
                    <Col sm={12} className='px-0'>
                        <Recommendation />
                    </Col>
                </Row>
                <Row className='mx-0'>
                    <Col sm={12}>
                        <SIOEnablementTeam
                            data={enablementData}
                            title={t("enablement-team.sio-enablement-team")}
                        />
                    </Col>
                </Row>
            </Container>
            <Scroller />
        </>
    );
}

export default Dashboard;
