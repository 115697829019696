import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Breadcrumbs from "../../common/Breadcrumbs";
import { apis } from "../../services/Api";
import Row from "react-bootstrap/Row";
import user from "../../assets/images/user_importcsv.png";
import moment from "moment";
import "./SkillSubmissionReport.css";
import calendar_icon from "../../assets/images/calendar_importcsv.png";

export default function SkillSubmissionReport() {
    const crumbs = [
        { label: "Home", url: "/" },
        { label: "Skill Submission Report", url: "/skillSubmissionReport" },
    ];

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [downloadUrl, setDownloadUrl] = useState("");
    const [getGuid, setGetGuid] = useState(null);
    const [statusMessage, setStatusMessage] = useState("");
    const [userName, setUserName] = useState("");
    const [status, setStatus] = useState("");
    const [disableButton, setDisableButton] = useState(false);

    let intervalId;

    const apiCall = async () => {
        try {
            let response = await apis?.getGuiId(fromDate, toDate);
           
            if (response?.data && response?.data?.guid) {
                setGetGuid(response?.data?.guid);
                setStatusMessage("In Progress...");
            }
            else if(response?.data && response?.data?.error){
                setDisableButton(false);
                setStatusMessage(response?.data?.error);
                
            }
            
        } catch (error) {
            console.warn(error);
        }
    };

    const handleGenerateReport = () => {
       
        setStatusMessage("")
        if (fromDate > toDate) {
            setDownloadUrl("");
            setStatusMessage("End Date must be greater than Start Date.");
        } else {
            setDisableButton(false);
            apiCall();
            setDownloadUrl("");
            setStatus("");
        }
    };

    const fetchSubmissionReportWithTimeout = async () => {
        try {
            if (getGuid) {
                let response = await apis.getSubmissionReports(getGuid);
                if (response?.Status === "Completed") {
                    setDownloadUrl(response?.downloadUrl);
                    setUserName(response?.UserName);
                    setStatus(response?.Status);
                    clearInterval(intervalId);
                    setDisableButton(false);
                }
            }
        } catch (error) {
            console.warn(error);
        }
    };

    useEffect(() => {
        let intervalCount = 0;

        const checkStatus = async () => {
            if (intervalCount > 60) {
                clearInterval(intervalId);
                setStatusMessage("Report generation timed out");
                return;
            }

            await fetchSubmissionReportWithTimeout();
            intervalCount++;
        };

        if (getGuid) {
            checkStatus();
            intervalId = setInterval(checkStatus, 5000);
        }

        return () => clearInterval(intervalId); 
    }, [getGuid]);

    const handleFetchReport = () => {
        if (downloadUrl) {
            window.location.href = downloadUrl;
        }
    };

    // Caluculating IST timezone 
    const today = new Date();
    today.setUTCHours(today.getUTCHours() + 5);
    today.setUTCMinutes(today.getUTCMinutes() + 30);
    const todayStr = today.toISOString().split("T")[0];

    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4 emailvptemplate">
                <Row>
                    <div className="headerImage">
                        <h1>Skill Submission Report</h1>
                    </div>
                </Row>
                <div className="d-flex justify-content-center flex-row centercard mt-5 skill-submission">
                    <div className="col-grid">
                        <div className="card">
                            <div className="daterow">
                                <div className="card-body">
                                    <span className="textSubject">Assigned Skill Start Date</span>
                                    <div className="subject-line-container">
                                        <input
                                            type="date"
                                            value={fromDate}
                                            onChange={(e) => {
                                                e.preventDefault()
                                                if (toDate !== "" && e.target.value > toDate) {
                                                    setStatusMessage("");
                                                }
                                                setFromDate(e.target.value);
                                            }}
                                            onKeyDown={(e) => e.preventDefault()}
                                            max={todayStr}
                                        />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <span className="textSubject">Assigned Skill End Date</span>
                                    <div className="subject-line-container">
                                        <input
                                            type="date"
                                            value={toDate}
                                            onChange={(e) => { 
                                                e.preventDefault()
                                                if (fromDate !== "" && e.target.value < fromDate) {
                                                    setStatusMessage("");
                                                }
                                                setToDate(e.target.value);
                                            }}
                                            onKeyDown={(e) => e.preventDefault()}
                                            max={todayStr}
                                            disabled={!fromDate} 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="download-msg">
                                {downloadUrl ? (
                                    <span>
                                        Report generated successfully.{" "}
                                        <button
                                            className="btn btn-link btn-view p-0 fw-bold"
                                            onClick={handleFetchReport}
                                        >
                                            Download
                                        </button>
                                    </span>
                                ) : (
                                    <span style={{ color: statusMessage === "In Progress..." ? "" : "#EB1000" }}>{statusMessage}</span>
                                )}
                            </div>

                            <div className="card-footer text-body-secondary d-flex align-items-center justify-content-between otherText">
                                <div>
                                    <span className="me-2">
                                        <img src={calendar_icon} alt=""></img>
                                    </span>
                                    <span>
                                        <b>{moment().utcOffset("+05:30").format("MMM Do YYYY")}</b>
                                    </span>{" "}
                                    {userName && (
                                        <>
                                            <span className="px-2"> | </span>
                                            <span>
                                                <img src={user} className="pe-2" alt=""></img>{" "}
                                                <b>{userName}</b>
                                            </span>
                                        </>
                                    )}{" "}
                                    {status && (
                                        <>
                                            <span className="px-2"> | </span>
                                            <span>
                                                Status: <b>{status}</b>
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <button
                                        className="btn primary-btn my-2 ms-4"
                                        type="button"
                                        onClick={handleGenerateReport}
                                        disabled={!fromDate || !toDate || disableButton}
                                    >
                                        Generate Report
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}
