/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import _, { isEmpty } from "lodash";
import scrollUp from "../../assets/images/scroll-up.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import info from "../../assets/images/info.svg";
import iconCancel from "../../assets/images/icon-cancel.png";
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import {
    _dataSS,
    _dataApprovalPreviewSS,
    _optionStatus,
    _optionLearnerSS,
    _optionRolesSS,
    _optionSkillSS,
    _tableHeaderSS
} from "../../data/dataMyTeamSkillsSubmission";
import CustomAccordionApprovalPreview from "../../component/CustomAccordion/CustomAccordionApprovalPreview/CustomAccordionApprovalPreview";
import MultiSelectAll from "../../component/multiselectall";
import CustomAccordionTwo from "../../component/CustomAccordion/CustomAccordionOpTwo/CustomAccordionTwo";
import Loader from "../../common/Loader/Loader";
import useLocalStorage from "../../hooks/useLocalStorage";
import SearchBar from "../../component/SearchBar/SearchBar";
import { apis } from "../../services/Api";
import { setMyTemSubmissionList } from "./myTeamSubmissionSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import { useContext } from "react";
import { appContext } from "../../context/appContext";
// Languages
import English from "../../i18n/English.json";
import "./MyTeamSkillsSubmission.css";
import Breadcrumbs from "../../common/Breadcrumbs";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";
import Failure_Icon from "../../assets/images/Failure_Icon.png";
import moment from "moment";
import Scroller from "../../common/Scroller/Scroller";
import { setSubmissionIsAllSelected } from "../../slices/mainSlice";
import { skillSubmissionSearch } from "../../utils/dataTable";
import ProficiencyLevelModal from "../../common/Modal/ProficienyLevelModal";

// eslint-disable-next-line no-unused-vars
function MyTeamSkillsSubmission(props) {
    const [displayMessageBox, setDisplayMessageBox] = useState(false);
    const [submitForApproval, setSubmitForApproval] = useState(false);
    const [displayMessageBoxForReject, setDisplayMessageBoxForReject] = useState(false);
    const [updatedStatus, setUpdatedStatus] = useState([]);
    const [updatedRoles, setUpdatedRoles] = useState([]);
    const [chekedLernerIds, setChekedLernerIds] = useState([]);
    const [localStorageData, setLocalStorageData] = useLocalStorage("skillSubmission", []);
    const [localStoragePrevData, setLocalStoragePrevData] = useLocalStorage(
        "skillSubmissionPrev",
        []
    );
    const [address, setAddress] = useState("");
    const [data, setdata] = useState([]);
    const [dataArray, setDataArray] = useState([]);
    const [displayMessageBoxForApproval, setDisplayMessageBoxForApproval] = useState(false);
    const [dataForApprove, setDataForApprove] = useState("");

    const { learnerList } = useSelector((state) => state.learnerList);
    const { rolesListData, skillsListData, dispatch } = useContext(appContext);

    const [myTeamSkillsApiDataObj, setMyTeamSkillsApiDataObj] = useState({});
    const [originalStatusOptionList, setOriginalStatusOptionList] = useState();
    const [originalLearnerOptionList, setOriginalLearnerOptionList] = useState();
    const [originalRolesOptionList, setOriginalRolesOptionList] = useState();
    const [originalSkillOptionList, setOriginalSkillOptionList] = useState();
    const [selectStatusOptions, setSelectStatusOptions] = useState([]);
    const [selectLearnerOptions, setSelectLearnerOptions] = useState([]);
    const [selectRolesOptions, setSelectRolesOptions] = useState([]);
    const [selectSkillOptions, setSelectSkillOptions] = useState([]);
    const [selectedStatusN, setSelectedStatusN] = useState([]);
    const [selectedLearnerN, setSelectedLearnerN] = useState([]);
    const [selectedRolesN, setSelectedRolesN] = useState([]);
    const [selectedSkillN, setSelectedSkillN] = useState([]);
    const [finalFilteredData, setFinalFilteredData] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showApplyLoader, setShowApplyLoader] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [searchData, setSearchData] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [rejectPopError, setRejectPopError] = useState("");
    const [noDataonClickApply, setNoDataonClickApply] = useState(false);
    const [tableData, setTableData] = useState(null);
    const [messageForFailure, setMessageForFailure] = useState("");
    const [showDisabled, setDisabled] = useState(false);
    const [fromApproveReject, setFromApproveReject] = useState(false);
    const [proficiencyShowModal, setProficiencyShowModal] = useState(false);
    const crumbs = [
        { label: "Home", url: "/" },
        { label: "Skills Submission", url: `/skillssubmission` }
    ];

    const reduxDispatch = useDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigateTo = (e) => {
        const anchor = e.target.closest("a");
        navigate("/" + anchor.getAttribute("href").replace("#", ""));
    };

    const prepareSelectStatusOptions = () => {
        const optionStatus = _optionStatus.sort((a, b) => a.label.localeCompare(b.label));
        setSelectStatusOptions(optionStatus);
        setSelectedStatusN(optionStatus);
        setOriginalStatusOptionList(optionStatus);
    };
    const prepareSelectLearnerOptions = (data) => {
        const uniqueNames = [];
        for (const userId in data) {
            data[userId].forEach((item) => {
                if (!uniqueNames.some((nameItem) => nameItem.value === item.Name)) {
                    uniqueNames.push({ id: item.UserId, value: item.Name, label: item.Name });
                }
            });
        }

        const sortedUniqueNames = uniqueNames.sort((a, b) => a.label.localeCompare(b.label));
        setSelectLearnerOptions(sortedUniqueNames);
        setSelectedLearnerN(sortedUniqueNames);
        setOriginalLearnerOptionList(sortedUniqueNames);
    };

    const prepareSelectRolesOptions = (data) => {
        const uniqueRoles = {};

        for (const userId in data) {
            const userRecords = data[userId];
            for (const record of userRecords) {
                const role = record.Role || "Additional Skills";
                uniqueRoles[role] = true;
            }
        }

        const roleOptions = Object.keys(uniqueRoles).map((role) => ({
            id: role,
            value: role,
            label: role
        }));
        setSelectRolesOptions(roleOptions);
        setSelectedRolesN(roleOptions);
        setOriginalRolesOptionList(roleOptions);
    };
    const prepareSelectSkillOptions = (data) => {
        const uniqueSkillNames = [];
        for (const userId in data) {
            data[userId].forEach((item) => {
                if (!uniqueSkillNames.some((nameItem) => nameItem.value === item.Skills)) {
                    uniqueSkillNames.push({
                        id: item.Skills,
                        value: item.Skills,
                        label: item.Skills
                    });
                }
            });
        }

        const sortedUniqueSkillNames = uniqueSkillNames.sort((a, b) =>
            a.label.localeCompare(b.label)
        );
        setSelectSkillOptions(sortedUniqueSkillNames);
        setSelectedSkillN(sortedUniqueSkillNames);
        setOriginalSkillOptionList(sortedUniqueSkillNames);
    };
    const prepareFilterOptions = () => {
        prepareSelectStatusOptions();
        // prepareSelectLearnerOptions();
        // prepareSelectRolesOptions();
        // prepareSelectSkillOptions();
    };

    const removeDuplicates = (array) => {
        return array.filter((value, index, self) => {
            return index === self.findIndex((obj) => obj.UserRoleSkillId === value.UserRoleSkillId);
        });
    };

    const prepareDataForUI = () => {
        // Selected values from status and learner arrays
        const selectedStatus = selectedStatusN.map((item) => item.value);
        const selectedLearner = selectedLearnerN.map((item) => item.value);
        const selectedRoles = selectedRolesN.map((item) => item.value);
        const selectedSkill = selectedSkillN.map((item) => item.value);

        const filteredArray = {};
        for (const key in myTeamSkillsApiDataObj) {
            if (myTeamSkillsApiDataObj.hasOwnProperty(key)) {
                const filteredItems = myTeamSkillsApiDataObj[key].filter((item) => {
                    return (
                        selectedStatus.includes(item.Status) &&
                        selectedLearner.includes(item.Name) &&
                        (selectedRoles.includes(item.Role) ||
                            (item.Role === null && selectedRoles.includes("Additional Skills"))) &&
                        selectedSkill.includes(item.Skills)
                    );
                });
                filteredArray[key] = filteredItems;
            }
        }

        if (filteredArray) {
            const obj = filteredArray;
            let tempArry = [];

            Object.entries(obj).forEach(([key, value]) => {
                let userRollSkillObject = {};
                let history = obj[key].map((item) => {
                    let TRId = item?.transactionId;
                    let URSId = item?.UserRoleSkillId;

                    if (!userRollSkillObject[URSId]) {
                        userRollSkillObject[URSId] = [];
                    }

                    userRollSkillObject[URSId].push({ item });
                    tempArry.push({
                        lernerId: key,
                        transactionId: TRId,
                        Skills: item.Skills,
                        UserRoleSkillId: item.UserRoleSkillId,
                        skillName: item.Name,
                        history: userRollSkillObject[URSId]
                    });
                });
            });

            const uniqueArray = removeDuplicates(tempArry);
            let updatedData = uniqueArray.sort((a, b) => b.transactionId - a.transactionId);
            setDataArray([{ skills: updatedData }]);
        }
    };

    const updateSelectedSkills = (selectedRoles, res) => {
        const uniqueSkills = new Set();
        const additionalSkills = new Set();

        for (const userId in res) {
            const userRecords = res[userId];
            for (const record of userRecords) {
                const role = record?.Role || "Additional Skills";
                const skill = record?.Skills;
                // const skillKey = `${skill}-${role.toLowerCase()}`;

                if (selectedRoles.length === 0) {
                    prepareSelectSkillOptions(tableData);
                    return;
                }

                if (selectedRoles.includes(role)) {
                    uniqueSkills.add(skill);
                }

                if (role === "Additional Skills") {
                    additionalSkills.add(skill);
                }
            }
        }

        const filteredSkills = Array.from(uniqueSkills).map((skillKey) => {
            const [skill, role] = skillKey.split("-");
            return {
                id: skill,
                value: skill,
                label: skill
            };
        });
        const uniqueSkills1 = [...new Set(filteredSkills)];
        setSelectSkillOptions(uniqueSkills1);

        setSelectedSkillN(uniqueSkills1);
    };
    const handleProficiencyCloseModal = () => {
        setProficiencyShowModal(false);
    };
    const handleProficiencyOpenModal = () => {
        setProficiencyShowModal(true);
    };

    const getMyTeamSkills = async (fromCloseModal = false) => {
        setShowLoader(true);
        try {
            let res = await apis.getMyTeamSkillsSubmissionList();
            setMyTeamSkillsApiDataObj(res.data);
            if(!fromCloseModal){
                prepareSelectRolesOptions(res?.data);
                prepareSelectLearnerOptions(res?.data);
                prepareSelectSkillOptions(res?.data);
            }
            setTableData(res?.data);
            updateSelectedSkills(
                selectedRolesN.map((role) => role.value),
                res?.data
            );
            if (res?.data) {
                const obj = res.data;
                let tempArry = [];

                Object.entries(obj).forEach(([key, value]) => {
                    let userRollSkillObject = {};
                    let history = obj[key].map((item) => {
                        let TRId = item?.transactionId;
                        let URSId = item?.UserRoleSkillId;

                        if (!userRollSkillObject[URSId]) {
                            userRollSkillObject[URSId] = [];
                        }
                        userRollSkillObject[URSId].push({ item });
                        tempArry.push({
                            lernerId: key,
                            transactionId: TRId,
                            Skills: item.Skills,
                            UserRoleSkillId: item.UserRoleSkillId,
                            skillName: item.Name,
                            history: userRollSkillObject[URSId]
                        });
                    });
                });

                const uniqueArray = removeDuplicates(tempArry);
                let updatedData = uniqueArray.sort((a, b) => b.transactionId - a.transactionId);
                setDataArray([{ skills: updatedData }]);

                setShowLoader(false);
            }
        } catch (error) {
            console.warn(error);
            setShowLoader(false);
        }
    };

    const handleCloseDisplayMessage = () => {
        setDisplayMessageBox(false);
        setDisplayMessageBoxForReject(false);
        setDisplayMessageBoxForApproval(false);
        setRejectPopError("");
    };

    const handleSubmit = (e) => {
        setSubmitForApproval(false);
        setDisplayMessageBox(false);
    };

    const handleApply = async () => {
        setShowApplyLoader(true);
        const data = await prepareDataForUI();
        setLocalStorageData(updatedRoles);
        setShowApplyLoader(false);
    };

    const handleClearAll = () => {
        prepareDataForUI();
        getMyTeamSkills();
        setLocalStorageData(_dataSS);
        setLocalStoragePrevData(_dataApprovalPreviewSS);

        setSelectStatusOptions(originalStatusOptionList);
        setSelectLearnerOptions(originalLearnerOptionList);
        setSelectRolesOptions(originalRolesOptionList);
        setSelectSkillOptions(originalSkillOptionList);

        setSelectedStatusN(originalStatusOptionList);
        setSelectedLearnerN(originalLearnerOptionList);
        setSelectedRolesN(originalRolesOptionList);
        setSelectedSkillN(originalSkillOptionList);
        setNoDataonClickApply(false);
        setSearchInput("");
    };

    const handlerSearchData = (data) => {
        console.warn("filtered data", data);
    };

    function showIfLernerIdIsSelected() {
        if (chekedLernerIds?.length) {
            return { cursor: "pointer" };
        }
        return { cursor: "pointer", pointerEvents: "none", opacity: "0.2" };
    }

    function getDataForApprove() {
        let selectedFile = [];
        chekedLernerIds?.map((id) => {
            let obj = { skillTransactionId: id, ApprovalStatus: "Approved" };
            selectedFile.push(obj);
        });
        return selectedFile;
    }

    function getDataForReject() {
        let selectedFile = [];
        chekedLernerIds?.map((id) => {
            let obj = { skillTransactionId: id, ApprovalStatus: "Rejected", rejectReason: address };
            selectedFile.push(obj);
        });
        return selectedFile;
    }

    async function handleApprove() {
        // setDataForApprove(hist);
        setDisplayMessageBoxForApproval(true);
    }

    async function handleReject() {
        setDisplayMessageBoxForReject(true);
        setAddress("");
    }

    const handleSubmitDisplayMessage = async (e) => {
        if (address.trim()) {
            let selectedFile = [];
            chekedLernerIds?.map((id) => {
                let obj = {
                    skillTransactionId: id,
                    ApprovalStatus: "Rejected",
                    rejectReason: address
                };
                selectedFile.push(obj);
            });
            setRejectPopError("");
            let payload = {
                skilltransactiondata: selectedFile ?? []
            };
            const response = await apis.getChangeStatusOfSkills(payload);
            console.log("response in myTeamSkillsSubmission 436",response)
            setDisplayMessageBoxForReject(false);
            setChekedLernerIds([]);
            // toast.success('Selected Skills are Rejected successfully.');
            if (response.message === "all_rejected") {
                setMessageforPopup("Selected Skill(s) have been rejected successfully.");
                setShowModal(true);
            } else if (response.message === "partially_rejected") {
                const skillName = [];
                response?.extraInfo?.notUpdatedSkills?.forEach((a) => {
                    skillName.push(a?.SkillName);
                });
                setMessageforPopup(
                    `Few Skill(s) have been rejected successfully. Unable to reject following Skill(s): ${skillName.join(
                        ", "
                    )}`
                );
                setShowModal(true);
            } else if (response.message === "none_rejected") {
                const link = (
                    <>
                        Unable to complete the request, please retry after some time. If you need
                        further help, contact{" "}
                        <a
                            href="mailto:dx-sio-enablement@adobe.com"
                            className="btn btn-link btn-view fontSize14Text filterEffect">
                            dx-sio-enablement@adobe.com
                        </a>
                        .
                    </>
                );
                setMessageForFailure(link);
                setShowModal(true);
            } else if (response.message === "check_notifications") {
                setMessageforPopup("Your request is under process. Please look at notifications for further response.");
                setShowModal(true);
            }
            // setMessageforPopup(response?.message)
            // setShowModal(true);
            setFromApproveReject(true);
            getMyTeamSkills(true);
        } else {
            setRejectPopError("Please enter a reason for rejection.");
        }
    };

    const handleInputChange = (event) => {
        setAddress(event.target.value);
        setRejectPopError("");
    };

    const handleOkDisplayMessage = async (e) => {
        setDisabled(true);
        const data = getDataForApprove();
        let payload = {
            skilltransactiondata: data ?? []
        };
        const response = await apis.getChangeStatusOfSkills(payload);
        console.log("response in myTeamSkillsSubmission 491",response)
        setDisplayMessageBoxForApproval(false);
        setDisabled(false);
        setChekedLernerIds([]);
        // toast.success('Selected Skills are Approved successfully.');
        if (response.message === "all_approved") {
            setMessageforPopup("Selected Skill(s) have been approved successfully.");
            setShowModal(true);
        } else if (response.message === "partially_approved") {
            const skillName = [];
            response?.extraInfo?.notUpdatedSkills?.forEach((a) => {
                skillName.push(a?.SkillName);
            });
            setMessageforPopup(
                `Few Skill(s) have been approved successfully. Unable to approve following Skill(s): ${skillName.join(
                    ", "
                )}`
            );
            setShowModal(true);
        } else if (response.message === "none_approved") {
            const link = (
                <>
                    Unable to complete the request, please retry after some time. If you need
                    further help, contact{" "}
                    <a
                        href="mailto:dx-sio-enablement@adobe.com"
                        className="btn btn-link btn-view fontSize14Text filterEffect">
                        dx-sio-enablement@adobe.com
                    </a>
                    .
                </>
            );
            setMessageForFailure(link);
            setShowModal(true);
        } else if (response.message === "check_notifications") {
            setMessageforPopup("Your request is under process. Please look at notifications for further response.");
            setShowModal(true);
        }
        // setMessageforPopup("Selected Skill(s) are approved successfully.")
        // setShowModal(true);
        setFromApproveReject(true);
        getMyTeamSkills(true);
    };

    useEffect(() => {
        setShowLoader(false);
        setDisplayMessageBox(false);
        prepareFilterOptions();
        setLocalStorageData(_dataSS);
        setLocalStoragePrevData(_dataApprovalPreviewSS);
        getMyTeamSkills();

        return () => {
            reduxDispatch(setSubmissionIsAllSelected(false));
        };
    }, []);

    useEffect(() => {
        prepareFilterOptions();
    }, [_optionStatus, learnerList, rolesListData, skillsListData]);

    useEffect(() => {
        prepareSelectSkillOptions(tableData);
    }, [tableData]);

    useEffect(() => {
        if (dataArray[0]?.skills?.length) setNoDataonClickApply(false);
        else setNoDataonClickApply(true);
    }, [dataArray]);

    const handleFilter = (event) => {
        const searchTerm = event.target.value;
        setSearchInput(searchTerm);
        // if (event.target.value) {
        //     setSearchInput(event.target.value);
        //     setSearchData(true);
        // } else if (event.target.value.length === 0) {
        //     setSearchInput("");
        //     prepareDataForUI();
        //     setSearchData(false);
        //     setNoDataonClickApply(false);
        // }
    };

    const searchResults = skillSubmissionSearch(dataArray, searchInput);

    // const handlerFilteredData = () => {
    //     const filteredResults = [];

    //     dataArray?.forEach((item) => {
    //         const filteredItem = {
    //             skills: []
    //         };

    //         item?.skills?.forEach((skill) => {
    //             const skillName = skill?.skillName;
    //             const Skills = skill?.Skills;
    //             const UserRoleSkillId = skill?.UserRoleSkillId;
    //             const lernerId = skill?.lernerId;
    //             const transactionId = skill?.transactionId;
    //             const filteredHistory = skill?.history?.filter((historyItem) => {
    //                 return Object.entries(historyItem.item).some(([key, value]) => {
    //                     if (
    //                         ["transactionId", "UserRoleSkillId", "RejectReason", "UserId"].includes(
    //                             key
    //                         )
    //                     ) {
    //                         return false;
    //                     }

    //                     const stringValue = value?.toString()?.toLowerCase();
    //                     const searchValue = searchInput?.toLowerCase();

    //                     if (key === "SubmittedDateTime" || key === "ApprovalOrRejectedDate") {
    //                         const formattedDate = moment(value).format("MMM Do YYYY");
    //                         return formattedDate.toLowerCase().includes(searchValue);
    //                     }

    //                     if (!isNaN(value) && stringValue?.includes(searchValue)) {
    //                         return true;
    //                     }

    //                     if (typeof value === "string" && stringValue?.includes(searchValue)) {
    //                         return true;
    //                     }

    //                     return false;
    //                 });
    //             });

    //             if (filteredHistory.length > 0) {
    //                 filteredItem.skills.push({
    //                     skillName: skillName,
    //                     Skills: Skills,
    //                     UserRoleSkillId: UserRoleSkillId,
    //                     lernerId: lernerId,
    //                     transactionId: transactionId,
    //                     history: filteredHistory.map((historyItem) => historyItem)
    //                 });
    //             }
    //         });

    //         if (filteredItem.skills.length > 0) {
    //             filteredResults.push(filteredItem);
    //         }
    //     });
    //     if (filteredResults?.length === 0) {
    //         setNoDataonClickApply(true);
    //     } else {
    //         setNoDataonClickApply(false);
    //     }
    //     setDataArray(filteredResults);
    // };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if(fromApproveReject){
            setShowLoader(true);
            prepareDataForUI();
            setShowLoader(false);
            setFromApproveReject(false);
        }
    }, [myTeamSkillsApiDataObj]);

    const handleData =()=>{
        setFromApproveReject(true);
    }
    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4">
                <div className="headerImage">
                    <h1>{t("my-team-skills-submission")}</h1>
                </div>
            </Container>
            <div className="pl-30 my-team-skills-submission">
                <div className="main-section pageBorder">
                    {!submitForApproval ? (
                        <>
                            {showLoader ? (
                                <Loader />
                            ) : (
                                <>
                                    <div className="my-skill-journey-container">
                                        <div
                                            className={`top-container-section ${
                                                isSticky ? "stickyBoxShadow" : ""
                                            }`}>
                                            <Row>
                                                <Col
                                                    lg={10}
                                                    md={10}
                                                    sm={12}
                                                    className="container-section">
                                                    <div className="filter-section mt-2 d-flex align-items-end">
                                                        <div className="d-flex flex-wrap">
                                                            <div className="select-section select-status-section">
                                                                <div className="title-label">
                                                                    Select Status
                                                                </div>
                                                                {/* {selectStatusOptions && selectStatusOptions.length > 0 ? ( */}
                                                                <MultiSelect
                                                                    options={selectStatusOptions}
                                                                    value={selectedStatusN}
                                                                    onChange={setSelectedStatusN}
                                                                    labelledBy={"Select"}
                                                                    isCreatable={true}
                                                                />
                                                                {/* ) : null} */}
                                                            </div>
                                                            <div className="select-section select-learner-section">
                                                                <div className="title-label">
                                                                    Select Learner
                                                                </div>
                                                                {/* {selectLearnerOptions && selectLearnerOptions.length > 0 ? ( */}
                                                                <MultiSelect
                                                                    options={selectLearnerOptions}
                                                                    value={selectedLearnerN}
                                                                    onChange={setSelectedLearnerN}
                                                                    labelledBy={"Select"}
                                                                    isCreatable={true}
                                                                />
                                                                {/* ) : null} */}
                                                            </div>
                                                            <div className="select-section select-roles-section">
                                                                <div className="title-label">
                                                                    Select Roles
                                                                </div>
                                                                {/* {selectRolesOptions && selectRolesOptions.length > 0 ? ( */}
                                                                <MultiSelect
                                                                    options={selectRolesOptions}
                                                                    value={selectedRolesN}
                                                                    onChange={(selectedRoles) => {
                                                                        setSelectedRolesN(
                                                                            selectedRoles
                                                                        );
                                                                        updateSelectedSkills(
                                                                            selectedRoles.map(
                                                                                (role) => role.value
                                                                            ),
                                                                            tableData
                                                                        );
                                                                    }}
                                                                    labelledBy={"Select"}
                                                                    isCreatable={true}
                                                                />
                                                                {/* ) : null} */}
                                                            </div>
                                                            <div className="select-section select-skill-section">
                                                                <div className="title-label">
                                                                    Select Skill
                                                                </div>
                                                                {/* {selectSkillOptions && selectSkillOptions.length > 0 ? ( */}
                                                                <MultiSelect
                                                                    options={selectSkillOptions}
                                                                    value={selectedSkillN}
                                                                    onChange={setSelectedSkillN}
                                                                    labelledBy={"Select"}
                                                                    isCreatable={true}
                                                                />
                                                                {/* ) : null} */}
                                                            </div>
                                                        </div>

                                                        <div className="btn-apply">
                                                            <div className="title-label"></div>
                                                            <button
                                                                className="btn primary-btn mx-2 mb-1"
                                                                onClick={handleApply}>
                                                                Apply
                                                            </button>
                                                        </div>
                                                        <div className="btn-clear-all align-self-center resetbtn-border ">
                                                            <div className="title-label"></div>
                                                            <button
                                                                className="btn btn-link btn-view "
                                                                onClick={handleClearAll}>
                                                                Reset Filters
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col
                                                    lg={2}
                                                    md={2}
                                                    sm={12}
                                                    className="button-section search-bar-skillssub ms-auto d-flex align-items-center justify-content-end mt-5 ">
                                                    <div></div>
                                                    <div className="searchDeviceFix ">
                                                        <div className="search searchClass">
                                                            <div className="searchInputs searchInputDiv">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Search"
                                                                    onChange={handleFilter}
                                                                    value={searchInput}
                                                                />
                                                                <button className="searchIcon searchBtn">
                                                                    <span className="pi pi-search"></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    className="container-section container-section-level">
                                                    <div className="level-options-device level-options-device-skillssub pt-4">
                                                        <div className="labels-group">
                                                            <div className="fontSize14 fw-bold">
                                                                <img
                                                                    src={info}
                                                                    alt="info"
                                                                    className="info-icon"
                                                                    onClick={handleProficiencyOpenModal}
                                                                    style={{ cursor: "pointer" }}
                                                                />{" "}
                                                                Proficiency Levels:
                                                            </div>

                                                            <div className="labels-item">
                                                                <div className=""></div>
                                                                <div className="text">
                                                                    100
                                                                    <span className="sub">
                                                                        (Beginner)
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="labels-item">
                                                                <div className=""></div>
                                                                <div className="text">
                                                                    200
                                                                    <span className="sub">
                                                                        (Intermediate)
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="labels-item">
                                                                <div className=""></div>
                                                                <div className="text">
                                                                    300
                                                                    <span className="sub">
                                                                        (Expert)
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        {showApplyLoader ? (
                                            <Loader />
                                        ) : (
                                            <div className="main-container-section skill-submission">
                                                {!isEmpty(searchResults) ? (
                                                    <CustomAccordionTwo
                                                        data={searchResults}
                                                        tableHeader={_tableHeaderSS}
                                                        setChekedLernerIds={setChekedLernerIds}
                                                        chekedLernerIds={chekedLernerIds}
                                                        setAddress={setAddress}
                                                        address={address}
                                                        getMyTeamSkills={getMyTeamSkills}
                                                        handleData={handleData}
                                                    />
                                                ) : noDataonClickApply ? (
                                                    <p className="no-data">
                                                        You do not have any Skill(s) to
                                                        review/approve.
                                                    </p>
                                                ) : (
                                                    <p className="no-data">
                                                        No results found. Please try another search
                                                        term.
                                                    </p>
                                                )}

                                                {!noDataonClickApply && !isEmpty(searchResults) && (
                                                    <div className="skill-submission-btn">
                                                        <div
                                                            className="btn-label btn-label-reject"
                                                            style={showIfLernerIdIsSelected()}
                                                            onClick={handleReject}>
                                                            <i className="pi pi-times"></i>{" "}
                                                            <span>Reject</span>
                                                        </div>
                                                        <div
                                                            className="btn-label btn-label-approve"
                                                            style={showIfLernerIdIsSelected()}
                                                            onClick={handleApprove}>
                                                            <i className="pi pi-check"></i>{" "}
                                                            <span>Approve</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <div className="skill-approval-preview-container">
                            <div className="top-container-section">
                                <Row>
                                    <Col lg={10} sm={12} className="container-section">
                                        <div className="title">Skill Approval Preview</div>
                                    </Col>
                                    <Col lg={2} sm={12} className="button-section">
                                        <button
                                            className="btn primary-btn m-2"
                                            onClick={handleSubmit}>
                                            Submit
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <div className="main-approval-preview-container-section">
                                <CustomAccordionApprovalPreview data={localStoragePrevData} />
                            </div>
                        </div>
                    )}
                </div>
                <Modal
                    show={displayMessageBoxForReject}
                    className="skill-submission-modal"
                    centered>
                    <Modal.Body>
                        <div className="success-modal-container px-3">
                            <div className="content-section">
                                <div>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>
                                                <div className="content-label">
                                                    Rejection Reason{" "}
                                                    <span className="astrix">*</span>
                                                </div>
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                className="content-textarea"
                                                rows="5"
                                                name="address"
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                            {rejectPopError && (
                                                <span style={{ color: "red" }}>
                                                    {rejectPopError}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-link m-2"
                            onClick={() => handleCloseDisplayMessage()}>
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn primary-blue m-2 brn-submit"
                            onClick={(e) => handleSubmitDisplayMessage(e)}>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
                <div className="modal-container success-modal">
                    <Modal centered show={displayMessageBoxForApproval}>
                        <Modal.Body>
                            <div className="success-modal-container my-5 text-center">
                                <img
                                    className="failed-icon mx-auto"
                                    src={Failure_Icon}
                                    alt="failed"></img>
                                <div className="success-text-message2 p-0 pt-3">
                                    Are you sure you want to approve selected Skill(s)?
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-link m-2"
                                onClick={() => handleCloseDisplayMessage()}>
                                Cancel
                            </button>
                            <button
                                className="btn primary-btn primary-blue m-2"
                                onClick={(e) => handleOkDisplayMessage(e)}
                                disabled={showDisabled}>
                                Ok
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
            <ModalforSuccessAndFailure
                messageforPopup={messageforPopup}
                show={showModal}
                onClose={handleCloseModal}
                messageForFailure={messageForFailure}
            />
            <ProficiencyLevelModal
                show={proficiencyShowModal}
                onClose={handleProficiencyCloseModal}
            />
            <Scroller />
        </>
    );
}

export default MyTeamSkillsSubmission;
