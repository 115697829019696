import React from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../common/Breadcrumbs";
import DataTable from "../../component/DataTable";
import Container from "react-bootstrap/Container";
import Scroller from "../../common/Scroller/Scroller";
import useDataReports from "../../hooks/useDataReports";
import { _tableHeaderLearnerDetails } from "../../data/dataLearnerReports";
import { learnerReportsDetailsBreadCrumbs } from "../../constant/breadCrumbs";
import TableHeading from "../../component/TableHeading";
import DetailsLearnerReportsCellContent from "./DetailsLearnerReportsCellContent";
import "./LearnerReports.css";

function DetailsTableForLearner() {
    const { detailsLearnerData, detailsLearnerUsername } = useSelector(
        (state) => state.learnerList
    );

    const {
        searchTerm,
        filteredData,
        currentItems,
        currentPage,
        itemsPerPage,
        sortKey,
        sortOrder,
        handleSort,
        handleInputChange,
        setCurrentPage
    } = useDataReports({ data: detailsLearnerData, pageName: "learnerDetails" });

    return (
        <>
            <Breadcrumbs crumbs={learnerReportsDetailsBreadCrumbs} />
            <Container fluid className="pl-30 py-4">
                <div className="headerImage">
                    <h1>Learner Report of {detailsLearnerUsername}</h1>
                </div>

                <div className="learnerReports mt-5">
                    <div className="learnerReportsTable">
                        <DataTable
                            handleInputChange={handleInputChange}
                            searchTerm={searchTerm}
                            currentItems={currentItems}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            filteredData={filteredData}
                            itemsPerPage={itemsPerPage}>
                            <TableHeading
                                sortKey={sortKey}
                                sortOrder={sortOrder}
                                columns={_tableHeaderLearnerDetails}
                                handleSort={handleSort}
                            />
                            <DetailsLearnerReportsCellContent data={currentItems} />
                        </DataTable>
                    </div>
                </div>
            </Container>
            <Scroller />
        </>
    );
}

export default DetailsTableForLearner;
