import {t} from "i18next"

export default function TranslateFunc(TextToTramslate) {
    if(TextToTramslate === "learningProgram")
        TextToTramslate = "Learning Program";
    const formatedText = TextToTramslate?.split(" ").join("-").toLowerCase()
    const translatedText = t(formatedText)
    if ( translatedText === formatedText ) {
        return TextToTramslate
    }
    else {
        return translatedText   
    }
}