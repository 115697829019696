/* eslint-disable */
import React,{useState, useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "react-multi-carousel/lib/styles.css";
import ImportCSVCard from "./ImportcsvCard/ImportcsvCard";
import "./Importcsv.css";
import Breadcrumbs from "../../common/Breadcrumbs";
import Scroller from "../../common/Scroller/Scroller";
import { apis } from "../../services/Api";

const Importcsv = () => {
    const crumbs = [
        { label: "Home", url: "/" },
        { label: "Import CSV", url: "/importcsv" },
    ];

    //  TODO - API is called multiple time thats why API is called here and passing the data as a props

    const [userName, setUserName] = useState([])
    const [lastUploadDate,setLastUploadDate] = useState([]);
    const[statusOfLastImport, setStatusOfLastImport] = useState([])

    let getStatusOfLastImport = async() => {
        await apis
            .getStatusOfLastImportCsv()
            .then(e =>{
               setStatusOfLastImport(e.statusOfImportCsv)
               setUserName(e.statusOfImportCsv)
               setLastUploadDate(e.statusOfImportCsv)
               })
            .catch(error => {
                console.error(error);
            });
    
    };
    
      useEffect(()=>{
        getStatusOfLastImport();
        
      },[])
//   --------------------------------END----------------------------    
    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4">
                <div className="headerImage"> 
                    <h1>Import CSV</h1> 
                </div>
            </Container>
            <Container fluid className="pl-30 ImportCsv"> 
                <Row>
                    {/* <ImportCSVCard title="Navigation Data" id="navigationCsvUploads"/> */}
                    <ImportCSVCard title="Navigation Data" userName={userName} lastUploadDate={lastUploadDate} statusOfLastImport={statusOfLastImport} id="navigationCsvUploadsTest"/>
                    <ImportCSVCard title="Master Skills" userName={userName} lastUploadDate={lastUploadDate} statusOfLastImport={statusOfLastImport} id="masterSkillsCsvUploads"/>
                </Row>
                <Row>
                    <ImportCSVCard title="Enroll User Roles Data" userName={userName} lastUploadDate={lastUploadDate} statusOfLastImport={statusOfLastImport} id="usrEnrlmntCsvUploads"/>
                    <ImportCSVCard title="Unenroll User Roles Data" userName={userName} lastUploadDate={lastUploadDate} statusOfLastImport={statusOfLastImport} id="usrUnEnrlmntCsvUploads"/>
                </Row>    
                <Row>
                    <ImportCSVCard title="Certification Wall of Fame" userName={userName} lastUploadDate={lastUploadDate} statusOfLastImport={statusOfLastImport} isYearOptionAvailable={true}  isQuaterlyOptionAvailable={true} 
                    id="csvUploads" className = 'me-1'
                    />
                    <ImportCSVCard title="Meet the SIO Enablement Team Data" userName={userName} lastUploadDate={lastUploadDate} statusOfLastImport={statusOfLastImport} id="enablementCsvUploads"/>
                </Row>   
                <Row>
                    <ImportCSVCard title="Import Catalogs" userName={userName} lastUploadDate={lastUploadDate} statusOfLastImport={statusOfLastImport} id="catalogCsvUploads"/>
                    <ImportCSVCard title="Import Custom User" userName={userName} lastUploadDate={lastUploadDate} statusOfLastImport={statusOfLastImport} id="customUsersCsvUploads"/>
                </Row>    
                <div className="modal-container">
        </div>
            </Container>  
            <Scroller /> 
        </>
    )
}

export default Importcsv
