/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React from "react";
import card_img from "../../../src/assets/images/card_img.png";
import lp_card_img from "../../assets/images/lp_card_img.png";
import certi_card_img from "../../assets/images/certi_card_img.png";
import jobaid_card_img from "../../assets/images/jobaid_card_img.png"
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apis } from "../../services/Api";

export default function MultiInstence({ coreContentItem, courseDetails,setList,setDisabaledEnrollBtn,setEnrollmentInstanceCounts }) {
    const naviagte = useNavigate();
    const location = useLocation();
    let { state } = location;
    const crumbs = state?.crumbs || [];
    let { instanceName, instanceId } = { ...coreContentItem };
    let { id, attributes } = { ...courseDetails };
    const handleInstance = async () => {
        if(instanceId &&( coreContentItem?.state === "Retired") ){
            setDisabaledEnrollBtn(true)
        }else{
            setDisabaledEnrollBtn(false)
        }
        const result =  await apis.getEnrollmentCount(
            id,
            instanceId
        );
        setEnrollmentInstanceCounts(result?.data?.attributes?.enrollmentCount)
        setList();
        naviagte(`/coursedetails/${id}/${instanceId}`,{state:{crumbs}});
    };

    const { t } = useTranslation();

    return (
        <div className="list-view">
            <div className="row mb-4 list-view-block cd justify-content-center justify-content-md-start mx-0">
                <div className="col-auto py-4 px-4">
                    {coreContentItem?.loType==="learningProgram" ?
                        <img
                            src={coreContentItem?.imageUrl || lp_card_img}
                            alt={t("loading")}
                            className="img-fluid d-block list-view-block-img"
                        />
                        :
                        coreContentItem?.loType === "certification" ?
                            <img
                                src={coreContentItem?.imageUrl || certi_card_img}
                                alt={t("loading")}
                                className="img-fluid d-block list-view-block-img"
                            />
                            :
                            coreContentItem?.loType === "jobAid" ?
                                <img
                                    src={coreContentItem?.imageUrl || jobaid_card_img}
                                    alt={t("loading")}
                                    className="img-fluid d-block list-view-block-img"
                                />
                                :
                                <img
                                    src={coreContentItem?.imageUrl || card_img}
                                    alt={t("loading")}
                                    className="img-fluid d-block list-view-block-img"
                                />  
                    }
                </div>
                <div className="col pe-4 text-padding">
                    <p className="head-text">{instanceName} {coreContentItem?.state === "Retired" && ( <span style={{color:"red"}}>(Retired)</span>)}</p>
                </div>
                <div className="list-footer align-items-center">
                    <div>
                        <span className="">{attributes.loFormat}</span>
                    </div>
                    <div className="text-padding">
                        <button className="btn primary-btn enrollbtn" disabled = {coreContentItem?.state === "Retired"} onClick={handleInstance}>
              View Details
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
