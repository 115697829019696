import Container from "react-bootstrap/Container";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useDataReports from "../../hooks/useDataReports";
import DataTable from "../../component/DataTable";
import CourseReportsCellContent from "./CourseReportsCellContent";
import Breadcrumbs from "../../common/Breadcrumbs";
import { apis } from "../../services/Api";
import Loader from "../../../src/common/Loader/Loader";
import Scroller from "../../common/Scroller/Scroller";
import { _tableHeaderCourseReports } from "../../data/dataCourseReports";
import { courseReportsBreadCrumbs } from "../../constant/breadCrumbs";
import TableHeading from "../../component/TableHeading";
import "./CourseReports.css";

function CourseReports() {
    const dispatch = useDispatch();
    const { isPending, courseReportList } = useSelector((state) => state.courseList);

    const {
        searchTerm,
        filteredData,
        currentItems,
        currentPage,
        itemsPerPage,
        sortKey,
        sortOrder,
        handleSort,
        handleInputChange,
        setCurrentPage
    } = useDataReports({ data: courseReportList });

    useEffect(() => {
        dispatch(apis.getCourseData());
    }, [dispatch]);

    return (
        <>
            <Breadcrumbs crumbs={courseReportsBreadCrumbs} />
            <Container fluid className="pl-30 py-4">
                <div className="headerImage">
                    <h1>Courses/Learning Paths</h1>
                </div>
            </Container>

            {!isPending ? (
                <Container fluid className="pl-30 py-4">
                    <div className="learnerReports">
                        <div className="learnerReportsTable">
                            <DataTable
                                handleInputChange={handleInputChange}
                                searchTerm={searchTerm}
                                currentItems={currentItems}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                filteredData={filteredData}
                                itemsPerPage={itemsPerPage}
                                flag={true}>
                                <TableHeading
                                    sortKey={sortKey}
                                    sortOrder={sortOrder}
                                    columns={_tableHeaderCourseReports}
                                    handleSort={handleSort}
                                />
                                <CourseReportsCellContent data={currentItems} />
                            </DataTable>
                        </div>
                    </div>
                </Container>
            ) : (
                <Loader />
            )}
            <Scroller />
        </>
    );
}

export default CourseReports;
