import React from "react";
import ReactHtmlParser from "react-html-parser";

function parseContent(content) {
    return content;
}

function DynamicHTMLContent({ htmlContent }) {
    const parsedContent = parseContent(htmlContent);

    return (
        <div>
            <p className="ms-2">{ReactHtmlParser(parsedContent)}</p>   
        </div>
    );
}

export default DynamicHTMLContent;