import SortableColumn from "./SortableColumn";

const TableHeaderRow = ({ tableHeaders, sortColumn, ascending, viewSkillJourney, handleSort }) => {
    return (
        <tr>
            {tableHeaders.map((itm, index) => {
                const isSortingColumn = sortColumn === index;
                return (
                    <SortableColumn
                        key={index}
                        index={index}
                        title={itm.title}
                        isSortingColumn={isSortingColumn}
                        ascending={ascending}
                        viewSkillJourney={viewSkillJourney}
                        handleSort={handleSort}
                    />
                );
            })}
        </tr>
    );
};

export default TableHeaderRow;
