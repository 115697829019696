import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "./CustomAccordion.css";

function LevelInfoIcon({ address }) {
    return (
        <>
            <OverlayTrigger
                trigger="hover"
                placement="right"
                rootClose
                overlay={
                    <Popover
                        id="popover-trigger-click-root-close"
                        className="current-level-popover">
                        <Popover.Body
                            className=""
                            style={{ fontSize: "13px", maxHeight: "200px", overflowY: "auto" }}>
                            <span className="current-level-title">Approved from Role Packet:</span>{" "}
                            {address}
                        </Popover.Body>
                    </Popover>
                }>
                <button className="tbl-level-info-btn" type="button"></button>
            </OverlayTrigger>
        </>
    );
}
export default LevelInfoIcon;
