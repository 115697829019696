// import React, {  useState } from "react";
import React, {  useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";
import NewTemplate from "./NewTemplate/NewTemplate";
import ExistingTemplate from "./ExistingTemplate/ExistingTemplate";
import "./Templates.css";
import Breadcrumbs from "../../common/Breadcrumbs";
import Scroller from "../../common/Scroller/Scroller";

const crumbs = [
    { label: "Home", url: "/" },
    { label: "Manage Templates" }    
];
const ManageTemplates = () => {
    // const [activeTab, setActiveTab] = useState("new-template");
    const [activeTab, setActiveTab] = useState("new-template");

    const handleTabSelect = (key) => {
        setActiveTab(key);
        localStorage.setItem("activeTab", key);
    };
    useEffect(() => {
        // Retrieve and set the active tab from localStorage if present
        const storedTab = localStorage.getItem("activeTab");
        if (storedTab) {
            setActiveTab(storedTab);
        } else {
            setActiveTab("new-template"); // Set default tab if no stored tab
        }

        // Cleanup function to reset active tab when navigating away
        return () => {
            localStorage.removeItem("activeTab");
        };
    }, []);
    // useEffect(()=>{
    //     const query = new URLSearchParams(window.location.search);
    //     const token = query.get("existingTemplates");
    //     if(token){
    //         setActiveTab("existing-template");
    //     }
    // },[]);
    
     

    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4">
                <div className="headerImage">
                    <h1>Manage Templates</h1>
                </div>
                <div className="horizontal-tab-block my-5">
                    <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3">
                        <Tab eventKey="new-template" title="New Template">
                            <NewTemplate />
                        </Tab>
                        <Tab eventKey="existing-template" title="Existing Template">
                            <ExistingTemplate />
                        </Tab>
                    </Tabs>
                </div>
            </Container>
            <Scroller />
        </>
    );
  
};

export default ManageTemplates;