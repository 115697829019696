/* eslint-disable no-debugger */
import React from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../common/Breadcrumbs";
import Container from "react-bootstrap/Container";
// import userimg from "../../assets/images/userimg.png";
import AllNotifications from "../../common/Notification/notification";

const Notifications = () => {
    const { t } = useTranslation();
    const crumbs = [
        { label: "Home", url: "/" },
        { label: "Notifications", url: "/notifications" }
    ];

    return (
        <>
            <Breadcrumbs crumbs={crumbs} />

            <Container fluid className="pl-30 pt-2">
                <div className="headerImage mb-4">
                    <h1>{t("notification-label")}</h1>
                </div>
                <div className="d-flex mb-3 px-0 py-4 container-fluid">
                    <AllNotifications />
                </div>
            </Container>
        </>
    );
};

export default Notifications;
