/* eslint-disable indent */
// import { sortBy } from "lodash";
import { chain } from "lodash";
import { apis } from "../services/Api";

export const enrollmentCountNumber = async (data) => {
    const enrollmentCounts = [];
    for (let i = 0; i < data.length; i++) {
        const result = await apis.getEnrollmentCount(
            data[i].id,
            data[i].relationships.instances.data[0].id
        );
        enrollmentCounts.push(result.data?.attributes?.enrollmentCount);
    }

    return enrollmentCounts;
};

export const convertBitToMb = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getFiscalYearDetail = (separator = "/") => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    const getFiscalYearDetails = {
        prevFiscalYear: {
            startYear: year - 1,
            endYear: year,
            fullDate: `${year - 1}${separator}${`${11}`}${separator}${30}`
        },
        currentFiscalYear: {
            startYear: year - 1,
            endYear: year,
            fullDate: `${year}${separator}${
                month < 10 ? `0${month}` : `${month}`
            }${separator}${date}`
        }
    };
    return getFiscalYearDetails;
};

export const isBefore = (todayDate, prevDate) => {
    return todayDate > prevDate;
};

export const setFiscalYearOptions = () => {
    const fiscalYearDetails = getFiscalYearDetail();
    const isFiscalYear = isBefore(
        fiscalYearDetails.currentFiscalYear.fullDate,
        fiscalYearDetails.prevFiscalYear.fullDate
    );

    const preFiscalYear = [
        {
            value: fiscalYearDetails.prevFiscalYear.startYear,
            label: `FY ${fiscalYearDetails.prevFiscalYear.startYear}`
        },
        {
            value: fiscalYearDetails.prevFiscalYear.endYear,
            label: `FY ${fiscalYearDetails.prevFiscalYear.endYear}`
        }
    ];
    const currFiscalYear = [
        {
            value: fiscalYearDetails.currentFiscalYear.startYear,
            label: `FY ${fiscalYearDetails.currentFiscalYear.startYear}`
        },
        {
            value: fiscalYearDetails.currentFiscalYear.endYear,
            label: `FY ${fiscalYearDetails.currentFiscalYear.endYear}`
        },
        {
            value: fiscalYearDetails.currentFiscalYear.endYear + 1,
            label: `FY ${fiscalYearDetails.currentFiscalYear.endYear + 1}`
        },
        {
            value: fiscalYearDetails.currentFiscalYear.endYear + 2,
            label: `FY ${fiscalYearDetails.currentFiscalYear.endYear + 2}`
        }
    ];

    return isFiscalYear ? currFiscalYear : preFiscalYear;
};

export const setButtonText = () => {
    // if( data?.progressPercent === undefined ){
    //     return "start"
    // } else if(data?.progressPercent >= 0 && data?.progressPercent < 100) {
    //     return "continue"
    // } else if ( data?.progressPercent == 100) {
    //     return "revisit"
    // }
    return "View Details";
};

export const removeDuplicateStringFromArray = (arry) => {
    var uniqueArray = arry.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
    return uniqueArray;
};

export const removeDuplicateObjectFromArray = (originalArray, key) => {
    console.warn("key", key);
    var uniqueArray = originalArray.filter((obj, index, self) => {
        return (
            index ===
            self.findIndex((item) => {
                return item.key === obj.key;
            })
        );
    });
    return uniqueArray;
};

export const sortByAwaitingFirst = (data) => {
    const awaitingApprovalItems= data.filter(item =>item.status === "Awaiting Approval");
    const otherItems= data.filter(item =>item.status !== "Awaiting Approval");
    // const sortedOtherItems = sortBy([...otherItems],["TransactionId"]);
    return [...awaitingApprovalItems, ...otherItems];
};

export const isConditionMetForApproveIcon = (history, mainData) => {
    const approvedFromRole = history.ApprovedFromRole;

    const hasMatchingApproval = mainData.find((s) => {
        return (
            s.ApprovalStatus === "Approved" &&
            s.RoleName === approvedFromRole &&
            s.CurrentLevel === 300 &&
            s.SubmittedLevel === 300
        );
    });

    return (
        (history.CurrentLevel !== 0 &&
            history.CurrentLevel !== null &&
            history.submittedDate === null &&
            history.aprroveDate !== null) ||
        (history.CurrentLevel === 300 &&
            history.SubmittedLevel === null &&
            history.RoleName !== history.ApprovedFromRole &&
            hasMatchingApproval?.SubmittedDateTime === null)
    );
};

// export const sortDataByAwaitingFirst = (data) => {
//     return sortBy(data, (item) => {
//         const latestStatus = get(last(item.history), "status", ""); // Get the latest status
//         switch (latestStatus) {
//             case "Awaiting Approval":
//                 return 1;
//             case "Rejected":
//                 return 2;
//             case "Approved":
//                 return 3;
//             case "Not Started":
//                 return 4;
//             default:
//                 return 5;
//         }
//     });
// };

// export const sortDataByAwaitingFirst = (data) => {
//     return orderBy(data, [
//         (item) =>
//             item.history[0].status !== "Awaiting Approval" ||
//             item.history[0].Status !== "Awaiting Approval", // Sort by "Awaiting Approval" status
//         (item) =>
//             item.history[0].status === "Awaiting Approval" ||
//             item.history[0].Status === "Awaiting Approval"
//                 ? item.history.length > 1
//                     ? item.history.length
//                     : 0
//                 : Infinity // Sort by history length for "Awaiting Approval" items and prioritize single history items
//     ]);
// };
export const removeDuplicateEn = (path) => {
    // Split, filter to remove duplicate 'en', and join back to a string
    return chain(path)
        .split("/")
        .uniqWith((a, b) => a === "en" && b === "en")
        .join("/")
        .value();
};
