/* eslint-disable */
import React, { useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { _data, _dataApprovalPreview } from './data/dataMySkillJourney';
import { PrivateRoute } from './component/PrivateRoute';
import SignIn from './component/SignIn/SignIn';
import 'react-multi-carousel/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';
// I18next
import './i18n';
import PrivatePath from './PrivatePath';
import { ToastContainer } from 'react-toastify';
import LoginFailed from './component/LoginFailed/LoginFailed';
import Footer from './component/Footer';

function App() {
  const location = useLocation();
  const locationpath = location.pathname;
  const hasCoursePlayer = locationpath.includes('/course-player');

  return (
    <React.Fragment>
      <div className="App">
        <div className="mainContentArea">
          <Routes>
            <Route path="/login" element={<SignIn />} />
            <Route path="/loginfailed" element={<LoginFailed />} />
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <PrivatePath />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
        {/* <ToastContainer /> */}
        <ToastContainer className="custom-toast-container" />
        {location.pathname === '/login' || hasCoursePlayer ? null : <Footer></Footer>}
      </div>
    </React.Fragment>
  );
}

export default App;
