/* eslint-disable indent */
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import RejectInfoIcon from "../RejectInfoIcon";
import { apis } from "../../../services/Api";
import ModalforSuccessAndFailure from "../../../common/Modal/Modal";
import Failure_Icon from "../../../assets/images/Failure_Icon.png";
import moment from "moment";
import Loader from "../../../common/Loader/Loader";
import "../CustomAccordion.css";
import { setSubmissionIsAllSelected } from "../../../slices/mainSlice";
import CheckBoxTooltip from "../../CheckBoxTooltip";
import { sortByAwaitingFirst } from "../../../helper/Helper";

function CustomSkillRowTwo({
    getMyTeamSkills,
    content,
    i,
    setChekedLernerIds,
    chekedLernerIds,
    contentTransectionId,
    setAddress,
    address,
    handleData
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipText, setTooltipText] = useState("");
    const [collapse, setCollapse] = useState(true);
    const [, setShow] = useState(false);
    const [dataContent, setDataContent] = useState();
    const [displayMessageBox, setDisplayMessageBox] = useState(false);
    const [displayMessageBoxForApproval, setDisplayMessageBoxForApproval] = useState(false);
    const [dataForReject, setdataForReject] = useState("");
    const [dataForApprove, setDataForApprove] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [rejectPopError, setRejectPopError] = useState("");
    const [showLoader] = useState(false);
    const [messageForFailure, setMessageForFailure] = useState("");
    const [showDisabled, setDisabled] = useState(false);

    useEffect(() => {
        const tData = sortByAwaitingFirst(
            [...content.history].map((item) => {
                return { ...item, status: item.Status };
            })
        );
        setDataContent([tData[0]]);
        setCollapse(false);
    }, [content]);

    useEffect(() => {
        const tData = sortByAwaitingFirst(
            [...content.history].map((item) => {
                return { ...item, status: item.Status };
            })
        );
        if (!collapse) {
            setDataContent([tData[0]]);
        } else {
            setDataContent([...tData]);
        }
    }, [collapse]);

    const styleStatus = (status) => {
        if (status === "Rejected") {
            return "rejected";
        } else if (status === "Approved") {
            return "approved";
        } else if (status === "Awaiting Approval") {
            return "awaiting";
        }
    };

    const navigateTo = (userId, userName) => {
        navigate(`/viewSkillJourney/${userId}`, { state: { userName, skillSubmission: true } });
    };

    const handleClick = () => {
        setCollapse((prev) => !prev);
        setShow(false);
    };

    const handleChange = (e, LId) => {
        const checkedValue = e.target.checked;
        if (checkedValue) {
            setChekedLernerIds([...chekedLernerIds, LId]);
        } else {
            let filter = chekedLernerIds?.filter((id) => id != LId);
            if (filter) {
                setChekedLernerIds(filter);
            }
        }
    };

    useEffect(() => {
        if (chekedLernerIds.length < contentTransectionId.length) {
            dispatch(setSubmissionIsAllSelected(false));
        }
        if (
            chekedLernerIds.length != 0 &&
            contentTransectionId.length != 0 &&
            chekedLernerIds.length === contentTransectionId.length
        ) {
            dispatch(setSubmissionIsAllSelected(true));
        }
    }, [chekedLernerIds]);

    const handleCloseDisplayMessage = () => {
        setDisplayMessageBox(false);
        setDisplayMessageBoxForApproval(false);
        setRejectPopError("");
    };

    const handleSubmitDisplayMessage = async () => {
        if (address.trim() !== "") {
            let payload = {
                skilltransactiondata:
                    [
                        {
                            skillTransactionId: dataForReject.transactionId,
                            ApprovalStatus: "Rejected",
                            rejectReason: address
                        }
                    ] ?? []
            };
            const response = await apis.getChangeStatusOfSkills(payload);
            console.log("response in CustomeSkillRow2 137",response)
            setRejectPopError("");
            setAddress("");
            setDisplayMessageBox(false);
            // toast.success("Selected Skills are Rejected successfully.");
            if (response.message === "all_rejected") {
                setMessageforPopup("Selected Skill(s) have been rejected successfully.");
                setShowModal(true);
            } else if (response.message === "partially_rejected") {
                const skillName = [];
                response?.extraInfo?.notUpdatedSkills?.forEach((a) => {
                    skillName.push(a?.SkillName);
                });
                setMessageforPopup(
                    `Few Skill(s) have been rejected successfully. Unable to reject following Skill(s): ${skillName.join(
                        ", "
                    )}`
                );
                setShowModal(true);
            } else if (response.message === "none_rejected") {
                const link = (
                    <>
                        Unable to complete the request, please retry after some time. If you need
                        further help, contact{" "}
                        <a
                            href="mailto:dx-sio-enablement@adobe.com"
                            className="btn btn-link btn-view fontSize14Text filterEffect">
                            dx-sio-enablement@adobe.com
                        </a>
                        .
                    </>
                );
                setMessageForFailure(link);
                setShowModal(true);
            } else if (response.message === "check_notifications") {
                setMessageforPopup("Your request is under process. Please look at notifications for further response.");
                setShowModal(true);
            }
            // setMessageforPopup(response?.message)
            // setShowModal(true);
            const updatedChecks = chekedLernerIds.filter(
                (ele) => ele !== dataForReject.transactionId
            );
            setChekedLernerIds([...updatedChecks]);
            // setTimeout(() => {
            //   getMyTeamSkills()

            // }, 8000);
        } else {
            setRejectPopError("Please enter a reason for rejection.");
        }
    };

    const handleClickApprove = async (hist) => {
        setDisplayMessageBoxForApproval(true);
        setDataForApprove(hist);
    };

    const handleOkDisplayMessage = async () => {
        setDisabled(true);
        let payload = {
            skilltransactiondata:
                [
                    { skillTransactionId: dataForApprove.transactionId, ApprovalStatus: "Approved" }
                ] ?? []
        };
        try {
            const response = await apis.getChangeStatusOfSkills(payload);
            console.log("response in CustomeSkillRow2 202",response)
            setDisplayMessageBoxForApproval(false);
            const updatedChecks = chekedLernerIds.filter(
                (ele) => ele !== dataForApprove.transactionId
            );
            setChekedLernerIds([...updatedChecks]);
            setDisabled(false);
            if (response.message === "all_approved") {
                setMessageforPopup("Selected Skill(s) have been approved successfully.");
                // setShowModal(true);
            } else if (response.message === "partially_approved") {
                const skillName = [];
                response?.extraInfo?.notUpdatedSkills?.forEach((a) => {
                    skillName.push(a?.SkillName);
                });
                setMessageforPopup(
                    `Few Skill(s) have been approved successfully. Unable to approve following Skill(s): ${skillName.join(
                        ", "
                    )}`
                );
                // setShowModal(true);
            } else if (response.message === "none_approved") {
                const link = (
                    <>
                        Unable to complete the request, please retry after some time. If you need
                        further help, contact{" "}
                        <a
                            href="mailto:dx-sio-enablement@adobe.com"
                            className="btn btn-link btn-view fontSize14Text filterEffect">
                            dx-sio-enablement@adobe.com
                        </a>
                        .
                    </>
                );
                setMessageForFailure(link);
                // setShowModal(true);
            } else if (response.message === "check_notifications") {
                setMessageforPopup("Your request is under process. Please look at notifications for further response.");
                setShowModal(true);
            }
            setRejectPopError("");
            // setMessageforPopup(response?.message)
        } catch (err) {
            console.warn(err);
        } finally {
            setShowModal(true);
        }
        // setTimeout(() => {
        //   getMyTeamSkills()
        setDataContent();
        // }, 8000);
    };

    const handleClickCancel = (data) => {
        setdataForReject(data);
        setAddress("");
        setDisplayMessageBox(true);
    };

    const handleInputChange = (event) => {
        setAddress(event.target.value);
        setRejectPopError("");
        console.warn(address);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        handleData();
        getMyTeamSkills(true);
        setDataContent();
    };

    const handleCheckboxMouseEnter = () => {
        setShowTooltip(!showTooltip);
        setTooltipText("This skill is not yet submitted for approval by the learner.");
    };
    const handleCheckboxMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <>
            {showLoader ? (
                <div className="container">
                    <Loader isPositionRelative={false} />
                </div>
            ) : (
                <tr key={i}>
                    <td className="container-tbl-tr-btn">
                        {content?.history?.length > 1 ? (
                            <div className="custom-accordion-item-action">
                                <button
                                    className="btn-action"
                                    onClick={() => handleClick()}>
                                    {collapse == true ? (
                                        <div className="arrow up"></div>
                                    ) : (
                                        <div className="arrow down"></div>
                                    )}
                                </button>
                            </div>
                        ) : (
                            <div className="custom-accordion-item-action disable-component hidden-visibility">
                                <button className="btn-action" disabled>
                                    <div className="arrow down"></div>
                                </button>
                            </div>
                        )}
                    </td>
                    <td className="container-tbl-tr-btn">
                        {dataContent && dataContent[0]?.Status === "Awaiting Approval" ? (
                            <Form.Check // prettier-ignore
                                type="checkbox"
                                id={i}
                                label=""
                                value={styleStatus(content.Status)}
                                className="check-box checkboxOne"
                                onChange={(e) => handleChange(e, content.transactionId)}
                                checked={chekedLernerIds.includes(content.transactionId)}
                            />
                        ) : (
                            <div
                                onMouseEnter={handleCheckboxMouseEnter}
                                onMouseLeave={handleCheckboxMouseLeave}>
                                <Form.Check // prettier-ignore
                                    type="checkbox"
                                    disabled
                                    id={i}
                                    label=""
                                    value={styleStatus(content.Status)}
                                    className="check-box checkboxOne custom-tooltip-checkbox"
                                />
                                {showTooltip && <CheckBoxTooltip index={i} message={tooltipText} />}
                            </div>
                        )}
                    </td>
                    <td className="container-tbl-tr-tbl">
                        <table className="custom-accordion-tbl">
                            {dataContent &&
                                dataContent?.length > 0 &&
                                dataContent.map((hist, keyHis) => {
                                    let submitDate = "-";
                                    let approveDate = "-";
                                    if (hist?.SubmittedDateTime) {
                                        let date = new Date(hist?.SubmittedDateTime);
                                        submitDate = moment(date).format("MMM Do YYYY");
                                    }
                                    if (hist?.ApprovalOrRejectedDate) {
                                        let date = new Date(hist?.ApprovalOrRejectedDate);
                                        approveDate = moment(date).format("MMM Do YYYY");
                                    }
                                    return (
                                        <tr key={keyHis}>
                                            <td className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-skillName">
                                                <Nav.Link
                                                    onClick={() =>
                                                        navigateTo(hist?.UserId, hist?.Name)
                                                    }>
                                                    {t(hist?.Name)}
                                                </Nav.Link>
                                            </td>
                                            <td className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-role">
                                                {hist?.Role}
                                            </td>
                                            <td
                                                className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-skill"
                                                title={hist?.Skills}>
                                                {hist?.Skills}
                                            </td>
                                            {/* <td className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-skillGroup" title={hist?.SkillGroup} >{hist?.SkillGroup}</td> */}
                                            <td className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-currencyLevel">
                                                {!hist?.SubmittedLevel || hist?.SubmittedLevel === 0
                                                    ? "-"
                                                    : hist?.SubmittedLevel}
                                            </td>
                                            {!hist?.TargetLevel || hist?.TargetLevel === 0 ? (
                                                <td className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-targetLevel">
                                                    -
                                                </td>
                                            ) : (
                                                <td className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-targetLevel">
                                                    {hist?.TargetLevel}
                                                </td>
                                            )}
                                            <td className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-submitDate">
                                                {submitDate}
                                            </td>
                                            <td className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-approvalDate">
                                                {approveDate}
                                            </td>
                                            <td className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-status">
                                                <div className="tbl-info-container">
                                                    <p
                                                        className={`tbl-btn-status ${styleStatus(
                                                            hist?.Status
                                                        )}`}>
                                                        {hist?.Status !== "Rejected" ? (
                                                            <span>{hist?.Status}</span>
                                                        ) : (
                                                            <>
                                                                <span>{hist?.Status}</span>
                                                                <RejectInfoIcon
                                                                    address={hist?.RejectReason}
                                                                />
                                                            </>
                                                        )}
                                                    </p>
                                                </div>
                                            </td>
                                            <td className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-hasActionBtns hasActionBtnsContainer">
                                                {hist?.Status == "Awaiting Approval" ? (
                                                    <>
                                                        <button
                                                            className="hasAction hasActionCancel"
                                                            onClick={() => handleClickCancel(hist)}>
                                                            <i className="pi pi-times"></i>
                                                        </button>
                                                        <button
                                                            className="hasAction hasActionCorrect"
                                                            onClick={() =>
                                                                handleClickApprove(hist)
                                                            }>
                                                            <i className="pi pi-check"></i>
                                                        </button>{" "}
                                                    </>
                                                ) : (
                                                    <>
                                                        <button
                                                            className="hasAction hasActionCancel hidden-visibility"
                                                            onClick={() => handleClickCancel(hist)}>
                                                            <i className="pi pi-times"></i>
                                                        </button>
                                                        <button
                                                            className="hasAction hasActionCorrect hidden-visibility"
                                                            onClick={() =>
                                                                handleClickApprove(hist)
                                                            }>
                                                            <i className="pi pi-check"></i>
                                                        </button>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </table>
                    </td>
                    <Modal show={displayMessageBox} className="skill-submission-modal" centered>
                        <Modal.Body>
                            <div className="success-modal-container px-3">
                                <div className="content-section mt-4">
                                    <div>
                                        <Form>
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>
                                                    <div className="content-label">
                                                        Rejection Reason{" "}
                                                        <span className="astrix">*</span>
                                                    </div>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    className="content-textarea"
                                                    rows="5"
                                                    name="address"
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                                {rejectPopError && (
                                                    <span style={{ color: "red" }}>
                                                        {rejectPopError}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-link m-2"
                                onClick={() => handleCloseDisplayMessage()}>
                                Cancel
                            </button>
                            <button
                                className="btn primary-btn primary-blue m-2 brn-submit"
                                onClick={() => handleSubmitDisplayMessage()}>
                                Submit
                            </button>
                        </Modal.Footer>
                    </Modal>
                    <div className="modal-container success-modal">
                        <Modal centered show={displayMessageBoxForApproval}>
                            <Modal.Body>
                                <div className="success-modal-container my-5 text-center">
                                    <img
                                        className="failed-icon mx-auto"
                                        src={Failure_Icon}
                                        alt="failed"></img>
                                    <div className="success-text-message2 p-0 pt-3">
                                        Are you sure you want to approve selected Skill(s)?
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-link m-2"
                                    onClick={() => handleCloseDisplayMessage()}>
                                    Cancel
                                </button>
                                <button
                                    className="btn primary-btn primary-blue m-2"
                                    onClick={() => handleOkDisplayMessage()}
                                    disabled={showDisabled}>
                                    Ok
                                </button>
                            </Modal.Footer>
                        </Modal>
                        <ModalforSuccessAndFailure
                            messageforPopup={messageforPopup}
                            show={showModal}
                            onClose={handleCloseModal}
                            messageForFailure={messageForFailure}
                        />
                    </div>
                </tr>
            )}
        </>
    );
}

export default CustomSkillRowTwo;
