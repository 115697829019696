/* eslint-disable indent */
import { useEffect } from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import CustomSkillRow from "./CustomSkillRow";
import { _tableHeaderForViewSkill, _tableHeader } from "../../data/dataMySkillJourney";
import { isEmpty, size } from "lodash";
import TableHeaderRow from "./TableHeaders";
import "./CustomAccordion.css";
import { useDispatch } from "react-redux";
import { setIsAllSelected, setIsAllSkillSelected } from "../../slices/mainSlice";
import { useSelector } from "react-redux";

function CustomAccordionItem({
    data,
    handleSFAClick,
    handleViewSelectSkill,
    showActionColumn,
    setAddress,
    address,
    setChekedLernerIds,
    chekedLernerIds,
    getMySkillJourneyData,
    handleClearAll,
    setSelectedSkills,
    selectedSkills,
    index,
    updateFilterUi
}) {
    const dispatch = useDispatch();
    const [contentData, setContentData] = useState();
    const [contentTransectionId, setContentTransectionId] = useState([]);
    const [contentSkillId, setContentSkillId] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [ascending, setAscending] = useState(true);

    const { isAllSelected, isAllSkillSelected } = useSelector((state) => state.main);

    useEffect(() => {
        setContentData(data);
    }, [data]);

    useEffect(() => {
        const arr = [];
        contentData?.map((content) => {
            if (content?.history[0]?.status === "Awaiting Approval") {
                arr.push(content?.history[0]?.TransactionId);
            }
        });
        setContentTransectionId(arr);
    }, [contentData]);

    // useEffect(() => {
    //     const arr = [];
    //     contentData?.map((content) => {
    //         if (content?.history[0]?.status?.toLowerCase() !== "awaiting approval") {
    //             if (content?.history[0]?.CurrentLevel !== 300) {
    //                 arr.push(content?.history[0]?.skillIdTargetLevel);
    //             }
    //         }
    //     });
    //     setContentSkillId(arr);
    // }, [contentData]);

    useEffect(() => {
        if (!isEmpty(contentData)) {
            const filteredSkillIds = contentData
                .filter((item) => {
                    const history = item.history[0];
                    return !(
                        item.disabled ||
                        history.CurrentLevel === 300 ||
                        history.status === "Awaiting Approval"
                    );
                })
                .map((content) => content?.history[0]?.skillIdTargetLevel);

            setContentSkillId(filteredSkillIds || []);
        }
    }, [contentData]);

    const isCheckedHandler = (isChecked, index, allSelected) => {
        const updatedAllSelected = [...allSelected]; // Create a new array

        // Ensure updatedIsAllSelected array has enough length to accommodate the index
        while (updatedAllSelected.length <= index) {
            updatedAllSelected.push(null);
        }

        // Assign the isChecked value to the specified index in the array
        updatedAllSelected[index] = isChecked;

        return updatedAllSelected;
    };

    const handleChangeCB = (e, index) => {
        const isChecked = e.target.checked;
        const updatedSelected = isCheckedHandler(isChecked, index, isAllSelected);
        dispatch(setIsAllSelected(updatedSelected));

        if (isChecked) {
            setChekedLernerIds([...new Set([...chekedLernerIds, ...contentTransectionId])]);
        } else {
            const newArry = chekedLernerIds.filter((ele) => {
                return !contentTransectionId.includes(ele);
            });
            setChekedLernerIds([...new Set(newArry)]);
        }
    };

    const handleChangeSelectAll = (e, index) => {
        const isChecked = e.target.checked;
        const updatedSelected = isCheckedHandler(isChecked, index, isAllSkillSelected);
        dispatch(setIsAllSkillSelected(updatedSelected));

        if (isChecked) {
            setSelectedSkills([...selectedSkills, ...contentSkillId]);
        } else {
            const newArry = selectedSkills.filter((ele) => {
                return !contentSkillId.includes(ele);
            });
            setSelectedSkills(newArry);
        }
    };

    const handleSort = (index, viewSkillJourney) => {
        if (index !== -1) {
            setSortColumn(index);
            setAscending(!ascending);

            const sortedData = [...contentData].sort((a, b) => {
                const propertyName = viewSkillJourney
                    ? _tableHeaderForViewSkill[index]?.property
                    : _tableHeader[index]?.property;
                const valueA = a?.history[0]?.[propertyName];
                const valueB = b?.history[0]?.[propertyName];

                if (propertyName === "submittedDate" || propertyName === "aprroveDate") {
                    const dateA = new Date(valueA);
                    const dateB = new Date(valueB);

                    return ascending ? dateA - dateB : dateB - dateA;
                } else if (typeof valueA === "number" && typeof valueB === "number") {
                    return ascending ? valueA - valueB : valueB - valueA;
                } else if (typeof valueA === "string" && typeof valueB === "string") {
                    return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                } else {
                    return ascending
                        ? (valueA || "") - (valueB || "")
                        : (valueB || "") - (valueA || "");
                }
            });

            setContentData(sortedData);
        }
    };

    return (
        <div className="custom-accordion-item">
            <div className="custom-accordion-item-content">
                <table className="custom-accordion-container-tbl">
                    <tr className="custom-thead">
                        <td></td>
                        {showActionColumn ? (
                            <td className="container-tbl-tr-checkbox">
                                <div className="container-tbl-tr-btn container-tbl-tr-btn-checkbox">
                                    <Form.Check
                                        disabled={!contentTransectionId.length}
                                        type="checkbox"
                                        id=""
                                        label=""
                                        value="checkAll"
                                        className="check-box checkboxOne"
                                        checked={isAllSelected[index]}
                                        onChange={(e) => handleChangeCB(e, index)}
                                    />
                                </div>
                            </td>
                        ) : (
                            <td className="container-tbl-tr-checkbox">
                                <div className="container-tbl-tr-btn container-tbl-tr-btn-checkbox">
                                    <Form.Check
                                        disabled={!contentSkillId.length}
                                        type="checkbox"
                                        id=""
                                        label=""
                                        value="checkAll"
                                        className="check-box checkboxOne"
                                        checked={isAllSkillSelected[index]}
                                        onChange={(e) => handleChangeSelectAll(e, index)}
                                    />
                                </div>
                            </td>
                        )}

                        <td>
                            <table className="custom-accordion-tbl custom-accordion-tbl-cus">
                                {showActionColumn ? (
                                    <TableHeaderRow
                                        tableHeaders={_tableHeaderForViewSkill}
                                        sortColumn={sortColumn}
                                        ascending={ascending}
                                        viewSkillJourney={true}
                                        handleSort={handleSort}
                                    />
                                ) : (
                                    <TableHeaderRow
                                        tableHeaders={_tableHeader}
                                        sortColumn={sortColumn}
                                        ascending={ascending}
                                        viewSkillJourney={false}
                                        handleSort={handleSort}
                                    />
                                )}
                            </table>
                        </td>
                    </tr>
                    {size(contentData) > 0 &&
                        contentData.map((content, i) => (
                            <CustomSkillRow
                                index={index}
                                totalData={size(contentData)}
                                key={i}
                                content={content}
                                handleSkillSelect={handleSFAClick}
                                handleViewSelectSkill={handleViewSelectSkill}
                                showActionColumn={showActionColumn}
                                setAddress={setAddress}
                                address={address}
                                setChekedLernerIds={setChekedLernerIds}
                                chekedLernerIds={chekedLernerIds}
                                contentTransectionId={contentTransectionId}
                                getMySkillJourneyData={getMySkillJourneyData}
                                handleClearAll={handleClearAll}
                                setSelectedSkills={setSelectedSkills}
                                selectedSkills={selectedSkills}
                                contentSkillId={contentSkillId}
                                updateFilterUi={updateFilterUi}
                            />
                        ))}
                </table>
            </div>
        </div>
    );
}

export default CustomAccordionItem;
