import { Image } from "react-bootstrap";
import dark_up from "../../assets/images/dark_up.svg";
import dark_down from "../../assets/images/dark_down.svg";
import down_icon from "../../assets/images/grey-down.svg";
import up_icon from "../../assets/images/grey-up.svg";

const SortableColumn = ({
    title,
    index,
    isSortingColumn,
    ascending,
    viewSkillJourney,
    handleSort
}) => {
    return (
        <td key={index}>
            {title}
            {title !== "Action" && (
                <span className="sortIcons mx-2">
                    <Image
                        src={isSortingColumn && ascending ? dark_up : up_icon}
                        className={`cpb-1-up ${isSortingColumn && ascending ? "active" : ""}`}
                        alt="Up"
                        title="Up"
                        height={11}
                        width={11}
                        onClick={() => handleSort(index, viewSkillJourney)}
                    />
                    <Image
                        src={isSortingColumn && !ascending ? dark_down : down_icon}
                        className={`cpb-1 ${isSortingColumn && !ascending ? "active" : ""}`}
                        alt="Down"
                        title="Down"
                        height={11}
                        width={11}
                        onClick={() => handleSort(index, viewSkillJourney)}
                    />
                </span>
            )}
        </td>
    );
};

export default SortableColumn;
