import React from "react";
import "./pageNotFound.css"

const PageNotFound = () => {
    return (
        <div className="position-relative" style={{height:"65vh"}}>
            <div className="pageNotFound">
                <h1>404 - Page Not Found</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
            </div>
        </div>
    );
};

export default PageNotFound;
