/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React,{ useEffect, useState } from "react"
import scrollUp from "../../assets/images/scroll-up.png"; 

const Scroller = ( ) => {

    const [showScrollToTop, setShowScrollToTop] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
                
        <React.Fragment>
            {
                showScrollToTop && (
                    <span className="scroll-button scrollTop" onClick={scrollToTop}>
                        <img src={scrollUp} title='Scroll to Top' alt= "scrollUp"></img>
                    </span>
                )
            }
        </React.Fragment>
            
    )
}

export default Scroller;