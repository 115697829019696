import axios from "axios";
import { getToken, isLogin } from "./Auth";

export function jwtInterceptor() {
    axios.interceptors.request.use((request) => { 
        // add auth header with jwt if account is logged in and request is to the api url
        const account =  getToken();
        const isLoggedIn = isLogin();
        const isApiUrl = `${process.env.REACT_APP_API_ENDPOINT}`;

        if (isLoggedIn && isApiUrl) {  
            if(request.headers){ 
                // request.headers = {Authorization:`oauth ${account.access_token}` }
                request.headers.Authorization =  `oauth ${account.access_token}`;
            }
        }
        return request;
    });
}