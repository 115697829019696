import { createSlice } from "@reduxjs/toolkit";

const initialState ={
};


export const myTeamSubmissionSlice = createSlice({
    name:"myTeamSubmissionList",
    initialState,
    reducers:{
        setMyTemSubmissionList:(state, action)=>{
            state.myTeamSubmissionList=action.payload
        },
    }
})

export const {setMyTemSubmissionList} =myTeamSubmissionSlice.actions;
export default myTeamSubmissionSlice.reducer;