/* eslint-disable indent */
import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import { useLocation, useNavigate } from "react-router-dom";
// import Row from "react-bootstrap/Row";
import "./ViewDetail.css";
// import time from "../../../assets/images/time.svg";
// import user1 from "../../../assets/images/user-1.svg";
import CourseCard from "../../../component/CourseCard/CourseCard";
import { appContext } from "../../../context/appContext";
import { useState, useEffect } from "react";
import { apis } from "../../../services/Api";
import Modal from "react-bootstrap/Modal";
import failed_icon from "../../../assets/images/failed_icon.svg";
import Breadcrumbs from "../../../common/Breadcrumbs";
import Failure_Icon from "../../../assets/images/Failure_Icon.png";
import { useSelector } from "react-redux";
import ModalforSuccessAndFailure from "../../../common/Modal/Modal";
import Loader from "../../../common/Loader/Loader";
import AssignRoleToTeam from "../AssignRoleToTeam";
import Scroller from "../../../common/Scroller/Scroller";

const ViewDetail = () => {
    const [skillName, setSkillName] = useState();
    const [skillDescription, setSkillDescription] = useState();
    const [skillGroupName, setSkillGroupName] = useState();
    const [displayMessageBox, setDisplayMessageBox] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [displayMessageBoxForConfirmation, setDisplayMessageBoxForConfirmation] = useState(false);
    const [showModalforSuccessorFail, setShowModalshowModalforSuccessorFail] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [messageForFailure, setMessageForFailure] = useState("");
    const [warningPopup, setWarningPopup] = useState("");
    const [checkUserRes, setCheckUserRes] = useState();
    const [
        displayMessageBoxForalreadyAssignRoleSkill,
        setDisplayMessageBoxForalreadyAssignRoleSkill
    ] = useState(false);
    const [assignToLearner, setAssignToLearner] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isManager, setIsManger] = useState(false);
    const [payloadForLearner, setPayloadForLearner] = useState([]);
    const [selectedOptionForLerner, setSelectedOptionForLerner] = useState("");
    const [_userId, set_UserId] = useState();
    const [settingSkillNameandDescription] = useState(sessionStorage.getItem("settingSkillNameandDescription")?JSON.parse(sessionStorage.getItem("settingSkillNameandDescription")):[]);
    const {roleWiseSkillsData, dispatch } = useContext(appContext);
    const Navigate = useNavigate();
    const location = useLocation();
    const name = location?.state?.name || (settingSkillNameandDescription && settingSkillNameandDescription[3]) || "Additional Skill";
    const { userProfile } = useSelector((state) => state?.userProfile);
    let crumbs1 = location?.state?.crumbs ?location?.state?.crumbs:[];

    const crumbs = [...crumbs1, { label: "View Content", url: location }];
    const capitalizeFirstLetter = (sentence) => {
        return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    };

    const capitalizeSentences = (text) => {
        var sentences = text?.split(" ");
        for (var i = 0; i < sentences?.length; i++) {
            sentences[i] = capitalizeFirstLetter(sentences[i]?.trim());
        }
        return sentences?.join(" ");
    };

    const getRoleWiseSkillsData = async () => {
        if (name === "Additional Skill") {
            let skill = settingSkillNameandDescription[4]?.skillId;
            const response = await apis.getRoleWiseSkillsData(name, skill);
            if (!response) {
                dispatch({ type: "RoleWiseSkillsItem", payload: [] });
                return;
            } else {
                dispatch({ type: "RoleWiseSkillsItem", payload: response.data });
            }
        } else {
            const response = await apis.getRoleWiseSkillsData(name);
            if (!response) {
                dispatch({ type: "RoleWiseSkillsItem", payload: [] });
                return;
            } else {
                dispatch({ type: "RoleWiseSkillsItem", payload: response.data });
            }
        }
    };

    useEffect(() => {
        getRoleWiseSkillsData();
        if (settingSkillNameandDescription.length > 0) {
            setIsManger(userProfile?.attributes?.roles?.indexOf("Manager") > -1 ? true : false);
            setShowLoader(false);
            setSkillName(settingSkillNameandDescription[0]);
            setSkillDescription(settingSkillNameandDescription[1]);
            setSkillGroupName(capitalizeSentences(settingSkillNameandDescription[2]));
        } else {
            Navigate("/myskilljourney");
        }
    }, [settingSkillNameandDescription]);

    const handleSubmit = async (value) => {
        try {
            const payload = [
                {
                    roleId: value?.RoleId || value.roleId,
                    groupId: value.SkillGroupId || value.groupId,
                    skillId: value.SkillId || value.skillId,
                    RoleName: value.RoleName,
                    SkillName: value.SkillName || value.skillName,
                    SkillGroupName: value.SkillGroupName || value.skillGroup
                }
            ];
            let res = await apis.getCheckUserRoleSkills(payload);

            if (res?.status == false) {
                setDisplayMessageBox(true);
            } else {
                setDisplayMessageBox(false);
                Navigate("/myskillJourney", {
                    state: { submitForApproval: true, viewContent: true, pathname: location }
                });
            }

            let newObj = {
                ...res,
                roleWiseSkillsData: roleWiseSkillsData,
                payload: payload
            };
            dispatch({ type: "CheckUserRoleSkillsItem", payload: newObj });
        } catch (error) {
            console.log(error);
        }
    };

    const handleCloseDisplayMessage = () => {
        setDisplayMessageBox(false);
        setDisplayMessageBoxForConfirmation(false);
        setDisplayMessageBoxForalreadyAssignRoleSkill(false);
    };

    const handleShow = () => setShowModal(!showModal);

    const handleOkDisplayMessage = () => {
        setDisplayMessageBox(false);
        setDisplayMessageBoxForConfirmation(false);
        setDisplayMessageBoxForalreadyAssignRoleSkill(false);
        Navigate("/myskillJourney", {
            state: { submitForApproval: true, viewContent: true, pathname: location }
        });
    };

    const getPayloadForValidation = (roleskillGroupData, userIds) => {
        const uniqueRoleskillGroupData = [];
        roleskillGroupData.map((element) => {
            const isElementExist = uniqueRoleskillGroupData.find((ele) => {
                return (
                    ele.roleId === element.roleId &&
                    ele.groupId === element.groupId &&
                    ele.skillId === element.skillId
                );
            });
            if (!isElementExist) {
                uniqueRoleskillGroupData.push(element);
            }
        });
        return {
            roleSkillGroupData: uniqueRoleskillGroupData,
            users: [...new Set(userIds)]
        };
    };

    const handleCloseModal = () => {
        setShowModalshowModalforSuccessorFail(false);
        setMessageforPopup("");
        setShowLoader(false);
    };

    const handleChange = async (value) => {
        setAssignToLearner(false);
        const state = [
            {
                roleId: value?.RoleId || value.roleId,
                groupId: value.SkillGroupId || value.groupId,
                skillId: value.SkillId || value.skillId,
                RoleName: value.RoleName,
                SkillName: value.SkillName || value.skillName,
                SkillGroupName: value.SkillGroupName || value.skillGroup
            }
        ];

        setShowLoader(true);
        let res = await apis.validateUserRoleSkill(
            getPayloadForValidation(state, [JSON.parse(localStorage.getItem("CPToken")).user_id])
        );
        setShowLoader(true);
        setCheckUserRes(res);
        if (!res?.assignUserSkills?.response) {
            setDisplayMessageBoxForConfirmation(false);
            setDisplayMessageBoxForalreadyAssignRoleSkill(true);
            setShowLoader(false);
        } else {
            setDisplayMessageBoxForConfirmation(true);
            setDisplayMessageBoxForalreadyAssignRoleSkill(false);
            // setRoleskill("Skill(s)");
            setShowLoader(true);
            setShowLoader(false);
        }
    };

    const handleChangeForLearner = (value) => {
        setAssignToLearner(true);
        const state = [
            {
                roleId: value?.RoleId || value.roleId,
                groupId: value.SkillGroupId || value.groupId,
                skillId: value.SkillId || value.skillId,
                RoleName: value.RoleName,
                SkillName: value.SkillName || value.skillName,
                SkillGroupName: value.SkillGroupName || value.skillGroup
            }
        ];
        setPayloadForLearner(state);
        setSelectedOptionForLerner(2);
        setDisplayMessageBoxForConfirmation(false);
        setDisplayMessageBoxForalreadyAssignRoleSkill(false);
        setShowModal(true);
    };

    const assignRoleSkillGrpIds = async () => {
        setShowLoader(true);
        try {
            let payload;
            const updatedUserId = _userId?.join(",");
            let roleSkillGrpIds = settingSkillNameandDescription[4]?.RoleSkillGroupsId?.toString();

            if (
                !settingSkillNameandDescription[4]?.RoleId &&
                !settingSkillNameandDescription[4]?.SkillGroupId
            ) {
                payload = {
                    users: updatedUserId || userProfile?.id,
                    roleSkillGrpIds: "",
                    skills: settingSkillNameandDescription[4]?.skillId?.toString()
                };
            } else {
                payload = {
                    users: updatedUserId || userProfile?.id,
                    roleSkillGrpIds:
                        roleSkillGrpIds || settingSkillNameandDescription[4]?.roleSkillGroupId,
                    skills: ""
                };
            }
            setShowLoader(true);
            setWarningPopup("");
            setDisplayMessageBoxForConfirmation(false);
            const response = await apis.assignSkillToUser(payload);
            setShowLoader(true);
            if (!response) {
                setShowLoader(false);
                setMessageForFailure(
                    "An error occurred while assigning Skill(s), please try again."
                );
                setWarningPopup("");
                setMessageforPopup("");
                setShowModalshowModalforSuccessorFail(true);
            } else {
                if (response?.status === 200) {
                    assignToLearner
                        ? setMessageforPopup(
                              "Selected Skill(s) have been assigned successfully to the Learner(s)"
                          )
                        : setMessageforPopup(
                              "Selected Skill(s) have been assigned successfully to you."
                          );
                } else {
                    setWarningPopup(response?.data?.message);
                }
                setDisplayMessageBoxForalreadyAssignRoleSkill(false);
                setShowLoader(false);
                setMessageForFailure("");
                setShowModalshowModalforSuccessorFail(true);
                getRoleWiseSkillsData();
            }
            // }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4 roleskillsjourney">
                <div className="headerImage">
                    <h1>{name || settingSkillNameandDescription[3]}</h1>
                </div>
                {showLoader ? (
                    <Loader />
                ) : (
                    <Container fluid className="viewSession mt-4 p-4">
                        <Container fluid className="px-4 ViewDetail p-3">
                            <div className="my-1">
                                {/*<button className="btn-back" onClick={() => handlerBack()}> Back</button>*/}
                                <div className="d-flex justify-content-between align-baseline">
                                    <div className="d-flex flex-column">
                                        <p className="head-text m-0">{skillName}</p>
                                        <p>{skillGroupName}</p>
                                    </div>
                                    <div>
                                        <button
                                            className="btn primary-btn m-2"
                                            onClick={() =>
                                                handleChange(settingSkillNameandDescription[4])
                                            }
                                            style={{ textTransform: "none" }}>
                                            Assign Skill to Myself
                                        </button>
                                        {isManager && (
                                            <button
                                                className="btn primary-btn m-2"
                                                onClick={() =>
                                                    handleChangeForLearner(
                                                        settingSkillNameandDescription[4]
                                                    )
                                                }
                                                style={{ textTransform: "none" }}>
                                                Assign Skill to Learner
                                            </button>
                                        )}
                                        <button
                                            className="btn primary-btn primary-blue"
                                            onClick={() =>
                                                handleSubmit(settingSkillNameandDescription[4])
                                            }>
                                            Submit Skill for Approval
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <p className="head-text m-0">Skill Description</p>
                                    {/* <p>{skillDescription}</p> */}
                                    <div
                                        style={{ display: "inline" }}
                                        className="assigned"
                                        dangerouslySetInnerHTML={{
                                            __html: skillDescription
                                        }}></div>
                                </div>
                            </div>
                        </Container>

                        <Container fluid className="ViewDetail p-3">
                            <h3 className="head-text px-1 mb-4">Courses / Learning Paths</h3>

                            <div className="list-view px-3">
                                <CourseCard skillName={skillName} crumbs1={crumbs1} />
                            </div>
                        </Container>
                    </Container>
                )}
                <div className="modal-container-skills">
                    <Modal show={displayMessageBox} centered>
                        <Modal.Body>
                            <div className="failure-modal-container-body my-5">
                                <img className="failed-icon" src={failed_icon} alt="failed"></img>
                                <div className="success-text-message2 pt-3 p-0">
                                    Selected Skill is not assigned to you. Do you want to
                                    proceed?
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="failure-modal-container-footer">
                                <button
                                    className="btn btn-link p-2"
                                    onClick={() => handleCloseDisplayMessage()}>
                                    Cancel
                                </button>
                                <button
                                    className="btn primary-btn primary-blue m-2"
                                    onClick={() => handleOkDisplayMessage()}>
                                    Ok
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
                <Modal centered show={displayMessageBoxForConfirmation}>
                    <Modal.Body>
                        <div className="success-modal-container my-5 text-center">
                            <img
                                className="failed-icon mx-auto"
                                src={Failure_Icon}
                                alt="failed"></img>
                            {assignToLearner ? (
                                <div className="success-text-message2 p-0 pt-3">
                                    Are you sure you want to assign selected Skill to the
                                    selected Learner(s)?
                                </div>
                            ) : (
                                <div className="success-text-message2 p-0 pt-3">
                                    Are you sure you want to assign selected Skill to you?
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-link m-2"
                            onClick={() => handleCloseDisplayMessage()}>
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn primary-blue m-2"
                            onClick={() => assignRoleSkillGrpIds()}>
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
                <ModalforSuccessAndFailure
                    messageforPopup={messageforPopup}
                    messageForFailure={messageForFailure}
                    warningPopup={warningPopup}
                    show={showModalforSuccessorFail}
                    onClose={handleCloseModal}
                />
                <Modal centered show={displayMessageBoxForalreadyAssignRoleSkill}>
                    <Modal.Body>
                        <div className="success-modal-container my-5 text-center">
                            <img
                                className="failed-icon mx-auto"
                                src={Failure_Icon}
                                alt="failed"></img>
                            <div className="success-text-message2 p-0 pt-3">
                                {assignToLearner ? (
                                    checkUserRes?.assignUserSkills?.msg === "all_assigned" ? (
                                        <>
                                            Selected Skill is already assigned to selected
                                            Learner(s).
                                        </>
                                    ) : (
                                        <>
                                            Selected Skill is already assigned to one or more
                                            selected Learner(s). Do you want to proceed with
                                            assigning the Skill to rest of the Learner(s)?
                                        </>
                                    )
                                ) :
                                    <>Selected Skill is already assigned to you.</>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!checkUserRes?.assignUserSkills?.response &&
                        checkUserRes?.assignUserSkills?.msg === "all_assigned" ? (
                            <>
                                <button
                                    className="btn primary-btn primary-blue m-2"
                                    onClick={() => handleCloseDisplayMessage()}>
                                    Ok
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className="btn btn-link m-2"
                                    onClick={() => handleCloseDisplayMessage()}>
                                    No
                                </button>
                                <button
                                    className="btn primary-btn primary-blue m-2"
                                    onClick={() => assignRoleSkillGrpIds()}>
                                    Yes
                                </button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>
                <AssignRoleToTeam
                    handleShow={handleShow}
                    showModal={showModal}
                    selectedOptionForLerner={selectedOptionForLerner}
                    assignRoleSkillGrpIds={assignRoleSkillGrpIds}
                    handleCloseDisplayMessage={handleCloseDisplayMessage}
                    name="Select Learner(s)"
                    getPayloadForValidation={getPayloadForValidation}
                    setDisplayMessageBoxForalreadyAssignRoleSkill={
                        setDisplayMessageBoxForalreadyAssignRoleSkill
                    }
                    displayMessageBoxForalreadyAssignRoleSkill={
                        displayMessageBoxForalreadyAssignRoleSkill
                    }
                    state={payloadForLearner}
                    displayMessageBoxForConfirmation={displayMessageBoxForConfirmation}
                    setDisplayMessageBoxForConfirmation={setDisplayMessageBoxForConfirmation}
                    setCheckUserRes={setCheckUserRes}
                    set_UserId={set_UserId}
                />
            </Container>
            <Scroller />
        </>
    );
};

export default ViewDetail;
