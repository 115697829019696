import { useState, useEffect } from "react";
import { debounce, orderBy } from "lodash";
import { searchFiltered } from "../utils/dataTable";

// Custom hook for managing the reports data and filtering
const useDataReports = ({ data = [], pageName = "" }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [sortKey, setSortKey] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    // const [filteredData, setFilteredData] = useState([]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm]);

    const handleSort = (key) => {
        if (key === sortKey) {
            const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
            setSortOrder(newSortOrder);
        } else {
            setSortKey(key);
            setSortOrder("asc");
        }
        setCurrentPage(1);
    };

    const handleInputChange = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        debouncedSearch();
    };

    const debouncedSearch = debounce(() => {
        setCurrentPage(1);
    }, 500);
    // const handlerFilteredData = () => {
    //     const filtered = searchFiltered(filteredData, searchTerm);
    //     setFilteredData(filtered);
    // };
    const filteredData = searchFiltered(data, searchTerm, pageName);
    // // If there is no data, ensure no further operations break the UI
    // if (!filteredData || filteredData.length === 0) {
    //     return {
    //         searchTerm,
    //         filteredData: [],
    //         currentItems: [],
    //         currentPage,
    //         setCurrentPage,
    //         itemsPerPage,
    //         sortKey,
    //         sortOrder,
    //         handleSort,
    //         handleInputChange
    //     };
    // }

    const sortedData = orderBy(
        filteredData,
        [
            (row) => {
                if (sortKey === "Progress") {
                    return parseFloat(row[sortKey].replace("%", ""));
                } else if (sortKey === "templateId") {
                    // Custom logic for sorting by templateId (extracting number after underscore)
                    const templateNumber = parseInt(row[sortKey].split("_")[1], 10);
                    return templateNumber;
                } else {
                    return row[sortKey];
                }
            }
        ],
        [sortOrder]
    );
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
    // useEffect(() => {
    //     if (!searchTerm) {
    //         setFilteredData(data);
    //     }
    // }, [data, searchTerm]);
    // Check if the currentPage exceeds total pages, and reset to 1 if needed
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(1);
        }
    }, [currentItems, currentPage, filteredData.length, totalPages]);
    return {
        searchTerm,
        filteredData,
        currentItems,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        sortKey,
        sortOrder,
        handleSort,
        handleInputChange
    };
};

export default useDataReports;
