/* eslint-disable */
import Accordion from 'react-bootstrap/Accordion';
import CustomAccordionApprovalPreviewHeader from './CustomAccordionApprovalPreviewHeader';
import CustomAccordionApprovalPreviewItem from './CustomAccordionApprovalPreviewItem';
import { useEffect,useState } from 'react';

function CustomAccordionApprovalPreview({ index,data, handleProficiencyLevel, proficiencyLevelsGroup }) {
  const [_data, _setData] = useState()
  const [_activeKeys,_setactiveKeys] = useState();
  const keys =[];

  useEffect(() => {
    _setData(data);
    data &&
      data.map((elm, i) =>{
        keys.push(i)
      });
      _setactiveKeys(keys);
  }, [data])
  
  return (
    <Accordion key={index} alwaysOpen={true} defaultActiveKey={keys} className='custom-accordion'>
      {_data &&
        _data.map((elm, i) => (
          <Accordion.Item eventKey={i} key={elm.id}>
            <Accordion.Header>{elm.title}</Accordion.Header>
            <Accordion.Body>
              <CustomAccordionApprovalPreviewHeader />
              {
                  <CustomAccordionApprovalPreviewItem key={i} data={elm} handleProficiencyLevel={handleProficiencyLevel} proficiencyLevelsGroup = {proficiencyLevelsGroup} />
              }
            </Accordion.Body>
          </Accordion.Item>
        ))}
    </Accordion>
  );
}

export default CustomAccordionApprovalPreview;