import React from "react";
import "primeicons/primeicons.css";
import "./RatingFeedback.css"

function RatingFeedBack({ number, handleRating }) {
    
    let ui = [];
    const wholeNumber = Math.floor(number); // Use Math.floor to get the whole number part
    const floatingValue = number - wholeNumber;
    for (let i = 0; i < wholeNumber; i++) {
   
        ui.push(<button
            key={"rating" + i}
            style={{ color: "#FEC030" }}
            onClick={() => handleRating(i + 1)}
            className="btn p-0">
            <i className="pi pi-star-fill" style={{ fontSize: "2rem", color: "#FEC030"}}></i>
        </button>);
    }

    if (floatingValue > 0) {
        ui.push(
            <button
                key={"ratingFloating"}
                style={{ color: "#FEC030" }}
                onClick={() => handleRating(wholeNumber + 1)}
                className="btn p-0">
                <i
                    className="pi pi-star-fill"
                    style={{
                        fontSize: "2rem",

                        color: "#FEC030",

                        clipPath: `inset(0 ${100 - floatingValue * 100}% 0 0)`
                    }}></i>
            </button>
        );
    }

    if (wholeNumber + Math.ceil(floatingValue) < 5) {
        for (let i = wholeNumber + Math.ceil(floatingValue); i < 5; i++) {
            ui.push(
                <button
                    key={"rating" + i}
                    style={{ color: "#FEC030" }}
                    onClick={() => handleRating(i + 1)}
                    className="btn p-0">
                    {/* {  number > 0 ? */}
                    {/* <i className="pi pi-star" style={{ fontSize: "2rem", color: "#5C5555"}}></i>: */}
                    <i className="pi pi-star" style={{ fontSize: "2rem", color: "#5C5555" }}></i>
                    {/* } */}
                </button>
            );
        }
    }
    return ui;
}

export default RatingFeedBack;