/* eslint-disable */
export const tableClassName = "custom-accordion-tbl-skill-submission";
export const _dataSS = [
    {
      id: 1,
      skills: [
        {
          skillName: 'Alex Harrison',
          history:[
            {
              skillName: 'Alex Harrison',
              role: 'Lorem ipsum dolor',
              skill:'Technical Skills',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Not Started',
              keynote:"",
hasActionBtns:true
            },
            {
              skillName: 'Alex Harrison',
              role: 'Lorem ipsum dolor',
              skill:'Technical Skills',
              skillGroup: 'Technical Skills',
              currentLevel: '100',
              targetLevel: '300',
              submittedDate: '04/18/2023',
              aprroveDate: '04/18/2023',
              status: 'Rejected',
              keynote:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery"
            }
          ]
        },
        {
          skillName: 'Alexis Mullinis',
tableClassName: tableClassName,
          history: [
            {
              skillName: 'Alexis Mullinis',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
hasActionBtns:true,
            }
          ]
        },
        {
          skillName: 'Andrea Ramirez',
          history: [
            {
              skillName: 'Andrea Ramirez',
              role: 'Lorem ipsum dolor',
skill:'Technical Skills',
          skillGroup: 'TechnicalSkills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'In Progress',
          keynote:"",
hasActionBtns:true,
            }
          ]
        },
        {
          skillName: 'Bennie',
          history: [{
            skillName: 'Bennie',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Approved',
          keynote:"",
hasActionBtns:true,
          }]
        },
        {
          skillName: 'Christian Cole',
          history: [{
            skillName: 'Christian Cole',
            role: 'Lorem ipsum dolor',
skill:'Technical Skills',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Awaiting Approval',
          keynote:"",
hasActionBtns:true,
          }]
        },
        {
          skillName: 'John Deo',
          history: [{
            skillName: 'John Deo',
            role: 'Lorem ipsum dolor',
skill:'Technical Skills',
          skillGroup: 'Soft Skills',
          currentLevel: '100',
          targetLevel: '300',
          submittedDate: '04/18/2023',
          aprroveDate: '04/18/2023',
          status: 'Awaiting Approval',
          keynote:"",
hasActionBtns:true,
          }]
        }
      ]
    }
  ];
export const _dataApprovalPreviewSS = [
    {
      id: 1,
      title: 'AEM Support Engineer',
      skills: [
        {
          skillName: 'AEM Sites: Authoring/Foundation',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
            {
              label: 'Level 100',
              status: 'started',
        checked: false
              },
              {
              label: 'Level 200',
              status: 'started',
        checked: false
              },
              {
              label: 'Level 300',
              status: 'started',
        checked: false
              }
          ]
        },
        {
          skillName: 'AEM Sites: Communities',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Integrations Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    },
    {
      id: 2,
      title: 'Marketo',
      skills: [
        {
          skillName: 'AEM Sites: Authoring / Foundation',
          skillGroup: 'Technical',
		  proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Communities',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites Integrations: Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    },
    {
      id: 3,
      title: 'Magento',
      skills: [
        {
          skillName: 'AEM Sites: Authoring / Foundation',
          skillGroup: 'Technical',
		  proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Communities',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites Integrations: Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    },
    {
      id: 4,
      title: 'Campaign',
      skills: [
        {
          skillName: 'AEM Sites: Authoring / Foundation',
          skillGroup: 'Technical',
		  proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Communities',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites Integrations: Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    },
    {
      id: 5,
      title: 'AAM',
      skills: [
        {
          skillName: 'AEM Sites: Authoring / Foundation',
          skillGroup: 'Technical',
		  proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Communities',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites Integrations: Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    },
    {
      id: 6,
      title: 'Analytics',
      skills: [
        {
          skillName: 'AEM Sites: Authoring / Foundation',
          skillGroup: 'Technical',
		  proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites: Communities',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'AEM Sites Integrations: Campaign',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
        {
          skillName: 'Technical Skill 3',
          skillGroup: 'Technical Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'started',
        checked: false
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 2',
          role: 'Lorem ipsum dolor',
skill:'Technical Skills',
skillGroup: 'Professional Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 200',
				status: 'started',
        checked: false
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        },
		{
          skillName: 'Technical Skill 1',
          skillGroup: 'Soft Skills',
          proficiencyLevels: [
			  {
				label: 'Level 100',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 200',
				status: 'completed',
        checked: null
			  },
			  {
				label: 'Level 300',
				status: 'completed',
        checked: null
			  }
		  ]
        }
      ]
    }
  ];
export const _tableHeaderSS = [
  {id:1, title:"Learner",property:"Name"},
  {id:2, title:"Role",property:"Role"},
  {id:3, title:"Skill",property:"Skills"},
  // {id:4, title:"Skill Group",property:"SkillGroup"},
  {id:5, title:"Submitted Level",property:"SubmittedLevel"},
  {id:6, title:"Target Level",property:"TargetLevel"},
  {id:7, title:"Submitted Date",property:"SubmittedDateTime"},
  {id:8, title:"Approved/ Rejected Date",property:"ApprovalOrRejectedDate"},
  {id:9, title:"Status",property:"Status"},
  {id:10, title:"Action",property:"SubmittedLevel"}
];
export const _optionStatusSS = [{
    "id": 1,
    "value": "Not-Started",
    "label": "Not Started"
  }, {
    "id": 2,
    "value": "Awaiting-Approval",
    "label": "Awaiting Approval"
  }, {
    "id": 3,
    "value": "In-Progress",
    "label": "In Progress"
  }, {
    "id": 4,
    "value": "Approved",
    "label": "Approved"
  }, {
    "id": 5,
    "value": "Rejected",
    "label": "Rejected",
  }];
export const _optionStatus = [
  {
    "id": 2,
    "value": "Awaiting Approval",
    "label": "Awaiting Approval"
  }, {
    "id": 4,
    "value": "Approved",
    "label": "Approved"
  }, {
    "id": 5,
    "value": "Rejected",
    "label": "Rejected",
  }];
export const _optionLearnerSS = [{
    "id": 1,
    "value": "9ce42304-b732-4791-9731-6f299b6df8c7",
    "label": "Alex"
  }, {
    "id": 2,
    "value": "90419f06-7d07-45c8-bcec-d675096fe27f",
    "label": "Alexis"
  }, {
    "id": 3,
    "value": "90419f06-7d07-45c8-bcec-d675096fe28d",
    "label": "Andrea"
  }, {
    "id": 4,
    "value": "a23521da-0a48-4ef6-baa2-d727704f65c2",
    "label": "Bennie"
  }, {
    "id": 5,
    "value": "34b2b58a-0123-49e2-b2de-1eef0922139b",
    "label": "John Deo",
  }];
export const _optionRolesSS = [{
    "id": 1,
    "value": "9ce42304-b732-4791-9731-6f299b6df8c7",
    "label": "AEM Support Engineer"
  }, {
    "id": 2,
    "value": "90419f06-7d07-45c8-bcec-d675096fe27f",
    "label": "Marketo"
  }, {
    "id": 3,
    "value": "a23521da-0a48-4ef6-baa2-d727704f65c2",
    "label": "Magento"
  }, {
    "id": 4,
    "value": "34b2b58a-0123-49e2-b2de-1eef0922139b",
    "label": "Campaign"
  }, {
    "id": 5,
    "value": "a23521da-0a48-4ef6-baa2-d727704f68c6",
    "label": "AAM"
  }, {
    "id": 6,
    "value": "34b2b58a-0123-49e2-b2de-1eef0922169d",
    "label": "Analytics"
  }]
export const _optionSkillSS = [{
    "id": 1,
    "value": "9ce42304-b732-4791-9731-6f299b6df8c7",
    "label": "Skill 1"
  }, {
    "id": 2,
    "value": "90419f06-7d07-45c8-bcec-d675096fe27f",
    "label": "Skill 2"
  }, {
    "id": 3,
    "value": "a23521da-0a48-4ef6-baa2-d727704f65c2",
    "label": "Skill 3"
  }, {
    "id": 4,
    "value": "34b2b58a-0123-49e2-b2de-1eef0922139b",
    "label": "Skill 4"
  }, {
    "id": 5,
    "value": "a23521da-0a48-4ef6-baa2-d727704f68c6",
    "label": "Skill 5"
  }, {
    "id": 6,
    "value": "34b2b58a-0123-49e2-b2de-1eef0922169d",
    "label": "Skill 6"
  }]