/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React from "react";
import card_img from "../../../src/assets/images/card_img.png";
import lp_card_img from "../../../src/assets/images/lp_card_img.png";
import certi_card_img from "../../../src//assets/images/certi_card_img.png";
import jobaid_card_img from "../../../src//assets/images/jobaid_card_img.png";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PrerequisiteCourse({ coreContentItem, courseDetails, startUi,countOfPrerequisiteLength ,mandatoryCountForPrerequisite }) {
    const naviagte = useNavigate();
    const location = useLocation();
    let { state } = location;
    const crumbs = state?.crumbs || [];
    const { t } = useTranslation();

    let { instanceName, instanceId, loType, authorNames, loFormat, description,isMandatoryPrerequisite, hasPassedForPrerequisite, progressPercentForPrerequisite  } = {
        ...coreContentItem
    };
    let { id, attributes } = { ...courseDetails };

    const hanldeInstance = () => {
        window.location.href = `/coursedetails/${instanceId}`
    };
    return (
        <div className="list-view">
            <div className="row mb-4 list-view-block cd justify-content-center justify-content-md-start mx-0">
                <div className="col-auto py-4 px-4">
                    {loType==="learningProgram" ?
                        <img
                            src={lp_card_img}
                            alt={t("loading")}
                            className="list-view-block-img"
                        />
                        :
                        loType === "certification" ?
                            <img
                                src={certi_card_img}
                                alt={t("loading")}
                                className="list-view-block-img"
                            />
                            :
                            loType === "jobAid" ?
                                <img
                                    src={jobaid_card_img}
                                    alt={t("loading")}
                                    className="list-view-block-img"
                                />
                                :
                                <img
                                    src={card_img}
                                    alt={t("loading")}
                                    className="list-view-block-img"
                                />  
                    }
                </div>
                <div className="col pe-4 text-padding" style={{ cursor: "pointer" }} onClick={hanldeInstance}>
                    <span className="head-text">{instanceName}</span>
                    <span>
                        {(isMandatoryPrerequisite) && (courseDetails?.attributes?.loType == "learningProgram") && ((mandatoryCountForPrerequisite !== countOfPrerequisiteLength) && (isMandatoryPrerequisite === true) ? <span className="section-btn">Required</span> : null)}
                        {(isMandatoryPrerequisite === false) && (courseDetails?.attributes?.loType == "learningProgram") &&  ((mandatoryCountForPrerequisite !== 0) && (isMandatoryPrerequisite === false) ? <span className="optional-btn">Optional</span> : null) }
                    </span>
                    <span>
                        { courseDetails?.relationships?.enrollment &&
                         ( hasPassedForPrerequisite ? <span className="completed-btn">Completed </span> : (progressPercentForPrerequisite > 0 && progressPercentForPrerequisite <= 100) ? <span className="inprogress-btn">In Progress </span> : <span className="notStarted-btn">Not Started </span>)
                        }
                    </span>
                    <div>
                        <span className="">{description}</span>
                    </div>
                </div>
                <div className="list-footer align-items-center">
                    <div>
                        <span className="">
                            {loFormat} <span className="seprator px-2"> | <span className="capsWord">{coreContentItem?.loType === "learningProgram"? "Learning Path" : coreContentItem?.loType}</span> | </span> {authorNames ?? "-"}
                        </span>
                    </div>
                    <div className="text-padding"> 
                        <button className="btn primary-btn enrollbtn" onClick={hanldeInstance}>
              View Details
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
