import React from "react";
// import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Filtersearch from "../FilterSearch/FilterSearch";
import { useState } from "react";
import Breadcrumbs from "../../common/Breadcrumbs";

const AllCourses = () => {
    const { t } = useTranslation();
    const [notenrolled] = useState(true);
    // const [myLearning] = useState(true);
    const crumbs = [
        { label: "Home", url: "/" },
        { label: "All Courses", url: "/allcourses" },
    ];
    return (
        <>
            {/* <Container fluid className="pl-30 sub-menu-list">

                <Row>
                    <div className="sub-menu">
                        <span>Home</span>
                        <span className="px-2">&#62;</span>
                        <span className="sub-page">{t("all-courses-label")}</span>
                    </div>
                </Row>
            </Container> */}
            <Breadcrumbs crumbs={crumbs} />

            <Container fluid className="pl-30 py-4">


                <div className="headerImage">
                    <h1>{t("all-courses-label")}</h1>
                </div>


            </Container>

            <Filtersearch notenrolled={notenrolled} MyLearning={false}></Filtersearch>
        </>
    )
}

export default AllCourses
