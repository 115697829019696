import React from "react";
import Card from "react-bootstrap/Card";
import DummyProfile from "../../assets/images/dummyProfile.svg";

const index = ({certificationdata,key}) => {
    return (
        <Card style={{ width: "auto" }} key={key}>
            <Card.Img variant="top" className="img-fluid d-block mx-auto" src={certificationdata?.AvatarUrl?certificationdata?.AvatarUrl:DummyProfile}
            />
            <Card.Body>
                <Card.Title>{certificationdata?.LearnerName}</Card.Title>
                <Card.Subtitle className="">{certificationdata?.CertificationName}</Card.Subtitle>
                <Card.Text>{certificationdata?.LearnerRegion}</Card.Text>
            </Card.Body>
        </Card>
    )
}

export default index
