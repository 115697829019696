import Modal from "react-bootstrap/Modal";
import Failure1_Icon from "../../src/assets/images/Failure1_Icon.png"

function errorModal({error, showModal, closeModal}) {
    console.log("bahsbxhabshxahsbxhabsxAS",error);
    return (
        <>
            <div className="modal-container">
                <Modal show={showModal} className="reject-modal" centered>
                    <Modal.Body className="py-4 px-5">
                        <div className="failure-modal-container-body my-5">
                            <img className="failed-icon" src={Failure1_Icon} alt="failed"></img> 
                            <p className="success-text-message pt-4 pb-0">{error}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-right">
                        <button
                            className="btn primary-btn primary-blue m-2"
                            onClick={() => closeModal()}>
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default errorModal
