import React,{useState,useEffect,useContext} from "react";
import "react-calendar/dist/Calendar.css";
import time from "../../assets/images/time.svg";
//import user1 from "../../assets/images/user-1.svg";
import "./CourseCard.css";
import { appContext } from "../../context/appContext";
import secondsToHms from "../../../src/common/SecondsToHms/secondsToHms";
import { apis } from "../../services/Api";
// import { setButtonText } from "../../helper/Helper";
import { useNavigate,useLocation } from "react-router-dom";
// import InfiniteScroll from "react-infinite-scroll-component";
// import Loader from "../../common/Loader/Loader"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const CourseCard = ({skillName,crumbs1}) => {
    //const [enrollmentCounts,setEnrollmentCounts] =useState();
    let [courseList, setCourseList] = useState([]);
    // let [afterCursorData, setAfterCursorData] = useState([]);
    // let [hasMore, setHasMore] = useState(true);
    let [cursor, setCursor] = useState(null);
    let [noData, setNoData] = useState(false);
    let [includedArray, setIncludedArray] = useState([]);
    let [updatedArrayForCard, setUpdatedArrayForCard] = useState([]);
    // let [setCourseList, setSetCourseList] = useState([]);
    let [setIncludedList, setSetIncludedList] = useState([]);
    // let [commanSkills, setCommanSkills] = useState([]);
    let [progressMap, setProgressMap] = useState({});
    const naviagte = useNavigate();
    const { skillsListData,dispatch} = useContext(appContext);
    // const [getALlEnrollmentData, setGetALlEnrollmentData] = useState();
    const location = useLocation();
    const crumbs = [
        ...crumbs1,
        { label: "View Content", url: location },
    ];
  
    

    const apiCall = async (c) => {
        if(skillName){
            if(skillsListData.length === 0) {
                const response = await apis.getRolesAndSkills();
                dispatch({ type: "rolesListItem", payload: response.roles });
                dispatch({ type: "skillsListItem", payload: response.skills });
                dispatch({ type: "catalogsListItem", payload: response.catalogs });
            }
            let { data} = await apis.getLearningCoursesbySkillName(skillName,c,10,"course%2ClearningProgram");   

            if (data?.links?.next) {
                const urlParams = new URLSearchParams(data?.links?.next);
                const nextCursor = urlParams.get("page[cursor]");
                setCursor(nextCursor);
                // setHasMore(true);
            }
            if (!c) {
                setCourseList([]);
                data?.data?.length > 0 ? setNoData(false) : setNoData(true);
            }
            if(data && data.included ){
                setIncludedArray((oldArray)=>[...oldArray, ...data.included ])
                setSetIncludedList((oldArray)=>[...oldArray, ...data.included ])
            }
            setCourseList((oldArray)=>[...oldArray, ...data.data]);
        }
    }
 
    const AssociatedSkills=(item)=>{
        let tempSkillDetails = item?.relationships?.skills?.data?.map((element) => {
            let learningObjectSkill = setIncludedList.find((learningObjectSkillElement) => {
                return  element?.id.split("_")[0] === item.id && learningObjectSkillElement?.id === element?.id;
            });
            let skillLevel = setIncludedList.find((skillElement) => {
                
                return (
                    skillElement?.id === learningObjectSkill?.relationships?.skillLevel?.data?.id
                );
            });
            let skill = setIncludedList.find((skillElement) => {
                return (
                    skillElement?.id === skillLevel?.relationships?.skill?.data?.id
                );
            });

            let convertedLevel;
            switch (skillLevel?.attributes?.level) {
            case "1":
                convertedLevel= 100;
                break;
            case "2":
                convertedLevel= 200;
                break;
            case "3":
                convertedLevel= 300;
                break;
            }
            return {
                name: skill?.attributes?.name,
                level:convertedLevel
            };
        });

        const commonSkills=[];
        skillsListData.forEach((itemA) => {
            const matchingSkills = tempSkillDetails?.find((itemB) => itemB?.name === itemA?.SkillName);
            if (matchingSkills) {
                commonSkills.push({
                    name: matchingSkills?.name,
                    level: matchingSkills?.level
                });
            }
        });
        // if(commonSkills.length > 0){
        //     setCommanSkills(commonSkills);
        // }
        if (commonSkills.length === 0) {
            return [];
        } else {
            return commonSkills?.sort((a, b) => a.level - b.level)
        }
            
    
    }
    useEffect(() => {
        if(skillName){
            setCourseList([]);
            apiCall();
        }
    }, [skillName])

    const nextCursor = () => {
        if(cursor){
            apiCall(cursor);
        }
    };

    useEffect(() => {
        nextCursor();
    }, [cursor])
    
    
    
    
    // useEffect(() => {
    //     // getEnrollment();
    //     //enrollmentCount(courseList);
    // }, [courseList])

    // const enrollmentCount = async (data) => {
    //     const enrollmentCounts = [];
        
    //     for (let i = 0; i < data?.length; i++) {
    //         const result = await apis.getEnrollmentCount(
    //             data[i]?.id,
    //             data[i]?.relationships?.instances?.data[0]?.id 
    //         );
    //         enrollmentCounts.push(result.data?.attributes?.enrollmentCount);
    //     }
    //     setEnrollmentCounts(enrollmentCounts);
    // };

    const goToCourseDetails = (course_id, item) => {
        let lid = item?.relationships?.instances?.data[0]?.id
        let enrollment = item?.relationships?.enrollment?.data?.id ? true : false;
        if(!enrollment){
            naviagte("/coursedetails/"+course_id,{state:{crumbs}});
        }else{
            naviagte(`/coursedetails/${course_id}/${lid}`, { state: { crumbs } });
        }
    };


    useEffect(() => {
        // const progressMap = {};
        includedArray?.forEach(item => {
            if(item?.attributes?.state === "COMPLETED" || item?.attributes?.state === "ENROLLED" ){
                const id = item?.relationships?.learningObject?.data?.id;
                const progressPercent = item?.attributes?.progressPercent;
                progressMap[id] = progressPercent;
            }
        });
        let updatedArray = courseList && courseList.map(item => ({
            ...item,
            progressPercent: progressMap[item?.id] !== undefined ? progressMap[item?.id] : (item?.progressPercent),
            associatedSkills:AssociatedSkills(item)
        }));
        
        updatedArray = updatedArray.filter((item) => {
            return item?.associatedSkills?.find((element) => {
                return element.name === skillName
            });
        });
        let array100=[];
        let array200=[];
        let array300=[];
        let emptyArray=[];
        for(var i=0; i < updatedArray.length;i++){
            if( updatedArray[i].associatedSkills.length === 0){
                emptyArray.push(updatedArray[i]);
            }
            else if( updatedArray[i].associatedSkills?.find(ele=>ele.level === 100 && ele.name === skillName)){
                array100.push(updatedArray[i]);
            }
            else if( updatedArray[i].associatedSkills?.find(ele=>ele.level === 200 && ele.name === skillName)){
                array200.push(updatedArray[i]);
            }
            else if( updatedArray[i].associatedSkills?.find(ele=>ele.level === 300 && ele.name === skillName)){
                array300.push(updatedArray[i]);
            }
            else{
                emptyArray.push(updatedArray[i]);
            }
        }
        const finalSortedArray=[...array100,...array200,...array300,...emptyArray];
        setUpdatedArrayForCard([...new Set (finalSortedArray)]);
        if(updatedArray && updatedArray.length > 0 ) {
            let newArrayForCard= updatedArray.filter(e=>!e?.attributes?.localizedMetadata[0]?.name?.includes("DNS"));
            if(newArrayForCard.length === 0) {
                setNoData(true);
            }
        }
        setProgressMap(progressMap);
    }, [includedArray])
    

    return (
        <>
            {noData && <h4 className="no-data">No content found.</h4>}
            {updatedArrayForCard && (updatedArrayForCard?.filter(e => !e?.attributes?.localizedMetadata[0]?.name?.includes("DNS"))?.map((item,i)=>{
                return ( <>
                    <div className="row mb-4 list-view-block" > 
                        <div className="col-10 py-4 px-4" key={i}>
                            <p className="head-text-1">{item?.attributes?.localizedMetadata[0]?.name}</p>
                            <p className="sub-text">{item?.attributes?.localizedMetadata[0]?.description}</p>
                        </div>
                        <div className="list-footer align-items-center">
                            <OverlayTrigger
                                trigger="click"
                                placement="right"
                                rootClose
                                overlay={
                                    <Popover id="popover-trigger-click-root-close" style={{ zIndex: 1000 }}>
                                        {/* <Popover.Header>Associated Skills</Popover.Header> */}
                                        <Popover.Body>
                                            { item?.associatedSkills.length >0 ?
                                                item?.associatedSkills?.map((ele,index)=>
                                                    <div key={index}>
                                                        <span>
                                                            {ele.name} (Proficiency Level {ele.level})
                                                        </span>
                                                    </div>):<div>No associated skills are available</div>}
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <button className="associated-skill" type="button">
                      Associated Skills
                                </button>
                            </OverlayTrigger>
                            <span> <img src={time} className="px-2" alt="" title="Duration"></img>
                                {(item?.attributes?.duration !== 0 && item?.attributes?.duration !== undefined)?secondsToHms(item?.attributes?.duration): "No Duration"}</span>
                            {/*<span className="seprator ps-2"> | </span><img src={user1} className="px-2" alt="" title="Enrollment Count"></img><span className="px-2">{enrollmentCounts && enrollmentCounts[i] || 0}</span>*/}
                            {/* <span>{item?.progressPercent}</span> */}
                            {(item?.progressPercent || item?.progressPercent == 0) && <><div className="progress ms-4">
                                <div className="progress-bar" style={{ width: `${item?.progressPercent}%` }}></div>
                            </div><span className="px-3">{item?.progressPercent}%</span></>}
                            <button className="btn primary-btn my-2" type="button" onClick={() => goToCourseDetails(item?.id,item)}>View Details</button>
                        </div>
                
                    </div>
                </>)
            }
            ))
            }
        </>
        
    );
};
export default CourseCard;
