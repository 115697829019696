import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./BreadCrumb.css";

const Breadcrumbs = ({ crumbs }) => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(-1);

    const handleClick = (crumb, index) => {
        if (typeof crumb?.url === "object") {
            const { pathname, state } = crumb.url;
            navigate(pathname, { state });
        } else {
            navigate(crumb?.url);
        }
        setActiveIndex(index);
    };

    // Filter out the crumbs after the clicked index
    const displayedCrumbs = activeIndex >= 0 ? crumbs.slice(0, activeIndex + 1) : crumbs;

    return (
        <>
            {crumbs && 
            (<div className="breadCrumb">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="pl-30 mb-0">
                            {displayedCrumbs?.map((crumb, index) => {
                                const isLastCrumb = index === displayedCrumbs.length - 1;
                                const isSpecialLabel =
                                crumb.label === "Roles/Skills Journey" ||
                                crumb.label === "Solution Enablement" ||
                                crumb.label === "Onboarding" ||
                                crumb.label === "Role/Skill Journey" ||
                                crumb.label === "Customer Engineering" ||
                                crumb.label === "Customer Success";

                                return (
                                    <li key={index} className={`breadcrumb-item b-text ${isLastCrumb ? "active" : ""}`}>
                                        {isLastCrumb ? (
                                            crumb.label
                                        ) : isSpecialLabel ? (crumb.label && (
                                            <span className="breadcrumbLabel">{crumb.label}</span>
                                        )) :  (crumb.label &&(
                                            <button onClick={() => handleClick(crumb, index)} className="link-button">
                                                {crumb.label}
                                            </button>
                                        ))}
                                        {!isLastCrumb && <span className="separator">{" > "}</span>}
                                    </li>
                                );
                            })}
                        </ol>
                    </nav>
                </div>
            </div>)}
        </>
    );
};

export default Breadcrumbs;
