/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Select from "react-select";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { appContext } from "../../context/appContext";
import Col from "react-bootstrap/Col";
import { useNavigate, useLocation } from "react-router-dom";
import failed_icon from "../../assets/images/failed_icon.svg";
import "./RoleSkillsJourney.css";
import "../Onboarding/Onboarding.css";

import { apis } from "../../services/Api";
import ViewDetail from "./ViewDetail/ViewDetail";
import SkillList from "./SkillList/SkillList";
import Loader from "../../common/Loader/Loader";
import { useSelector } from "react-redux";
import AssignRoleToTeam from "./AssignRoleToTeam";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../common/Breadcrumbs";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";
import Failure_Icon from "../../../src/assets/images/Failure_Icon.png";
import Scroller from "../../common/Scroller/Scroller";

const RoleSkillsJourney = () => {
    const [selectedOption, setSelectedOption] = useState();
    const [selectedOptionForLerner, setSelectedOptionForLerner] = useState("");
    const [showViewDetail, setShowViewDetail] = useState();
    const [displayMessageBox, setDisplayMessageBox] = useState(false);
    const [skillGroupTab, setSkillGroupTab] = useState([]);
    const [skillGroupTabData, setSkillGroupTabData] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [showDataNotFound, setShowDataNotFound] = useState(false);
    const [selectedSkillIds, setSelectedSkillIds] = useState([]);
    const [selectedRollIds, setSelectedRollIds] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isManager, setIsManger] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSelected, setSetected] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [state, setState] = useState([]);
    const [updatePayload, setUpdatePayload] = useState([]);
    const [showModalforSuccessorFail, setShowModalshowModalforSuccessorFail] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [messageForFailure, setMessageForFailure] = useState("");
    const [warningPopup, setWarningPopup] = useState("");
    const [activeTab, setActiveTab] = useState();
    const [roleskill, setRoleskill] = useState("");
    const [checkUserRes, setCheckUserRes] = useState();
    const [displayMessageBoxForConfirmation, setDisplayMessageBoxForConfirmation] = useState(false);
    const [
        displayMessageBoxForalreadyAssignRoleSkill,
        setDisplayMessageBoxForalreadyAssignRoleSkill
    ] = useState(false);
    const [displayMessageBoxForalreadyAssignRole, setDisplayMessageBoxForalreadyAssignRole] =
        useState(false);
    const [stateForRole, setStateForRole] = useState([]);
    const [_userId, set_UserId] = useState();
    const [skillAssign, setSkillAssign] = useState(false);
    const { navigationNewData } = useContext(appContext);
    const location = useLocation();
    const crumbs = [
        { label: "Home", url: "/" }
        // { label: `${activeTab}`, url: "/onboarding" },
    ];
    const [assignToLearner, setAssignToLearner] = useState(false);
    const [msg, setMsg] = useState("");

    const findNestedObj = (entireObj, keyToFind, valToFind) => {
        let foundObj;
        JSON.stringify(entireObj, (_, nestedValue) => {
            if (nestedValue && nestedValue[keyToFind] === valToFind) {
                foundObj = nestedValue;
            }
            return nestedValue;
        });
        return foundObj;
    };

    const currentMenuObj = findNestedObj(
        navigationNewData,
        "MenuIdentifier",
        location?.pathname?.substring(location?.pathname?.lastIndexOf("/") + 1)
    );
    const name = currentMenuObj?.MenuTitle;
    const [previousName, setPreviousName] = useState(name);

    let completePath = [];
    const getParentPath = (object) => {
        if (object?.ParentMenu && parseInt(object?.ParentMenu) !== 0) {
            completePath.push(object?.MenuTitle);
            getParentPath(findNestedObj(navigationNewData, "MenuId", parseInt(object?.ParentMenu)));
        } else {
            completePath.push(object?.MenuTitle);
        }
    };
    getParentPath(currentMenuObj);

    const breadCrumb = completePath?.reverse();
    breadCrumb.forEach((data) => {
        crumbs.push({ label: data, url: location });
    });

    const handleSelectAll = (event) => {
        const selectAllChecked = event.target.checked;
        const selectAll = true;
        const updatedSelectedSkillIds = selectAllChecked
            ? skillGroupTabData?.map((item) => item.RoleSkillGroupsId)
            : [];
        setSelectedSkillIds(updatedSelectedSkillIds);
        handleSelectedIds(skillGroupTabData, updatedSelectedSkillIds, selectAll);
        setSelectAll(selectAllChecked);
    };

    const handleShow = () => setShowModal(!showModal);
    const { userProfile } = useSelector((state) => state.userProfile);
    const Navigate = useNavigate();

    const { roleWiseSkillsData, loginUser, dispatch } = useContext(appContext);

    const capitalizeFirstLetter = (sentence) => {
        return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    };

    const capitalizeSentences = (text) => {
        var sentences = text.split(" ");
        for (var i = 0; i < sentences.length; i++) {
            sentences[i] = capitalizeFirstLetter(sentences[i].trim());
        }
        return sentences.join(" ");
    };

    const skillGroupTabs = (roles) => {
        const tabs = roles.map((tab) => {
            const capitalizeContent = capitalizeSentences(tab.SkillGroupName);
            return {
                tabId: capitalizeContent.split(" ").join("_"),
                tabName: capitalizeContent
            };
        });

        var uniqueArray = tabs.filter((value, index, self) => {
            return index === self.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(value));
        });

        setSkillGroupTab(uniqueArray);
    };

    const getRoleWiseSkillsData = async () => {
        if (!name) {
            return;
        }
        setShowLoader(true);
        const response = await apis.getRoleWiseSkillsData(name);
        let payload = response.data.map((roleObj) => {
            return {
                roleId: roleObj.RoleId,
                groupId: roleObj.SkillGroupId,
                skillId: roleObj.SkillId
            };
        });
        setStateForRole(payload);
        if (!response) {
            setShowLoader(false);
            dispatch({ type: "RoleWiseSkillsItem", payload: [] });
            setSkillGroupTabData([]);
            setSkillGroupTab([]);
            setSelectedRollIds("");
            return;
        } else {
            setShowLoader(false);
            dispatch({ type: "RoleWiseSkillsItem", payload: response.data });

            setSelectedRollIds(String(response?.data[0]?.RoleId));
        }

        skillGroupTabs(response?.data);
        const temp = response.data.filter((item) => {
            return item.RoleName.trim().toUpperCase() === name.trim().toUpperCase();
        });
        if (temp.length > 0) {
            setShowDataNotFound(true);
        } else {
            setShowDataNotFound(false);
        }
        setSkillGroupTabData(temp);
    };

    const handleSelectedIds = (value, updatedSelectedSkillIds, selectAll) => {
        const temp = [...state];
        if (selectAll) {
            value.forEach((value) => {
                const newObj = {
                    roleId: value.RoleId,
                    groupId: value.SkillGroupId,
                    skillId: value.SkillId,
                    RoleName: value.RoleName,
                    SkillName: value.SkillName,
                    GroupName: value.SkillGroupName,
                    RoleSkillGroupsId: value.RoleSkillGroupsId,
                    TargetLevel: value.TargetLevel
                };
                temp.push(newObj);
            });
        } else {
            const obj = {
                roleId: value.RoleId,
                groupId: value.SkillGroupId,
                skillId: value.SkillId,
                RoleName: value.RoleName,
                SkillName: value.SkillName,
                GroupName: value.SkillGroupName,
                RoleSkillGroupsId: value.RoleSkillGroupsId,
                TargetLevel: value.TargetLevel
            };
            temp.push(obj);
        }
        let updatedTempdata = temp?.filter((item) =>
            updatedSelectedSkillIds?.includes(item.RoleSkillGroupsId)
        );
        setState(updatedTempdata);
    };

    const handleSubmit = async (e) => {
        if (selectedSkillIds.length > 0) {
            setShowLoader(true);
            try {
                const payload = state;
                let res = await apis.getCheckUserRoleSkills(payload);
                let newObj = {
                    ...res,
                    roleWiseSkillsData: roleWiseSkillsData,
                    payload: payload
                };

                dispatch({ type: "CheckUserRoleSkillsItem", payload: newObj });

                if (res?.status == false) {
                    setDisplayMessageBox(true);
                    setShowLoader(false);
                } else {
                    setDisplayMessageBox(false);
                    setShowLoader(false);
                    Navigate("/myskillJourney", {
                        state: { submitForApproval: true, pathname: location }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setMessageForFailure("Please select at least one Skill to continue.");
            setWarningPopup("");
            setMessageforPopup("");
            setShowModalshowModalforSuccessorFail(true);
        }
    };

    const handleCloseDisplayMessage = async (e) => {
        await dispatch({ type: "CheckUserRoleSkillsItem", payload: [] });
        setDisplayMessageBox(false);
        setDisplayMessageBoxForConfirmation(false);
        setDisplayMessageBoxForalreadyAssignRoleSkill(false);
        setDisplayMessageBoxForalreadyAssignRole(false);
    };

    const handleOkDisplayMessage = () => {
        setDisplayMessageBox(false);
        Navigate("/myskillJourney", { state: { submitForApproval: true ,  pathname: location } });
    };

    const handleConfirmForAssign = (e) => {
        if (selectedOption == 2) {
            if (selectedSkillIds.length > 0) {
                assignRoleSkillGrpIds().then(setSelectedSkillIds([]));
            } else {
                assignRoleSkillGrpIds("");
            }
        }
        if (selectedOption == 1) {
            if (selectedRollIds.length) {
                assignRoleIds().then(setSelectedSkillIds([]));
            } else {
                assignRoleIds("");
            }
        }
        setDisplayMessageBoxForalreadyAssignRole(false);
        setDisplayMessageBoxForConfirmation(false);
        setDisplayMessageBoxForalreadyAssignRoleSkill(false);
    };

    const assignRoleSkillGrpIds = async () => {
        setShowLoader(true);
        try {
            const updatedUserId = _userId?.join(",");
            const payload = {
                users: updatedUserId ?? userProfile?.id,
                roleSkillGrpIds: selectedSkillIds.join(","),
                skills: ""
            };
            if (selectedSkillIds?.length === 0) {
                setShowLoader(false);
                setMessageForFailure("Please select at least one Skill to continue.");
                setWarningPopup("");
                setMessageforPopup("");
                setShowModalshowModalforSuccessorFail(true);
            } else if (selectedSkillIds?.length > 0) {
                const response = await apis.assignSkillToUser(payload);
                if (!response) {
                    setShowLoader(false);
                    setMessageForFailure(
                        "An error occurred while assigning Skill(s), please try again."
                    );
                    setWarningPopup("");
                    setMessageforPopup("");
                    setShowModalshowModalforSuccessorFail(true);
                } else {
                    setShowLoader(false);
                    setMessageforPopup(
                        "Selected Skill(s) have been assigned successfully to " + msg
                    );
                    setMessageForFailure("");
                    setWarningPopup("");
                    setShowModalshowModalforSuccessorFail(true);
                    getRoleWiseSkillsData();
                    setSelectedSkillIds([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const assignRoleIds = async () => {
        try {
            const updatedUserId = _userId?.join(",");
            setShowLoader(true);
            const payload = {
                users: updatedUserId ?? userProfile?.id,
                roleid: selectedRollIds
            };
            if (selectedRollIds?.length === 0) {
                setShowLoader(false);
                setWarningPopup("Please select role and try again.");
                setMessageForFailure("");
                setMessageforPopup("");
                setShowModalshowModalforSuccessorFail(true);
            } else if (selectedRollIds?.length > 0) {
                const response = await apis.assignRollToUser(payload);
                if (!response) {
                    setShowLoader(false);
                    setMessageForFailure(
                        "An error occurred while assigning role, please try again."
                    );
                    setWarningPopup("");
                    setMessageforPopup("");
                    setShowModalshowModalforSuccessorFail(true);
                } else {
                    setShowLoader(false);
                    setMessageforPopup(response?.data?.message || response?.message);
                    setMessageForFailure("");
                    setWarningPopup("");
                    setShowModalshowModalforSuccessorFail(true);
                    getRoleWiseSkillsData();
                    setSelectedSkillIds([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getPayloadForValidation = (roleskillGroupData, userIds) => {
        const uniqueRoleskillGroupData = [];
        roleskillGroupData.map((element) => {
            const isElementExist = uniqueRoleskillGroupData.find((ele) => {
                return (
                    ele.roleId === element.roleId &&
                    ele.groupId === element.groupId &&
                    ele.skillId === element.skillId
                );
            });
            if (!isElementExist) {
                uniqueRoleskillGroupData.push(element);
            }
        });
        return {
            roleSkillGroupData: uniqueRoleskillGroupData,
            users: [...new Set(userIds)]
        };
    };

    const handleChange = async (selectedOption) => {
        setAssignToLearner(false);
        setSelectedOption(selectedOption);
        setMsg("you.");
        if (selectedOption == 2) {
            if (selectedSkillIds.length > 0) {
                setShowLoader(true);
                let res = await apis.validateUserRoleSkill(
                    getPayloadForValidation(state, [
                        JSON.parse(localStorage.getItem("CPToken")).user_id
                    ])
                );
                setCheckUserRes(res);
                if (!res?.assignUserSkills?.response) {
                    setDisplayMessageBoxForConfirmation(false);
                    setDisplayMessageBoxForalreadyAssignRoleSkill(true);
                    setRoleskill("Skill(s)");
                    setShowLoader(false);
                } else {
                    setDisplayMessageBoxForConfirmation(true);
                    setDisplayMessageBoxForalreadyAssignRoleSkill(false);

                    setRoleskill("Skill(s)");
                    setShowLoader(true);
                    setShowLoader(false);
                }
            } else {
                setDisplayMessageBoxForConfirmation(false);
                setDisplayMessageBoxForalreadyAssignRoleSkill(false);
                assignRoleSkillGrpIds("");
            }
        }
        if (selectedOption == 1) {
            if (selectedRollIds.length) {
                setShowLoader(true);
                let res = await apis.validateUserRoleSkill(
                    getPayloadForValidation(stateForRole, [
                        JSON.parse(localStorage.getItem("CPToken")).user_id
                    ])
                );
                setCheckUserRes(res);
                if (!res?.assignUserRoles?.response) {
                    setShowLoader(false);
                    setDisplayMessageBoxForConfirmation(false);
                    setDisplayMessageBoxForalreadyAssignRole(true);
                    setRoleskill("Role");
                } else {
                    setShowLoader(false);
                    setDisplayMessageBoxForConfirmation(true);
                    setDisplayMessageBoxForalreadyAssignRole(false);
                    setRoleskill("Role");
                }
            } else {
                setDisplayMessageBoxForConfirmation(false);
                setDisplayMessageBoxForalreadyAssignRole(false);
                assignRoleIds("");
            }
        }
    };

    const handleChangeForLearner = (selectedOption) => {
        setAssignToLearner(true);
        selectedOption === 1 ? setRoleskill("Role") : setRoleskill("Skill(s)");
        setMsg("Learner(s).");
        if (selectedSkillIds.length <= 0 && selectedOption === 2) {
            setMessageForFailure("Please select at least one Skill to continue.");
            setWarningPopup("");
            setMessageforPopup("");
            setShowModalshowModalforSuccessorFail(true);
        } else {
            setSelectedOptionForLerner(selectedOption);
            setSelectedOption(selectedOption);

            setShowModal(true);
        }
    };
    const handleViewDetail = (showVideDetailsPage, data) => {
        setShowViewDetail(true);
    };

    useEffect(() => {
        getRoleWiseSkillsData(name);
        setShowViewDetail(false);
        setIsManger(userProfile?.attributes?.roles?.indexOf("Manager") > -1 ? true : false);
        setIsAdmin(loginUser?.result?.isAdminUser);
    }, []);

    useEffect(() => {
        setIsManger(userProfile?.attributes?.roles?.indexOf("Manager") > -1 ? true : false);
    }, [userProfile]);

    useEffect(() => {
        if (name !== previousName) {
            setState([]);
            setSelectedSkillIds([]);
        }
        getRoleWiseSkillsData(name);
        setShowViewDetail(false);
        setIsManger(userProfile?.attributes?.roles?.indexOf("Manager") > -1 ? true : false);
        setIsAdmin(loginUser?.result?.isAdminUser);
        setSelectAll(false);
        setSkillAssign(false);
    }, [name, selectedRollIds]);

    const handleListCheck = (flag) => {
        setSelectAll(flag);
    };

    useEffect(() => {
        setPreviousName(name);
    }, [name, selectedSkillIds]);

    const handleCloseModal = () => {
        setSkillAssign(true);
        setShowModalshowModalforSuccessorFail(false);
        setMessageforPopup("");
    };
    const countCheckedSkillsForTab = (tabName) => {
        const data = skillGroupTabData.filter((item) => item.SkillGroupName === tabName);
        let tabData = data.reduce((count, item) => {
            if (selectedSkillIds.includes(item.RoleSkillGroupsId)) {
                return count + 1;
            }
            return count;
        }, 0);
        return tabData;
    };
    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4 roleskillsjourney">
                <div className="headerImage">
                    <h1>{name}</h1>
                </div>
                {showLoader ? (
                    <div className="show-loader">
                        <Loader />
                    </div>
                ) : skillGroupTab.length > 0 ? (
                    <div className="horizontal-tab-block mt-4">
                        <Row>
                            <div className="top-section mt-4 d-flex justify-content-between align-items-md-center">
                                <Form.Check
                                    className="roleSkillSelectALL"
                                    type="checkbox"
                                    id="selectALL"
                                    checked={
                                        selectAll &&
                                        selectedSkillIds.length === skillGroupTabData.length
                                    }
                                    onChange={handleSelectAll}
                                    label={<span>Select All Skills</span>}
                                />
                                <div className="d-flex custom-wrap">
                                    <div className="d-flex flex-wrap flex-row align-items-center w-100">
                                        <button
                                            className="btn primary-btn m-2"
                                            onClick={() => handleChange(1)}
                                            style={{ textTransform: "none" }}>
                                            Assign Role to Myself
                                        </button>
                                        <button
                                            className="btn primary-btn m-2"
                                            onClick={() => handleChange(2)}
                                            style={{ textTransform: "none" }}>
                                            Assign Skill(s) to Myself
                                        </button>

                                        {isManager && (
                                            <>
                                                <button
                                                    className="btn primary-btn m-2"
                                                    onClick={() => handleChangeForLearner(1)}
                                                    style={{ textTransform: "none" }}>
                                                    Assign Role to Learner
                                                </button>
                                                <button
                                                    className="btn primary-btn m-2"
                                                    onClick={() => handleChangeForLearner(2)}
                                                    style={{ textTransform: "none" }}>
                                                    Assign Skill(s) to Learner
                                                </button>
                                            </>
                                        )}

                                        <button
                                            // className="btn primary-btn my-2"
                                            className="btn primary-btn ms-1 my-2 primary-blue"
                                            onClick={(e) => handleSubmit(e)}
                                            style={{ textTransform: "none" }}>
                                            Submit Skill(s) for Approval
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Tabs
                            // defaultActiveKey={skillGroupTab.length && skillGroupTab[0]?.tabId}
                            onSelect={(tabKey) => {
                                setSkillAssign(true);
                                setActiveTab(tabKey);
                            }}
                            activeKey={skillAssign ? activeTab : skillGroupTab[0]?.tabId}
                            id="uncontrolled-tab-example"
                            className="mb-3">
                            {skillGroupTab &&
                                skillGroupTab.map((tab, key) => {
                                    const tabSkillsCount = skillGroupTabData.filter(
                                        (skill) => skill.SkillGroupName === tab.tabName
                                    ).length;
                                    return (
                                        <Tab
                                            key={key}
                                            eventKey={tab.tabId}
                                            title={`${tab.tabName} (${countCheckedSkillsForTab(
                                                tab.tabName
                                            )} / ${tabSkillsCount})`}>
                                            <SkillList
                                                handleListCheck={handleListCheck}
                                                handleViewDetail={handleViewDetail}
                                                role={name}
                                                tab={tab.tabName}
                                                data={skillGroupTabData}
                                                isSelected={isSelected}
                                                selectedSkillIds={selectedSkillIds}
                                                setSelectedSkillIds={setSelectedSkillIds}
                                                handleSelectedIds={handleSelectedIds}
                                                selectAll={selectAll}
                                                setSelectAll={setSelectAll}
                                                crumbs={crumbs}
                                            />
                                        </Tab>
                                    );
                                })}
                        </Tabs>
                    </div>
                ) : (
                    <>
                        <div class="center-container">
                            <div class="content-box">
                                <h2 class="styled-heading noData">No content found.</h2>
                            </div>
                        </div>
                    </>
                )}
                <div className="modal-container-skills">
                    <Modal show={displayMessageBox} centered>
                        <Modal.Body>
                            <div className="failure-modal-container-body my-5 text-center">
                                <img className="failed-icon" src={failed_icon} alt="failed"></img>
                                <div className="success-text-message2 p-0 pt-3">
                                    One or more selected Skill(s) are not assigned to you. <br />
                                    Do you want to proceed?
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="failure-modal-container-footer">
                                <button
                                    className="btn btn-link  p-2"
                                    onClick={() => handleCloseDisplayMessage()}>
                                    Cancel
                                </button>
                                <button
                                    className="btn primary-btn primary-blue m-2"
                                    onClick={() => handleOkDisplayMessage()}>
                                    Ok
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
                {/* Assign Role to Team Modal below */}
                <AssignRoleToTeam
                    handleShow={handleShow}
                    showModal={showModal}
                    selectedOptionForLerner={selectedOptionForLerner}
                    assignRoleIds={assignRoleIds}
                    assignRoleSkillGrpIds={assignRoleSkillGrpIds}
                    handleCloseDisplayMessage={handleCloseDisplayMessage}
                    name="Select Learner(s)"
                    getPayloadForValidation={getPayloadForValidation}
                    setDisplayMessageBoxForalreadyAssignRoleSkill={
                        setDisplayMessageBoxForalreadyAssignRoleSkill
                    }
                    displayMessageBoxForalreadyAssignRoleSkill={
                        displayMessageBoxForalreadyAssignRoleSkill
                    }
                    setStateForRole={setStateForRole}
                    stateForRole={stateForRole}
                    setState={setState}
                    state={state}
                    displayMessageBoxForConfirmation={displayMessageBoxForConfirmation}
                    setDisplayMessageBoxForConfirmation={setDisplayMessageBoxForConfirmation}
                    displayMessageBoxForalreadyAssignRole={displayMessageBoxForalreadyAssignRole}
                    setDisplayMessageBoxForalreadyAssignRole={
                        setDisplayMessageBoxForalreadyAssignRole
                    }
                    setCheckUserRes={setCheckUserRes}
                    set_UserId={set_UserId}
                />
                <Modal centered show={displayMessageBoxForConfirmation}>
                    <Modal.Body>
                        <div className="success-modal-container my-5 text-center">
                            <img
                                className="failed-icon mx-auto"
                                src={Failure_Icon}
                                alt="failed"></img>
                            {assignToLearner ? (
                                <div className="success-text-message2 p-0 pt-3">
                                    Are you sure you want to assign selected {roleskill} to the selected Learner(s)?
                                </div>
                            ) : (
                                <div className="success-text-message2 p-0 pt-3">
                                    Are you sure you want to assign selected {roleskill} to you?
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-link m-2"
                            onClick={() => handleCloseDisplayMessage()}>
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn primary-blue m-2"
                            onClick={(e) => handleConfirmForAssign(e)}>
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={displayMessageBoxForalreadyAssignRoleSkill}>
                    <Modal.Body>
                        <div className="success-modal-container my-5 text-center">
                            <img
                                className="failed-icon mx-auto"
                                src={Failure_Icon}
                                alt="failed"></img>
                            <div className="success-text-message2 p-0 pt-3">
                                {assignToLearner ? (
                                    checkUserRes?.assignUserSkills?.msg === "all_assigned" ? (
                                        <>
                                            Selected Skill(s) are already assigned to selected
                                            Learner(s).
                                        </>
                                    ) : (
                                        <>
                                            {" "}
                                            One or more Skill(s) are already assigned to some of the selected Learner(s). Do you want to proceed with assigning selected Skill(s) to rest of the Learner(s)?
                                        </>
                                    )
                                ) : checkUserRes?.assignUserSkills?.msg === "all_assigned" ? (
                                    <>Selected Skill(s) are already assigned to you.</>
                                ) : (
                                    <>
                                        One or more Skill(s) of this role packet are already
                                        assigned to you. Do you want to proceed with assigning rest
                                        of the Skill(s)?
                                    </>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!checkUserRes?.assignUserSkills?.response &&
                        checkUserRes?.assignUserSkills?.msg === "all_assigned" ? (
                            <>
                                <button
                                    className="btn primary-btn primary-blue m-2"
                                    onClick={(e) => handleCloseDisplayMessage()}>
                                    Ok
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className="btn btn-link m-2"
                                    onClick={() => handleCloseDisplayMessage()}>
                                    No
                                </button>
                                <button
                                    className="btn primary-btn primary-blue m-2"
                                    onClick={(e) => handleConfirmForAssign(e)}>
                                    Yes
                                </button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>
                <Modal centered show={displayMessageBoxForalreadyAssignRole}>
                    <Modal.Body>
                        <div className="success-modal-container my-5 text-center">
                            <img
                                className="failed-icon mx-auto"
                                src={Failure_Icon}
                                alt="failed"></img>
                            <div className="success-text-message2 p-0 pt-3">
                                {assignToLearner ? (
                                    checkUserRes?.assignUserRoles?.msg === "all_assigned" ? (
                                        <>
                                            All the skills of this role packet are already assigned.
                                        </>
                                    ) : (
                                        <>
                                            One or more Skill(s) of this role packet are already
                                            assigned to some of the selected Learner(s). Do you want to proceed
                                            with assigning the remaining Skill(s)?
                                        </>
                                    )
                                ) : checkUserRes?.assignUserRoles?.msg === "all_assigned" ? (
                                    <>All the skills of this role packet are already assigned.</>
                                ) : (
                                    <>
                                        One or more Skill(s) of this role packet are already
                                        assigned to you. Do you want to proceed with assigning the
                                        remaining Skill(s)?
                                    </>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!checkUserRes?.assignUserRoles?.response &&
                        checkUserRes?.assignUserRoles?.msg === "all_assigned" ? (
                            <>
                                <button
                                    className="btn primary-btn primary-blue m-2"
                                    onClick={(e) => handleCloseDisplayMessage()}>
                                    Ok
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className="btn btn-link m-2"
                                    onClick={() => handleCloseDisplayMessage()}>
                                    No
                                </button>
                                <button
                                    className="btn primary-btn primary-blue m-2"
                                    onClick={(e) => handleConfirmForAssign(e)}>
                                    Yes
                                </button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>
                <ModalforSuccessAndFailure
                    messageforPopup={messageforPopup}
                    messageForFailure={messageForFailure}
                    warningPopup={warningPopup}
                    show={showModalforSuccessorFail}
                    onClose={handleCloseModal}
                />
            </Container>
            <Scroller />
        </>
    );
};

export default RoleSkillsJourney;
