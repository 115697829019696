import React from "react";
import Card from "react-bootstrap/Card";
import DummyProfile from "../../assets/images/dummyProfile.svg";

const CardSIOEnablementTeam = ({data,key}) => {
    let image = data?.img;
    if(image === "https://cpcontents.adobe.com/public/images/default_user_avatar.svg?cp_oauth_jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwIjoidXJsIiwicmFuZCI6ImFlMDAwMGZkZDAyM2E5Y2EzMzJlNjJhYTUxNDYwY2NkIiwidiI6IjEiLCJvcmlnaW5hbFVybEhhc2giOiIxZTc5ZTk3Y2RiYzIzNTIzYjVmMzE0ZjVjODg0MTc0YjhkNDBhYzczIiwiZXhwIjoxNjg0MzkxNjU4LCJyaWQiOiIxMTg5NTU0NSIsImlhdCI6MTY4Mzc4Njg1OCwiY2lkIjoiMjA4MCJ9.4TBuAREmIuDumWdVDpAKKZVadWhzVGoON2cXC1JnwuI"){
        image = DummyProfile;
    }
    
    return (
        <Card style={{ width: "auto" }} key={key}>
            <Card.Img variant="top" className="img-fluid d-block mx-auto" src={ data?.AvatarUrl || DummyProfile} />
            <Card.Body>
                <Card.Title>{data?.name}</Card.Title>
                <Card.Subtitle className="">{data?.Designation}</Card.Subtitle>
            </Card.Body>

        </Card>
    )
}

export default CardSIOEnablementTeam;
