import React, { useContext } from "react";
import { appContext } from "../../context/appContext";
import bell from "../../../src/assets/images/bell.svg";
import moment from "moment";
import { isEmpty } from "lodash";

const AllNotifications = () => {
    const { notifications } = useContext(appContext);

    return isEmpty(notifications) ? (
        <div className="empty-notifications-container">No notification available.</div>
    ) : (
        <div className="notifications-container">
            {notifications
                ?.filter(
                    (notificationdata) => !notificationdata?.NotificationMessage?.includes("DNS")
                )
                .map((notificationdata, index) => {
                    notificationdata.CreatedOn = new Date(notificationdata?.CreatedOn);
                    let formattedDate = moment(notificationdata?.CreatedOn).format("MMM Do YYYY");
                    let formattedTime = moment(notificationdata?.CreatedOn).format("LT");
                    const notificationText = notificationdata?.NotificationMessage;
                    return (
                        <div className="notifications-list" key={index}>
                            <div className="notifications-icon">
                                <img className="icon" src={bell} alt="bell"></img>
                            </div>
                            <div className="notifications-details">
                                <div className="assignTime">
                                    <span>{formattedDate}</span>
                                    <span className="separator px-2">|</span>
                                    <span>{formattedTime}</span>
                                </div>
                                <div
                                    style={{ display: "inline" }}
                                    className="assigned"
                                    dangerouslySetInnerHTML={{ __html: notificationText }}></div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default AllNotifications;
