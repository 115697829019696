import React from "react";
import moment from "moment";

const DetailsCourseReportsCellContent = ({ data }) => {
    return (
        <tbody>
            {data.map((user, index) => (
                <tr className="tableRows" key={index}>
                    <td className="columnDataDetails">{user?.Name}</td>
                    {/* <td className="columnDataDetails">{user?.Type}</td> */}
                    <td className="columnDataDetails">{user?.Email}</td>
                    <td className="columnDataDetails">
                        {user?.EnrollmentDate !== "" &&
                            moment(user?.EnrollmentDate).format("MMM Do YYYY")}
                    </td>
                    <td className="columnDataDetails">
                        {user?.DueDate !== "" && moment(user?.DueDate).format("MMM Do YYYY")}
                    </td>
                    <td className="columnDataDetails">
                        {user?.CompletionDate !== "" &&
                            moment(user?.CompletionDate).format("MMM Do YYYY")}
                    </td>
                    <td className="columnDataDetails">
                        {Number.isInteger(Number(user?.Progress))
                            ? Math.floor(user?.Progress)
                            : user?.Progress}
                        %
                    </td>
                    <td className="columnDataDetails">{user?.Status}</td>
                </tr>
            ))}
        </tbody>
    );
};

export default DetailsCourseReportsCellContent;
