/* eslint-disable */
import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import { apis } from "../../services/Api";
import { useEffect } from "react";
import "./MyCalendar.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const MyCalendar = () => {
    let navigate = useNavigate();

    const [date, onChange] = useState(new Date());
    const [myCalendarData, setMyCalendarData] = useState([]);
    let [calendarDate, setCalendarDate] = useState();
    const [highlightedDates, setHighlightedDates] = useState([]);

    const profile = useSelector((state) => state.userProfile.userProfile);

    const currentDate = new Date();
    let TodayDate = moment(currentDate).format("MMM Do YYYY");

    const init = async (profile) => {
        const calData = await apis.getMyCalendar(profile?.id);

        const filtered = calData?.data?.filter((calendardata) => {
            const eventDate = new Date(calendardata?.attributes?.dateStart);
            return eventDate >= date;
        });

        setMyCalendarData(filtered);

        const expectedDates = new Set(
            filtered?.map(
                (calendardata) =>
                    moment(calendardata?.attributes?.dateStart)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .format("ddd MMM D YYYY HH:mm:ss [GMT]ZZ") + " (India Standard Time)"
            )
        );
        setHighlightedDates(Array.from(expectedDates));
    };

    let filterdata = myCalendarData?.filter((data) => {
        return (
            moment(data?.attributes?.dateStart).format("YYYY-MM-DD") ===
            moment(date).format("YYYY-MM-DD")
        );
    });

    const goToCourseDetails = (event, cid) => {
        event.preventDefault();
        navigate(`/coursedetails/${cid}`, { state: { crumbs: [{ label: "Home", url: "/" }] } });
    };

    let finddata = filterdata?.find((founddata) => {
        return (
            moment(founddata?.attributes?.dateStart).format("YYYY-MM-DD") ===
            moment(date).format("YYYY-MM-DD")
        );
    })?.attributes?.dateStart;

    const tileClassName = ({ date }) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        for (let eventDate of highlightedDates) {
            if (
                new Date(eventDate).getDate() === new Date(date).getDate() &&
                new Date(eventDate).getMonth() === new Date(date).getMonth() &&
                new Date(eventDate).getFullYear() === new Date(date).getFullYear()
            ) {
                return "highlight-date";
            }
        }

        if (date.getFullYear() === currentYear) {
            return "highlighted-year"; // Apply the "highlighted" class to the current year
        }
        return "";
    };

    useEffect(() => {
        init(profile);
    }, [profile]);

    return (
        <div className="calendar-block">
            <Calendar
                onChange={onChange}
                value={date}
                tileClassName={tileClassName}
                onClickDay={(e) => {
                    setCalendarDate(moment(e).format("MMM Do YYYY"));
                }}
                style={
                    moment(finddata).format("YYYY-MM-DD") !== moment(date).format("YYYY-MM-DD")
                        ? {
                              color: "black"
                          }
                        : {
                              color: "red",
                              background: "pink"
                          }
                }
            />
            <hr />

            <div className="enroll-date">{calendarDate ? calendarDate : TodayDate}</div>

            <div className="event-enroll-card">
                {finddata ? (
                    filterdata.map((calendardata) => {
                        let newDate = calendardata?.attributes?.dateStart;
                        let expectedDate = new Date(newDate).toString();
                        let modifieddate = date.toString();
                        let sessionName = calendardata?.attributes?.sessionName;
                        let course = calendardata?.attributes?.course;
                        let enrolled = calendardata?.attributes?.enrolled;

                        // Only render the event if a course exists
                        if (course) {
                            return (
                                <div className="enroll-list" key={calendardata.id}>
                                    {moment(expectedDate).format("MMMM Do YYYY") ===
                                    moment(modifieddate).format("MMMM Do YYYY") ? (
                                        <div style={{ flexFlow: "row wrap" }}>
                                            <div
                                                className="event-sm"
                                                style={{ flexFlow: "column wrap" }}>
                                                <div className="event-time">
                                                    {`${new Date(newDate).toLocaleTimeString([], {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        hour12: true
                                                    })} - ${new Date(
                                                        calendardata?.attributes?.dateEnd
                                                    ).toLocaleTimeString([], {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        hour12: true
                                                    })}`}
                                                    {enrolled && (
                                                        <span className="enrollStatus p-1 mx-2 mb-2">
                                                            Enrolled{" "}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="event-head my-2">{sessionName}</div>
                                                <button
                                                    className="btn primary-btn event-btn"
                                                    onClick={(event) =>
                                                        goToCourseDetails(
                                                            event,
                                                            calendardata?.relationships?.course
                                                                ?.data?.id
                                                        )
                                                    }>
                                                    View Details
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            );
                        }
                        return null; // Skip rendering if course doesn't exist
                    })
                ) : (
                    <div className="ms-3"> No event for selected date</div>
                )}
            </div>
        </div>
    );
};
export default MyCalendar;
