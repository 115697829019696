/* eslint-disable indent */
import { useState } from "react";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import CustomSkillRowTwo from "./CustomSkillRowTwo";
import down_icon from "../../../assets/images/grey-down.svg";
import up_icon from "../../../assets/images/grey-up.svg";
import { Image } from "react-bootstrap";
import { _tableHeaderSS } from "../../../data/dataMyTeamSkillsSubmission";
import dark_up from "../../../assets/images/dark_up.svg";
import dark_down from "../../../assets/images/dark_down.svg";
import { setSubmissionIsAllSelected } from "../../../slices/mainSlice";
import "../CustomAccordion.css";

function CustomAccordionItemTwo({
    getMyTeamSkills,
    data,
    setChekedLernerIds,
    chekedLernerIds,
    setAddress,
    address,
    searchData,
    handleData
}) {
    const dispatch = useDispatch();

    const { submissionIsAllSelected } = useSelector((state) => state.main);

    const [contentData, setContentData] = useState();
    const [contentTransectionId, setContentTransectionId] = useState([]);
    const [sortColumn1, setSortColumn1] = useState(null);
    const [ascending, setAscending] = useState(true);

    useEffect(() => {
        const dataArray = data.map((result) => {
            return {
                ...result,
                history: result.history.map((historyItem) => ({
                    ...historyItem.item
                }))
            };
        });
        setContentData(dataArray);
    }, [data]);

    useEffect(() => {
        const arr = [];
        contentData?.map((content) => {
            if (content?.history[0]?.Status === "Awaiting Approval") {
                arr.push(content.transactionId);
            }
        });
        setContentTransectionId(arr);
    }, [contentData]);

    const handleChangeCB = (e) => {
        const isChecked = e.target.checked;
        dispatch(setSubmissionIsAllSelected(isChecked));
        if (isChecked) {
            setChekedLernerIds(contentTransectionId);
        } else {
            setChekedLernerIds([]);
        }
    };

    const handleSort = (index) => {
        if (index !== -1) {
            setSortColumn1(index);
            setAscending(!ascending);

            const sortedData = [...contentData].sort((a, b) => {
                const propertyName = _tableHeaderSS[index]?.property;
                const valueA = a?.history[0][propertyName];
                const valueB = b?.history[0][propertyName];

                if (
                    propertyName === "SubmittedDateTime" ||
                    propertyName === "ApprovalOrRejectedDate"
                ) {
                    const dateA = new Date(valueA);
                    const dateB = new Date(valueB);

                    return ascending ? dateA - dateB : dateB - dateA;
                } else if (typeof valueA === "number" && typeof valueB === "number") {
                    return ascending ? valueA - valueB : valueB - valueA;
                } else if (typeof valueA === "string" && typeof valueB === "string") {
                    return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                } else {
                    return ascending
                        ? (valueA || "") - (valueB || "")
                        : (valueB || "") - (valueA || "");
                }
            });

            setContentData(sortedData);
        }
    };

    return (
        <div className="custom-accordion-item">
            <div className="custom-accordion-item-content">
                <table className="custom-accordion-container-tbl">
                    <tr className="custom-thead">
                        <td></td>
                        <td className="container-tbl-tr-checkbox">
                            <div className="container-tbl-tr-btn container-tbl-tr-btn-checkbox">
                                <Form.Check
                                    disabled={!contentTransectionId.length}
                                    type="checkbox"
                                    id=""
                                    label=""
                                    value="checkAll"
                                    className="check-box checkboxOne"
                                    checked={submissionIsAllSelected}
                                    onChange={(e) => handleChangeCB(e)}
                                />
                            </div>
                        </td>
                        <td>
                            <table className="custom-accordion-tbl custom-accordion-tbl-cus">
                                <tr>
                                    {_tableHeaderSS.map((item, index) => {
                                        const isSortingColumn = sortColumn1 === index;
                                        const isAscending = isSortingColumn && ascending;
                                        return (
                                            <td key={index}>
                                                {item?.title}
                                                {item?.title !== "Action" && (
                                                    <span className="sortIcons mx-2">
                                                        <Image
                                                            src={
                                                                isSortingColumn && isAscending
                                                                    ? dark_up
                                                                    : up_icon
                                                            }
                                                            className={`cpb-1-up ${
                                                                isSortingColumn && isAscending
                                                                    ? "active"
                                                                    : ""
                                                            }`}
                                                            alt="Up"
                                                            title="Up"
                                                            height={11}
                                                            width={11}
                                                            onClick={() => handleSort(index)}
                                                        />
                                                        <Image
                                                            src={
                                                                isSortingColumn && !isAscending
                                                                    ? dark_down
                                                                    : down_icon
                                                            }
                                                            className={`cpb-1 ${
                                                                isSortingColumn && !isAscending
                                                                    ? "active"
                                                                    : ""
                                                            }`}
                                                            alt="Down"
                                                            title="Down"
                                                            height={11}
                                                            width={11}
                                                            onClick={() => handleSort(index)}
                                                        />
                                                    </span>
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {contentData && contentData.length
                        ? contentData.map((content, i) => {
                              return (
                                  <CustomSkillRowTwo
                                      getMyTeamSkills={getMyTeamSkills}
                                      content={content}
                                      key={i}
                                      setChekedLernerIds={setChekedLernerIds}
                                      chekedLernerIds={chekedLernerIds}
                                      contentTransectionId={contentTransectionId}
                                      setAddress={setAddress}
                                      address={address}
                                      searchData={searchData}
                                      handleData={handleData}
                                  />
                              );
                          })
                        : null}
                </table>
            </div>
        </div>
    );
}

export default CustomAccordionItemTwo;
