/* eslint-disable */
import React, { useEffect, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import camera from '../../assets/images/camera.svg';
import './profile.css';
import { useTranslation } from 'react-i18next';
import { apis } from '../../services/Api';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { appContext } from '../../context/appContext';
import { useNavigate } from 'react-router-dom';
import Evaporate from 'evaporate';
import AWS from 'aws-sdk';
import { getToken } from '../../services/Auth';
import { signin_url } from '../../AppConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from "../../common/Breadcrumbs";
import ModalforSuccessAndFailure from '../../common/Modal/Modal';
import Loader from '../../common/Loader/Loader';

const Profile = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const profile = useSelector((state) => state.userProfile);
  let [userDetails, setUserDetails] = useState({});
  let [bio, setBio] = useState('');
  const { dispatch, updateProfile } = useContext(appContext);
  const [img, setImg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [messageforPopup, setMessageforPopup] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const crumbs = [
    { label: "Home", url: "/" },
    { label: "Profile Settings", url: "/profile" },
  ];
  var UPLOAD_SINGER_URL = `${signin_url}/primeapi/v2/uploadSigner`;
  let papiAwsCredJsonObj = {
    awsKey: '',
    bucket: '',
    region: '',
    awsUrl: '',
    key: ''
  };
  async function uploadProfile(imageUrl) {
    try {
      const response = await apis.uploadImage(imageUrl);
      const AvatarUrl = response?.data?.data?.attributes?.avatarUrl;
      const email = response?.data?.data?.attributes?.email;
      const req = {
        avatarurl: AvatarUrl,
        emailid: email
      }
      setIsLoading(false); // Set loading state to false
      const avatarurlresponse = await apis.uploadAvatarUrlImage(req);
      CheckToken()
    } catch (e) {
      console.log(e)
      setIsLoading(false); // Set loading state to true
    }
  }

  let info;
  async function getUploadInfo() {
    const response = await apis.getUploadInfo();
    const result = response?.data;
    info = result;
    saveAwsCred();
  }

  function getCheckToken() {
    const response = getToken();
    const result = response.access_token;
    return result;
  }

  async function CheckToken() {
    try {
      // setIsLoading(true); // Set loading state to true
      const response = await apis.checkToken()
      const result = response?.data?.attributes;
      setImg(result?.avatarUrl);
      setBio(result?.bio);
      // setIsLoading(false); // Set loading state to true
      dispatch({ type: "AvatarUrl", payload: result })
    } catch (e) {
      console.log(e);
      //  setIsLoading(false)
    }
  }

  useEffect(() => {
    CheckToken();
  }, [])
  function saveAwsCred() {
    papiAwsCredJsonObj = info;
    const awsRegionUrl =
      papiAwsCredJsonObj?.region.indexOf('us-') === 0
        ? '.s3.amazonaws.com/'
        : '.s3.' + papiAwsCredJsonObj?.region + '.amazonaws.com/';
    const awsUrl =
      window?.location?.host?.indexOf('9010') === -1
        ? 'https://' + papiAwsCredJsonObj?.bucket + awsRegionUrl + papiAwsCredJsonObj?.key
        : 'http://localhost:4567/' + papiAwsCredJsonObj?.bucket + '/' + papiAwsCredJsonObj?.key;
    papiAwsCredJsonObj.awsUrl = awsUrl;
    // setIsLoading(true)
    initEvaporate(getEvaporateConfig());
  }

  function getEvaporateConfig() {
    return {
      aws_key: papiAwsCredJsonObj?.awsKey,
      bucket: papiAwsCredJsonObj?.bucket,
      awsRegion: papiAwsCredJsonObj?.region,
      cloudfront: true,
      xhrWithCredentials: true,
      signerUrl: UPLOAD_SINGER_URL,
      awsSignatureVersion: '4',
      computeContentMd5: true,
      signHeaders: {
        Authorization: 'oauth ' + getCheckToken(),
        'Content-Type': 'application/vnd.api+json',
        Origin: 'localhost'
      },
      aws_url: papiAwsCredJsonObj?.awsUrl,
      cryptoMd5Method: function (data) {
        return AWS?.util?.crypto?.md5(data, 'base64');
      },
      cryptoHexEncodedHash256: function (data) {
        return AWS?.util?.crypto?.sha256(data, 'hex');
      }
    };
  }
  function initEvaporate(config) {
    Evaporate.create(config).then(
      function success(_e_) {
        let fileInput = document.getElementById('files'),
          filePromises = [];
        fileInput.onchange = function (evt) {
          setIsLoading(true); // Set loading state to true
          let file_name;
          let files = evt.target.files;
          for (let i = 0; i < files.length; i++) {
            const timestamp = new Date().getTime();
            const file = files[i];
            const filename = `profile_${timestamp}`;
            const fileExtension = file.name.split('.').pop();


            let promise = _e_
              .add({
                name: filename,
                file,
                progress: function (progress) { }
              })
              .then(function (awsKey) {
                file_name = awsKey;
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />;
                <ToastContainer />;
                // toast.success('Upload complete!', {
                //     position: 'top-right',
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: 'light'
                //   });
                setMessageforPopup("Profile picture uploaded successfully.");
                setShowModal(true);
              });
            filePromises.push(promise);
          }
          Promise.all(filePromises).then(
            function () {
              const imgurl = papiAwsCredJsonObj?.awsUrl + '/' + file_name;
              if (imgurl !== null) uploadProfile(imgurl)
            },
            function (reason) { }
          );
          evt.target.value = '';
        };
      },
      function failure(reason) { }
    );
  }

  let bioData = (e) => {
    setBio(e?.target?.value);
  };

  const BodyData = {
    data: {
      id: `${userDetails?.id}`,
      type: `${userDetails?.type}`,
      attributes: {
        bio: `${bio}`
      }
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleUpdateUser = async (BodyData, userDetailId) => {
    try {
      setIsLoading(true)
      const response = await apis.getUpdateProfile(BodyData, userDetailId);
      setMessageforPopup("Profile updated successfully.")
      setShowModal(true);
      setIsLoading(false)
      dispatch({ type: 'updateProfile', payload: response?.data });
    } catch (e) {
      console.log(e);
      setMessageforPopup("Profile is not Uploaded.")
      setShowModal(true);
    }
  };

  function isEmpty(str) {
    return str === '' || str === undefined || str === null;
  }
  useEffect(() => {
    if (isEmpty(getCheckToken())) {
      alert('save access token first');
      return;
    }
    getUploadInfo();
  }, [isLoading]);
  useEffect(() => {
    setUserDetails(profile.userProfile);
  }, [profile, bio]);


  return (
    <>
      <><Breadcrumbs crumbs={crumbs} />
        <Container fluid className="pl-30 py-4">
          <div className="headerImage">
            <h1>{t('profile-setting')}</h1>
          </div>
        </Container>
        {isLoading ? (<Loader></Loader>) :
          <Container fluid>
            <Row className="pl-30 profileSetting p-5">
              <div className="d-flex justify-content-end">
                <Row className="custom-margin-profile">
                  <Col>
                    <button
                      className="btn primary-btn"
                      onClick={() => {
                        navigate('/');
                      }}>
                      {t('cancel')}
                    </button>
                  </Col>
                  <Col>
                    <button
                      className="btn primary-btn primary-blue"
                      onClick={() => {
                        handleUpdateUser(BodyData, userDetails?.id);
                      }}>
                      {t('save')}
                    </button>
                  </Col>
                </Row>
              </div>
              <div className="col-lg-5 col-sm-12 col-md-5 ">
                <div className="profile-left-section">
                  <div className="profile">
                    <div className="userImage">
                      <img
                        className="profile-icon"
                        src={img}
                        alt="user pic"></img>
                    </div>
                    <div>
                      <div className="camera-icon">
                        <label htmlFor="files">
                          <img
                            src={camera}
                            alt="camera"
                            aria-hidden="true"></img>
                        </label>
                      </div>
                      <input
                        type="file"
                        id="files"
                        accept="image/*"
                        style={{ display: 'none' }}
                        multiple />
                    </div>
                  </div>
                  <div className="text-center nameText">
                    <p className="userName">{userDetails?.attributes?.name}</p>
                    <span className="me-2 pe-2 designation right-bar">
                      {userDetails?.attributes?.profile}
                    </span>
                    <span className="designation">{userDetails?.attributes?.email}</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-sm-12 col-md-7 profile-right-section">
                <div className="content-section">
                  <div className="">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bold">
                      {t('about')}
                    </label>
                    <textarea
                      className="form-control form-control-profile"
                      defaultValue={bio}
                      id="userbio"
                      rows="7"
                      onChange={bioData}>
                    </textarea>
                  </div>
                </div>
              </div>
            </Row>
            <ModalforSuccessAndFailure messageforPopup={messageforPopup} show={showModal} onClose={handleCloseModal} />
          </Container>
        }</>
    </>
  );
};
export default Profile;
// export default connect(mapStateToProps, mapDispatchToProps)(Profile)
