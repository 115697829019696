export const dataMyTeam = [
    {
        coupon_id: "1",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "AEM Support Engineer",
        skills: "AEM Sites: Authoring / Foundation",
        skill_group: "Technical Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-notStart">Not Started</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "2",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "Marketo",
        skills: "AEM Sites: Communities",
        skill_group: "Professional Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-notStart">Not Started</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "3",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "AEM Support Engineer",
        skills: "AEM Sites Integrations: Campaign",
        skill_group: "Soft Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-awaitng">Awaiting</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "6",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "Magento",
        skills: "Technical Skill 3",
        skill_group: "Professional Skills",
        submitted_level: 0,
        target_level: 300,
        status: <button className="btn primary-btn status-awaitng">Awaiting</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "7",
        coupon_code: "t5",
        learner: "Andrea Ramirez",
        role_name: "Marketo",
        skills: "Technical Skills 4",
        skill_group: "Professional Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "8",
        coupon_code: "t5",
        learner: "Bennie",
        role_name: "Campaign",
        skills: "Technical Skills 2",
        skill_group: "Soft Skills",
        submitted_level: 0,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "9",
        coupon_code: "t5",
        learner: "Christian Cole",
        role_name: "AAM",
        skills: "Technical Skills 1",
        skill_group: "Technical Skills",
        submitted_level: 0,
        target_level: "300",
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "10",
        coupon_code: "t5",
        learner: "CourtneyCole",
        role_name: "Campaign",
        skills: "Technical Skills 2",
        skill_group: "Technical Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "11",
        coupon_code: "t5",
        learner: "John Deo",
        role_name: "AAM",
        skills: "Technical Skills 1",
        skill_group: "Professional Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "14",
        coupon_code: "t5",
        learner: "John Deo",
        role_name: "Analytics",
        skills: "AEM Sites: Authoring / Foundation",
        skill_group: "Technical Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id1: "15",
        coupon_code: "t6",
        learner: "Veeny",
        role_name: "Analytics",
        skills: "AEM Sites: Communities",
        skill_group: "Soft Skills",
        submitted_level: 0,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    }
];

// export const columnsMyTeam = [
//     { Header: "Learner", accessor: "" },
//     { Header: "Total Assigned Role Packets", accessor: "learner" },
//     { Header: "Total Skills", accessor: "role_name" },
//     { Header: "Number of Total Skills", accessor: "skills" },
//     { Header: "Number of Aquired Skills", accessor: "skill_group" },
//     {
//         Header: "Action",
//         accessor: "couponList.actions",
//         Cell: () => (
//             <div className="d-flex justify-content-center">
//                 {/* <EditCoupon /> */}
//                 <button className="btn primary-btn view-skill-journey-btn">View Skill Journey</button>
//                 {/* <DeleteCoupon /> */}
//             </div>
//         ),
//     },
// ]

export const dataFilterByRole = [
    {
        coupon_id: "1",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "AEM Support Engineer",
        skills: "AEM Sites: Authoring / Foundation",
        skill_group: "Technical Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-notStart">Not Started</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "2",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "Marketo",
        skills: "AEM Sites: Communities",
        skill_group: "Professional Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-notStart">Not Started</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "3",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "AEM Support Engineer",
        skills: "AEM Sites Integrations: Campaign",
        skill_group: "Soft Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-awaitng">Awaiting</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "6",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "Magento",
        skills: "Technical Skill 3",
        skill_group: "Professional Skills",
        submitted_level: 0,
        target_level: 300,
        status: <button className="btn primary-btn status-awaitng">Awaiting</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "7",
        coupon_code: "t5",
        learner: "Andrea Ramirez",
        role_name: "Marketo",
        skills: "Technical Skills 4",
        skill_group: "Professional Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "8",
        coupon_code: "t5",
        learner: "Bennie",
        role_name: "Campaign",
        skills: "Technical Skills 2",
        skill_group: "Soft Skills",
        submitted_level: 0,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "9",
        coupon_code: "t5",
        learner: "Christian Cole",
        role_name: "AAM",
        skills: "Technical Skills 1",
        skill_group: "Technical Skills",
        submitted_level: 0,
        target_level: "300",
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "10",
        coupon_code: "t5",
        learner: "CourtneyCole",
        role_name: "Campaign",
        skills: "Technical Skills 2",
        skill_group: "Technical Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "11",
        coupon_code: "t5",
        learner: "John Deo",
        role_name: "AAM",
        skills: "Technical Skills 1",
        skill_group: "Professional Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "14",
        coupon_code: "t5",
        learner: "John Deo",
        role_name: "Analytics",
        skills: "AEM Sites: Authoring / Foundation",
        skill_group: "Technical Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id1: "15",
        coupon_code: "t6",
        learner: "Veeny",
        role_name: "Analytics",
        skills: "AEM Sites: Communities",
        skill_group: "Soft Skills",
        submitted_level: 0,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    }
];

// export const columnsFilterByRole = [
//     { Header: "Image", accessor: "" },
//     { Header: "Name", accessor: "learner" },
//     { Header: "Role Name", accessor: "role_name" },
//     { Header: "Number of Total Skills", accessor: "skills" },
//     { Header: "Number of Aquired Skills", accessor: "skill_group" },
//     {
//         Header: "Action",
//         accessor: "couponList.actions",
//         Cell: () => (
//             <div className="d-flex justify-content-center">
//                 {/* <EditCoupon /> */}
//                 <button className="btn primary-btn view-skill-journey-btn">
//                     View Skill Journey
//                 </button>
//                 {/* <DeleteCoupon /> */}
//             </div>
//         )
//     }
// ];

export const dataFilterBySkill = [
    {
        coupon_id: "1",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "AEM Support Engineer",
        skills: "AEM Sites: Authoring / Foundation",
        skill_group: "Technical Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-notStart">Not Started</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "2",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "Marketo",
        skills: "AEM Sites: Communities",
        skill_group: "Professional Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-notStart">Not Started</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "3",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "AEM Support Engineer",
        skills: "AEM Sites Integrations: Campaign",
        skill_group: "Soft Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-awaitng">Awaiting</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "6",
        coupon_code: "t5",
        learner: "Alex Harris",
        role_name: "Magento",
        skills: "Technical Skill 3",
        skill_group: "Professional Skills",
        submitted_level: 0,
        target_level: 300,
        status: <button className="btn primary-btn status-awaitng">Awaiting</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "7",
        coupon_code: "t5",
        learner: "Andrea Ramirez",
        role_name: "Marketo",
        skills: "Technical Skills 4",
        skill_group: "Professional Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "8",
        coupon_code: "t5",
        learner: "Bennie",
        role_name: "Campaign",
        skills: "Technical Skills 2",
        skill_group: "Soft Skills",
        submitted_level: 0,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "9",
        coupon_code: "t5",
        learner: "Christian Cole",
        role_name: "AAM",
        skills: "Technical Skills 1",
        skill_group: "Technical Skills",
        submitted_level: 0,
        target_level: "300",
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "10",
        coupon_code: "t5",
        learner: "CourtneyCole",
        role_name: "Campaign",
        skills: "Technical Skills 2",
        skill_group: "Technical Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "11",
        coupon_code: "t5",
        learner: "John Deo",
        role_name: "AAM",
        skills: "Technical Skills 1",
        skill_group: "Professional Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id: "14",
        coupon_code: "t5",
        learner: "John Deo",
        role_name: "Analytics",
        skills: "AEM Sites: Authoring / Foundation",
        skill_group: "Technical Skills",
        submitted_level: 100,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    },
    {
        coupon_id1: "15",
        coupon_code: "t6",
        learner: "Veeny",
        role_name: "Analytics",
        skills: "AEM Sites: Communities",
        skill_group: "Soft Skills",
        submitted_level: 0,
        target_level: 300,
        status: <button className="btn primary-btn status-approvd">Approved</button>,
        coupon_status1: ""
    }
];

export const columnsFilterBySkill = [
    { Header: "Image", accessor: "" },
    { Header: "Learner", accessor: "learner" },
    { Header: "Role Name", accessor: "role_name" },
    { Header: "Skills", accessor: "skills" },
    { Header: "Skill Group", accessor: "skill_group" },
    { Header: "Submitted Level", accessor: "submitted_level" },
    { Header: "Target Level", accessor: "target_level" },
    { Header: "Status", accessor: "status" },
    // { Header: "Action", accessor: "coupon_status1" },
    {
        Header: "Action",
        accessor: "couponList.actions",
        Cell: () => (
            <div className="d-flex justify-content-center">
                {/* <EditCoupon /> */}
                <button className="btn primary-btn view-skill-journey-btn">
                    View Skill Journey
                </button>
                {/* <DeleteCoupon /> */}
            </div>
        )
    }
];

export const _tableHeaderMyTeamData = [
    { id: 1, title: "User Name", property: "UserName" },
    { id: 2, title: "Total Assigned Role Packets", property: "TotalAssignedRolePackets" },
    { id: 3, title: "Total Skills", property: "TotalSkills" },
    { id: 4, title: "Awaiting Approval", property: "AwaitingApproval" },
    { id: 5, title: "Approved Skills", property: "ApprovedSkills" },
    { id: 6, title: "Not Started", property: "NotStarted" },
    { id: 7, title: "Rejected Skills", property: "RejectedSkills" },
    { id: 8, title: "Action", property: "Action" }
];
