import React from "react";
import { useNavigate } from "react-router-dom";

const MyTeamCellContent = ({ data }) => {
    const navigate = useNavigate();

    const onClickViewSkillJourney = (userId, userName) => {
        navigate(`/viewskilljourney/${userId}`, { state: { userName } });
    };

    return (
        <tbody>
            {data?.map((myteamdata, index) => {
                let btnDisabled = false;
                if (myteamdata?.TotalSkills === 0) {
                    btnDisabled = true;
                }
                let values = Object.values(myteamdata);

                return (
                    <tr key={index}>
                        {values?.map((value, innerIndex) => {
                            if (Object.keys(myteamdata)[innerIndex] !== "UserId") {
                                let finalValue = value;
                                if (Object.keys(myteamdata)[innerIndex] === "TargetLevel") {
                                    finalValue = value === 0 ? "-" : value;
                                }
                                // Exclude "UserId" column
                                return (
                                    <td key={innerIndex} className="columnData">
                                        {finalValue}
                                    </td>
                                );
                            }
                            return null;
                        })}
                        <td className="columnData">
                            <button
                                disabled={btnDisabled}
                                className="btn primary-btn my-2 view-skill-journey-btn "
                                onClick={() =>
                                    onClickViewSkillJourney(
                                        myteamdata?.UserId,
                                        myteamdata?.UserName
                                    )
                                }>
                                View Skill Journey
                            </button>
                        </td>
                    </tr>
                );
            })}
        </tbody>
    );
};

export default MyTeamCellContent;
