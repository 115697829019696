import React, { useState } from "react";
import "./DataTable.css";

const Pagination = ({ currentPage, setCurrentPage, filteredData, itemsPerPage }) => {
    const [visiblePages] = useState(5);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > Math.ceil(filteredData.length / itemsPerPage)) {
            return;
        }
        setCurrentPage(pageNumber);
    };

    const generatePageNumbers = () => {
        const pageNumbers = [];
        const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        const endPage = Math.min(totalPages, startPage + visiblePages - 1);

        for (let page = startPage; page <= endPage; page++) {
            pageNumbers.push(page);
        }

        return pageNumbers;
    };

    const renderPageNumbers = generatePageNumbers();

    return (
        <div className="pagination mb-4">
            {currentPage !== 1 && (
                <button onClick={() => paginate(currentPage - 1)} className="arrow-button-prev" />
            )}

            {renderPageNumbers.map((page, index) => (
                <button
                    key={index}
                    onClick={() => paginate(page)}
                    className={page === currentPage ? "active" : ""}>
                    {page}
                </button>
            ))}

            {currentPage !== totalPages && (
                <button onClick={() => paginate(currentPage + 1)} className="arrow-button" />
            )}
        </div>
    );
};

export default Pagination;
