/* eslint-disable */

export const _tableHeaderCourseReports = [
    { id: 1, title: "Courses/LPs Report", property: "courseName" },
    { id: 2, title: "Type", property: "Type" },
    { id: 3, title: "Enrolled Learners", property: "Enrolled" },
    { id: 4, title: "Completed", property: "Completed" },
    { id: 5, title: "In Progress", property: "InProgress" },
    { id: 6, title: "Yet to Start", property: "NotStarted" }
];

export const _tableHeaderCourseDetails = [
    { id: 1, title: "Name", property: "Name" },
    { id: 2, title: "Email", property: "Email" },
    { id: 3, title: "Enrollment Date", property: "EnrollmentDate" },
    { id: 4, title: "Due Date", property: "DueDate" },
    { id: 5, title: "Completion Date", property: "CompletionDate" },
    { id: 6, title: "Progress", property: "Progress" },
    { id: 7, title: "Status", property: "Status" }
];
