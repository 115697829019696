import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDetailsLearnerData, setDetailsUsername } from "../MyTeam/learnerListSlice";

const LearnerReportsCellContent = ({ data }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const openDetailsTable = (data, userName) => {
        dispatch(setDetailsUsername(userName));
        dispatch(setDetailsLearnerData(data));
        navigate("/learnerreports/learnerdetails");
    };

    return (
        <tbody>
            {data.map((item, index) => (
                <tr key={index} className="tableRows">
                    <td className="columnData">{item?.Name || item?.courseName}</td>
                    <td className="columnData">
                        <button
                            className="table-cell-button"
                            onClick={() =>
                                openDetailsTable(
                                    item?.EnrolledLPCertificationCourse,
                                    item?.Name || item?.courseName
                                )
                            }>
                            {item?.Enrolled}
                        </button>
                    </td>
                    <td className="columnData">
                        <button
                            className="table-cell-button"
                            onClick={() =>
                                openDetailsTable(
                                    item?.CompletedLPCertificationCourse,
                                    item?.Name || item?.courseName
                                )
                            }>
                            {item?.Completed}
                        </button>
                    </td>
                    <td className="columnData">
                        <button
                            className="table-cell-button"
                            onClick={() =>
                                openDetailsTable(
                                    item?.InProgressLPCertificationCourse,
                                    item?.Name || item?.courseName
                                )
                            }>
                            {item?.InProgress}
                        </button>
                    </td>
                    <td className="columnData">
                        <button
                            className="table-cell-button"
                            onClick={() =>
                                openDetailsTable(
                                    item?.NotStartedLPCertificationCourse,
                                    item?.Name || item?.courseName
                                )
                            }>
                            {item?.NotStarted}
                        </button>
                    </td>
                </tr>
            ))}
        </tbody>
    );
};

export default LearnerReportsCellContent;
