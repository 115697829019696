/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-mixed-spaces-and-tabs */

import React, { useState, useEffect, useRef } from "react";
import secondsToHms from "../SecondsToHms/secondsToHms";
import { apis } from "../../services/Api";
import { useTranslation } from "react-i18next";
import TranslateFunc from "../../i18n/translateFunc";
import clock from "../../assets/images/time.svg";
import card_img from "../../assets/images/card_img.png";
import lp_card_img from "../../assets/images/lp_card_img.png";
import certi_card_img from "../../assets/images/certi_card_img.png";
import jobaid_card_img from "../../assets/images/jobaid_card_img.png";
import enrollUser from "../../assets/images/enrollUser.svg";
import {useNavigate} from "react-router-dom";
import moment from "moment";
function Card(props) {
    const [cardDetails, setCardDetails] = useState(props.data);
    const [countDays, setCountDays] = useState("");
    const [countDaysColor, setCountDaysColor] = useState("");
    const { t } = useTranslation();
    const [popUpSide, setPopUpSide] = useState("left");
    const navigate = useNavigate();
    const crumbs = (props?.homePageMyLearning || props?.fromHomePage) ? [{ label: "Home", url: "/" }]:
        [
            { label: "Home", url: "/" },
            { label: "My Learning", url: "/mylearning" },
        ];
    
    const navigateTo = (path) => {
        if(!props?.homePageMyLearning && !props?.fromHomePage){
            crumbs[1].url = "/mylearning" + window.location.search;
        }
        navigate(path, { state: { crumbs } });
    }
    
    const goToCourseDetails = (cid, lid) => {
        if (cardDetails.enrollment && cardDetails.enrollment === true) {
            navigateTo(`/coursedetails/${cid}/${lid}`);
            //navigate(`/coursedetails/${cid}/${lid}`, { state: { crumbs } });
        } else {
            navigateTo(`/coursedetails/${cid}`);
            //navigate(`/coursedetails/${cid}`, { state: { crumbs } });
        }
    }


    const inputRef = useRef(null);

    useEffect(() => {
        
        let element = inputRef.current.getBoundingClientRect();
        let centerX = document.documentElement.clientWidth / 2;
        if (element.x > centerX) {
            
            setPopUpSide("left");
        } else {
            
            setPopUpSide("right");
        }
        if(props){
            if(props?.fromHomePage && props?.fromUpcommingOverdue){
                const dueDate = new Date(cardDetails?.dueDate);
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);
                const timeDifference = dueDate - currentDate;
                const Days = Math.floor(timeDifference / (1000 * 3600 * 24));
                let count="";
                if(Days > 0) {
                    count = "Due in " +Days + " days";
                    Days > 5 ? setCountDaysColor("due-orange") : setCountDaysColor("due-red");
                }
                else if (Days === 0) {
                    count = "Due Today";
                    setCountDaysColor("due-red");
                }
                else {
                    count = Math.abs(Days) + " days overdue";
                    setCountDaysColor("overdue");
                }
                setCountDays(count);
            }
        }
    }, []);

    // const findSkill = (id) => {
    //     // return props.skills.find((e) => {
    //     //     return e.id === id;
    //     // });
    //     console.warn("find skill", id);
    // };

    const setBookmarked = async (id) => {
        try {
            await apis.addTheLearningObjectToTheBookmarks(id);
            setCardDetails({
                ...cardDetails,
                isBookmarked: true,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const deleteBookmark = async (id) => {
        try {
            await apis.removeRheLearningObjectFromTheBookmarks(
                id
            );
            setCardDetails({
                ...cardDetails,
                isBookmarked: false,
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <React.Fragment>
            {/* <div className='card-block' ref={inputRef}> */} 
            <div className="card-block coursesgrid-element" ref={inputRef}>
                <div className="ribbon-wrapper-one">
                    {/* <div className="ribbon-one">{cardDetails?.loType}</div> */}
                </div>
               
                {props?.showCartPopUp && (
                    <div
                        className={
                            (popUpSide === "right"
                                ? "hover-card-right "
                                : "hover-card-left ") + "d-none p-3"
                        }
                    >
                        <div>
                            <div className="d-flex justify-content-between mb-2 align-items-center">
                                {TranslateFunc(cardDetails?.loFormat).length >
									0 && (
                                    <span className="btn hover-btn-activity">
                                        {TranslateFunc(cardDetails?.loFormat)}
                                    </span>

                                )}

                                <span className='btn hover-btn-activity'>{TranslateFunc(cardDetails?.loFormat)}</span>
                                {cardDetails?.isBookmarked ? (
                                    <button
                                        className="btn-action"
                                        onClick={() =>
                                            deleteBookmark(cardDetails?.id)
                                        }
                                    >
                                        {t("remove")}
                                    </button>
                                ) : (
                                    <button
                                        className="btn-action"
                                        onClick={() =>
                                            setBookmarked(cardDetails?.id)
                                        }
                                    >
                                        {t("save")}
                                    </button>
                                )}
                            </div>
                            {/* <p className="card-title mb-2">
                                {cardDetails.name}
                            </p> */}
                           
                            <div className="row">
                                <div className="time-recent row m-0">
                                    <span className="col-7 p-0">
                                        {/* {findSkill(cardDetails?.skills)?.name} */}
                                    </span>
                                  
                                    <React.Fragment>
                                        <span
                                            className="col-5 p-0"
                                            style={{
                                                visibility:
													cardDetails?.duration != 0
													    ? "visible"
													    : "hidden",
                                            }}
                                        >
                                            <span className="small-separator"></span>
                                            {secondsToHms(cardDetails?.duration)}
                                        </span>
                                    </React.Fragment>
                                </div>
                                <div className="activity row m-0">
                                    <div className="col-7 p-0 py-2">
                                        <span className="mr-2">
                                            {TranslateFunc(cardDetails?.loType)}
                                        </span>

                                        
                                    </div>
                                    <div className="col-5 p-0 py-2">
                                        <span className="author mb-2">
                                            <span className="small-separator"></span>
                                            {/* <span className=''> </span> */}
											Sara Wilson
                                        </span>

                                        {/* <p className='author mb-2'><span className=''>{t("author")} : </span> Sara Wilson</p> */}
                                    </div>
                                </div>
                            </div>
                            <p className="card-details">
                                {cardDetails?.overview}
                            </p>
                           
                        </div>
                    </div>
                )}
                <div
                    className="card-img-block cp"
                    // onClick={() => goToCourseDetails(cardDetails?.id, cardDetails.instancesId)}
                >
                    {countDays != "" && <div className={`due-date-strip ${countDaysColor}`}>{countDays}</div>}
                    {cardDetails?.loType==="learningProgram" ?
                        <img
                            src={cardDetails?.imageUrl || lp_card_img}
                            alt={t("loading")}
                            className="img-fluid d-block"
                        />
                        :
                        cardDetails?.loType === "certification" ?
                            <img
                                src={cardDetails?.imageUrl || certi_card_img}
                                alt={t("loading")}
                                className="img-fluid d-block"
                            />
                            :
                            cardDetails?.loType === "jobAid" ?
                                <img
                                    src={cardDetails?.imageUrl || jobaid_card_img}
                                    alt={t("loading")}
                                    className="img-fluid d-block"
                                />
                                :
                                <img
                                    src={cardDetails?.imageUrl || card_img}
                                    alt={t("loading")}
                                    className="img-fluid d-block"
                                />  
                    }
                    {/* <span>{cardDetails.loFormat}</span> */}
                </div>
                
                <div className="card-content">
                    {/* Progress Bar */}
                    {cardDetails?.enrollment && (
                        <div className="progress">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${cardDetails?.progressPercent}%` }}
                                aria-valuenow={cardDetails?.progressPercent}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                <span
                                    style={{
                                        left: `${cardDetails?.progressPercent}%`,
                                        transform: `translateX(-${cardDetails?.progressPercent}%)`,
                                    }}
                                >
                                    {cardDetails?.progressPercent}%
                                </span>
                            </div>
                        </div>
                    )}
                    <div className="col time-recent m-0">
                        <React.Fragment>
                            <img className="clock-img" src={clock} alt="" title="Duration"/>
                            <span
                                className=""
                                // style={{
                                //     visibility:
                                // 			cardDetails.duration != 0
                                // 			    ? "visible"
                                // 			    : "hidden",
                                // }}
                            >
                                {(cardDetails?.duration !== 0 && cardDetails?.duration !== undefined)?secondsToHms(cardDetails?.duration): "No Duration"}
                            </span>
                            
                            {props.componentName ==="Recommendation"?
                                <><span className="seprator px-2"> | </span>
                                    <img className="user-img userIMG" src={enrollUser} alt="" title="Enrollment Count"></img>
                                    <span className="px-2">{cardDetails?.enrollmentCount}</span></>: null
                            }
                            
                        </React.Fragment>
                        {props.componentName ==="MyLearning"?
                           
                            <span className="progress-status">{cardDetails?.progressPercent?`${cardDetails?.progressPercent}%`:null}</span>
                            : null
                        }
                       
                    </div>
                    
                    <div className="listview-flex">
                        <div className="card-content-section">
                            <p
                                className="card-title cp"
                                // onClick={() => goToCourseDetails(cardDetails?.id)}
                            >
                                {cardDetails?.name}
                            </p>                        
                            {props.componentName === "MyLearning"? 
                                <p className="discription">{cardDetails?.description}</p> : null
                            }
                            {props.componentName === "Recommendation"? 
                                <p className="discription">{cardDetails?.description}</p> : null
                            }
                            <div className="d-flex mt-1 dateCss">
                                {  cardDetails?.dueDate && <><span className="c-date me-2">Due Date:</span><span className="c-date">{moment(cardDetails?.dueDate).format("MMM Do YYYY")}</span></>
                                }
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-2">
                            <button
                                className="btn primary-btn my-2  "
                                onClick={() => goToCourseDetails(cardDetails?.id, cardDetails.instancesId)}
                            >
                                View Details
                            </button>
                            
                        </div>
                    </div>
                    
                   
                </div>
               

                
            </div>
        </React.Fragment>
    );
}


export default Card;
