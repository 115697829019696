import React from "react";
import Select from "react-select";
import { isEmpty } from "lodash";
import { myTeamcolourStyles } from "../../../constant/styling";
import { myTeamFilterOptions } from "../../../constant/options";
import "../MyTeam.css";

function HeaderFilter({
    selectedParent,
    selectedChild,
    childOptionsData,
    disableApply,
    handleParentChange,
    handleChildChange,
    handleApply,
    resetFilters
}) {
    return (
        <div className="d-flex align-items-end mb-3 flex-wrap">
            <div className="d-flex flex-row align-items-end flex-wrap">
                <div className="mb-1">
                    <p className="filterMargin">Filter By</p>
                    <Select
                        isSearchable={true}
                        options={myTeamFilterOptions}
                        value={selectedParent}
                        styles={myTeamcolourStyles}
                        className="filterBy select-option"
                        onChange={handleParentChange}
                    />
                </div>
                {!isEmpty(selectedParent) && (
                    <div className="mb-1 ">
                        <div className="filterMargin">Select {selectedParent?.label}</div>
                        <Select
                            isSearchable={true}
                            options={childOptionsData}
                            value={selectedChild}
                            styles={myTeamcolourStyles}
                            className="pgno select-option"
                            placeholder="Select..."
                            onChange={handleChildChange}
                        />
                    </div>
                )}
            </div>
            <div className="d-flex mt-3">
                <span className="mb-2">
                    <button
                        className="btn primary-btn "
                        onClick={handleApply}
                        disabled={disableApply}>
                        Apply
                    </button>
                </span>
                <span className="mb-2 resetbtnFont">
                    <button className="btn btn-link btn-view mt-1" onClick={resetFilters}>
                        Reset Filters
                    </button>
                </span>
            </div>
        </div>
    );
}

export default HeaderFilter;
