
export const colourSelectOption = {

    option: (base, state) => {
        let backgroundColor  = "white";  
  
        if (state.isSelected) {
            backgroundColor = "#3273DE";            
            state.isSelected ? "green": "yellow";
        }
  
        return {
            ...base,
            backgroundColor,
            ":hover": {
                backgroundColor: (state.isSelected) ? "null" : "#F6F5FF",
            },
        };
    }
      
};