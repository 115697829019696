import Container from "react-bootstrap/Container";

const Title = ({ title }) => {
    return (
        <Container fluid className="pl-30 py-4">
            <div className="headerImage">
                <h1>{title}</h1>
            </div>
        </Container>
    );
};

export default Title;
