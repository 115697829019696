import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { Provider } from "react-redux"
import { store } from "./app/store"
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../src/assets/css/global.css";
import "./index.css";
import { jwtInterceptor } from "./services/JwtInterceptor";
import { ErrorInterceptor } from "./services/ErrorInterceptor";
import { AppContextProvider } from "./context/appContext"

// enable interceptors for http requests
jwtInterceptor();
// errorInterceptor(); 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <Suspense fallback={<div></div>}>
                <AppContextProvider >
                    <ErrorInterceptor />
                    <App />
                </AppContextProvider>
            </Suspense>
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
)