import React,{ useContext, useState } from "react";
import SearchCourseDetails from "./SearchCourseDetails";
import { useSearchParams } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import { appContext } from "../../context/appContext";
import { useTranslation } from "react-i18next";
// import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Breadcrumbs from "../../common/Breadcrumbs";

const NavigationSearchFilter = () => { 
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const {searchCourseParam} = useContext(appContext)
    // const {state} = useLocation();
    const [notenrolled] = useState(true);
    const crumbs = [
        { label: "Home", url: "/" },
        { label: "Search Course", url: "/searchcourse" },
    ];
    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4">
                <div className="headerImage">
                    <h1>{t("search-label")}</h1>
                </div>
            </Container>
            <SearchCourseDetails searchQuery={searchParams.get("q")} notenrolled={notenrolled} MyLearning={false} filterSnippetTypes={searchCourseParam}></SearchCourseDetails>
        </>
    )
}

export default NavigationSearchFilter
