import React from "react"
import "./Loader.css";
const Loader = ({isPositionRelative = true}) => {
    return (
        <>  
            {isPositionRelative ?
                (
                    <div>
                        <div id="loader"></div>   
                    </div>
                ) : 
                (
                    <div className="position-relative">
                        <div id="loader"></div>   
                    </div>
                )
            }
        </>
    )
}

export default Loader
