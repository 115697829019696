/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
// import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import "./SkillList.css";
import { appContext } from "../../../context/appContext";
import { apis } from "../../../services/Api";
import { useLocation, useNavigate } from "react-router";

const SkillList = ({
    handleViewDetail,
    tab,
    data,
    role,
    selectedSkillIds,
    setSelectedSkillIds,
    selectAll,
    setSelectAll,
    handleSelectedIds,
    crumbs
}) => {
    const [tabData, setTabData] = useState([]);
    const { roleWiseSkillsData, dispatch } = useContext(appContext);
    const navigate = useNavigate();
    const location = useLocation();
    const name = location?.state?.item?.subMenuTitle;
    const populateDataAsPerTab = (_tab, _data, _role) => {
        const temp = _data.filter((item) => {
            return item.SkillGroupName.toLowerCase() === _tab.toLowerCase();
        });
        setTabData(temp);
    };

    const handleSelectSkill = (e, item) => {
        const isChecked = e.target.checked;
        let updatedSelectedSkillIds = [];
        let selectedSkills = [];

        if (isChecked) {
            selectedSkills = [...selectedSkillIds, item.SkillId];
            updatedSelectedSkillIds = [...selectedSkillIds, item.RoleSkillGroupsId];
        } else {
            let removeSkillsIds = selectedSkillIds.filter((id) => id != item.SkillId);
            selectedSkills = removeSkillsIds;

            updatedSelectedSkillIds = selectedSkillIds.filter((id) => id !== item.RoleSkillGroupsId);
        }
        // setSelectedSkillIds(selectedSkills)
        setSelectedSkillIds(updatedSelectedSkillIds);
        handleSelectedIds(item, updatedSelectedSkillIds);
    };

    const getRoleWiseSkillsData = async (skillName) => {
        let { data, included, links } = await apis.getLearningCoursesbySkillName(skillName);
        dispatch({ type: "skillNameWiseCourseData", payload: data });
        dispatch({ type: "skillName", payload: skillName });
    };

    const handleShowViewDetail = (e, RoleId, skillName, skillDescription, SkillGroupName,RoleName, item) => {
        let skillNameandDescription = [skillName, skillDescription, SkillGroupName,RoleName, item];
        let newData = roleWiseSkillsData.filter((value) => {
            if (RoleId === value.RoleSkillGroupsId) {
                return value;
            }
        });
        sessionStorage.setItem("settingSkillNameandDescription", JSON.stringify(skillNameandDescription));
        dispatch({ type: "filterRoleWiseSkillsData", payload: newData });
        getRoleWiseSkillsData(skillName);
        handleViewDetail(true, {})
        navigate("/roleskilljourney/viewcontent",{state:{crumbs,name}});
    }

    useEffect(() => {
        populateDataAsPerTab(tab, data, role);
    }, []);

    useEffect(() => {
        populateDataAsPerTab(tab, data, role);
    }, [role, data]);

    return (
        <>
            <div className="col-12 col-sm-12 solutionTechnical" id="solutionTechnical">
                {tabData &&
          tabData.map((item, key) => (
              <Accordion defaultActiveKey="0" key={key}>
                  <Accordion.Item>
                      <Accordion.Header>
                          <Form.Check
                              onClick={(e) => {
                                  e.stopPropagation();
                                  handleSelectSkill(e, item);
                              }}
                              type="checkbox"
                              label={<span>{item.SkillName}</span>}
                              checked={selectedSkillIds.includes(item.RoleSkillGroupsId)}
                              className="ms-5"
                          />
                          <button
                              className="btn btn-link btn-view ms-auto view-btn-font"
                              onClick={(e) =>
                                  handleShowViewDetail(
                                      e,
                                      item.RoleId,
                                      item?.SkillName,
                                      item?.SkillDescription,
                                      item?.SkillGroupName,
                                      item?.RoleName,
                                      item
                                  )
                              }>
                      View Content
                          </button>
                      </Accordion.Header>
                      <Accordion.Body className="d-flex flex-column">
                          {/* <div>{item.SkillDescription}1</div> */}
                          <div
                              style={{ display: "inline" }}
                              className="assigned"
                              dangerouslySetInnerHTML={{ __html: item.SkillDescription}}></div>   
                          {/* <div className="d-flex justify-content-end view-all">
                              <button
                                  className="btn btn-link btn-view"
                                  onClick={(e) =>
                                      handleShowViewDetail(
                                          e,
                                          item.RoleId,
                                          item?.SkillName,
                                          item?.SkillDescription,
                                          item?.SkillGroupName,
                                          item?.RoleName,
                                          item
                                      )
                                  }>
                      View Content
                              </button>
                          </div> */}
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
          ))}
            </div>
        </>
    );
};

export default SkillList;
