import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filterBySearch: false,
    filterByAllCourse: false,
    isAllSkillSelected: [],
    isAllSelected: [],
    submissionIsAllSelected: false,
    collapseData: [],
    approveRejectData:[]
};

export const mainSlice = createSlice({
    name: "main",
    initialState,
    reducers: {
        setFilterByAllCourse: (state, action) => {
            state.filterByAllCourse = action.payload;
        },
        setFilterBySearch: (state, action) => {
            state.filterBySearch = action.payload;
        },
        setIsAllSkillSelected: (state, action) => {
            state.isAllSkillSelected = action.payload;
        },
        setIsAllSelected: (state, action) => {
            state.isAllSelected = action.payload;
        },
        setSubmissionIsAllSelected: (state, action) => {
            state.submissionIsAllSelected = action.payload;
        },
        setCollapseData: (state, action) => {
            state.collapseData = action.payload;
        },
        setApproveRejectData: (state, action) => {
            state.approveRejectData = action.payload;
        }
    }
});

export const {
    setFilterByAllCourse,
    setFilterBySearch,
    setIsAllSkillSelected,
    setIsAllSelected,
    setSubmissionIsAllSelected,
    setCollapseData,
    setApproveRejectData
} = mainSlice.actions;

export default mainSlice.reducer;
