// dataTemplateManagement.js
export const _tableHeaderTemplateManagement = [
    {
        title: "Template Name",
        property: "templateId",
        sortKey: "templateName",
        width: "50%"
    },
    { title: "Count of Menu", property: "Action", sortKey: null, width: "10%" },
    {
        title: "Last Updated",
        property: "updatedOn",
        sortKey: "lastUpdated",
        width: "10%"
    },
    { title: "Update By", property: "Action", sortKey: null, width: "10%" },
    { title: "Download", property: "Action", sortKey: null, width: "10%" },
    { title: "Update", property: "Action", sortKey: null, width: "10%" },
    { title: "Delete", property: "Action", sortKey: null, width: "10%" }
];
