/* eslint-disable */
import React from 'react';
import logofooter from '../../assets/images/logoFooter.svg';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import './Footer.css';
function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <React.Fragment>
      <div className='mt-3'>
      <Navbar bg="light" className="navbar-footer navigation footerMain mt-auto">
        <Container fluid className="home-footer">
          <Navbar.Brand className="m-0 ps-2">
            <img src={logofooter} className="logoFooter" alt="" />
          </Navbar.Brand>
          <Nav className="justify-content-end">
            <Nav.Link className="p-0 footer-logo-css">
              Copyright © {currentYear} Adobe. All rights reserved.
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar></div>
    </React.Fragment>
  );
}

export default Footer;
