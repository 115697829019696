/* eslint-disable */
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import navigation_icon from '../../../assets/images/navigation_importcsv.png';
import '../../../App.css';
import '../Importcsv.css';

function FileUploadFileList({ fileName, fileSize }) {
  return (
    <div className="alm-file-upload-uploaded-files d-flex align-items-center justify-content-center">
      <div className="navBackgroundColor p-2">
        <span className="me-1">
          <img src={navigation_icon} alt=""></img>
        </span>
        <span className="right-bar px-2 alm-file-upload-file-name">{fileName}</span>
        <span className="px-2 alm-file-upload-file-size">{fileSize}</span>
      </div>
    </div>
  );
}

export default FileUploadFileList;
