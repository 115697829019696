import { createSlice } from "@reduxjs/toolkit";
import { apis } from "../../services/Api";

const initialState = {
    isPending: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    courseList: [],
    courseReportList: [],
    detailsCourseData: [],
    detailsCourseName: null
};

export const courseListSlice = createSlice({
    name: "courseList",
    initialState,
    reducers: {
        setCourseList: (state, action) => {
            state.courseList = action.payload;
        },
        setDetailsCourseData: (state, action) => {
            return {
                ...state,
                detailsCourseData: action.payload
            };
        },
        setDetailsCourseName: (state, action) => {
            return {
                ...state,
                detailsCourseName: action.payload
            };
        }
    },
    extraReducers(builder) {
        builder
            .addCase(apis.getCourseData.pending, (state) => {
                return {
                    ...state,
                    isPending: true
                };
            })
            .addCase(apis.getCourseData.fulfilled, (state, action) => {
                let list = Object.entries(action.payload?.data).map(([courseName, courseInfo]) => {
                    return {
                        courseName,
                        Enrolled: courseInfo?.EnrolledLearners,
                        Completed: courseInfo?.Completed,
                        NotStarted: courseInfo?.NotStarted,
                        InProgress: courseInfo?.InProgress,
                        EnrolledLearnersList: courseInfo?.EnrolledLearnersList,
                        CompletedLearners: courseInfo?.CompletedLearners,
                        NotStartedLearners: courseInfo?.NotStartedLearners,
                        InProgressLearners: courseInfo?.InProgressLearners,
                        Type: courseInfo?.Type
                    };
                });

                return {
                    ...state,
                    isPending: false,
                    isSuccess: true,
                    courseReportList: list
                };
            })
            .addCase(apis.getCourseData.rejected, (state, action) => {
                return {
                    ...state,
                    isPending: false,
                    isError: true,
                    errorMessage: action.error.message
                };
            });
    }
});

export const { setCourseList, setDetailsCourseData, setDetailsCourseName } =
    courseListSlice.actions;
export default courseListSlice.reducer;
