import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "./CustomAccordion.css";

function ApprovedInfoIcon({ address }) {
    return (
        <>
            <OverlayTrigger
                trigger="hover"
                placement="left"
                rootClose
                overlay={
                    <Popover
                        id="popover-trigger-click-root-close"
                        className="popover-body-rejectioned">
                        <Popover.Body
                            className="popover-body-rejection"
                            style={{ fontSize: "15px", maxHeight: "200px", overflowY: "auto" }}>
                            {address}
                        </Popover.Body>
                    </Popover>
                }>
                <button className="tbl-approve-btn-status " type="button"></button>
            </OverlayTrigger>
        </>
    );
}
export default ApprovedInfoIcon;
