/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */

/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React from "react";
import card_img from "../../../src/assets/images/card_img.png";
import lp_card_img from "../../assets/images/lp_card_img.png";
import certi_card_img from "../../assets/images/certi_card_img.png";
import jobaid_card_img from "../../assets/images/jobaid_card_img.png";
import bookmark from "../../../src/assets/images/bookmark.svg";
import secondsToHms from "../../common/SecondsToHms/secondsToHms";
import time from "../../../src/assets/images/time.svg";
import ClassRoomVirtualClassroomTypeDetails from "./ClassRoomVirtualClassroomTypeDetails";
import "primeicons/primeicons.css";
import PrerequisiteCourse from "./PrerequisiteCourse";
import { useTranslation } from "react-i18next";

export default function PrerequisiteCourseDetails({
  coreContentItem,
  startUi,
  courseDetails,
  isVisibleBtn,
  prerequisiteCourse
})
 {
  const { t } = useTranslation();
  return (
    <>
      <span>{coreContentItem?.richTextOverview ?? ""}</span>
      {/*<h3>Core content</h3>*/}
      {coreContentItem?.course?.map((coreContentItem, i) => {
        let { authorDesiredDuration, name, contentType } = coreContentItem.attributes;
        return (
          <div className="list-view" key={i + 1}>
            <div className="row mb-4 list-view-block cd justify-content-center justify-content-md-start mx-0">
              <div className="col-auto py-4 px-4">
                    {coreContentItem?.loType==="learningProgram" ?
                        <img
                            src={coreContentItem?.imageUrl || lp_card_img}
                            alt={t("loading")}
                            className="img-fluid d-block list-view-block-img"
                        />
                        :
                        coreContentItem?.loType === "certification" ?
                            <img
                                src={coreContentItem?.imageUrl || certi_card_img}
                                alt={t("loading")}
                                className="img-fluid d-block list-view-block-img"
                            />
                            :
                            coreContentItem?.loType === "jobAid" ?
                                <img
                                    src={coreContentItem?.imageUrl || jobaid_card_img}
                                    alt={t("loading")}
                                    className="img-fluid d-block list-view-block-img"
                                />
                                :
                                <img
                                    src={coreContentItem?.imageUrl || card_img}
                                    alt={t("loading")}
                                    className="img-fluid d-block list-view-block-img"
                                />  
                    }
              </div>
              <div className="col pe-4 text-padding">
                <p className="head-text">{name}</p>
                {/* <p className="sub-text">{coreContentItem?.description}</p> */}
              </div>
              <div className="list-footer align-items-center">
                <div className="py-2">
                  <img src={bookmark} className="px-2" alt=""></img>
                  {/* <span className="module">{coreContentItem?.contentType}</span> */}
                  <span className="my-2 inner-span">
                    <span className="capsWord">{contentType}</span>
                    <span className="seprator px-2"> | </span>
                    <span className="capsWord">{coreContentItem?.loType === "learningProgram"? "Learning Path" : coreContentItem?.loType}</span>      
                    <span className="seprator px-2"> | </span>
                    {coreContentItem.authorNames ?? "-"}

                    <span className="seprator px-2"> | </span>

                    <span>
                      <img src={time} className="px-2" alt="" title="Duration"></img>{" "}
                      {secondsToHms(authorDesiredDuration) || "0 mins" }
                    </span>
                  </span>
                </div>
                {isVisibleBtn ? <div className="">{startUi(coreContentItem?.id)}</div> : null}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
