 
import { createContext,useReducer } from "react";

const initialState={
    catalogListOB:[],
    catalogListSE:[],
    catalogListRSJ:[],
    catalogListAR:[],
    enrollmentList:[],
    learningObjectData:[],
    enrollmentCountOfCourse:[],
    roleWiseSkillsData:[],
    filterRoleWiseSkillsData:[],
    skillNameWiseCourseData:[],
    searchCourseParam:[],
    settingSkillNameandDescription:[],
    rolesListData:[],
    skillsListData:[],
    catalogsListItem:[],
    skillName:"",
    CheckUserRoleSkills:[],
    updateProfile:[],
    defaultDataOnMyTeam:[],
    AvatarUrl:[],
    notifications: [],
    editorDataforPreview:"",
    cancelData:"",
    loginUser:[],
    navigationNewData:[],
    templateJSONData: [],
    loader:true,
    flag: false,
}
export const appContext=createContext(initialState);

const OnBoardingReducer=(state,action)=>{
   
    switch(action.type){
    case "catalogItemsOB":
        return{
            ...state,
            catalogListOB:action.payload
        }
    case "catalogItemsSE":
        return{
            ...state,
            catalogListSE:action.payload
        }
    case "catalogItemsRSJ":
        return{
            ...state,
            catalogListRSJ:action.payload
        }
    case "catalogItemsAR":
        return{
            ...state,
            catalogListAR:action.payload
        }
    case "enrollmentItems":
        return{
            ...state,
            enrollmentList:action.payload
        }
    case "learningObjectDataItem":
        return{
            ...state,
            learningObjectData:action.payload
        }
    case "enrollmentCountItem":
        return{
            ...state,
            enrollmentCountOfCourse:action.payload
        }
    case "RoleWiseSkillsItem":
        return{
            ...state,
            roleWiseSkillsData:action.payload
        }
    case "filterRoleWiseSkillsData":
        return{
            ...state,
            filterRoleWiseSkillsData:action.payload
        }
    case "skillNameWiseCourseData":
        return{
            ...state,
            skillNameWiseCourseData:action.payload
        }
    case "searchCourseParamItem":
        return{
            ...state,
            searchCourseParam:action.payload
        }
    case "settingSkillNameandDescription":
        return{
            ...state,
            settingSkillNameandDescription:action.payload
        }
    case "rolesListItem":
        return{
            ...state,
            rolesListData:action.payload
        }
    case "skillsListItem":
        return{
            ...state,
            skillsListData:action.payload
        }
    case "skillName":
        return{
            ...state,
            skillName:action.payload
        }
    case "CheckUserRoleSkillsItem":
        return{
            ...state,
            CheckUserRoleSkills:action.payload
        }
    case "updateProfile":
        return{
            ...state,
            updateProfile:action.payload
        }    
    case "defaultDataOnMyTeam":
        return{
            ...state,
            defaultDataOnMyTeam:action.payload
        }
    case "catalogsListItem":
        return{
            ...state,
            catalogsListItem:action.payload
        }      
    case "AvatarUrl":
        return{
            ...state,
            AvatarUrl:action.payload
        }
    case "notifications":
        return{
            ...state,
            notifications:action.payload
        }    
    case "editorDataforPreview":
        return{
            ...state,
            editorDataforPreview:action.payload
        }
    case "cancelData":
        return{
            ...state,
            cancelData:action.payload
        }
    case "loginUser":
        return{
            ...state,
            loginUser:action.payload
        }
    case "navigationNewData":
        return{
            ...state,
            navigationNewData:action.payload
        }
    case "templateJSONData":
        return{
            ...state,
            templateJSONData:action.payload
        }
    case "loader":
        return{
            ...state,
            loader:action.payload
        }  
    case "flag":
        return{
            ...state,
            flag:action.payload
        }  
    default:
        return state;
    }
}

export const AppContextProvider=({children})=>{
    const [state,dispatch]=useReducer(OnBoardingReducer,initialState)
 
    return (
        <appContext.Provider value={{
            catalogListOB:state.catalogListOB,
            catalogListSE:state.catalogListSE,
            catalogListRSJ:state.catalogListRSJ,
            catalogListAR:state.catalogListAR,
            enrollmentList:state.enrollmentList,
            learningObjectData:state.learningObjectData,
            enrollmentCountOfCourse:state.enrollmentCountOfCourse,
            roleWiseSkillsData:state.roleWiseSkillsData,
            filterRoleWiseSkillsData:state.filterRoleWiseSkillsData,
            skillNameWiseCourseData:state.skillNameWiseCourseData,
            searchCourseParam: state.searchCourseParam,
            settingSkillNameandDescription: state.settingSkillNameandDescription,
            rolesListData: state.rolesListData,
            skillsListData: state.skillsListData,
            skillName: state.skillName,
            CheckUserRoleSkills:state.CheckUserRoleSkills,
            updateProfile:state.updateProfile,
            defaultDataOnMyTeam:state.defaultDataOnMyTeam,
            catalogsListItem:state.catalogsListItem,
            AvatarUrl:state.AvatarUrl,
            notifications:state.notifications,
            editorDataforPreview:state.editorDataforPreview,
            cancelData:state.cancelData,
            loginUser:state.loginUser,
            navigationNewData:state.navigationNewData,
            templateJSONData:state.templateJSONData,
            loader:state.loader,
            flag:state.flag,
            dispatch,
        }}>
            {children}
        </appContext.Provider>)
    
}
