import React from "react";
import Filtersearch from "./FilterSearch";
// import { apis } from "../../services/Api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Breadcrumbs from "../../common/Breadcrumbs";


const MyLearningComponent = () => {
    const { t } = useTranslation();
    const [notenrolled] = useState(false);
    const crumbs = [
        { label: "Home", url: "/" },
        { label: "My Learning", url: "/mylearning" },
    ];
    return (
        <>
            {/* <Container fluid className="pl-30 sub-menu-list">
                <Row>
                    <div className="sub-menu">
                        <span>Home</span>
                        <span className="px-2">&#62;</span>
                        <span className="sub-page">{t("My Learning")}</span>
                    </div>
                </Row>
            </Container> */}
            <Breadcrumbs crumbs={crumbs} />
            <Container fluid className="pl-30 py-4">
                <div className="headerImage">
                    <h1>{t("my-learning-label")}</h1>
                </div>
            </Container>
            <Filtersearch notenrolled={notenrolled}  myLearning={true}/>
        </>
    )
}

export default MyLearningComponent;
