import Container from "react-bootstrap/Container";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { apis } from "../../services/Api";
import Breadcrumbs from "../../common/Breadcrumbs";
import Scroller from "../../common/Scroller/Scroller";
import { myTeamBreadcrumbs } from "../../constant/breadCrumbs";
import Title from "../../component/Title";
import HeaderFilter from "./components/HeaderFilter";
import useDataReports from "../../hooks/useDataReports";
import { Table } from "react-bootstrap";
import TableHeading from "../../component/TableHeading";
import Loader from "../../common/Loader/Loader";
import { _tableHeaderMyTeamData } from "../../data/dataMyTeam";
import MyTeamCellContent from "./components/MyTeamCellContent";
import Pagination from "../../component/DataTable/Pagination";
import "./MyTeam.css";

function MyTeam() {
    const dispatch = useDispatch();
    const { isPending, myTeamData, roles, skills } = useSelector((state) => state.myTeam);

    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const {
        searchTerm,
        filteredData,
        currentItems,
        currentPage,
        itemsPerPage,
        sortKey,
        sortOrder,
        handleSort,
        handleInputChange,
        setCurrentPage
    } = useDataReports({ data: myTeamData });

    const [selectedParent, setSelectedParent] = useState(null);
    const [selectedChild, setSelectedChild] = useState("");
    const [childOptionsData, setChildOptionsData] = useState([]);
    const [disableApply, setDisableApply] = useState(true);

    const handleParentChange = (option) => {
        setSelectedParent(option);
    };

    const loadChildDropdown = () => {
        if (selectedParent?.id === "role") {
            const options = roles.map((item) => ({
                value: item.id,
                label: item.RoleName
            }));
            setChildOptionsData(options);
        } else if (selectedParent?.id === "skill") {
            const options = skills.map((item) => ({
                value: item.id,
                label: item.SkillName
            }));
            setChildOptionsData(options);
        } else {
            setChildOptionsData([]);
        }
    };

    const handleChildChange = (option) => {
        setSelectedChild(option);
        setDisableApply(false);
    };

    const handleApply = () => {
        setCurrentPage(1);
        dispatch(
            apis.getFilteredDataOnApply({ type: selectedParent.id, value: selectedChild?.value })
        );
    };

    const resetFilters = () => {
        dispatch(apis.getMyTeamStats());
        setSelectedChild("");
        setSelectedParent("");
        setDisableApply(true);
        setCurrentPage(1);
    };

    useEffect(() => {
        !isEmpty(selectedParent) && loadChildDropdown();
    }, [selectedParent]);

    useEffect(() => {
        // Fetch data and set isDataLoaded to true when data fetching is complete
        dispatch(apis.getMyTeamStats()).then(() => {
            setIsDataLoaded(true);
        });
        dispatch(apis.getRolesAndSkillsDropDown());
    }, [dispatch]);

    return (
        <>
            <Breadcrumbs crumbs={myTeamBreadcrumbs} />
            <Title title="Skill Dashboard" />
            <Container fluid className="pl-30 pb-4">
                <div className="headerTeam pb-0">
                    <HeaderFilter
                        selectedParent={selectedParent}
                        selectedChild={selectedChild}
                        childOptionsData={childOptionsData}
                        disableApply={disableApply}
                        handleParentChange={handleParentChange}
                        handleChildChange={handleChildChange}
                        handleApply={handleApply}
                        resetFilters={resetFilters}
                    />
                    <div className="manager-my-team-filter-by-role">
                        <div className="d-flex align-items-end justify-content-end flex-wrap mb-4">
                            <div className="myteam-search search searchClass">
                                <div className="searchInputs searchInputDiv">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        onChange={handleInputChange}
                                        value={searchTerm}
                                    />
                                    <button className="searchIcon searchBtn">
                                        <span className="pi pi-search"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {!isPending && isDataLoaded ? (
                            !isEmpty(currentItems) ? (
                                <>
                                    <div className="tableContainer">
                                        <Table responsive="lg" className="table">
                                            <TableHeading
                                                sortKey={sortKey}
                                                sortOrder={sortOrder}
                                                columns={_tableHeaderMyTeamData}
                                                handleSort={handleSort}
                                            />
                                            <MyTeamCellContent data={currentItems} />
                                        </Table>
                                    </div>

                                    <Pagination
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        filteredData={filteredData}
                                        itemsPerPage={itemsPerPage}
                                    />
                                </>
                            ) : (
                                <h2 className="noData mb-3">
                                    No results found. Please try another search term.
                                </h2>
                            )
                        ) : (
                            <Loader />
                        )}
                    </div>
                </div>
            </Container>
            <Scroller />
        </>
    );
}

export default MyTeam;
