import React from "react";
import { Table } from "react-bootstrap";
import Pagination from "./Pagination";
import SearchInput from "./SearchInput";
import { size } from "lodash";

function DataTable({
    handleInputChange,
    searchTerm,
    currentItems,
    currentPage,
    setCurrentPage,
    filteredData,
    itemsPerPage,
    children,
    flag,
    noResultsMessage
}) {
    return (
        <>
            { flag && <SearchInput  handleInputChange={handleInputChange} searchTerm={searchTerm} />}

            {size(currentItems) > 0 ? (
                <>
                    <div className={flag && "p-4 pb-0 mb-4"}>
                        <Table responsive="lg" className="table tableBorder">
                            {children}
                        </Table>
                    </div>

                    { <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        filteredData={filteredData}
                        itemsPerPage={itemsPerPage}
                    />}
                </>
            ) : (
                <h2 className="noData mb-3">{noResultsMessage}</h2>
            )}
        </>
    );
}
DataTable.defaultProps = {
    noResultsMessage: "No results found. Please try another search term.",
};
export default DataTable;
