import React, { useState, useEffect } from "react";
// import Container from "react-bootstrap/Container";
// import { useSelector, useDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import useDataReports from "../../../hooks/useDataReports";
import DataTable from "../../../component/DataTable";
import TableHeading from "../../../component/TableHeading";
import { _tableHeaderTemplateManagement } from "../dataTemplateManagement";
import Loader from "../../../common/Loader/Loader";
import Scroller from "../../../common/Scroller/Scroller";
import { apis } from "../../../services/Api";
import info from "../../../assets/images/info.svg";
import checkIcon from "../../../assets/images/checkIcon.png";
import errorIconTemp from "../../../assets/images/errorIconTemp.png";
console.log(errorIconTemp);
import parse from "html-react-parser";
import Modal from "react-bootstrap/Modal";
// import user_icon from "../../../assets/images/user_importcsv.png";
// import calendar_icon from "../../../assets/images/calendar_importcsv.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faDownload, faEdit } from "@fortawesome/free-solid-svg-icons";
import Delete from "../../../assets/images/deleteTemplate.svg";
import Edit from "../../../assets/images/editTemplate.svg";
import Download from "../../../assets/images/downloadTemplate.svg";
import "../Templates.css";
import ModalforSuccessAndFailure from "../../../common/Modal/Modal";
import { buildNestedDataGroupedByTemplateId } from "../../../utils/dataTable";
// import Row from "react-bootstrap/Row";
/* eslint-disable */
const TemplateManagementCellContent = ({
    data,
    presgnUrl,
    setPresgnUrl,
    setMessageforPopup,
    setShowModal,
    setShowStatus,
    setTemplateId,
    setShowLoader
}) => {
    const [templateStatusData, setTemplateStatusData] = useState([]);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showBreadCrumbsModal, setShowBreadCrumbsModal] = useState(false);
    const [messageforInfo, setMessageforInfo] = useState("");
    const [menuCompletePath, setMenuCompletePath] = useState("");
    const [statusOfLastImport, setStatusOfLastImport] = useState(null);
    const [menuCounts, setMenuCounts] = useState({}); // Store current template ID
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorMsg, setErrorMsg] = useState([]);
    const [rowNumber, setRowNumber] = useState([]);
    const [templateId, setNewTemplateId] = useState("");
    const handleDownload = async (templateId) => {
        setShowStatus("");
        setShowLoader(true);
        const res = await apis.getExistingTemplateDownloadCsv(templateId);
        if (!res) {
            setMessageforPopup("Failed to download file!");
            setShowModal(true);
            setShowLoader(false);
        } else {
            window.open(res.PresignedUrl, "_self");
            setPresgnUrl(res.PresignedUrl);
            setMessageforPopup("Download Completed.");
            setShowModal(true);
            setShowLoader(false);
        }
    };
    const findAllNestedObjs = (entireObj, keyToFind, valToFind) => {
        const foundObjs = [];
        JSON.stringify(entireObj, (_, nestedValue) => {
            if (nestedValue && nestedValue[keyToFind] === valToFind) {
                foundObjs.push(nestedValue);
            }
            return nestedValue;
        });
        return foundObjs;
    };
    let completePath;
    const handleInfoForBreadCrumbs = (templateId) => {
        console.log("templateId handleInfoForBreadCrumbs", templateId);
        const menuObj = findAllNestedObjs(
            JSON.parse(localStorage.getItem("NavMenuData")),
            "TemplateId",
            templateId
        );

        // Get the full parent path for this menu object
        completePath = getParentPath(menuObj);
        // Now show the modal with the complete path
        if (completePath && completePath.length > 0) {
            showBreadCrumbsModalOpen(completePath, templateId); // Open the modal
        }
    };
    const menuObj = (templateId) => {
        // Find all the objects related to the templateId
        const menuObj = findAllNestedObjs(
            JSON.parse(localStorage.getItem("NavMenuData")),
            "TemplateId",
            templateId
        );
        // Return the complete path and length as an object
        console.log("menuObj.length", menuObj)
        return menuObj.length;
    };

    const getParentPath = (menuArray) => {
        let completePath = "";
        menuArray.forEach((object, index) => {
            let currentPath = object?.MenuTitle;
            if (object?.ParentMenu && parseInt(object?.ParentMenu) !== 0) {
                const parentMenu = findAllNestedObjs(
                    JSON.parse(localStorage.getItem("NavMenuData")),
                    "MenuId",
                    parseInt(object?.ParentMenu)
                );
                const parentPath = getParentPath(parentMenu);
                if (parentPath) {
                    currentPath = parentPath + " > " + currentPath;
                }
            }

            // Append a newline only after the first object
            if (index > 0) {
                completePath += ","; // New line after the first object
            }
            completePath += currentPath;
        });
        return completePath;
    };

    const showInfoModalOpen = (msg) => {
        if (completePath != null) {
            setMenuCompletePath(completePath);
        }
        setShowInfoModal(true);
        setMessageforInfo(msg);
    };
    const showBreadCrumbsModalOpen = (msg, templateId) => {
        if (completePath != null) {
            setMenuCompletePath(completePath);
        }
        setNewTemplateId(templateId);
        setShowBreadCrumbsModal(true);
        // setMessageforBreadCrumbs(msg);
    };
    const showInfoModalClose = () => {
        setShowInfoModal(false);
        // setMessageforInfo("");
    };
    // const showInfoModalClose = () => {
    //     setShowInfoModal(false);
    //     setMessageforInfo("");
    // };
    const handleInforToggle = () => {
        let rowNumber = [];
        // let msg = "";
        let apiError = false;
        console.log("hi");
        let templateError = [];
        console.log("templateError", templateError, "errorMessage", errorMessage);
        if (Array.isArray(errorMessage)) {
            errorMessage?.forEach((ele) => {
                if (Array.isArray(ele)) {
                    let rowError = {};
                    rowError.rowNumber = ele.rowNumber;
                    rowError.err = ele.err;
                    apiError = false;
                    templateError.push(rowError);
                }
                setErrorMsg(templateError);
            });
        } else {
            let rowError = {};
            rowError.rowNumber = 0;
            rowError.err = [{ msg: errorMessage, colName: "" }];
            templateError.push(rowError);
            console.log(templateError, "templateError");
            setErrorMsg(templateError);
        }
        if (rowNumber.length > 0) {
            let Text = "";
            if (apiError) showInfoModalOpen(rowNumber);
            else showInfoModalOpen(Text);
        } else {
            showInfoModalOpen();
        }
        setRowNumber(rowNumber);
    };
    // breadCrumb.forEach((data) => {
    //     crumbs.push({ label: data, url: location });
    // });

    useEffect(() => {
        const fetchTemplateStatus = async () => {
            try {
                // const response = await apis.getPageTemplateStatus();
                // console.log("response fetchTemplateStatus>>>", response.data.response);
                const templateData = data;
                console.log("templateData  inside cell", templateData)
                setTemplateStatusData(templateData);
                const counts = {};
                templateData.forEach((item) => {
                    const errorMessage = item?.responseMessage?.body?.error;
                    if (errorMessage && errorMessage.length > 0) {
                        setErrorMessage(item?.responseMessage?.body?.error);
                    }
                    const count = menuObj(item.templateId);
                    counts[item.templateId] = count;
                });
                setMenuCounts(counts); // Set all menu counts in state
            } catch (error) {
                console.error("Error fetching template status:", error);
            }
        };

        fetchTemplateStatus();
    }, [data]);
    const confirmPopup = (templateId) => {
        setMessageforPopup(
            "Warning: Deleting this template may result in a blank page for certain solutions and roles. Do you still want to proceed with the deletion?"
        );
        setShowModal(true);
        setShowStatus("delete");
        setTemplateId(templateId);
    };
    const updatePopup = (templateId) => {
        setShowLoader(true);
        setShowModal(true);
        setShowStatus("upload");
        setTemplateId(templateId);
        // console.log(templateId,"template123");
    };
    // Function to format the date to "DD/MM/YYYY"
    const formatDate = (dateString) => {
        if (!dateString) return "N/A";

        const date = new Date(dateString);

        if (isNaN(date.getTime())) return "Invalid Date";

        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    console.log(data, "listingData");
    const formatErrors = (errors) => {
        const errorMap = new Map();
        errors.forEach((ele) => {
            ele?.err?.forEach((text) => {
                const key = text?.msg;
                if (errorMap.has(key)) {
                    if (ele?.rowNumber) {
                        errorMap.get(key).push(ele?.rowNumber);
                    }
                } else {
                    errorMap.set(key, ele?.rowNumber ? [ele?.rowNumber] : []);
                }
            });
        });
        return Array.from(errorMap.entries()).map(([msg, rows]) => ({
            msg,
            rows
        }));
    };
    return (
        <>
            <tbody>
                {templateStatusData.map((item) => {
                    const templateId = item.templateId;
                    const updatedOn = formatDate(item.updatedOn);
                    const updatedBy = item.updatedBy || "N/A";
                    const status = item.status;
                    // Ensure that the menu count is retrieved after templateId and menuCounts are initialized
                    const menuCount = menuCounts[templateId]; // Get the menu count for each templateId
                    {
                    }
                    return (
                        <tr key={templateId}>
                            <td className="fontSize14 p-3">
                                {status === "update_Failed" || status === "add_Failed" ? (
                                    <button
                                        onClick={() => handleInforToggle()}
                                        className="button-tooltip mx-2">
                                        <img src={errorIconTemp} alt="error" />
                                    </button>
                                ) : status === "update_Passed" || status === "add_Passed" ? (
                                    <button className="button-tooltip mx-2 buttonUi">
                                        <img src={checkIcon} alt="success" />
                                    </button>
                                ) : (
                                    <button  onClick={() => window.location.reload()}  className="button-tooltip btnTable mx-2">
                                      <img src={info} alt="info" title="Click here to see the status of update"/>
                                    </button>
                                  )}
                                  
                                {templateId}
                            </td>
                            <td className="tableHeadings p-3">
                                {menuCount !== null && (
                                    <button
                                        onClick={
                                            menuCount !== 0
                                                ? () => handleInfoForBreadCrumbs(templateId)
                                                : null
                                        } // Only trigger if menuCount is not 0
                                        className={`button-tooltip btnTable btnTemplate mx-2 ${
                                            menuCount === 0 ? "no-underline no-pointer" : ""
                                        }`}>
                                        {menuCount} {/* Render menuCount (length) */}
                                    </button>
                                )}
                            </td>
                            <td className="tableHeadings p-3">{updatedOn}</td>
                            <td className="tableHeadings p-3">{updatedBy}</td>
                            <td className="tableHeadings">
                                <button
                                    className="btnTable b-0 bg-0"
                                    onClick={() => handleDownload(templateId)}>
                                    <img className="iconChanges" src={Download} alt="Download" />
                                </button>
                            </td>
                            <td className="tableHeadings">
                                <button
                                    className="btnTable b-0 bg-0"
                                    onClick={() => updatePopup(templateId)}>
                                    <img className="iconChanges" src={Edit} alt="Edit" />
                                </button>
                            </td>
                            <td className="tableHeadings">
                                <button
                                    className="btnTable b-0 bg-0"
                                    onClick={() => confirmPopup(templateId)}>
                                    <img className="iconChanges" src={Delete} alt="Delete" />
                                </button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
            <Modal
                show={showBreadCrumbsModal}
                centered
                onHide={() => setShowBreadCrumbsModal(false)}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="infoHeader">Navigation Paths For {templateId}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="infoPopup">
                    <div className="infoPopup-body">
                        <div className="justify-content-start px-2 py-2">
                            {menuCompletePath && (
                                <div>
                                    <ul className="ms-2">
                                        {menuCompletePath.split(",").map((item, index) => (
                                            <li key={index}>{item.trim()}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button
                            className="btn primary-btn  m-2"
                            onClick={() => setShowBreadCrumbsModal(false)} // Close modal
                        >
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={showInfoModal} centered onHide={showInfoModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="infoHeader">Status</Modal.Title>
                </Modal.Header>
                <Modal.Body className="infoPopup">
                    <div className="infoPopup-body">
                        <div className="justify-content-start px-2 py-2">
                            {Array.isArray(errorMessage) && errorMessage.length >= 1 && (
                                <div>
                                    <p>Following row(s) were not able to insert due to error:</p>
                                    <ul>
                                        {formatErrors(errorMessage).map((error, index) => (
                                            <li key={index}>
                                                {error.rows.length > 0 &&
                                                    `Row no: ${error.rows.join(", ")} : `}
                                                {parse(error.msg)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {errorMsg?.length >= 1 && (
                                <div>
                                    <p>Following row(s) were not able to insert due to error:</p>
                                    {errorMsg.map((ele, index) => (
                                        <div key={index}>
                                            {parse(ele.err?.map((text) => text?.msg).join(""))}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button
                            className="btn primary-btn  m-2"
                            onClick={() => showInfoModalClose()}>
                            Ok
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

function ExistingTemplate() {
    const [messageforPopup, setMessageforPopup] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [presgnUrl, setPresgnUrl] = useState("");
    const [showStatus, setShowStatus] = useState("");
    const [templateId, setTemplateId] = useState("");
    const [csvFile, setCsvFile] = useState();
    const [fileValidation, setfileValidation] = useState("");
    const [showLoader, setShowLoader] = useState(true);
    const [fileName, setFileName] = useState("");
    const [statusOfLastImport, setStatusOfLastImport] = useState(null);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorMsg, setErrorMsg] = useState([]);
    const [messageforInfo, setMessageforInfo] = useState("");
    const [rowNumber, setRowNumber] = useState([]);
    const [templateData, setTemplateData] = useState([]);

    // let templateData;
    const dispatch = useDispatch();
    // const { isPending, existingTemplateList } = useSelector((state) => state.existingTemplateList);
    const {
        filteredData,
        currentItems,
        currentPage,
        itemsPerPage,
        sortKey,
        sortOrder,
        handleSort,
        setCurrentPage
    } = useDataReports({ data: templateData, pageName: "existingTemplate" });
    console.log("filteredData", filteredData, "currentItems", currentItems, "currentPage", currentPage, "sortOrder", sortOrder)
    useEffect(() => {
        // dispatch(apis.getManageTemplateList());
        fetchTemplateStatus();
    }, []);
    const fetchTemplateStatus = async () => {
        let templateData = [];
        try {
            const response = await apis.getPageTemplateStatus();
          if(response?.data?.response){
            console.log("response fetchTemplateStatus 1>>>", response);
            templateData = response?.data?.response || [];
            if(templateData){
                setTemplateData(templateData);
                // setShowLoader(false);
            } 
            setShowLoader(false);
          } else {
            setShowLoader(false);
        }
        } catch (error) {
            console.error("Error fetching template status:", error);
            setShowLoader(false);
        }
    };
    const handleDelete = async () => {
        try {
            setShowLoader(true); // Start showing the loader
            setShowModal(false); // Close the modal

            // Proceed with the deletion API call
            const response = await apis.removeExistingTemplateCsv(templateId);
            if (response) {
                console.log("get Template response", response);
                let templateResponse = { JSONData: [] };
                localStorage.setItem("TemplateJSONData", JSON.stringify(templateResponse));
                dispatch({ type: "templateJSONData", payload: templateResponse });
                console.log(
                    "get Template data",
                    JSON.parse(localStorage.getItem("TemplateJSONData"))
                );
                const flatData = await apis.getNavigationFlatData();
                if (flatData) {
                    templateResponse = await buildNestedDataGroupedByTemplateId(flatData);
                    // Ensure the data is in the correct format
                    const convertedData = { JSONData: templateResponse };
                    localStorage.setItem("TemplateJSONData", JSON.stringify(convertedData));
                    dispatch({ type: "templateJSONData", payload: templateResponse.JSONData });
                }
                // console.log("flatData >>>", flatData.message)
                fetchTemplateStatus();
                setShowModal(false);
            }
        } catch (error) {
            console.log("error inside delete catch", error);
            setMessageforPopup("An error occurred during deletion.");
            setShowModal(true);
            console.error(error);
        } finally {
            setShowLoader(false); // Stop showing the loader once the process is complete
        }
    };

    const setPresignedUrl = async () => {
        let result;
        result = await apis.updateTemplate(fileName);
        // localStorage.removeItem("NavMenuData");
        let res = result?.data?.presigned_url;
        return res;
    };
    const getStatusOfLastImport = async () => {
        try {
            const response = await apis.getStatusOfFile();

            if (response?.status == 200) {
                const templateData = response?.data?.statusOfImportCsv;

                const filteredData = templateData.filter(
                    (ele) => ele?.ImportType === "pageTemplateCsvUploads"
                );
                const finalData =
                    filteredData.length > 0 ? filteredData[filteredData.length - 1] : null;

                if (finalData.responseMessage.statusCode == 400) {
                    const {
                        body: { error }
                    } = finalData.responseMessage;
                    setErrorMessage(error);
                }
                if (finalData.status === "add_Passed" || finalData.status === "update_Passed") {
                    let response = { JSONData: [] };
                    localStorage.setItem("TemplateJSONData", JSON.stringify(response));
                    dispatch({ type: "templateJSONData", payload: response });
                    const flatData = await apis.getNavigationFlatData();
                    if (flatData) {
                        // console.log("flatData >>>", flatData.message)
                        response = await buildNestedDataGroupedByTemplateId(flatData);
                        // Ensure the data is in the correct format
                        const convertedData = { JSONData: response };
                        localStorage.setItem("TemplateJSONData", JSON.stringify(convertedData));
                        dispatch({ type: "templateJSONData", payload: response.JSONData });
                    }
                }
                setStatusOfLastImport(finalData);
            }
            // Update the state with the status message
        } catch (error) {
            console.error("Error fetching status:", error);
            setStatusOfLastImport(null);
        }
    };
    // const handleInforToggle = () => {
    //     let rowNumber = [];
    //     // let msg = "";
    //     let apiError = false;
    //     console.log("hi");
    //     let templateError = [];
    //     console.log("templateError", templateError);
    //     if (Array.isArray(errorMessage)) {
    //         errorMessage?.forEach((ele) => {
    //             if (Array.isArray(ele)) {
    //                 let rowError = {};
    //                 rowError.rowNumber = ele.rowNumber;
    //                 rowError.err = ele.err;
    //                 apiError = false;
    //                 templateError.push(rowError);
    //             }
    //             setErrorMsg(templateError);
    //         });
    //     } else {
    //         let rowError = {};
    //         rowError.rowNumber = 0;
    //         rowError.err = [{ msg: errorMessage, colName: "" }];
    //         templateError.push(rowError);
    //         console.log(templateError, "templateError");
    //         setErrorMsg(templateError);
    //     }
    //     if (rowNumber.length > 0) {
    //         let Text = "";
    //         if (apiError) showInfoModalOpen(rowNumber);
    //         else showInfoModalOpen(Text);
    //     } else {
    //         showInfoModalOpen();
    //     }
    //     setRowNumber(rowNumber);
    // };
    const formatErrors = (errors) => {
        const errorMap = new Map();
        errors.forEach((ele) => {
            ele?.err?.forEach((text) => {
                const key = text?.msg;
                if (errorMap.has(key)) {
                    if (ele?.rowNumber) {
                        errorMap.get(key).push(ele?.rowNumber);
                    }
                } else {
                    errorMap.set(key, ele?.rowNumber ? [ele?.rowNumber] : []);
                }
            });
        });
        return Array.from(errorMap.entries()).map(([msg, rows]) => ({
            msg,
            rows
        }));
    };
    const fetchData = async (presignedResult) => {
        var requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "text/csv" },
            body: csvFile,
            redirect: "follow"
        };
        fetch(presignedResult, requestOptions)
            .then((response) => {
                if (response?.url) {
                    setShowLoader(false);
                }
                return response.text();
            })
            .catch((error) => {
                console.error(error);
                setShowLoader(false);
            });
        setTimeout(() => {}, 2000);
        // setShowFileUploadStatus(true);
    };

    const reload = () => {
        window.location.reload();
        // window.location.href='/managetemplate?existingTemplates=true';
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCsvFile(null);
        setfileValidation(null);
        reload();
    };
    useEffect(() => {
        getStatusOfLastImport();
    }, []);
    // Fetch template status data

    const showInfoModalOpen = (msg) => {
        setShowInfoModal(true);
        setMessageforInfo(msg);
    };
    const showInfoModalClose = () => {
        setShowInfoModal(false);
        setMessageforInfo("");
    };

    const handleUpdate = async () => {
        // e.preventDefault();
        setShowLoader(true);
        setfileValidation("");
        setCsvFile(null);
        setShowModal(false);
        try {
            const presignedResult = await setPresignedUrl();

            if (presignedResult) {
                if (showStatus === "upload") {
                    setMessageforPopup(
                        <>
                            Thank you for uploading, the update will start soon!
                            <br />
                            Please refresh to see the changes, if it does not auto refresh.
                        </>
                    );
                    setShowModal(true);
                } else {
                    setMessageforPopup("File uploaded already.");
                    setShowModal(true);
                }
                setShowStatus(true);
            } else {
                setMessageforPopup("Failed to upload file!");
                setShowModal(true);
            }
            fetchData(presignedResult);
            fetchTemplateStatus();
        } catch (error) {
            console.log("error while fetching presignedURL for page Template", error);
        }
    };

    const handleChange = (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const fileExtension = file?.name.split(".").pop().toLowerCase();

            // Specific filename pattern
            const filenamePattern = /^Template_\d+$/;

            if (fileExtension === "csv") {
                const filenameWithoutExtension = file.name.replace(/\.csv$/i, "");
                if (filenamePattern.test(filenameWithoutExtension)) {
                    if (filenameWithoutExtension === `${templateId}`) {
                        // Valid file name matching the template ID
                        setCsvFile(file);
                        setfileValidation("");
                        setFileName(filenameWithoutExtension);
                    } else {
                        // Filename doesn't match the template ID
                        setCsvFile(null);
                        setfileValidation("File name does not match the current TemplateId.");
                    }
                } else {
                    // Filename doesn't match the pattern
                    setCsvFile(null);
                    setfileValidation("Invalid file name.");
                }
            } else {
                // Invalid file extension
                setCsvFile(null);
                setfileValidation("Only .csv files are allowed.");
            }
        }
    };

    const handleCloseModalPopup = () => {
        setShowModal(false);
        // reload();
    };
    // const formatDate = (dateString) => {
    //     if (!dateString) {
    //         return "No Date";
    //     }

    //     const date = new Date(dateString);

    //     if (isNaN(date.getTime())) {
    //         return "Invalid Date";
    //     }

    //     const options = { year: "numeric", month: "short", day: "numeric" };
    //     const formattedDate = date.toLocaleDateString("en-US", options);

    //     // Adding "th", "st", "nd", "rd" to the day
    //     const day = date.getDate();
    //     const daySuffix =
    //         day % 10 === 1 && day !== 11
    //             ? "st"
    //             : day % 10 === 2 && day !== 12
    //             ? "nd"
    //             : day % 10 === 3 && day !== 13
    //             ? "rd"
    //             : "th";

    //     return formattedDate.replace(/(\d+)(?=,)/, `${day}${daySuffix}`);
    // };
    const totalItems = currentItems.length;
    const itemsPerTable = Math.ceil(totalItems / 2); // Calculate items per table

    const firstTableItems = currentItems.slice(0, itemsPerTable);
    const secondTableItems = currentItems.slice(itemsPerTable, itemsPerTable * 2);
    // const tableHeader1 = _tableHeaderTemplateManagement1;
    // const tableHeader2 = _tableHeaderTemplateManagement2;

    return (
        <>
            {showLoader ? (
  <Loader />
)  : (
                <div className="existingTemplates">
                    <div className="learnerReports">
                        <div className=" my-2 statusUI">                            
                        </div>                                                               
                                {/* {currentItems?.length>0 &&  */}
                        <div className="learnerReportsTables">
                            <div>
                                <DataTable
                                    currentItems={currentItems}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    filteredData={filteredData}
                                    itemsPerPage={itemsPerPage}
                                    flag={false}
                                    noResultsMessage="No Templates Found. Please add through New Template Tab."
                                    className="tableHeading"> 
                                    <TableHeading
                                        sortKey={sortKey}
                                        sortOrder={sortOrder}
                                        columns={_tableHeaderTemplateManagement}
                                        handleSort={handleSort}
                                    />
                                    <TemplateManagementCellContent
                                        data={currentItems}
                                        setMessageforPopup={setMessageforPopup}
                                        setShowModal={setShowModal}
                                        setPresgnUrl={setPresgnUrl}
                                        setShowStatus={setShowStatus}
                                        setTemplateId={setTemplateId}
                                        setShowLoader={setShowLoader}
                                        presgnUrl={presgnUrl}
                                    />
                                </DataTable>
                            </div>
                        </div>
                        {/* } */}
                    </div>
                </div>
            )}
            <Scroller />
            <Modal show={showInfoModal} centered onHide={showInfoModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="infoHeader">Status</Modal.Title>
                </Modal.Header>
                <Modal.Body className="infoPopup">
                    <div className="infoPopup-body">
                        <div className="justify-content-start px-2 py-2">
                            {Array.isArray(errorMessage) && errorMessage.length >= 1 && (
                                <div>
                                    <p>Following row(s) were not able to insert due to error:</p>
                                    <ul>
                                        {formatErrors(errorMessage).map((error, index) => (
                                            <li key={index}>
                                                {error.rows.length > 0 &&
                                                    `Row no: ${error.rows.join(", ")} : `}
                                                {parse(error.msg)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {errorMsg?.length >= 1 && (
                                <div>
                                    <p>Following row(s) were not able to insert due to error:</p>
                                    {errorMsg.map((ele, index) => (
                                        <div key={index}>
                                            {parse(ele.err?.map((text) => text?.msg).join(""))}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button
                            className="btn primary-btn  m-2"
                            onClick={() => showInfoModalClose()}>
                            Ok
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            {showStatus === "delete" ? (
                <ModalforSuccessAndFailure
                    handleDelete={handleDelete}
                    confirmButton
                    warningPopup={messageforPopup}
                    show={showModal}
                    onClose={() => setShowModal(false)}
                />
            ) : showStatus === "upload" ? (
                <ModalforSuccessAndFailure
                    handleChange={handleChange}
                    handleUpdate={handleUpdate}
                    updateTemplate
                    show={showModal}
                    onClose={handleCloseModal}
                    fileValidation={fileValidation}
                    csvFile={csvFile}
                />
            ) : (
                <ModalforSuccessAndFailure
                    messageforPopup={messageforPopup}
                    show={showModal}
                    // onClose={() => reload()}
                    onClose={handleCloseModalPopup}
                />
            )}
        </>
    );
}

export default ExistingTemplate;
