/* eslint-disable no-undef */
/* eslint-disable linebreak-style */
import React from "react";
import { useEffect } from "react";
import { apis } from "../../services/Api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Recommendation.css";
import CarouselBlock from "../../common/slider/slider";
import Loader from "../../common/Loader/Loader";
import {useNavigate} from "react-router-dom";
// import login_bg from "../../assets/images/login_bg.png";

// eslint-disable-next-line no-unused-vars
export default function Recommendation(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let [recLoDetails, setRecLoDetails] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const homePageMyLearning=true;

    let myRecommendationIncluded = []; 
    let myRecommendationData = [];
    let filteredRecommendation =[];
    async function fetchMyRecommendation(cursor = "") {
      
        let response = null;
        if(cursor != "") {
            response  = await apis.getRecommendations(cursor);
        }
        else {
            response = await apis.getRecommendations();
        }

        if (response) {
            if(response.data?.length > 0) {
                for (const data of response.data) {
                    myRecommendationData.push(data);
                }
            }
            if(response?.included?.length > 0){
                for (const included of response.included) {
                    myRecommendationIncluded.push(included);
                    filteredRecommendation.push(included);
                }
                //const filteredIncludedArr = myRecommendationIncluded.filter((e) => !e?.attributes?.localizedMetadata[0]?.name?.toUpperCase()?.includes("DNS"));
                
                //filteredRecommendation = [...filteredRecommendation, ...filteredIncludedArr];
            }
            if(response?.links?.next) {
                const urlParams = new URLSearchParams(response.links.next);
                const nextCursor = urlParams.get("page[cursor]");
                
                if( filteredRecommendation.length < 20) {
                    await fetchMyRecommendation(nextCursor);
                }
            }
        }
    }

    //This function gets the details for recommended courses
    async function getRecommendedLo() {
        setShowLoader(true);
        myRecommendationIncluded = []; 
        myRecommendationData = [];
        filteredRecommendation = [];
        await fetchMyRecommendation();
        let recLoContent = [];
        for (let j = 0; j < filteredRecommendation.length; j++) {
            const result=  await apis.getEnrollmentCount(filteredRecommendation[j]?.id,filteredRecommendation[j]?.relationships?.instances?.data[0]?.id)
            const enrollmentCount = result?.data?.attributes?.enrollmentCount
            recLoContent.push({
                id: filteredRecommendation[j]?.id,
                imageUrl: filteredRecommendation[j]?.attributes?.imageUrl,
                name: filteredRecommendation[j]?.attributes?.localizedMetadata[0]?.name,
                description: filteredRecommendation[j]?.attributes?.localizedMetadata[0]?.description,
                Author: "",
                duration: filteredRecommendation[j]?.attributes?.duration,
                averageRating: filteredRecommendation[j]?.attributes?.rating?.averageRating,
                ratingsCount: filteredRecommendation[j]?.attributes?.rating?.ratingsCount || 0,
                instancesId: filteredRecommendation[j]?.relationships?.instances?.data[0]?.id,
                enrollmentCount: enrollmentCount,             
            });
        }
        setRecLoDetails(recLoContent);
        setShowLoader(false);
        myRecommendationIncluded = []; 
        myRecommendationData = [];
        filteredRecommendation = [];
    }
    
    const initApiCall = async () => {
        try{
            await getRecommendedLo();
        }catch (error) {
            console.warn(error);
        }
    }

    useEffect(() => {
        initApiCall();
    }, []);

    const playCourse = (cid, mid) => {
        navigate("/course-player", {
            state: { cid, mid, goBackUrl: location?.pathname },
        });
        // setLoDetails();
    };
    const recommendationNavigate = ()=>{
        navigate("/allcourses")
    }
    return (
        <React.Fragment>
            <div className="Carousel-block row">
                <div className="title-block d-flex justify-content-between align-items-center mt-3">
                    <span className="title main-head"> {t("recommendations-based-on-peer-activity-label")}</span>
                    <span className=""><button className="btn btn-link btn-view" onClick={recommendationNavigate}>{t("view-all")}</button></span>
                </div>
            </div>        
            <div className="Carousel-block row recommendation px-5">
                   
                <div style={{ position: "relative", padding:0 }}>
                    {showLoader ? (
                        <Loader  />
                    ) : (
                        <CarouselBlock
                            skills={props.skills}
                            myLearningObj={recLoDetails}
                            playCourse={playCourse}
                            componentName="Recommendation"
                            homePageMyLearning={homePageMyLearning}
                        />
                    )}
                </div>
            </div> 
        </React.Fragment>
    );
}
